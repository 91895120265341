import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
  Table,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  // capitalize,
  deepClone,
  formatCurrencyValue,
  // formatDateHideYearIfSame,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  formatTime,
  openGoogleMapOnNewTab,
  showToast,
  formatDateMoment,
  formatDateAsPerTimeZoneWithDateAndTime,
  errorHandler,
} from "../../../helper-methods";
import {
  getAgentClosingBidDetail,
  agentAcceptOrRejectBidding,
  agentCounterBidding,
  guestAgentBiddingDetail,
  guestAgentCounterBidding,
  guestAgentAcceptOrRejectBidding,
  checkAgentGuestUser,
  agentAcceptOrRejectBiddingNew,
  agentCounterBiddingNew,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import TextEditor from "../components/TextEdittor";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";

class NewClosingAgentPublic extends React.Component {
  state = {
    closingDetails: null,
    loading: false,
    isOpenCounterForm: false,
    agentId: "",
    counterForm: {
      counterAmount: {
        value: "",
        isDirty: false,
        error: null,
      },
      appointmentTime: {
        value: "",
        isDirty: false,
        error: null,
      },
    },
  };

  _redirectToSignup = (e) => {
    if (e) e.preventDefault();
    // const { userData } = this.props;
    const parts = this.props.match.params.id.split("&");

    let agentId = null;

    for (const part of parts) {
      const [key, value] = part.split("=");
      if (key === "agentid") {
        agentId = value;
      }
    }

    this.props.history.push(`/nna-signup/${agentId}`);
  };

  componentDidMount = async () => {
    console.log("1111", this.props);

    try {
      const id = this.props.match.params.id.replace("guestuser=true", "");
      // console.log("id",id)
      // const id=this.props.match.params.id.match(/cameVia=\/agent\/new-closing\/([a-zA-Z0-9]+)/);
      this.props.showLoader("Fetching Closing Details...");
      // console.log("gues", this._checkIsGuestUser(this.props.match.params.id));
      // if (!this._checkIsGuestUser(this.props.match.params.id)) {
      await this._getAgentClosingBidDetail(id);
      // } else {
      //   await this._guestAgentBiddingDetail();
      // }
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToSignup();
    }
  };

  _extractUrl = (url) => {
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const cameViaValue = params.get("cameVia");
    const remainingParams = queryString.replace(`cameVia=${cameViaValue}&`, "");
    return cameViaValue + "&" + remainingParams;
    // console.log(cameViaValue + "&" + remainingParams);
  };
  _extractAgentId(url) {
    const match = url.match(/[?&]agentid=([^&]+)/);
    return match && match[1];
  }

  _checkAgentGuestUser = async () => {
    let agentId = this._extractAgentId(this.props.history.location.search);
    try {
      let response = await checkAgentGuestUser(agentId);
      if (response?.isGuest) {
        console.log("age", agentId);
        // historyWithForceRefresh.push(`/nna-signup/${agentId}`);
        // historyWithForceRefresh.push("/nna-signup/64dca1d2d811be8c4229068c");
      } else {
        this.props.history.push("/login");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // _checkIsGuestUser = (str) => {
  //   const parts = str.split("&");

  //   let isGuestUser = false;

  //   for (const part of parts) {
  //     if (part.toLowerCase() === "guestuser=true") {
  //       isGuestUser = true;
  //       break;
  //     }
  //   }

  //   if (isGuestUser) {
  //     return true;
  //     // console.log('Guest user is true');
  //   } else {
  //     return false;
  //     // console.log('Guest user is false');
  //   }
  // };
  _getAgentClosingBidDetail = (id = null) => {
    const { userData } = this.props;

    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }
    // if (id!==null&&id.includes("&")) {
    //   id = id.split("&")[0];
    // }
    const parts = this.props.match.params.id.split("&");

    let agentId = null;

    for (const part of parts) {
      const [key, value] = part.split("=");
      if (key === "agentid") {
        agentId = value;
      }
    }
    const payload = {
      closingId: parts[0],
      agentId,
    };
    return new Promise((resolve, reject) => {
      getAgentClosingBidDetail(payload)
        .then((res) => {
          this.setState(
            {
              closingDetails: res.closing,
              agentId,
            },
            () => {
              HeaderEventEmitter.dispatch("closing-details", {
                closingDetails: res.closing,
              });
            }
          );
          if (res?.closing?._agent !== undefined) {
            showToast("Job no longer available", "error");
            this.props.history.push(`/${userData.type}/dashboard`);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // _guestAgentBiddingDetail = () => {
  //   const { userData } = this.props;
  //   return new Promise((resolve, reject) => {
  //     const parts = this.props.match.params.id.split("&");

  //     let closingId = parts[0];
  //     let agentId = null;

  //     for (const part of parts) {
  //       const [key, value] = part.split("=");
  //       if (key === "agentid") {
  //         agentId = value;
  //       }
  //     }
  //     let payload = {
  //       closingId,
  //       agentId,
  //     };
  //     guestAgentBiddingDetail(payload)
  //       .then((res) => {
  //         this.setState(
  //           {
  //             closingDetails: res.closing,
  //           },
  //           () => {
  //             HeaderEventEmitter.dispatch("closing-details", {
  //               closingDetails: res.closing,
  //             });
  //           }
  //         );

  //         if (!res?.isGuest) {
  //           this.props.history.push(this.props.history.location?.state?.from);
  //         }

  //         if (res?.closing?._agent !== undefined) {
  //           showToast("Job no longer available", "error");
  //           this.props.history.push(`/${userData.type}/dashboard`);
  //         }
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData?.type}/dashboard`);
  };

  _agentAcceptOrRejectBidding = async (status) => {
    try {
      this.setState({ loading: true });

      const payload = {
        status,
        closingId: this.state.closingDetails?.id,
        agentId: this.state.agentId,
      };

      if (this.state.closingDetails?.isAgentWebsiteClosing) {
        await agentAcceptOrRejectBiddingNew(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToDashboard();
      } else if (this.state.closingDetails?.isGuest) {
        await guestAgentAcceptOrRejectBidding(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToSignup();
      } else {
        await agentAcceptOrRejectBidding(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToDashboard();
      }
    } catch (err) {
      this.setState({ loading: false });
      errorHandler(err);
    }
  };

  // _guestAgentAcceptOrRejectBidding = () => {
  //   guestAgentAcceptOrRejectBidding(payload)
  //     .then((res) => {
  //       this.setState({ loading: false });
  //       showToast(`Order ${status}`, "success");
  //       this._redirectToSignup();
  //     })
  //     .catch((error) => {
  //       this.setState({ loading: false });
  //       showToast(
  //         error && error.reason && error.reason.length
  //           ? error.reason
  //           : "Server error. Try again after sometime.",
  //         "error"
  //       );
  //     });
  // };

  _validatecounterFormField = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;

      let isFormValid = true;

      Object.keys(counterForm).forEach((key) => {
        if (counterForm[key].isDirty) {
          switch (key) {
            case "appointmentTime": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                counterForm[key].isDirty = false;
                counterForm[key].error = null;
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "counterAmount": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                if (
                  isNaN(counterForm[key].value) ||
                  Number(counterForm[key].value) < 1
                ) {
                  counterForm[key].isDirty = true;
                  counterForm[key].error = "*Invalid Fee";
                  isFormValid = false;
                } else {
                  counterForm[key].isDirty = false;
                  counterForm[key].error = null;
                }
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ counterForm }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeCounterFormField = (fieldName, value) => {
    const { counterForm } = this.state;
    if (fieldName === "counterAmount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }
    if (fieldName === "appointmentTime") {
      counterForm[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      counterForm[fieldName].value = value;
    }
    counterForm[fieldName].isDirty = true;
    this.setState({ counterForm }, () => {
      // Validation
      this._validatecounterFormField();
    });
  };

  _markAllCounterFormFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;
      Object.keys(counterForm).forEach((key) => {
        counterForm[key].isDirty = true;
      });
      this.setState({ counterForm }, () => resolve(true));
    });
  };

  _onSubmitCounterOffer = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllCounterFormFieldDirty();

      const isFormValid = await this._validatecounterFormField();

      if (isFormValid) {
        this.setState({ loading: true });

        const { counterForm, closingDetails, agentId } = deepClone(this.state);
        // const parts = this.props.match.params.id.split("&");

        // let agentId = null;

        // for (const part of parts) {
        //   const [key, value] = part.split("=");
        //   if (key === "agentid") {
        //     agentId = value;
        //   }
        // }

        let payload = {
          closingId: closingDetails?.id,
          counterAmount: Number(counterForm.counterAmount.value.trim()),
          appointmentTime: formatDateMoment(counterForm.appointmentTime.value),
          agentId,

          // appointmentTime: formatDateMoment(
          //   `${closingDetails.appointmentDate.split("T")[0]}T${
          //     counterForm.appointmentTime.value.split("T")[1]
          //   }`
          // ),
          // appointmentTime: `${closingDetails.appointmentDate.split("T")[0]}T${
          //   counterForm.appointmentTime.value.split("T")[1]
          // }`,
        };

        // await guestAgentCounterBidding(payload);
        if (closingDetails?.isAgentWebsiteClosing) {
          await agentCounterBiddingNew(payload);
          this.setState({ loading: false });
          // showToast(`Order ${status}`, "success");
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToDashboard();
        } else if (closingDetails?.isGuest) {
          await guestAgentCounterBidding(payload);
          this.setState({ loading: false });
          // showToast(`Order ${status}`, "success");
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToSignup();
        } else {
          await agentCounterBidding(payload);
          this.setState({ loading: false });
          // showToast(`Order ${status}`, "success");
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToDashboard();
        }
        // this.setState({ loading: false });
        // showToast(`Order counter offer has been sent.`, "success");
        // this._redirectToSignup();
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _setCounterFormField = () => {
    const { counterForm, closingDetails } = deepClone(this.state);

    counterForm.counterAmount.value =
      closingDetails?.biddingAmount?.toString() || "";
    // counterForm.appointmentTime.value = closingDetails?.appointmentDate || "";
    counterForm.appointmentTime.value = closingDetails?.appointmentDate
      ? formatDateAsPerTimeZoneWithDateAndTime(
          closingDetails.appointmentDate,
          closingDetails.closingAddress.timeZone
        )
      : "";
    Object.keys(counterForm).forEach((key) => {
      counterForm[key].isDirty = false;
      counterForm[key].error = null;
    });
    this.setState({ counterForm });
  };

  _toggleCounterFormCard = () => {
    if (!this.state.isOpenCounterForm) {
      this._setCounterFormField();
    }
    this.setState({ isOpenCounterForm: !this.state.isOpenCounterForm });
  };

  render() {
    const { closingDetails, loading, counterForm, isOpenCounterForm } =
      this.state;
    // console.log(this.state);
    return (
      <div className="p-3">
        <div className="publicPageTitle">
          <h2>
            New Signing Offer
            {closingDetails && (
              <span>Order No #{closingDetails?.closingNumber || "N/A"}</span>
            )}
          </h2>
        </div>

        {closingDetails && (
          <Row>
            {/* <Col md="12">
              <div className="d-flex justify-content-between align-items-end mb-3 mt-n2">
                <h5 className="mb-0 fs-18 themeColor">
                  Order #{closingDetails.closingNumber || "N/A"}
                </h5>
              </div>
            </Col> */}

            <Col sm="12" lg="6">
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Order Details</h2>
                </div>
                <div className="CustomTableWrapper hideMobile">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Appointment Date</th>
                        <th>Address</th>
                        <th>Fees Offered</th>
                        <th>Product type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {closingDetails.appointmentDate ? (
                            <>
                              <div>
                                <DateTimeShowWithClosingDetails
                                  closingDetails={closingDetails}
                                />
                                {" | "}
                                <span>
                                  {getTimeZoneAbbr(
                                    closingDetails.appointmentDate,
                                    closingDetails.closingAddress.timeZone
                                  )}
                                </span>
                              </div>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          <span
                            style={{
                              fontWeight: 600,
                              width: 150,
                              display: "block",
                            }}
                          >
                            {closingDetails?.closingAddress ? (
                              <>
                                {closingDetails?.closingAddress?.line1
                                  ? `${closingDetails?.closingAddress?.line1}, `
                                  : ""}
                                {closingDetails?.closingAddress?.line2
                                  ? `${closingDetails?.closingAddress?.line2}, `
                                  : ""}
                                {closingDetails?.closingAddress?.city
                                  ? `${closingDetails?.closingAddress?.city}, `
                                  : ""}
                                {closingDetails?.closingAddress?.state
                                  ? `${closingDetails?.closingAddress?.state}, `
                                  : ""}
                                {closingDetails?.closingAddress?.zip
                                  ? `${closingDetails?.closingAddress?.zip}`
                                  : ""}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </span>

                          {closingDetails.closingAddress ? (
                            <Button
                              color="link"
                              // className="showMap"
                              onClick={() =>
                                openGoogleMapOnNewTab(
                                  closingDetails.closingAddress
                                )
                              }
                            >
                              <img
                                className="mr-1"
                                src={
                                  require("../../../assets/img/map-marker.svg")
                                    .default
                                }
                                alt="final"
                              />
                              Map
                            </Button>
                          ) : null}
                        </td>
                        <td>
                          {closingDetails.biddingAmount
                            ? formatCurrencyValue(closingDetails.biddingAmount)
                            : "N/A"}
                        </td>
                        <td>
                          {closingDetails.loanType &&
                          closingDetails.loanType.length > 0 ? (
                            closingDetails.loanType.indexOf("Other") > -1 ? (
                              <span style={{ marginRight: 6 }}>
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}
                          {closingDetails.loanType
                            .filter((each) => each !== "Other")
                            .map((item) => (
                              <>
                                <span style={{ marginRight: 6 }}>
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              </>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label style={{ fontSize: 12 }}>
                          Appointment Date:{" "}
                        </Label>
                        <div style={{ fontWeight: 600, fontSize: 12 }}>
                          {closingDetails?.appointmentDate ? (
                            <>
                              {/* {formatDateHideYearIfSame(
                                closingDetails?.appointmentDate
                              )}
                              <br />
                              {formatTime(closingDetails?.appointmentDate)} */}
                              {/* </> */}
                              <>
                                {/* {formatDateAsPerTimeZOne(
                                  closingDetails?.appointmentDate,
                                  closingDetails?.closingAddress?.timeZone
                                )} */}
                                <DateTimeShowWithClosingDetails
                                  closingDetails={closingDetails}
                                />
                                {" | "}
                                <span style={{ fontWeight: 400 }}>
                                  {getTimeZoneAbbr(
                                    closingDetails?.appointmentDate,
                                    closingDetails?.closingAddress?.timeZone
                                  )}
                                </span>
                              </>
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label style={{ fontSize: 12 }}>Address </Label>
                        <div style={{ fontWeight: 600, fontSize: 12 }}>
                          {closingDetails?.closingAddress ? (
                            <>
                              {closingDetails?.closingAddress?.line1
                                ? `${closingDetails?.closingAddress?.line1}, `
                                : ""}
                              {closingDetails?.closingAddress?.line2
                                ? `${closingDetails?.closingAddress?.line2}, `
                                : ""}
                              {closingDetails?.closingAddress?.city
                                ? `${closingDetails?.closingAddress?.city}, `
                                : ""}
                              {closingDetails?.closingAddress?.state
                                ? `${closingDetails?.closingAddress?.state}, `
                                : ""}
                              {closingDetails?.closingAddress?.zip
                                ? `${closingDetails?.closingAddress?.zip}`
                                : ""}
                            </>
                          ) : (
                            "N/A"
                          )}

                          {closingDetails?.closingAddress ? (
                            <Button
                              color="link"
                              // className="showMap"
                              onClick={() =>
                                openGoogleMapOnNewTab(
                                  closingDetails?.closingAddress
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/map-marker.svg")
                                    .default
                                }
                                alt="final"
                              />
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <Label style={{ fontSize: 12 }}>Fees Offered: </Label>
                        <div style={{ fontSize: 12, fontWeight: 600 }}>
                          {closingDetails?.biddingAmount
                            ? formatCurrencyValue(closingDetails?.biddingAmount)
                            : "N/A"}
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label style={{ fontSize: 12 }}>Product Type: </Label>
                        <div style={{ fontWeight: 600, fontSize: 12 }}>
                          {closingDetails?.loanType &&
                          closingDetails?.loanType.length > 0 ? (
                            closingDetails?.loanType.indexOf("Other") > -1 ? (
                              <span style={{ marginRight: 6 }}>
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}
                          {closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            ?.map((item) => (
                              <>
                                <span style={{ marginRight: 6 }}>
                                  {item?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              </>
                            ))}
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </div>
            </Col>

            {/* <Col sm="12" lg="6">
              <Card className="normalTableWrap">
                <CardHeader>
                  <CardTitle tag="h6">Instructions</CardTitle>
                </CardHeader>
                <CardBody className="p-3">
                  {closingDetails.instructions ? (
                    <p>{closingDetails.instructions}</p>
                  ) : (
                    "N/A"
                  )}
                </CardBody>
              </Card>
            </Col> */}

            <Col sm="12" lg="6">
              <div className="CardWrapper mt-3 mt-xl-0">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Signing Instructions</h2>
                </div>
                <Card>
                  <CardHeader>
                    <CustomInput
                      type="checkbox"
                      id="isScanBackNeeded"
                      checked={closingDetails.isScanBackNeeded}
                      label="Scan Backs Needed"
                      disabled={true}
                    />
                  </CardHeader>
                  <CardBody>
                    {closingDetails.bidInstructions ? (
                      // <p>
                      //   {closingDetails.bidInstructions.replace(
                      //     /(<([^>]+)>)/gi,
                      //     ""
                      //   )}
                      // </p>
                      <div style={{ pointerEvents: "none" }}>
                        <TextEditor
                          disabled
                          content={closingDetails.bidInstructions}
                          onChange={() => {}} // caution: don't remove
                        />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </CardBody>
                </Card>
              </div>
            </Col>

            <Col md="12">
              {closingDetails?._mainForwardAgent?.toString() !==
                this.state.agentId.toString() && (
                <div className="signingOfferButton mt-4">
                  <Button
                    color="primary"
                    outline
                    size="lg"
                    onClick={() => this._agentAcceptOrRejectBidding("Accepted")}
                  >
                    Accept
                  </Button>
                  <Button
                    color="danger"
                    outline
                    size="lg"
                    className="mx-3"
                    onClick={() => this._agentAcceptOrRejectBidding("Rejected")}
                  >
                    Decline
                  </Button>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={this._toggleCounterFormCard}
                  >
                    Counter Offer
                  </Button>
                </div>
              )}

              <Collapse isOpen={isOpenCounterForm}>
                <div className="counterOfferWrap">
                  <Row>
                    <Col md={6}>
                      <FormGroup
                        className={`floatingLabel ${
                          counterForm.appointmentTime.value ||
                          this.state.isOpenReactDatetime
                            ? "valueAdded"
                            : ""
                        }`}
                      >
                        <ReactDatetime
                          inputProps={{
                            className: "form-control",
                            placeholder: " ",
                            value: formatTime(
                              counterForm.appointmentTime.value
                            ),
                          }}
                          value={
                            counterForm.appointmentTime.value
                              ? new Date(counterForm.appointmentTime.value)
                              : ""
                          }
                          onChange={(e) =>
                            this._onChangeCounterFormField(
                              "appointmentTime",
                              e._d
                            )
                          }
                          onOpen={() =>
                            this.setState({ isOpenReactDatetime: true })
                          }
                          onClose={() =>
                            this.setState({ isOpenReactDatetime: false })
                          }
                          dateFormat={false}
                          timeFormat={true}
                          timeConstraints={{ minutes: { step: 15 } }}
                        />
                        <Label>Appointment Time</Label>
                      </FormGroup>
                      {counterForm.appointmentTime.error && (
                        <div className="validation-error">
                          {counterForm.appointmentTime.error}
                        </div>
                      )}
                    </Col>

                    <Col md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/dollar.svg")
                                    .default
                                }
                                height={12}
                                alt="doller"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={counterForm.counterAmount.value}
                            onChange={(e) =>
                              this._onChangeCounterFormField(
                                "counterAmount",
                                e.target.value
                              )
                            }
                          />
                          <Label>Fee</Label>
                        </InputGroup>
                      </FormGroup>
                      {counterForm.counterAmount.error && (
                        <div className="validation-error">
                          {counterForm.counterAmount.error}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <div className="text-center my-3">
                    <Button
                      color="primary"
                      onClick={this._onSubmitCounterOffer}
                    >
                      Submit Offer
                    </Button>
                  </div>
                </div>
              </Collapse>

              {loading && (
                <div className="table-overlay">
                  <div>
                    <i className="fa fa-spinner fa-spin" />
                  </div>
                </div>
              )}
            </Col>
            <Col>
              {closingDetails?._mainForwardAgent?.toString() ===
                this.state.agentId.toString() && (
                <p className="text-center">
                  You have already shared your feedback. Please wait for
                  Client's response.
                </p>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewClosingAgentPublic);
