import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroupItem,
  ListGroup,
  CustomInput,
  UncontrolledTooltip,
  CardBody,
  Card,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  createAgentClosing,
  uploadClosingPdf,
  getClosingDetailsById,
  getAgentClient,
  updateAgentClosingById,
  getRonPlatformTypes,
  FileNumberDuplicateCheck,
  FileNumberDuplicateMatchCheck,
} from "../../../http/http-calls";
import {
  deepClone,
  formatDateAndTime,
  getFileExtension,
  getPhoneNumberFromBrackets,
  showToast,
  sleepTime,
  formatDateMoment,
  isRegularUser,
  timezoneList,
  addUnixTimeToString,
  errorHandler,
  formatDateAsPerTimeZoneWithDateAndTime,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { RegexConfig } from "../../../config/RegexConfig";
import { PostManager } from "../../../post-manager";
import { UploadQueueManager } from "../../../upload-queue-manager";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import usaStates from "../../../config/usa_states_titlecase";
import TextEditor from "../components/TextEdittor";
import AddClientAgentModal from "../components/Modals/add-client-modal-agent";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import Chips from "react-chips";
import FileChecked from "../components/FileChecked";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select";
import { LanguageList, loanTypeConfig } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ReportModal from "../components/report-modal";
import ProductType from "../components/ProductType";
import Select from "react-select/creatable";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";

const animatedComponents = makeAnimated();

class CreateClosing extends React.Component {
  state = {
    formFields: {
      borrower: [
        {
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          phoneHome: {
            value: "",
            error: null,
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        },
      ],
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneHome: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneWork: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneMobile: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneHomeDialCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      phoneWorkDialCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      phoneMobileDialCode: {
        value: "+1",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      loanOfficer: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      borrowerAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      addressLine1: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      addressLine2: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      city: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      county: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      state: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      zip: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      fileNumber: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientId: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      personOfContactName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      agentFee: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      appointmentDate: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      appointmentAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine1: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine2: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressCounty: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressState: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressZip: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      agentNotes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    isBorrowerAddressSameAsAppointment: false,
    uploadFiles: [],
    googleAddressResult: [],
    isAddressListShow: null, // appointmentAddress or borrowerAddress
    manuallyTypeAddress: {
      borrowerAddress: false,
      appointmentAddress: false,
    },
    confirmationFileLoading: false,
    inputLoanType: false,
    loanType: [],
    clients: [],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    reportModalData: {
      isOpen: false,
      data: null,
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    isOpenReactDatetime: false,
    aiParserResponse: {},
    ronPlatformTypeOptions: {},
    loading: {
      submitLoading: false,
    },
    existingFileNos: null,
    keepFileNo: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _resetState = () => {
    return new Promise((resolve) => {
      this.setState(
        {
          formFields: {
            borrower: [
              {
                borrowerName: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                email: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                workPhone: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                phoneHome: {
                  value: "",
                  error: null,
                  isDirty: false,
                  isValidate: true,
                },
                isForeignNational: {
                  value: false,
                  isDirty: false,
                  isValidate: true,
                },
                language: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                languageTypeOther: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                timeZone: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
              },
            ],
            loanOfficer: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
            borrowerAddress: {
              value: "",
              placeId: null,
              error: null,
              isDirty: false,
              isValidate: true,
            },
            addressLine1: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            addressLine2: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
            city: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            county: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            state: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            zip: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            fileNumber: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            clientId: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            titleCompany: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            lender: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            personOfContactName: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            personOfContactEmail: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: true,
            },
            signingType: {
              value: "Mobile",
              isDirty: false,
              isValidate: true,
            },
            ronPlatformType: {
              value: [],
              isDirty: false,
              isValidate: true,
            },
            witnessCount: {
              value: 0,
              isDirty: false,
              isValidate: true,
            },
            loanType: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: true,
            },
            loanTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            loanCategories: {
              value: [],
              error: null,
              isDirty: false,
              isValidate: false,
            },
            agentFee: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            appointmentDate: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            appointmentAddress: {
              value: "",
              placeId: null,
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressLine1: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressLine2: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
            closingAddressCity: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressCounty: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressState: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressZip: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: true,
            },
            closingAddressTimeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            agentNotes: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
            instructions: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
            isScanBackNeeded: {
              value: false,
              isDirty: false,
              isValidate: false,
            },
          },
          isBorrowerAddressSameAsAppointment: false,
          uploadFiles: [],
          googleAddressResult: [],
          isAddressListShow: null, // appointmentAddress or borrowerAddress
          manuallyTypeAddress: {
            borrowerAddress: false,
            appointmentAddress: false,
          },
          confirmationFileLoading: false,
          loading: {
            submitLoading: false,
          },
        },
        resolve()
      );
    });
  };

  componentDidMount = async () => {
    try {
      this._getAgentClient();
      this._getRonPlatformType();
      let LoanType = [
        "Refinance",
        "HomeEquity",
        "AttorneyPhone",
        "Purchase",
        "LoanMod",
        "ReverseMtg",
        "HybridESign",
        "DeedInLieu",
        "SingleDoc",
        "LoanApplication",
        "Other",
      ];

      let options = LoanType.map((item) => ({
        value: item,
        label: item.replace(/([A-Z])/g, " $1").trim(),
      }));
      options.splice(0, 0, { value: "", label: "Select" });
      this.setState({ loanType: options });

      if (this.props.location.search) {
        let id = this.props.location.search.replace(/[^\w\s]/gi, "");
        await this._getClosingDetailsById(id);
      }

      const res = await getRonPlatformTypes();
      console.log({ res });
      const ronOptions = res?.ronPlatformTypes
        ? Object.keys(res?.ronPlatformTypes)?.map((each) => ({
            label: each,
            value: res?.ronPlatformTypes[each],
          }))
        : [];
      console.log({ ronOptions });
      this.setState({
        ronPlatformTypeOptions: ronOptions,
      });
    } catch (error) {
      errorHandler(error);
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this._goBackBtn();
    });
    HeaderEventEmitter.subscribe("closingFile", (event) => {
      this._uploadConfirmationFile(event);
    });
  };
  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray = Object.entries(response.ronPlatformTypes).map(
          ([label, value]) => ({ label, value })
        );
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  _getClosingDetailsById = (id) => {
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this._setForm(res.closing);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _getAgentClient = () => {
    getAgentClient()
      .then((res) => {
        this.setState({ clients: res?.clients || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _setForm = (closingDetail) => {
    const { formFields, ronPlatformTypeOptions } = this.state;
    console.log("first", closingDetail, ronPlatformTypeOptions);
    let borrower = [];
    closingDetail?._borrower?.forEach((each) => {
      borrower.push({
        borrowerName: {
          value: each.name.full || "",
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: each.email || "",
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
          isDirty: false,
          isValidate: true,
        },
        phoneHome: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        isForeignNational: {
          value: each.isForeignNational,
          isDirty: false,
          isValidate: true,
        },
        language: {
          value: each.language,
          isDirty: false,
          isValidate: true,
        },
        languageTypeOther: {
          value: each.languageTypeOther || "",
          isDirty: false,
          isValidate: true,
        },
        timeZone: {
          value: each.timeZone || "",
          isDirty: false,
          isValidate: true,
        },
      });
    });
    formFields["borrower"] = borrower;
    formFields["fileNumber"].value = closingDetail?.fileNumber;
    formFields["lender"].value = closingDetail?.lender || "";

    formFields["personOfContactName"].value =
      closingDetail?.personOfContact?.name || "";
    formFields["personOfContactEmail"].value =
      closingDetail?.personOfContact?.email[0]?.trim().length
        ? closingDetail?.personOfContact?.email
        : [];

    formFields["loanType"].value = closingDetail?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanCategories"].value = closingDetail?.loanCategories || [];

    formFields["ronPlatformType"].value = closingDetail?.ronPlatformTypes.map(
      (each) => ({
        label: each,
        value: each,
      })
    );

    formFields["loanTypeOther"].value = closingDetail?.loanTypeOther || "";

    formFields["agentNotes"].value = closingDetail?.agentNotes || "";
    formFields["agentFee"].value = closingDetail?.agentFee?.toString() || "";
    formFields["instructions"].value = closingDetail?.instructions || "";
    formFields["signingType"].value = closingDetail?.signingType || "";
    formFields["closingAddressTimeZone"].value =
      closingDetail?.closingAddress?.timeZone || "";

    if (Object.keys(ronPlatformTypeOptions)?.length) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        closingDetail?.ronPlatformTypes.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      console.log({ temp });
      formFields["ronPlatformType"].value = temp || [];
    }

    if (closingDetail?.isImportedFile) {
      formFields["appointmentDate"].value =
        closingDetail?.appointmentDate || "";
    }

    formFields["addressLine1"].value =
      closingDetail?._borrower[0]?.address?.line1;
    formFields["addressLine2"].value =
      closingDetail?._borrower[0]?.address?.line2 || "";
    formFields["city"].value = closingDetail?._borrower[0]?.address?.city;
    if (closingDetail?._borrower[0]?.address?.state) {
      let abbr = usaStates.find(
        (item) =>
          item.name === closingDetail?._borrower[0]?.address?.state ||
          item.abbreviation === closingDetail?._borrower[0]?.address?.state
      )?.abbreviation;

      formFields["state"].value = abbr || "";
    }
    // formFields["state"].value = closingDetail?._borrower[0]?.address?.state;
    formFields["zip"].value = closingDetail?._borrower[0]?.address?.zip;
    formFields["borrowerAddress"].value =
      closingDetail?._borrower[0]?.address?.line1 +
      "," +
      (closingDetail?._borrower[0]?.address?.line2
        ? closingDetail?._borrower[0]?.address?.line2 + ","
        : "") +
      closingDetail?._borrower[0]?.address?.city +
      "," +
      closingDetail?._borrower[0]?.address?.state;

    // formFields["addressLine1"].value =
    //   closingDetail?.closingAddress?.line1 || "";
    // formFields["addressLine2"].value =
    //   closingDetail?.closingAddress?.line2 || "";
    // formFields["city"].value = closingDetail?.closingAddress?.city || "";
    // formFields["state"].value = closingDetail?.closingAddress?.state || "";
    // formFields["zip"].value = closingDetail?.closingAddress?.zip || "";
    // formFields["borrowerAddress"].value = closingDetail?.closingAddress?.line1
    //   ? closingDetail?.closingAddress?.line1
    //   : "" + "," + closingDetail?.closingAddress?.line2
    //   ? closingDetail?.closingAddress?.line2
    //   : "" + "," + closingDetail?.closingAddress?.city
    //   ? closingDetail?.closingAddress?.city
    //   : "" + "," + closingDetail?.closingAddress?.state
    //   ? closingDetail?.closingAddress?.state
    //   : "";

    formFields["closingAddressLine1"].value =
      closingDetail?.closingAddress?.line1;
    formFields["closingAddressLine2"].value =
      closingDetail?.closingAddress?.line2 || "";
    formFields["closingAddressCity"].value =
      closingDetail?.closingAddress?.city;
    // formFields["closingAddressState"].value =
    // closingDetail?.closingAddress?.state;
    if (closingDetail?.closingAddress?.state) {
      let abbr = usaStates.find(
        (item) =>
          item.name === closingDetail?.closingAddress?.state ||
          item.abbreviation === closingDetail?.closingAddress?.state
      )?.abbreviation;

      formFields["closingAddressState"].value = abbr || "";
    }
    formFields["closingAddressZip"].value = closingDetail?.closingAddress?.zip;

    formFields["appointmentAddress"].value =
      closingDetail?.closingAddress?.line1 +
      "," +
      (closingDetail?.closingAddress?.line2
        ? closingDetail?.closingAddress?.line2 + ","
        : "") +
      closingDetail?.closingAddress?.city +
      "," +
      closingDetail?.closingAddress?.state;

    // formFields["closingAddressLine1"].value =
    //   closingDetail?._borrower[0]?.address?.line1 || "";
    // formFields["closingAddressLine2"].value =
    //   closingDetail?._borrower[0]?.address?.line2 || "";
    // formFields["closingAddressCity"].value =
    //   closingDetail?._borrower[0]?.address?.city || "";
    // formFields["closingAddressState"].value =
    //   closingDetail?._borrower[0]?.address?.state || "";
    // formFields["closingAddressZip"].value =
    //   closingDetail?._borrower[0]?.address?.zip || "";

    // formFields["appointmentAddress"].value =
    //   closingDetail?._borrower[0]?.address?.line1 ||
    //   "" + "," + closingDetail?._borrower[0]?.address?.line2 ||
    //   "" + "," + closingDetail?._borrower[0]?.address?.city ||
    //   "" + "," + closingDetail?._borrower[0]?.address?.state ||
    //   "";

    if (closingDetail?.appointmentDate) {
      formFields["appointmentDate"].value =
        formatDateAsPerTimeZoneWithDateAndTime(
          closingDetail?.appointmentDate,
          closingDetail?.closingAddress?.timeZone
        );
    }

    let manuallyTypeAddress = {
      borrowerAddress: true,
      appointmentAddress: true,
    };

    this.setState({
      formFields,
      manuallyTypeAddress,
      closingDetail,
      aiParserResponse: closingDetail,
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const {
        formFields,
        manuallyTypeAddress,
        isBorrowerAddressSameAsAppointment,
      } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "borrower": {
              formFields.borrower.forEach((each, index) => {
                if (each.borrowerName?.value.trim().length) {
                  formFields[key][index]["borrowerName"].isDirty = false;
                  formFields[key][index]["borrowerName"].error = null;
                } else {
                  formFields[key][index]["borrowerName"].isDirty = true;
                  formFields[key][index]["borrowerName"].error = "*Required";
                  isFormValid = false;
                }

                if (
                  formFields[key][index]["email"].value &&
                  formFields[key][index]["email"].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(
                        formFields[key][index]["email"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  } else {
                    formFields[key][index]["email"].isDirty = true;
                    formFields[key][index]["email"].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key][index]["email"].isDirty = false;
                  formFields[key][index]["email"].error = null;
                }

                if (
                  formFields[key][index]["workPhone"].value &&
                  formFields[key][index]["workPhone"].value.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields[key][index]["workPhone"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["workPhone"].isDirty = true;
                    formFields[key][index]["workPhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["workPhone"].isDirty = false;
                  formFields[key][index]["workPhone"].error = null;
                }

                if (
                  formFields?.[key]?.[index]?.["phoneHome"]?.value &&
                  formFields?.[key]?.[index]?.["phoneHome"]?.value?.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields?.[key]?.[index]?.["phoneHome"]?.value
                      )?.toLowerCase()
                    )
                  ) {
                    formFields[key][index]["phoneHome"].isDirty = false;
                    formFields[key][index]["phoneHome"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["phoneHome"].isDirty = true;
                    formFields[key][index]["phoneHome"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["phoneHome"].isDirty = false;
                  formFields[key][index]["phoneHome"].error = null;
                }
              });
              break;
            }

            case "clientId": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressLine1":
            case "closingAddressCity":
            case "closingAddressState":
            case "closingAddressZip": {
              if (
                formFields.signingType.value !== "RON" &&
                !(
                  formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                ) &&
                manuallyTypeAddress.appointmentAddress
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "appointmentAddress": {
              if (
                formFields.signingType.value !== "RON" &&
                !(
                  formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                ) &&
                !manuallyTypeAddress.appointmentAddress
              ) {
                if (!isBorrowerAddressSameAsAppointment) {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    if (formFields[key].placeId) {
                      formFields[key].isDirty = false;
                      formFields[key].error = null;
                    } else {
                      formFields[key].isDirty = true;
                      formFields[key].error = "*Invalid Address";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "loanType":
            case "fileNumber":
            case "appointmentDate": {
              // console.log("sasasa ", formFields[key]);
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "loanTypeOther": {
              if (formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "agentFee": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Fee must be a non-negative number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "agentFee": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     if (
            //       isNaN(formFields[key].value) ||
            //       Number(formFields[key].value) <= 0
            //     ) {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Fee must be a positive number";
            //       isFormValid = false;
            //     } else {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     }
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onSelectAddress = (fieldName, addr) => {
    this.getPlaceDetail(addr, fieldName);
  };

  getPlaceDetail = async (pid, type) => {
    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    if (detail) {
      if (type === "borrowerAddress") {
        if (detail.postal) {
          formFields["addressLine1"].value = detail.address;
          formFields["city"].value = detail.city;
          formFields["county"].value = detail.county;
          let state = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["state"].value = state;
          formFields["zip"].value = detail.postal;
          formFields["borrowerAddress"].value = pid.description;
          formFields["borrowerAddress"].placeId = pid.place_id;
          formFields["borrowerAddress"].isDirty = true;
        } else {
          showToast("Invalid Address", "error");
        }
      } else {
        if (detail.postal) {
          formFields["closingAddressLine1"].value = detail.address;
          formFields["closingAddressCity"].value = detail.city;
          formFields["closingAddressCounty"].value = detail.county;
          let cState = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["closingAddressState"].value = cState;
          formFields["closingAddressZip"].value = detail.postal;
          formFields["appointmentAddress"].value = pid.description;
          formFields["appointmentAddress"].placeId = pid.place_id;
          formFields["appointmentAddress"].isDirty = true;
        } else {
          showToast("Invalid Address", "error");
        }
      }
      this.setState({ formFields, isAddressListShow: null }, () => {
        this._validateForm();
      });
    }
  };

  _googlePlaceSearch = async (searchValue, isAddressListShow) => {
    const googleAddressResult = await googlePlaceSearch(searchValue);
    this.setState({ googleAddressResult, isAddressListShow });
  };

  _onChangeFormField = (fieldName, value) => {
    console.log(fieldName, value);
    const { formFields } = deepClone(this.state);

    if (fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "borrowerAddress" || fieldName === "appointmentAddress") {
      this._googlePlaceSearch(value, fieldName);
      formFields[fieldName].placeId = null;
    }

    if (fieldName === "appointmentDate") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[fieldName].value = value;
    }

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !formFields.instructions.value.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(
                formFields.instructions.value
              )
            : formFields.instructions.value.replace(
                /\d+(?= witness required)/,
                value
              )
          : formFields.instructions.value.replace(/<p>#.*?<\/p>/, "");

      formFields["instructions"].value = text;
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );

      console.log({ text });
      formFields["instructions"].value = text;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      // console.log(this.state.formFields);
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _createAgentClosing = (data) => {
    console.log("api call data: ", data);
    createAgentClosing(data)
      .then((res) => {
        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/agent/dashboard");
      })
      .catch((error) => {
        console.log(error);
        errorHandler(error);
        this._manageLoading("submitLoading", false);
        this.props.hideLoader();
      });
  };

  _updateAgentClosingById = (data) => {
    updateAgentClosingById(this.state.closingDetail.id, data)
      .then((res) => {
        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/agent/dashboard");
      })
      .catch((error) => {
        console.log(error);
        errorHandler(error);
        this._manageLoading("submitLoading", false);
        this.props.hideLoader();
      });
  };
  _createPayload = async () => {
    const {
      formFields,
      uploadFiles,
      isBorrowerAddressSameAsAppointment,
      manuallyTypeAddress,
      clients,
    } = deepClone(this.state);

    const payload = {
      borrower: [],

      agentFee: formFields.agentFee.value.trim(),
      fileNumber: formFields.fileNumber.value.trim(),
      lender: formFields.lender.value,

      personOfContact: {
        name: formFields.personOfContactName.value.trim(),
        email: formFields.personOfContactEmail.value,
      },

      loanTypeOther:
        formFields.loanType.value.filter((item) => item.value === "Other")
          .length > 0
          ? formFields.loanTypeOther.value
          : undefined,
      appointmentDate: formatDateMoment(formFields.appointmentDate.value),
      closingAddress: {},
      isScanBackNeeded: formFields.isScanBackNeeded.value,
      // closingAddress: {
      //   line1: formFields.closingAddressLine1?.value?.trim().length
      //     ? formFields?.closingAddressLine1?.value
      //     : undefined,
      //   line2: formFields?.closingAddressLine2?.value?.trim().length
      //     ? formFields?.closingAddressLine2?.value
      //     : undefined,
      //   city: formFields?.closingAddressCity?.value?.trim().length
      //     ? formFields?.closingAddressCity?.value
      //     : undefined,
      //   state: formFields?.closingAddressState?.value?.trim().length
      //     ? formFields?.closingAddressState?.value
      //     : undefined,
      //   zip: formFields?.closingAddressZip?.value?.trim().length
      //     ? formFields?.closingAddressZip?.value
      //     : undefined,
      // },
    };

    let borrower = await formFields.borrower.map(
      ({
        borrowerName,
        email,
        workPhone,
        phoneHome,
        isForeignNational,
        language,
        languageTypeOther,
        timeZone,
      }) => {
        const splitBorrowerName = borrowerName.value.trim().split(" ");

        return {
          name: {
            first: splitBorrowerName.slice(0, 1).join(" "),
            last: splitBorrowerName[1]
              ? splitBorrowerName.slice(1).join(" ")
              : "",
          },
          email: email.value ? email.value : undefined,
          phone: {
            work: workPhone.value ? workPhone.value : undefined,
            alternate: phoneHome.value ? phoneHome.value : undefined,
          },
          isForeignNational: isForeignNational.value,
          language: language.value ? language.value : undefined,
          languageTypeOther: languageTypeOther.value
            ? languageTypeOther.value
            : undefined,
          timeZone: timeZone.value ? timeZone.value : undefined,
          address: {},
          // isSavedAsDraft: this.state?.closingDetail?.isSavedAsDraft,
        };
      }
    );
    payload["borrower"] = borrower;

    if (formFields.loanType.value) {
      payload["loanType"] = await formFields.loanType.value.map(
        (item) => item.value
      );
    }
    if (formFields.loanCategories.value) {
      payload["loanCategories"] = await formFields?.loanCategories?.value;
    }

    // if (formFields.borrowerAddress.placeId) {
    //   const addressObj = await googlePlaceDetails(
    //     formFields.borrowerAddress.placeId
    //   );
    //   let abbr = usaStates.find(
    //     (item) => item.name === addressObj.state
    //   ).abbreviation;
    //   await borrower.map(
    //     (item) => (
    //       // eslint-disable-next-line no-sequences
    //       (item.address["line1"] = addressObj.address),
    //       (item.address["city"] = addressObj.city),
    //       (item.address["county"] = addressObj.county),
    //       (item.address["state"] =
    //         addressObj.state.length > 2 ? abbr : addressObj.state),
    //       (item.address["zip"] = addressObj.postal),
    //       (item.address["lat"] = addressObj.lat),
    //       (item.address["lng"] = addressObj.lng)
    //     )
    //   );
    //     }

    if (isBorrowerAddressSameAsAppointment) {
      // if manuallyTypeAddress.borrowerAddress is true then address save from formfield otherwise search address by google
      if (manuallyTypeAddress.borrowerAddress) {
        payload.closingAddress["line1"] =
          formFields.addressLine1.value?.trim() || "";
        payload.closingAddress["line2"] =
          formFields.addressLine2.value?.trim() || "";
        payload.closingAddress["city"] = formFields.city.value?.trim() || "";

        payload.closingAddress["county"] =
          formFields.county.value?.trim() || "";

        payload.closingAddress["state"] = formFields.state.value?.trim() || "";
        payload.closingAddress["zip"] = formFields.zip.value?.trim() || "";
      } else {
        if (formFields.borrowerAddress.placeId) {
          const addressObj = await googlePlaceDetails(
            formFields.borrowerAddress.placeId
          );
          let abbr = usaStates.find(
            (item) => item.name === addressObj.state
          ).abbreviation;
          payload.closingAddress["line1"] = addressObj.address;
          payload.closingAddress["city"] = addressObj.city;
          payload.closingAddress["county"] = addressObj.county;
          payload.closingAddress["state"] =
            addressObj.state.length > 2 ? abbr : addressObj.state;
          payload.closingAddress["zip"] = addressObj.postal;
          payload.closingAddress["lat"] = addressObj.lat;
          payload.closingAddress["lng"] = addressObj.lng;
          payload.closingAddress["timeZone"] =
            formFields.closingAddressTimeZone.value;
          await borrower.map(
            (item) => (
              // eslint-disable-next-line no-sequences
              (item.address["line1"] = addressObj.address),
              (item.address["city"] = addressObj.city),
              (item.address["state"] =
                addressObj.state.length > 2 ? abbr : addressObj.state),
              (item.address["zip"] = addressObj.postal),
              (item.address["lat"] = addressObj.lat),
              (item.address["lng"] = addressObj.lng)
            )
          );
        }
      }
    }

    if (!isBorrowerAddressSameAsAppointment) {
      // if manuallyTypeAddress.borrowerAddress is true then address save from formfield otherwise search address by google
      if (manuallyTypeAddress.borrowerAddress) {
        await borrower.map(
          (item) => (
            (item.address["line1"] =
              // eslint-disable-next-line no-sequences
              formFields.addressLine1.value?.trim() || ""),
            (item.address["line2"] =
              formFields.addressLine2.value?.trim() || ""),
            (item.address["city"] = formFields.city.value?.trim() || ""),
            (item.address["county"] = formFields.county.value?.trim() || ""),
            (item.address["state"] = formFields.state.value?.trim() || ""),
            (item.address["zip"] = formFields.zip.value?.trim() || "")
          )
        );
      } else {
        if (formFields.borrowerAddress.placeId) {
          const addressObj = await googlePlaceDetails(
            formFields.borrowerAddress.placeId
          );
          let abbr = usaStates.find(
            (item) => item.name === addressObj.state
          ).abbreviation;
          await borrower.map(
            (item) => (
              // eslint-disable-next-line no-sequences
              (item.address["line1"] = addressObj.address),
              (item.address["city"] = addressObj.city),
              (item.address["county"] = addressObj.county),
              (item.address["state"] =
                addressObj.state.length > 2 ? abbr : addressObj.state),
              (item.address["zip"] = addressObj.postal),
              (item.address["lat"] = addressObj.lat),
              (item.address["lng"] = addressObj.lng)
            )
          );
        }
      }

      if (manuallyTypeAddress.appointmentAddress) {
        payload.closingAddress["line1"] =
          formFields.closingAddressLine1.value?.trim() || "";
        payload.closingAddress["line2"] =
          formFields.closingAddressLine2.value?.trim() || "";
        payload.closingAddress["city"] =
          formFields.closingAddressCity.value?.trim() || "";

        payload.closingAddress["county"] =
          formFields.closingAddressCounty.value?.trim() || "";

        payload.closingAddress["state"] =
          formFields.closingAddressState.value?.trim() || "";
        payload.closingAddress["zip"] =
          formFields.closingAddressZip.value?.trim() || "";
      } else {
        if (formFields.appointmentAddress.placeId) {
          const addressObj = await googlePlaceDetails(
            formFields.appointmentAddress.placeId
          );
          let abbr = usaStates.find(
            (item) => item.name === addressObj.state
          ).abbreviation;

          payload.closingAddress["line1"] = addressObj.address;
          payload.closingAddress["city"] = addressObj.city;
          payload.closingAddress["county"] = addressObj.county;
          payload.closingAddress["state"] =
            addressObj.state.length > 2 ? abbr : addressObj.state;
          payload.closingAddress["zip"] = addressObj.postal;
          payload.closingAddress["lat"] = addressObj.lat;
          payload.closingAddress["lng"] = addressObj.lng;
        }
      }
    }

    if (formFields.agentNotes.value?.trim().length)
      payload["agentNotes"] = formFields.agentNotes.value.trim();

    if (formFields.instructions.value?.trim().length)
      payload["instructions"] = formFields.instructions.value.trim();

    if (formFields.loanOfficer.value?.trim().length)
      payload["loanOfficer"] = formFields.loanOfficer.value.trim();

    if (
      formFields.signingType.value === "RON" ||
      (formFields?.loanType?.value?.length === 1 &&
        formFields?.loanType?.value[0]?.value === "AttorneyPhone")
    )
      payload.closingAddress["timeZone"] =
        formFields.closingAddressTimeZone.value;

    if (formFields.signingType.value === "RON")
      payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
        ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
        : undefined;

    if (
      formFields.signingType.value &&
      formFields.signingType.value.trim().length
    ) {
      payload["signingType"] = formFields.signingType.value.trim();
    }
    if (
      formFields.witnessCount.value &&
      formFields.witnessCount.value.trim().length
    ) {
      payload["witnessCount"] = Number(formFields.witnessCount.value.trim());
    }
    if (formFields.clientId.value && formFields.clientId.value.trim().length) {
      clients.find((each) => {
        if (each._id === formFields.clientId.value) {
          console.log("each >>", each);
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload["clientId"] = each._client;
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload["signingCompanyId"] = each._signingCompany;
          } else {
            payload["assistantId"] = each._assistant;
          }
        }
      });
    }
    if (formFields.ronPlatformType.value) {
      payload["ronPlatformTypes"] = await formFields.ronPlatformType.value.map(
        (item) => item.value
      );
    }
    // if (
    //   formFields.closingSearch.placeId ||
    //   (formFields.closingAddressCity.value &&
    //     formFields.closingAddressState.value)
    // ) {
    //   delete payload.closingAddress["timeZone"];
    // }

    if (
      this.state.closingDetail?.isImportedFile &&
      this.state.closingDetail?.isCreatedForAgent &&
      this.state.closingDetail?.isSavedAsDraft
    ) {
      payload["isSavedAsDraft"] = false;
    }

    return payload;
  };

  _submitCreateAgentClosing = async (e) => {
    this._manageLoading("submitLoading", true);
    const { closingDetail } = this.state;

    if (e) e.preventDefault();

    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      Object.keys(this.state.formFields).forEach((key) => {
        if (this.state.formFields[key].error) {
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          const finalResult2 =
            finalResult === "Loan Type" ? "Product Type" : finalResult;
          showToast(`Please Fill The ${finalResult2} Fields`, "error");
        }
      });
      if (!this.state.formFields.fileNumber.value.trim().length > 0) {
        this._toggleFileCheckedModal(true);
      }
      this._manageLoading("submitLoading", false);
    }

    if (isFormValid) {
      this.props.showLoader("Create Closing...");

      const {
        // formFields,
        uploadFiles,
        // isBorrowerAddressSameAsAppointment,
        // manuallyTypeAddress,
        // clients,
      } = deepClone(this.state);

      const payload = await this._createPayload();
      console.log({ payload });

      if (uploadFiles && uploadFiles.length) {
        payload["documents"] = [];

        let postID = PostManager.addMediaFilesCount(uploadFiles.length);

        PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
          if (id.postID === postID) {
            await sleepTime(500);
            this._createAgentClosing(payload);
            PostManager.deletePostID(postID);
          } else {
            return;
          }
        });

        uploadFiles.forEach((uploadFile) => {
          // for logo images
          let mediaData = {
            file: uploadFile.uploadData,
            blobObject: uploadFile.previewBlob,
          };
          const uploadId = UploadQueueManager.addMediaToQueue(mediaData, "pdf");

          // Listen for upload complete
          UploadQueueManager.onUploadComplete(
            uploadId,
            async (mediaResponse) => {
              PostManager.onSingleMediaFileUploadCompleted(postID);

              // Upload complete
              // Get content id from backend
              payload.documents.push({
                title: addUnixTimeToString(uploadFile.uploadData.name),
                url: mediaResponse.fileUrl,
                docType: getFileExtension(uploadFile.uploadData.name),
                category: "confirmation",
              });
            }
          );
        });
      } else {
        if (closingDetail?.isSavedAsDraft) {
          this._updateAgentClosingById(payload);
        } else {
          this._createAgentClosing(payload);
        }
      }
    }
  };

  _toggleSameAddress = (isBorrowerAddressSameAsAppointment = false) => {
    const { formFields } = deepClone(this.state);

    // if (isBorrowerAddressSameAsAppointment) {
    //   // formFields.borrowerAddress = {
    //   //   value: "",
    //   //   placeId: null,
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: true,
    //   // };
    //   // formFields.addressLine1 = {
    //   //   value: "",
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: true,
    //   // };
    //   // formFields.addressLine2 = {
    //   //   value: "",
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: false,
    //   // };
    //   // formFields.city = {
    //   //   value: "",
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: true,
    //   // };
    //   // formFields.state = {
    //   //   value: "",
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: true,
    //   // };
    //   // formFields.zip = {
    //   //   value: "",
    //   //   error: null,
    //   //   isDirty: false,
    //   //   isValidate: true,
    //   // };
    //   formFields.appointmentAddress = {
    //     value: "",
    //     placeId: null,
    //     error: null,
    //     isDirty: false,
    //     isValidate: true,
    //   };
    //   formFields.closingAddressLine1 = {
    //     value: "",
    //     error: null,
    //     isDirty: false,
    //     isValidate: true,
    //   };
    //   formFields.closingAddressLine2 = {
    //     value: "",
    //     error: null,
    //     isDirty: false,
    //     isValidate: false,
    //   };
    //   formFields.closingAddressCity = {
    //     value: "",
    //     error: null,
    //     isDirty: false,
    //     isValidate: true,
    //   };
    //   formFields.closingAddressState = {
    //     value: "",
    //     error: null,
    //     isDirty: false,
    //     isValidate: true,
    //   };
    //   formFields.closingAddressZip = {
    //     value: "",
    //     error: null,
    //     isDirty: false,
    //     isValidate: true,
    //   };
    // }
    formFields["closingAddressLine1"].value = isBorrowerAddressSameAsAppointment
      ? formFields.addressLine1.value
      : "";
    formFields["closingAddressLine2"].value = isBorrowerAddressSameAsAppointment
      ? formFields.addressLine2.value
      : "";
    formFields["closingAddressCity"].value = isBorrowerAddressSameAsAppointment
      ? formFields.city.value
      : "";
    formFields["closingAddressState"].value = isBorrowerAddressSameAsAppointment
      ? formFields.state.value
      : "";
    formFields["closingAddressZip"].value = isBorrowerAddressSameAsAppointment
      ? formFields.zip.value
      : "";
    formFields["appointmentAddress"].value = isBorrowerAddressSameAsAppointment
      ? formFields.borrowerAddress.value
      : "";
    this.setState({
      isBorrowerAddressSameAsAppointment,
      formFields,
      googleAddressResult: [],
      isAddressListShow: null,
    });
  };

  _goBackBtn = () => {
    this.props.history.push("/agent/dashboard");
  };

  _toggleManualOrGoogleAddress = (fieldName) => {
    const { manuallyTypeAddress } = this.state;
    manuallyTypeAddress[fieldName] = !manuallyTypeAddress[fieldName];
    this.setState({ manuallyTypeAddress });
  };

  checkNotMetioned = (text) => {
    let toAvoid = [
      "Not mentioned",
      "N/A (not specified)",
      "Not Specified",
      "Not mentioned in the text",
      "N/A (not mentioned in the text)",
      "None provided in text",
    ];
    let lowerCaseText = text.toLowerCase();

    for (let phrase of toAvoid) {
      if (lowerCaseText.includes(phrase.toLowerCase())) {
        return false;
      }
    }

    return true;
  };

  _filterValidEmail = (string) => {
    const emailPattern = /[^\s@]+@[^\s@]+\.[^\s@]+/g;
    const emails = string.match(emailPattern) || [];
    return emails.filter((email) =>
      RegexConfig.email.test(String(email).toLowerCase())
    );
  };

  _removeKeyValuePair(obj) {
    return new Promise((resolve, reject) => {
      try {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            const updatedBorrowers = value.map((borrower) => {
              const updatedBorrower = {};
              Object.entries(borrower).forEach(
                ([borrowerKey, borrowerValue]) => {
                  if (
                    !(
                      borrowerValue.toLowerCase().includes("not ") ||
                      borrowerValue.toLowerCase().includes("n/a") ||
                      borrowerValue.toLowerCase().includes("none")
                    )
                  ) {
                    updatedBorrower[borrowerKey] = borrowerValue;
                  }
                }
              );
              return updatedBorrower;
            });
            newObj[key] = updatedBorrowers;
          } else if (
            // typeof value === "string" &&
            // !(
            //   value.toLowerCase().includes("not ") ||
            //   value.toLowerCase().includes("n/a") ||
            //   value.toLowerCase().includes("none")
            // )
            !(
              typeof value === "string" &&
              (value.toLowerCase().includes("not ") ||
                value.toLowerCase().includes("n/a") ||
                value.toLowerCase().includes("none"))
            )
          ) {
            newObj[key] = value;
          }
        });
        resolve(newObj);
      } catch (error) {
        reject(error);
      }
    });
  }

  _setformFieldData = async (response, objFile) => {
    try {
      await this._resetState();
      let data = await this._removeKeyValuePair(response);
      // console.log("res", data);

      const { formFields } = deepClone(this.state);
      let borrower = [];
      data?.borrowers?.forEach((each) => {
        borrower.push({
          borrowerName: {
            value: each.borrowerName || "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            // value: each.borrowerEmail || "",
            value: each.borrowerEmail
              ? // ? this._filterValidEmail(each.borrowerEmail)
                each.borrowerEmail
                  ?.match(/[^\s@]+@[^\s@]+\.[^\s@]+/g)
                  ?.join("") || ""
              : "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: each?.borrowerWorkPhone
              ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
              : each?.borrowerHomePhone
              ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
              : each?.borrowerCellPhone
              ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
              : "",
            isDirty: false,
            isValidate: true,
          },
          phoneHome: {
            value: "",
            error: null,
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        });
      });
      formFields["borrower"] =
        borrower.length > 0
          ? borrower
          : [
              {
                _id: {
                  value: "",
                },
                borrowerName: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                email: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                workPhone: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                phoneHome: {
                  value: "",
                  error: null,
                  isDirty: false,
                  isValidate: true,
                },
                isForeignNational: {
                  value: false,
                  isDirty: false,
                  isValidate: true,
                },
                language: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                languageTypeOther: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
                timeZone: {
                  value: "",
                  isDirty: false,
                  isValidate: true,
                },
              },
            ];
      // formFields["personOfContactName"].value = data?.pocName || "";
      // formFields["personOfContactEmail"].value =
      //   data?.pocEmail?.split(" ") || [];
      formFields["personOfContactName"].value =
        // && this.checkNotMetioned(data?.pocName)
        data?.pocName ? data?.pocName : "";
      formFields["personOfContactEmail"].value = data?.pocEmail
        ? // ? data?.pocEmail?.replace(RegexConfig.email, "$1").split(" ")
          this._filterValidEmail(data?.pocEmail)
        : [];

      formFields["fileNumber"].value = data?.fileNumber || "";
      // formFields["lender"].value = data?.lender || "";
      formFields["lender"].value =
        // && this.checkNotMetioned(data?.lender)
        data?.lender ? data.lender : "";

      if (data?.appointmentDateTime) {
        if (data?.appointmentDateTime.includes("EST")) {
          formFields["appointmentDate"].value = data?.appointmentDateTime.slice(
            0,
            data?.appointmentDateTime.indexOf("E") - 1
          );
        } else {
          formFields["appointmentDate"].value = data?.appointmentDateTime;
        }
      }

      formFields["signingType"].value =
        data?.signingType &&
        (data?.signingType === "Mobile" || data?.signingType === "RON")
          ? data?.signingType
          : "Mobile";

      formFields["loanType"].value =
        Object.values(loanTypeConfig)
          .flat()
          .filter((item) => item === data?.closingType).length === 0
          ? [{ label: "Other", value: "Other" }]
          : [{ label: data?.closingType, value: data?.closingType }];
      // formFields["loanTypeOther"].value = data?.closingType || "";
      formFields["loanTypeOther"].value =
        // && this.checkNotMetioned(data?.closingType)
        data?.closingType ? data?.closingType : "";

      formFields["agentFee"].value = data?.agentFee
        ? data?.agentFee?.replace(/[^\d.]/g, "")
        : // ? data?.agentFee.replace(/[$]/g, "")
          "";

      formFields["closingAddressLine1"].value =
        data?.propertyAddress?.addressLine || "";
      formFields["closingAddressCity"].value =
        data?.propertyAddress?.city || "";
      if (data?.propertyAddress?.state) {
        let abbr = usaStates.find(
          (item) =>
            item.name === data?.propertyAddress?.state ||
            item.abbreviation === data?.propertyAddress?.state
        )?.abbreviation;

        formFields["closingAddressState"].value = abbr || "";
      }
      formFields["closingAddressZip"].value = data?.propertyAddress?.zip || "";
      formFields["appointmentAddress"].value =
        data?.propertyAddress?.addressLine +
        " " +
        data?.propertyAddress?.city +
        " " +
        data?.propertyAddress?.state +
        " " +
        data?.propertyAddress?.zip;

      formFields["addressLine1"].value =
        data?.closingAddress?.addressLine || "";
      formFields["city"].value = data?.closingAddress?.city || "";
      if (data?.closingAddress?.state) {
        let abbr = usaStates.find(
          (item) =>
            item.name === data?.closingAddress?.state ||
            item.abbreviation === data?.closingAddress?.state
        )?.abbreviation;

        formFields["state"].value = abbr || "";
      }
      formFields["zip"].value = data?.closingAddress?.zip || "";
      formFields["borrowerAddress"].value =
        data?.closingAddress?.addressLine +
        " " +
        data?.closingAddress?.city +
        " " +
        data?.closingAddress?.state +
        " " +
        data?.closingAddress?.zip;

      // formFields["appointmentAddressLine1"].value =
      //   data?.closingAddress?.addressLine || "";
      // formFields["appointmentAddressCity"].value =
      //   data?.closingAddress?.city || "";
      // if (data?.closingAddress?.state) {
      //   let abbr = usaStates.find(
      //     (item) =>
      //       item.name === data?.closingAddress?.state ||
      //       item.abbreviation === data?.closingAddress?.state
      //   )?.abbreviation;

      //   formFields["appointmentAddressState"].value = abbr || "";
      // }
      // formFields["appointmentAddressZip"].value =
      //   data?.closingAddress?.zip || "";

      // formFields["addressLine1"].value =
      //   data?.propertyAddress?.addressLine || "";
      // formFields["city"].value = data?.propertyAddress?.city || "";
      // if (data?.propertyAddress?.state) {
      //   let abbr = usaStates.find(
      //     (item) =>
      //       item.name === data?.propertyAddress?.state ||
      //       item.abbreviation === data?.propertyAddress?.state
      //   )?.abbreviation;

      //   formFields["state"].value = abbr || "";
      // }
      // formFields["zip"].value = data?.propertyAddress?.zip || "";

      let uploadFiles = [
        {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          isConfirmationFile: true,
        },
      ];

      let manuallyTypeAddress = {
        borrowerAddress: true,
        appointmentAddress: true,
      };

      this.setState({
        formFields,
        uploadFiles,
        manuallyTypeAddress,
        aiParserResponse: data,
      });
      showToast("File uploaded", "success");
    } catch (error) {
      console.log(error);
      showToast("Something went wrong. Try again after sometime.", "error");
    }
  };

  _uploadConfirmationFile = async (event) => {
    try {
      if (
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length
      ) {
        let objFile = event.target.files[0];
        if (objFile.type.includes("pdf")) {
          this.setState({ confirmationFileLoading: true });
          HeaderEventEmitter.dispatch("confirmationFileLoading", true);

          const confirmationFile = new FormData();
          confirmationFile.append("file", objFile);

          const response = await uploadClosingPdf(confirmationFile);

          // if (response.data?.borrowers?.length) {
          isRegularUser() && this._setformFieldData(response?.data, objFile);
          // } else {
          //   showToast("Invalid File", "error");
          // }

          this.setState({ confirmationFileLoading: false });
          HeaderEventEmitter.dispatch("confirmationFileLoading", false);
        } else {
          this.setState({ confirmationFileLoading: false });
          showToast("Only PDF file is allowed", "error");
        }
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _toggleReportModal = (isOpen = false, data = null) => {
    const { formFields, aiParserResponse } = deepClone(this.state);
    this.setState({
      reportModalData: {
        isOpen,
        data: {
          aiParserResponse: aiParserResponse,
        },
      },
    });
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };
  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          // chips: chips,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");

      return false;
    }
  };
  _addBorrower = () => {
    const { formFields } = this.state;
    formFields.borrower.push({
      borrowerName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      workPhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      phoneHome: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isForeignNational: {
        value: false,
        isDirty: false,
        isValidate: true,
      },
      language: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      languageTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      timeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
    });
    this.setState({ formFields });
  };
  _removeBorrower = (index) => {
    const { formFields } = this.state;
    formFields.borrower.splice(index, 1);
    this.setState({ formFields });
  };
  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            formFields.instructions.value
          )
        : formFields.instructions.value.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );

      formFields["instructions"].value = this._sortText(text);
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
              formFields.instructions.value
            )
          : formFields.instructions.value.replace(/<p>.*?<\/p>/, "");

      formFields["instructions"].value = this._sortText(text);
    }
    if (subField === "language") {
      // let text =
      //   value !== ""
      //     ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
      //         formFields.instructions.value
      //       )
      //     : formFields.instructions.value.replace(/<p>.*?<\/p>/, "");
      let text =
        value !== ""
          ? !formFields.instructions.value.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                formFields.instructions.value
              )
            : formFields.instructions.value.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : formFields.instructions.value.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );
      formFields["instructions"].value = this._sortText(text);
    }
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };
  _sortText = (input) => {
    if (!input) {
      return "";
    }

    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input.match(signerRegex);

    // sort the signer strings in ascending order
    signerMatches.sort();

    // replace the original signer strings with the sorted ones
    let output = input.replace(signerRegex, () => signerMatches.shift());

    return output;
  };
  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _duplicateFileNumberCheck = async (e) => {
    let { fileNumber } = deepClone(this.state.formFields);
    try {
      this._manageLoading("submitLoading", true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        this._manageLoading("submitLoading", false);
      } else {
        this._submitCreateAgentClosing(e);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("submitLoading", false);
    }
  };

  render() {
    const {
      formFields,
      googleAddressResult,
      isAddressListShow,
      isBorrowerAddressSameAsAppointment,
      manuallyTypeAddress,
      // eslint-disable-next-line no-unused-vars
      confirmationFileLoading,
      addClientModalData,
      upgradeAccountModal,
      fileChecked,
      clients,
      uploadFiles,
      reportModalData,
      closingDetail,
      aiParserResponse,
      ronPlatformTypeOptions,
      FileNumberUniqueChecked,
      loading,
      existingFileNos,
      keepFileNo,
    } = this.state;
    console.log("y", this.state);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                  onClick={() => this._goBackBtn()}
                />
              </Button>
              <h2>Create Order</h2>
            </div>

            <div className="rightSide">
              <Label
                className="uploadBtn floatingButton"
                for="uploadAutomation"
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                {confirmationFileLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <>
                    <img
                      src={
                        require("../../../assets/img/uploadIcon.svg").default
                      }
                      alt="upload automation"
                    />{" "}
                    UPLOAD
                  </>
                )}
                {isRegularUser() && (
                  <Input
                    type="file"
                    id="uploadAutomation"
                    accept=".pdf"
                    disabled={confirmationFileLoading}
                    title="Upload Confirmation"
                    value=""
                    onChange={(event) => this._uploadConfirmationFile(event)}
                  />
                )}
              </Label>
              {/* <Button color="link" onClick={this._resetFilter}>
                <img
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="Filter"
                  height={14}
                />
              </Button> */}
            </div>
          </div>

          <Row>
            <Col>
              {formFields.borrower.map((each, index) => (
                <>
                  <div className="ClosingWrapper">
                    <Row>
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          {/* add a searchable text field below in place of the input field */}
                          <Input
                            type="text"
                            placeholder=" "
                            value={each.borrowerName.value}
                            name="borrowerName"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "borrowerName",
                                index
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Signer Name</Label>
                          {each.borrowerName.error && (
                            <div className="validation-error">
                              {each.borrowerName.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="email"
                            placeholder=" "
                            value={each.email.value}
                            name="email"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "email",
                                index
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Email</Label>
                          {each.email.error && (
                            <div className="validation-error">
                              {each.email.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel withInputGroup">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+1</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder=" "
                              value={each.workPhone.value}
                              name="phone"
                              onChange={(event) =>
                                this._onChangeFormFieldBorrower(
                                  "borrower",
                                  event.target.value,
                                  "workPhone",
                                  index
                                )
                              }
                              onKeyPress={(e) => this._isNumber(e)}
                            />
                            <Label>Phone</Label>
                          </InputGroup>
                          {each.workPhone.error && (
                            <div className="validation-error">
                              {each.workPhone.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel withInputGroup">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>+1</InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder=" "
                              value={each.phoneHome.value}
                              name="phone"
                              onChange={(event) =>
                                this._onChangeFormFieldBorrower(
                                  "borrower",
                                  event.target.value,
                                  "phoneHome",
                                  index
                                )
                              }
                              onKeyPress={(e) => this._isNumber(e)}
                            />
                            <Label>Alternate Phone</Label>
                          </InputGroup>
                          {each.phoneHome.error && (
                            <div className="validation-error">
                              {each.phoneHome.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel custom-select-wrapper">
                          <Input
                            type="select"
                            name="language"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "language",
                                index
                              )
                            }
                            value={each.language.value}
                          >
                            <option value="">Select</option>

                            {LanguageList.map((lang, index) => (
                              <option key={index} value={lang.value}>
                                {lang.label}
                              </option>
                            ))}
                          </Input>
                          <Label>Language</Label>
                        </FormGroup>
                      </Col>
                      {each.language.value === "Other" && (
                        <Col sm={6} md={6} xl={4}>
                          <FormGroup className="floatingLabel ">
                            <Input
                              type="text"
                              placeholder=" "
                              value={each.languageTypeOther.value}
                              name="languageOther"
                              onChange={(event) =>
                                this._onChangeFormFieldBorrower(
                                  "borrower",
                                  event.target.value,
                                  "languageTypeOther",
                                  index
                                )
                              }
                            />
                            <Label>Other Language</Label>
                          </FormGroup>
                        </Col>
                      )}
                      {formFields.signingType.value === "RON" ||
                      formFields?.loanType?.value[0]?.value ===
                        "AttorneyPhone" ? (
                        <Col sm={6} md={6} xl={4}>
                          <FormGroup className="floatingLabel custom-select-wrapper">
                            <Input
                              type="select"
                              name="timeZone"
                              value={each.timeZone.value}
                              onChange={(event) =>
                                this._onChangeFormFieldBorrower(
                                  "borrower",
                                  event.target.value,
                                  "timeZone",
                                  index
                                )
                              }
                            >
                              <option value="">Select</option>
                              {timezoneList().map((item) => (
                                <option>{item}</option>
                              ))}
                            </Input>
                            <Label>Time Zone</Label>
                          </FormGroup>
                        </Col>
                      ) : null}
                      <Col sm={6} md={6} xl={4}>
                        <CustomInput
                          type="checkbox"
                          // id="isSignerForeigner"
                          id={`"isSignerForeigner+${index}"`}
                          label="Is the signer a foreign national?"
                          checked={each.isForeignNational.value}
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.checked,
                              "isForeignNational",
                              index
                            )
                          }
                        />
                      </Col>
                      <Col className="text-right">
                        {index === formFields.borrower.length - 1 && (
                          <Button
                            color="link"
                            onClick={() => this._addBorrower()}
                          >
                            <img
                              src={
                                require("../../../assets/img/addItem.svg")
                                  .default
                              }
                              alt="add item"
                            />
                          </Button>
                        )}
                        {formFields.borrower.length > 1 ? (
                          <Button
                            color="link"
                            onClick={() => this._removeBorrower(index)}
                          >
                            <img
                              src={
                                require("../../../assets/img/removeItem.svg")
                                  .default
                              }
                              alt="remove item"
                            />
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </>
              ))}
              <hr />

              <div className="ClosingWrapper">
                <Row>
                  <Col sm="12">
                    <CustomInput
                      type="switch"
                      className="mb-3"
                      checked={
                        // isBorrowerAddressSameAsAppointment
                        //   ? manuallyTypeAddress.appointmentAddress
                        manuallyTypeAddress.borrowerAddress
                      }
                      // disabled={isBorrowerAddressSameAsAppointment}
                      onChange={() =>
                        this._toggleManualOrGoogleAddress("borrowerAddress")
                      }
                      id="borrowerAddress_Custom_Switch"
                      name="borrowerAddress"
                      label="Enter Address Manually"

                      // checked={manuallyTypeAddress.appointmentAddress}
                      // onChange={() =>
                      //   this._toggleManualOrGoogleAddress("appointmentAddress")
                      // }
                      // id="appointmentAddress_Custom_Switch"
                      // name="appointmentAddress"
                      // label="Enter Address Manually"
                    />
                  </Col>

                  {manuallyTypeAddress.borrowerAddress ? (
                    <>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={formFields.addressLine1.value}
                            name="addressLine1"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "addressLine1",
                                event.target.value
                              )
                            }
                          />
                          <Label>Property Address Line 1</Label>
                          {formFields.addressLine1.error && (
                            <div className="validation-error">
                              {formFields.addressLine1.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={formFields.addressLine2.value}
                            name="addressLine2"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "addressLine2",
                                event.target.value
                              )
                            }
                          />
                          <Label>Property Address Line 2</Label>
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={formFields.city.value}
                            name="city"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "city",
                                event.target.value
                              )
                            }
                          />
                          <Label>City</Label>
                          {formFields.city.error && (
                            <div className="validation-error">
                              {formFields.city.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <div className="custom-select-wrapper">
                            <Input
                              type="select"
                              value={formFields.state.value}
                              name="state"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "state",
                                  event.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              {usaStates.map((state) => (
                                <option
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </Input>
                            <Label>State</Label>
                          </div>
                          {formFields.state.error && (
                            <div className="validation-error">
                              {formFields.state.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="number"
                            placeholder=" "
                            value={formFields.zip.value}
                            name="zip"
                            onChange={(event) =>
                              this._onChangeFormField("zip", event.target.value)
                            }
                          />
                          <Label>Zip</Label>
                          {formFields.zip.error && (
                            <div className="validation-error">
                              {formFields.zip.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  ) : (
                    <Col lg={6} md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/mapMarker_blue.svg")
                                    .default
                                }
                                alt="map icon"
                                height={14}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            // value={formFields.appointmentAddress.value}
                            // name="appointmentAddress"
                            // onChange={(event) =>
                            //   this._onChangeFormField(
                            //     "appointmentAddress",
                            //     event.target.value
                            //   )
                            // }

                            value={formFields.borrowerAddress.value}
                            name="borrowerAddress"
                            placeholder=" "
                            autoComplete="off"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "borrowerAddress",
                                event.target.value
                              )
                            }
                          />
                          <Label>Property Address</Label>
                        </InputGroup>
                        {formFields.borrowerAddress.error && (
                          <div className="validation-error">
                            {formFields.borrowerAddress.error}
                          </div>
                        )}
                        {/* address suggestion list for input tag - borrower address */}
                        {googleAddressResult &&
                        googleAddressResult.length &&
                        isAddressListShow === "borrowerAddress" ? (
                          <ListGroup flush className="customSearchOptions">
                            {googleAddressResult.map((addr, index) => {
                              return (
                                <ListGroupItem
                                  key={addr.place_id || index}
                                  className="cursorPointer"
                                  onClick={() =>
                                    this._onSelectAddress(
                                      "borrowerAddress",
                                      addr
                                    )
                                  }
                                >
                                  {addr.description}
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )}

                  <Col sm="12" className="mt-2 mb-3">
                    <CustomInput
                      type="checkbox"
                      id="isBorrowerAddressSameAsAppointment_checkbox"
                      label="Signing & Property Address Same Location"
                      checked={isBorrowerAddressSameAsAppointment}
                      onChange={(event) =>
                        this._toggleSameAddress(event.target.checked)
                      }
                    />
                  </Col>

                  {!isBorrowerAddressSameAsAppointment && (
                    <Col sm="12">
                      <CustomInput
                        type="switch"
                        className="mb-3"
                        checked={
                          isBorrowerAddressSameAsAppointment
                            ? manuallyTypeAddress.borrowerAddress
                            : manuallyTypeAddress.appointmentAddress
                        }
                        // disabled={isBorrowerAddressSameAsAppointment}
                        // onChange={() =>
                        //   this._toggleManualOrGoogleAddress("borrowerAddress")
                        // }
                        // id="borrowerAddress_Custom_Switch"
                        // name="borrowerAddress"
                        // label="Enter Address Manually"

                        // checked={manuallyTypeAddress.appointmentAddress}
                        onChange={() =>
                          this._toggleManualOrGoogleAddress(
                            "appointmentAddress"
                          )
                        }
                        disabled={isBorrowerAddressSameAsAppointment}
                        id="appointmentAddress_Custom_Switch"
                        name="appointmentAddress"
                        label="Enter Address Manually"
                      />
                    </Col>
                  )}

                  {(isBorrowerAddressSameAsAppointment &&
                    manuallyTypeAddress.borrowerAddress) ||
                  (!isBorrowerAddressSameAsAppointment &&
                    manuallyTypeAddress.appointmentAddress) ? (
                    <>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSameAsAppointment
                                ? formFields.addressLine1.value
                                : formFields.closingAddressLine1.value
                            }
                            disabled={isBorrowerAddressSameAsAppointment}
                            name="addressLine1"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressLine1",
                                event.target.value
                              )
                            }
                          />
                          <Label>Signing Address Line 1</Label>
                          {formFields.closingAddressLine1.error && (
                            <div className="validation-error">
                              {formFields.closingAddressLine1.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSameAsAppointment
                                ? formFields.addressLine2.value
                                : formFields.closingAddressLine2.value
                            }
                            disabled={isBorrowerAddressSameAsAppointment}
                            name="closingAddressLine2"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressLine2",
                                event.target.value
                              )
                            }
                          />
                          <Label>Signing Address Line 2</Label>
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSameAsAppointment
                                ? formFields.city.value
                                : formFields.closingAddressCity.value
                            }
                            disabled={isBorrowerAddressSameAsAppointment}
                            name="closingAddressCity"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressCity",
                                event.target.value
                              )
                            }
                          />
                          <Label>City</Label>
                          {formFields.closingAddressCity.error && (
                            <div className="validation-error">
                              {formFields.closingAddressCity.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <div className="custom-select-wrapper">
                            <Input
                              type="select"
                              placeholder=" "
                              value={
                                isBorrowerAddressSameAsAppointment
                                  ? formFields.state.value
                                  : formFields.closingAddressState.value
                              }
                              disabled={isBorrowerAddressSameAsAppointment}
                              name="closingAddressState"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "closingAddressState",
                                  event.target.value
                                )
                              }
                            >
                              <option value="">Select</option>
                              {usaStates.map((state) => (
                                <option
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </Input>
                            <Label>State</Label>
                          </div>
                          {formFields.closingAddressState.error && (
                            <div className="validation-error">
                              {formFields.closingAddressState.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="number"
                            placeholder=" "
                            value={
                              isBorrowerAddressSameAsAppointment
                                ? formFields.zip.value
                                : formFields.closingAddressZip.value
                            }
                            disabled={isBorrowerAddressSameAsAppointment}
                            name="closingAddressZip"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressZip",
                                event.target.value
                              )
                            }
                          />
                          <Label>Zip</Label>
                          {formFields.closingAddressZip.error && (
                            <div className="validation-error">
                              {formFields.closingAddressZip.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  ) : (
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/mapMarker_blue.svg")
                                    .default
                                }
                                alt="map icon"
                                height={14}
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            value={
                              isBorrowerAddressSameAsAppointment
                                ? formFields.borrowerAddress.value
                                : formFields.appointmentAddress.value
                            }
                            disabled={isBorrowerAddressSameAsAppointment}
                            name="appointmentAddress"
                            placeholder=" "
                            autoComplete="off"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "appointmentAddress",
                                event.target.value
                              )
                            }
                          />
                          <Label>Signing Address</Label>
                        </InputGroup>
                        {formFields.appointmentAddress.error && (
                          <div className="validation-error">
                            {formFields.appointmentAddress.error}
                          </div>
                        )}
                        {/* address suggestion list for input tag - borrower address */}
                        {googleAddressResult &&
                        googleAddressResult.length &&
                        isAddressListShow === "appointmentAddress" ? (
                          <ListGroup flush className="customSearchOptions">
                            {googleAddressResult.map((addr, index) => {
                              return (
                                <ListGroupItem
                                  key={addr.place_id || index}
                                  className="cursorPointer"
                                  onClick={() =>
                                    this._onSelectAddress(
                                      "appointmentAddress",
                                      addr
                                    )
                                  }
                                >
                                  {addr.description}
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        ) : null}
                      </FormGroup>
                    </Col>
                  )}

                  <Col xl={4} md={6}>
                    <FormGroup
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: " ",
                          value: formatDateAndTime(
                            formFields.appointmentDate.value
                          ),
                        }}
                        onChange={(e) =>
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        onOpen={() =>
                          this.setState({ isOpenReactDatetime: true })
                        }
                        onClose={() =>
                          this.setState({ isOpenReactDatetime: false })
                        }
                        value={
                          formFields.appointmentDate.value
                            ? new Date(formFields.appointmentDate.value)
                            : ""
                        }
                        timeFormat={true}
                        timeConstraints={{ minutes: { step: 15 } }}
                      />
                      {formFields.appointmentDate.error && (
                        <div className="validation-error">
                          {formFields.appointmentDate.error}
                        </div>
                      )}
                      <Label>Appointment Date & Time</Label>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr />
              <div className="ClosingWrapper">
                <Row>
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.fileNumber.value}
                        name="fileNumber"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "fileNumber",
                            event.target.value
                          )
                        }
                      />
                      <Label>File Number</Label>
                      {formFields.fileNumber.error && (
                        <div className="validation-error">
                          {formFields.fileNumber.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <div className="InputWithButton">
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            value={formFields.clientId.value}
                            name="clientId"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "clientId",
                                event.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            {clients.map((client) => (
                              <option key={client._id} value={client._id}>
                                {/* {capitalize(client.name.full)} */}
                                {client?._signingCompany
                                  ? client.companyName
                                  : client?._client
                                  ? client.name?.full
                                  : client?._assistant
                                  ? // : client?._companyAssistant
                                    // ? client.name?.full
                                    // : client?._clientAssistant
                                    client.name?.full
                                  : "N/A"}
                              </option>
                            ))}
                          </Input>
                          <Label>Client</Label>
                        </div>
                        {formFields.clientId.error && (
                          <div className="validation-error">
                            {formFields.clientId.error}
                          </div>
                        )}
                      </FormGroup>

                      <Button
                        color="primary"
                        outline
                        onClick={() => this._toggleAddClientModal(true)}
                      >
                        Add Client
                      </Button>
                    </div>
                  </Col>
                  {formFields.clientId.value !== "" ? (
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            clients.filter(
                              (client) =>
                                client._id === formFields.clientId.value
                            )[0]?.email
                          }
                          name="clientId"
                          readOnly
                        />
                        <Label>Client Email</Label>
                      </FormGroup>
                    </Col>
                  ) : null}
                  {/* <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={formFields.lender.value}
                        name="lender"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeFormField("lender", event.target.value)
                        }
                      />
                      <Label>Order on behalf of</Label>
                      {formFields.lender.error && (
                        <div className="validation-error">
                          {formFields.lender.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col> */}
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={formFields.personOfContactName.value}
                        name="personOfContactName"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeFormField(
                            "personOfContactName",
                            event.target.value
                          )
                        }
                      />
                      <Label>Person Of Contact (Name)</Label>
                      {formFields.personOfContactName.error && (
                        <div className="validation-error">
                          {formFields.personOfContactName.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <FormGroup className="ChipsWrap floatingLabel valueAdded">
                      <InputGroup>
                        <Chips
                          value={formFields.personOfContactEmail.value}
                          onChange={(arr) => this._onChipSelect(arr, "chips")}
                          getChipValue={(e) => console.log(e)}
                          createChipKeys={[9, 13, 32]} //  Key codes to add chips
                          placeholder="Additional update emails for clients"
                        />
                        <Label>Person Of Contact (Email) </Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText href="#" id="pocEmail">
                            <i className="fa fa-info-circle"></i>
                            <UncontrolledTooltip
                              placement="top"
                              target="pocEmail"
                            >
                              Add emails to send status updates to
                            </UncontrolledTooltip>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.personOfContactEmail.error && (
                        <div className="validation-error">
                          {formFields.personOfContactEmail.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel ">
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          name="signingType"
                          placeholder=" "
                          value={formFields.signingType.value}
                          onChange={(event) =>
                            this._onChangeFormField(
                              "signingType",
                              event.target.value
                            )
                          }
                        >
                          <option value="Mobile">Mobile Signing</option>
                          <option value="RON">RON</option>
                        </Input>
                        <Label>Signing Type</Label>
                      </div>
                    </FormGroup>
                  </Col>

                  {formFields?.signingType?.value === "RON" ? (
                    <Col xl={4} md={6}>
                      <FormGroup
                        className="withChips floatingLabel valueAdded mb-3"
                        style={{ zIndex: 6 }}
                      >
                        <Select
                          isClearable={true}
                          options={this.state.ronPlatformOptions}
                          value={formFields?.ronPlatformType?.value}
                          onChange={(event) =>
                            this._onChangeFormField("ronPlatformType", event)
                          }
                          placeholder="Select"
                          className="customMultiSelect"
                          isMulti={true}
                        />
                        <Label style={{ zIndex: 3 }}>RON Platform Type</Label>
                      </FormGroup>
                    </Col>
                  ) : null}

                  {(formFields?.signingType?.value === "RON" ||
                    (formFields?.loanType?.value?.length === 1 &&
                      formFields?.loanType?.value[0]?.value ===
                        "AttorneyPhone")) && (
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="timeZone"
                            value={formFields.closingAddressTimeZone.value}
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressTimeZone",
                                event.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            {timezoneList().map((item) => (
                              <option>{item}</option>
                            ))}
                          </Input>
                          <Label style={{ zIndex: 3 }}>Time Zone</Label>
                        </div>
                        {formFields.closingAddressTimeZone.error && (
                          <div className="validation-error">
                            {formFields.closingAddressTimeZone.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                  {/* {formFields?.signingType?.value === "RON" ? (
                    <Col xl={4} md={6}>
                      <FormGroup className="withChips floatingLabel valueAdded">
                        <Select
                          isClearable={true}
                          placeholder="Select"
                          options={ronPlatformTypeOptions}
                          value={formFields?.ronPlatformType?.value}
                          onChange={(event) =>
                            this._onChangeFormField("ronPlatformType", event)
                          }
                          // placeholder="RON Platform Type"
                          className="customMultiSelect"
                          isMulti={true}
                        />
                        <Label style={{zIndex:3}}>RON Platform Type</Label>
                      </FormGroup>
                    </Col>
                  ) : null} */}

                  <Col xl={4} md={6}>
                    {/* <FormGroup className={`withChips floatingLabel valueAdded`}>
                      <CreatableSelect
                        isClearable
                        placeholder="Select"
                        className="customMultiSelect"
                        isMulti
                        components={animatedComponents}
                        value={formFields.loanType.value}
                        options={loanTypeConfig}
                        onChange={(event) =>
                          this._onChangeFormField("loanType", event)
                        }
                        blur={() => {
                          this._validateForm();
                        }}
                      />
                      <Label style={{ zIndex: 3 }}>Product Type</Label>

                      {formFields.loanType.error && (
                        <div className="validation-error">
                          {formFields.loanType.error}
                        </div>
                      )}
                    </FormGroup>  */}

                    <ProductType
                      value={formFields?.loanType?.value}
                      onChange={(type, event) =>
                        this._onChangeFormField(type, event)
                      }
                      // blur={() => this._validateForm()}
                      productTypeField={"loanType"}
                      selectedCategoriesField={"loanCategories"}
                      error={formFields?.loanType?.error}
                      onClickOutside={() => this._validateForm()}
                      clientCreateOrderOptions={loanTypeConfig}
                    />
                  </Col>
                  {formFields?.loanType?.value.filter(
                    (i) => i.value === "Other"
                  ).length > 0 && (
                    <Col xl={4} md={6}>
                      <FormGroup
                        className="floatingLabel"
                        style={{ zIndex: 2 }}
                      >
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields.loanTypeOther.value}
                          name="loanType"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "loanTypeOther",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Other</Label>
                      </FormGroup>
                      {formFields.loanTypeOther.error && (
                        <div className="validation-error">
                          {formFields.loanTypeOther.error}
                        </div>
                      )}
                    </Col>
                  )}
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="number"
                        value={formFields.witnessCount.value}
                        name="witnessCount"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeFormField(
                            "witnessCount",
                            event.target.value
                          )
                        }
                        // disabled={formFields.signingType.value === "Mobile"}
                      />
                      <Label>Witness Number</Label>
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/dollar.svg")
                                  .default
                              }
                              height={14}
                              alt="doller"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder=" "
                          type="text"
                          value={formFields.agentFee.value}
                          name="agentFee"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "agentFee",
                              event.target.value
                            )
                          }
                        />
                        <Label>Agent Fee</Label>
                      </InputGroup>
                      {formFields.agentFee.error && (
                        <div className="validation-error">
                          {formFields.agentFee.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr />

              <Card>
                <CardBody>
                  <CustomInput
                    type="checkbox"
                    id="isScanBackNeeded"
                    label="Scan Backs Needed"
                    className="mb-2"
                    checked={formFields.isScanBackNeeded.value}
                    onChange={(event) =>
                      this._onChangeFormField(
                        "isScanBackNeeded",
                        event.target.checked
                      )
                    }
                  />
                  <FormGroup
                    className={`mb-0 floatingLabel reactQuill ${
                      (formFields.instructions.value &&
                        formFields.instructions.value !== "<p><br></p>") ||
                      this.state.isFocusTextEditor
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <TextEditor
                      content={formFields.instructions.value}
                      onChange={(event) =>
                        this._onChangeFormField("instructions", event)
                      }
                      onFocus={() => this.setState({ isFocusTextEditor: true })}
                      onBlur={() => this.setState({ isFocusTextEditor: false })}
                    />
                    <Label>Instructions</Label>
                  </FormGroup>
                </CardBody>
              </Card>
              {/* {Object.keys(aiParserResponse)?.length ? ( */}
              <p className="bold">
                Not satisfied with the results of our automated system?
                {/* {Report} */}
                <span
                  onClick={() => {
                    if (!Object.keys(aiParserResponse)?.length) {
                      errorHandler("Pls upload a file first");
                      return;
                    }

                    this._toggleReportModal(true);
                  }}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    marginLeft: "10px",
                    textDecoration: "underline",
                  }}
                >
                  Report
                </span>
              </p>
              {/* ) : null} */}
              <Button
                color="primary"
                className="mx-auto d-block mt-4"
                size="lg"
                onClick={(e) =>
                  this.state.formFields?.fileNumber?.value?.length &&
                  !keepFileNo
                    ? this._duplicateFileNumberCheck(e)
                    : this._submitCreateAgentClosing(e)
                }
                disabled={loading?.submitLoading}
              >
                {loading?.submitLoading ? (
                  <i className="fa fa-spinner fa-spin mr-2" />
                ) : null}{" "}
                Create
              </Button>
            </Col>
            {uploadFiles.length > 0 && (
              <Col xl={4} lg={4}>
                <div className="pdfView">
                  <iframe src={uploadFiles[0]?.previewBlob}></iframe>
                </div>
              </Col>
            )}
            {closingDetail?.importedEmailBody &&
              !closingDetail?.documents?.length > 0 && (
                <Col xl={3} lg={4}>
                  <div className="pdfContent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: closingDetail?.importedEmailBody,
                      }}
                    />
                  </div>
                </Col>
              )}
            {/* {console.log("wefef", closingDetail?.documents[0]?.url)} */}
            {closingDetail?.documents?.length > 0 ? (
              <Col xl={3} lg={4}>
                <embed
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    closingDetail?.documents[0]?.url
                  )}&embedded=true`}
                  type="application/pdf"
                  width="100%"
                  height="800"
                />
              </Col>
            ) : null}
          </Row>
        </div>
        {/* modals */}
        <AddClientAgentModal
          isOpen={addClientModalData.isOpen}
          data={addClientModalData.data}
          resetTable={this._getAgentClient}
          toggle={this._toggleAddClientModal}
        />

        <ReportModal
          isOpen={reportModalData.isOpen}
          data={reportModalData.data}
          // isBorrowerAddressSameAsAppointment={
          //   isBorrowerAddressSameAsAppointment
          // }
          // manuallyTypeAddress={manuallyTypeAddress}
          uploadFiles={uploadFiles}
          getParentpayload={this._createPayload}
          // resetTable={this._getAgentClient}
          // reportingComment={()=>reportingAI()}
          toggle={this._toggleReportModal}
        />

        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />

        <FileChecked
          isOpen={fileChecked.isOpen}
          approved={this._approvedFileNumber}
          toggle={() => this._toggleFileCheckedModal()}
        />

        {/* {FileNumberUniqueChecked?.isOpen ? (
          <FileNumberDuplicateModal
            isOpen={FileNumberUniqueChecked.isOpen}
            toggle={() => this._toggleFileNumberUniqueCheckedModal()}
            FileNumberSubmitHandler={(e) => this._submitCreateAgentClosing(e)}
          />
        ) : null} */}
        {FileNumberUniqueChecked?.isOpen ? (
          <FileNumberDuplicateModal
            isOpen={FileNumberUniqueChecked.isOpen}
            fileNumber={formFields.fileNumber.value}
            toggle={() => this._toggleFileNumberUniqueCheckedModal()}
            existingFileNos={existingFileNos}
            keep={(e) =>
              this.setState({ keepFileNo: true }, () => {
                this._toggleFileNumberUniqueCheckedModal();
                this._submitCreateAgentClosing(e);
              })
            }
            FileNumberSubmitHandler={
              (value) => this._onChangeFormField("fileNumber", value)
            }
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
