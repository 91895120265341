import React, { createRef } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroupItem,
  ListGroup,
  CustomInput,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import { PostManager } from "../../../post-manager";
import { UploadQueueManager } from "../../../upload-queue-manager";
import {
  createNewSigningCompanyClosing,
  getAllFiltersData,
  uploadClosingPdf,
  // getLoanType,
  getClosingDetailsById,
  getAgentWithZipcode,
  createNewDraft,
  getAllCompanySettings,
  startAutomationForClosing,
  updateCompanyDetails,
  editDraft,
  getRonPlatformTypes,
  companyGetAllProductTypes,
  companyGetListTeamsOfClient,
  // FileNumberDuplicateCheck,
  createChatLog,
  FileNumberDuplicateMatchCheck,
  // editDraft,
} from "../../../http/http-calls";
import {
  capitalize,
  deepClone,
  formatDateAndTime,
  showToast,
  sleepTime,
  getPhoneNumberFromBrackets,
  formatDateMoment,
  errorHandler,
  formatDateAsPerTimeZoneWithDateAndTime,
  timezoneList,
  addUnixTimeToString,
  isRegularUser,
  generateUniqueFileNo,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { RegexConfig } from "../../../config/RegexConfig";
import usaStates from "../../../config/usa_states_titlecase";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import Select from "react-select/creatable";
import TextEditor from "../components/TextEdittor";
import Chips from "react-chips";
import InviteClient from "../components/InviteClient";
import FileChecked from "../components/FileChecked";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select";
import { LanguageList, loanTypeConfig } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ProductType from "../components/ProductType";
import InfiniteScrollDropdown from "../components/AgentDropdown";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";
import AppointmentScheduler from "../components/AppointmentScheduler";

const animatedComponents = makeAnimated();

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class CreateClosing extends React.Component {
  constructor(props) {
    super(props);
    this.appointmentSchedulerRef = createRef();
  }
  state = {
    formFields: {
      borrower: [
        {
          _id: {
            value: "",
          },
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          alternatePhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "English",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        },
      ],
      // borrowerName: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      additionalSigner: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      // email: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phoneHomeDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneWorkDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneMobileDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // homePhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // workPhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },

      borrowerSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      line1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      line2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      city: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      state: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      zip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanOfficer: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      client: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      clientTeams: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      agent: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      agentFee: {
        value: "",
        error: null,
        isValidate: true,
        isDirty: false,
      },
      // appointmentDate: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      //   isFieldDirty: false,
      // },
      closingAddressLine1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressState: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressZip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      files: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionId: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionSignerData: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      paymentInstructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      internalNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
        isFieldDirty: false,
      },
      companyNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    uploadFiles: [],
    clients: [],
    clientTeamsList: [],
    agents: [],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    pdfFile: null,
    loading: {
      fileUploadLoading: false,
      agentSearchLoading: false,
      submitLoading: false,
      draftLoading: false,
    },
    show: null,
    searchResult: [],
    loanType: {},
    isBorrowerAddressSame: false,
    isAppointmentAddressSame: false,
    isBorrowerSearch: true,
    isAppointmentSearch: true,
    inputLoanType: false,
    dropdownAgentList: [],
    signingInstruction: [],
    // ronPlatformOptions: [],
    draftClosing: null,
    companySetting: {},
    closingDetails: {},
    createDraft: false,
    createClosing: false,
    textInstruction: "",
    ronPlatformTypeOptions: [],
    isChecked: false,
    agentsZipCodePage: {
      page: 1,
      limit: 30,
    },
    agentSuggestedTotalCount: 0,
    appointmentSchedulerData: {
      appointmentDate: null,
      endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
    isFocusTextEditor: null,
    isFocusTextEditorPay: null,
    existingFileNos: null,
    keepFileNo: false,
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  _resetForm = () => {
    const { formFields } = deepClone(this.state);
    Object.keys(formFields).forEach((field) => {
      if (
        field !== "personOfContactEmail" &&
        field !== "isScanBackNeeded" &&
        field !== "loanType"
      ) {
        formFields[field].value = "";
      } else if (field === "isScanBackNeeded") {
        formFields[field].value = false;
      } else {
        formFields[field].value = [];
      }
      formFields[field].isDirty = false;
      formFields[field].isValidate = true;
      if (formFields[field].placeId) {
        formFields[field].placeId = null;
      }
    });
    this.setState({ formFields });
  };

  componentDidMount = async () => {
    const { location } = this.props;

    // Concurrent asynchronous operations using Promise.all
    await Promise.all([
      this._getAllFiltersData(),
      this._getAllCompanySettings(),
      this._getRonPlatformType(),
      this._companyGetAllProductTypes(),
    ]);

    // // Loan Type mapping
    // const LoanType = [
    //   "Refinance",
    //   "HomeEquity",
    //   "AttorneyPhone",
    //   "Purchase",
    //   "LoanMod",
    //   "ReverseMtg",
    //   "HybridESign",
    //   "DeedInLieu",
    //   "SingleDoc",
    //   "LoanApplication",
    //   "Other",
    // ];

    // const loanTypeOptions = [
    //   { value: "", label: "Select" },
    //   ...LoanType.map((item) => ({
    //     value: item,
    //     label: item.replace(/([A-Z])/g, " $1").trim(),
    //   })),
    // ];

    // // Set state for loanType
    // this.setState({ loanType: loanTypeOptions });

    // Process location search params
    if (location.search) {
      const id = location.search.split("&")[0].replace(/[^\w\s]/gi, "");
      this._getSigningClosingById(id);
    }

    // Fetch RON platform types
    // const res = await getRonPlatformTypes();
    // const ronOptions = Object.keys(res?.ronPlatformTypes)?.map(each => ({
    //   label: each,
    //   value: res?.ronPlatformTypes[each],
    // }));

    // this.setState({ ronPlatformTypeOptions: ronOptions });

    // Event subscriptions
    HeaderEventEmitter.subscribe("back-button", () => {
      this._redirectToClosingDashboard();
    });

    HeaderEventEmitter.subscribe("closingFile", (event) => {
      this.uploadClosingPdfFile(event);
    });
  };
  _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      console.log({ res });
      if (res?.customProductTypes) {
        this._setProductType(res?.customProductTypes?.products);
      }
      // showToast("Settings are", "success");
      // setLoading(false);
    } catch (error) {
      errorHandler(error);
      // setLoading(false);
    }
  };

  _setProductType = (products) => {
    // console.log("loantype",products)

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    this.setState({ loanType: groupedProducts });
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray = Object.entries(response.ronPlatformTypes).map(
          ([label, value]) => ({ label, value })
        );
        this.setState({ ronPlatformTypeOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  _getSigningClosingById = (id) => {
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this.setState({
            closingDetails: res.closing,
            draftClosing: res.closing.isSavedAsDraft,
          });
          HeaderEventEmitter.dispatch("closing-details", {
            closingDetails: res.closing,
          });
          this._setForm(res.closing);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // _setForm = (closingDetail) => {
  //   const { formFields, ronPlatformTypeOptions } = deepClone(this.state);
  //   // let obj = clients.find(
  //   //   (each) => (each.name.full === closingDetail?._client?.name?.full)||(each.companyName === closingDetail?._client?.companyName)
  //   // );
  //   //  console.log("client",obj)
  //   // formFields["borrowerName"].value =
  //   //   closingDetail?._borrower?.name?.full || "";
  //   // formFields["email"].value = closingDetail?._borrower?.email || "";
  //   // formFields["phone"].value = closingDetail?._borrower?.phone?.mobile
  //   // ? getPhoneNumberFromBrackets(closingDetail?._borrower?.phone?.mobile)
  //   // : "";
  //   // formFields["homePhone"].value = closingDetail?._borrower?.phone?.home
  //   // ? getPhoneNumberFromBrackets(closingDetail?._borrower?.phone?.home)
  //   // : "";
  //   // formFields["workPhone"].value = closingDetail?._borrower?.phone?.work
  //   // ? getPhoneNumberFromBrackets(closingDetail?._borrower?.phone?.work)
  //   // : "";
  //   let borrower = [];
  //   closingDetail?._borrower.forEach((each) => {
  //     borrower.push(
  //       // contactNumber: each.contactNumber || "",
  //       // pointOfContact: each.pointOfContact || "",
  //       // contactEmail: each.contactEmail || "",
  //       {
  //         _id: {
  //           value: each._id || "",
  //         },
  //         borrowerName: {
  //           value: each.name.full || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         email: {
  //           value: each.email || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         workPhone: {
  //           value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         alternatePhone: {
  //           value: getPhoneNumberFromBrackets(each?.phone?.alternate) || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         isForeignNational: {
  //           value: each.isForeignNational,
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         language: {
  //           value: each.language,
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         languageTypeOther: {
  //           value: each.languageTypeOther || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //         timeZone: {
  //           value: each.timeZone || "",
  //           isDirty: false,
  //           isValidate: true,
  //         },
  //       }
  //     );
  //   });
  //   formFields["borrower"] =
  //     borrower.length > 0
  //       ? borrower
  //       : [
  //           {
  //             _id: {
  //               value: "",
  //             },
  //             borrowerName: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             email: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             workPhone: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             alternatePhone: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             isForeignNational: {
  //               value: false,
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             language: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             languageTypeOther: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //             timeZone: {
  //               value: "",
  //               isDirty: false,
  //               isValidate: true,
  //             },
  //           },
  //         ];

  //   formFields["fileNumber"].value = closingDetail?.fileNumber;
  //   formFields["lender"].value = closingDetail?.lender || "";

  //   formFields["personOfContactName"].value =
  //     closingDetail?.personOfContact?.name || "";
  //   // console.log(closingDetail?.personOfContact?.email);
  //   formFields["personOfContactEmail"].value =
  //     closingDetail?.personOfContact?.email[0]?.trim().length
  //       ? closingDetail?.personOfContact?.email
  //       : [];
  //   // formFields["loanType"].value = closingDetail?.loanType || "";
  //   // formFields["loanType"].value =
  //   //   closingDetail?.loanType !== "Other" ? closingDetail?.loanType : "Other";
  //   formFields["signingType"].value = closingDetail?.signingType
  //     ? closingDetail?.signingType
  //     : "Mobile";

  //   formFields["loanTypeOther"].value = closingDetail?.loanTypeOther || "";

  //   formFields["companyNotes"].value = closingDetail?.companyNotes || "";
  //   formFields["internalNotes"].value = closingDetail?.internalNotes || "";
  //   formFields["signingCompanyFee"].value =
  //     closingDetail?.signingCompanyFee?.toString() || "";
  //   formFields["instructions"].value = closingDetail?.instructions || "";

  //   formFields["closingAddressTimeZone"].value =
  //     closingDetail?.closingAddress?.timeZone || "";

  //   if (Object.keys(ronPlatformTypeOptions)?.length) {
  //     const temp = [];
  //     ronPlatformTypeOptions?.forEach((each) => {
  //       closingDetail?.ronPlatformTypes.forEach((subEach) => {
  //         if (subEach === each?.value) {
  //           temp.push(each);
  //         }
  //       });
  //     });

  //     console.log({ temp });
  //     formFields["ronPlatformType"].value = temp || [];
  //   }

  //   formFields["client"].value = closingDetail?._client?.id || "";

  //   formFields["line1"].value = closingDetail?._borrower[0]?.address?.line1;
  //   formFields["line2"].value = closingDetail?._borrower[0]?.address?.line2;
  //   formFields["city"].value = closingDetail?._borrower[0]?.address?.city;
  //   formFields["state"].value = closingDetail?._borrower[0]?.address?.state;
  //   formFields["zip"].value = closingDetail?._borrower[0]?.address?.zip;
  //   formFields["borrowerSearch"].value =
  //     closingDetail?._borrower[0]?.address !== undefined
  //       ? closingDetail?._borrower[0]?.address?.line1 !== undefined
  //         ? closingDetail?._borrower[0]?.address?.line1
  //         : "," + closingDetail?._borrower[0]?.address?.line2 !== undefined
  //         ? closingDetail?._borrower[0]?.address?.line2
  //         : "," + closingDetail?._borrower[0]?.address?.city !== undefined
  //         ? closingDetail?._borrower[0]?.address?.city
  //         : "," + closingDetail?._borrower[0]?.address?.state !== undefined
  //         ? closingDetail?._borrower[0]?.address?.state
  //         : ""
  //       : "";

  //   formFields["closingAddressLine1"].value =
  //     closingDetail?.closingAddress?.line1;
  //   formFields["closingAddressLine2"].value =
  //     closingDetail?.closingAddress?.line2 || "";
  //   formFields["closingAddressCity"].value =
  //     closingDetail?.closingAddress?.city;
  //   formFields["closingAddressState"].value =
  //     closingDetail?.closingAddress?.state;
  //   formFields["closingAddressZip"].value = closingDetail?.closingAddress?.zip;
  //   formFields["closingSearch"].value =
  //     closingDetail?.closingAddress?.line1 ||
  //     "" + "," + closingDetail?.closingAddress?.line2 ||
  //     "" + "," + closingDetail?.closingAddress?.city ||
  //     "" + "," + closingDetail?.closingAddress?.state ||
  //     "";
  //   // formFields["closingSearch"].placeId = pid.place_id;
  //   // let manuallyTypeAddress = {
  //   //   borrowerAddress: true,
  //   //   appointmentAddress: true,
  //   // };
  //   formFields["loanType"].value = closingDetail?.loanType.map((each) => ({
  //     label: each,
  //     value: each,
  //   }));

  //   formFields["ronPlatformType"].value = closingDetail?.ronPlatformTypes.map(
  //     (each) => ({
  //       label: each,
  //       value: each,
  //     })
  //   );

  //   if (closingDetail?.appointmentDate) {
  //     formFields["appointmentDate"].value =
  //       formatDateAsPerTimeZoneWithDateAndTime(
  //         closingDetail?.appointmentDate,
  //         closingDetail?.closingAddress?.timeZone
  //       );
  //   }
  //   if (closingDetail.isSavedAsDraft) {
  //     if (closingDetail?.appointmentDate) {
  //       // if (closingDetail?.appointmentDate?.includes("EST")) {
  //       //   formFields["appointmentDate"].value = closingDetail?.appointmentDate.slice(
  //       //     0,
  //       //     closingDetail?.appointmentDate?.indexOf("E") - 1
  //       //   );
  //       // } else {
  //       let params = this.props.location.search.split("&");
  //       let agentId = params[1];
  //       let counterTime = params[2];
  //       let counterAmount = params[3];
  //       formFields["appointmentDate"].value =
  //         counterTime && counterTime !== "undefined"
  //           ? formatDateAsPerTimeZoneWithDateAndTime(
  //               counterTime,
  //               closingDetail?.closingAddress?.timeZone
  //             )
  //           : formatDateAsPerTimeZoneWithDateAndTime(
  //               closingDetail?.appointmentDate,
  //               closingDetail?.closingAddress?.timeZone
  //             );
  //       formFields["agentFee"].value =
  //         counterAmount !== "undefined"
  //           ? counterAmount
  //           : closingDetail.agentFee
  //           ? closingDetail.agentFee
  //           : closingDetail.biddingAmount
  //           ? closingDetail.biddingAmount
  //           : "";

  //       formFields["agent"].value = agentId !== "undefined" ? agentId : "";
  //     }
  //   }
  //   if (closingDetail?.closingAddress?.zip) {
  //     this._getAgentWithZipcode(closingDetail);
  //   }
  //   this.setState(
  //     {
  //       formFields,
  //       isBorrowerSearch: false,
  //       isAppointmentSearch: false,
  //     },
  //     () => {
  //       this._findStandardrate();
  //     }
  //   );
  // };

  _setForm = (closingDetail) => {
    console.log("_setForm >>", closingDetail);
    const { formFields, appointmentSchedulerData, ronPlatformTypeOptions } =
      deepClone(this.state);

    const mapBorrower = (borrower) => ({
      _id: { value: borrower._id || "" },
      borrowerName: {
        value: borrower.name.full || "",
        isDirty: false,
        isValidate: true,
      },
      email: { value: borrower.email || "", isDirty: false, isValidate: true },
      workPhone: {
        value: getPhoneNumberFromBrackets(borrower?.phone?.work) || "",
        isDirty: false,
        isValidate: true,
      },
      alternatePhone: {
        value: getPhoneNumberFromBrackets(borrower?.phone?.alternate) || "",
        isDirty: false,
        isValidate: true,
      },
      isForeignNational: {
        value: borrower.isForeignNational,
        isDirty: false,
        isValidate: true,
      },
      language: { value: borrower.language, isDirty: false, isValidate: true },
      languageTypeOther: {
        value: borrower.languageTypeOther || "",
        isDirty: false,
        isValidate: true,
      },
      timeZone: {
        value: borrower.timeZone || "",
        isDirty: false,
        isValidate: true,
      },
    });

    formFields.borrower = closingDetail?._borrower.map(mapBorrower) || [
      {
        _id: { value: "" },
        borrowerName: { value: "", isDirty: false, isValidate: true },
        email: { value: "", isDirty: false, isValidate: true },
        workPhone: { value: "", isDirty: false, isValidate: true },
        alternatePhone: { value: "", isDirty: false, isValidate: true },
        isForeignNational: { value: false, isDirty: false, isValidate: true },
        language: { value: "", isDirty: false, isValidate: true },
        languageTypeOther: { value: "", isDirty: false, isValidate: true },
        timeZone: { value: "", isDirty: false, isValidate: true },
      },
    ];

    formFields.fileNumber.value = closingDetail?.fileNumber;
    formFields.lender.value = closingDetail?.lender || "";
    formFields.personOfContactName.value =
      closingDetail?.personOfContact?.name || "";
    formFields.personOfContactEmail.value =
      closingDetail?.personOfContact?.email[0]?.trim().length
        ? closingDetail?.personOfContact?.email
        : [];
    formFields.signingType.value = closingDetail?.signingType || "Mobile";
    formFields.loanTypeOther.value = closingDetail?.loanTypeOther || "";
    formFields.companyNotes.value = closingDetail?.companyNotes || "";
    formFields.internalNotes.value = closingDetail?.internalNotes || "";
    if (closingDetail?.internalNotes) {
      formFields.internalNotes.isFieldDirty = true;
    }
    formFields.signingCompanyFee.value =
      closingDetail?.signingCompanyFee?.toString() || "";
    formFields.instructions.value = closingDetail?.instructions || "";
    formFields.paymentInstructions.value =
      closingDetail?.paymentInstructions || "";
    formFields.closingAddressTimeZone.value =
      closingDetail?.closingAddress?.timeZone || "";

    if (ronPlatformTypeOptions.length > 0) {
      const selectedRonPlatformTypes = ronPlatformTypeOptions.filter((option) =>
        closingDetail?.ronPlatformTypes.includes(option?.value)
      );

      formFields.ronPlatformType.value = selectedRonPlatformTypes || [];
    }

    formFields.client.value = closingDetail?._client?.id || "";

    formFields.clientTeams.value =
      closingDetail?._clientAssistantTeam?._id || "";

    const firstBorrowerAddress = closingDetail?._borrower[0]?.address || {};
    formFields.line1.value = firstBorrowerAddress.line1;
    formFields.line2.value = firstBorrowerAddress.line2 || "";
    formFields.city.value = firstBorrowerAddress.city;
    formFields.state.value = firstBorrowerAddress.state;
    formFields.zip.value = firstBorrowerAddress.zip;

    formFields.borrowerSearch.value = Object.values({
      line1: firstBorrowerAddress.line1,
      line2: firstBorrowerAddress.line2 || "",
      city: firstBorrowerAddress.city,
      state: firstBorrowerAddress.state,
      zip: firstBorrowerAddress.zip,
    })
      .filter(Boolean)
      .join(",");

    const closingAddress = closingDetail?.closingAddress || {};
    formFields.closingAddressLine1.value = closingAddress.line1 || "";
    formFields.closingAddressLine2.value = closingAddress.line2 || "";
    formFields.closingAddressCity.value = closingAddress.city || "";
    formFields.closingAddressState.value = closingAddress.state || "";
    formFields.closingAddressZip.value = closingAddress.zip || "";

    formFields.closingSearch.value = Object.values({
      line1: closingAddress.line1 || "",
      line2: closingAddress.line2 || "",
      city: closingAddress.city || "",
      state: closingAddress.state || "",
      zip: closingAddress.zip || "",
    })
      .filter(Boolean)
      .join(",");

    formFields.loanType.value =
      closingDetail?.loanType.map((each) => ({ label: each, value: each })) ||
      [];
    formFields.ronPlatformType.value =
      closingDetail?.ronPlatformTypes.map((each) => ({
        label: each,
        value: each,
      })) || [];

    // if (closingDetail?.appointmentDate) {
    //   formFields.appointmentDate.value = formatDateAsPerTimeZoneWithDateAndTime(
    //     closingDetail?.appointmentDate,
    //     closingAddress?.timeZone
    //   );
    //   formFields.appointmentDate.isFieldDirty = true;
    // }

    if (closingDetail.isSavedAsDraft && closingDetail?.appointmentDate) {
      const params = this.props.location.search.split("&");
      const agentId = params[1];
      const counterTime = params[2];
      const counterAmount = params[3];

      // formFields.appointmentDate.value =
      //   counterTime && counterTime !== "undefined"
      //     ? formatDateAsPerTimeZoneWithDateAndTime(
      //         counterTime,
      //         closingAddress?.timeZone
      //       )
      //     : formatDateAsPerTimeZoneWithDateAndTime(
      //         closingDetail?.appointmentDate,
      //         closingAddress?.timeZone
      //       );

      appointmentSchedulerData.appointmentDate =
        counterTime && counterTime !== "undefined"
          ? counterTime
          : closingDetail?.appointmentDate;

      formFields.agentFee.value =
        counterAmount !== "undefined"
          ? counterAmount
          : closingDetail.agentFee || closingDetail.biddingAmount || "";

      formFields.agent.value =
        agentId !== undefined
          ? { label: closingDetail?._agent?.name?.full, value: agentId }
          : "";
    }
    if (closingDetail?.closingAddress?.zip) {
      // this._getAgentWithZipcode(closingDetail);
      appointmentSchedulerData.appointmentDate = closingDetail?.appointmentDate;
    }

    this.setState(
      {
        formFields,
        isBorrowerSearch: false,
        isAppointmentSearch: false,
        appointmentSchedulerData,
      },
      () => {
        this._findStandardrate();
        if (this.state.formFields?.client?.value) {
          this._getCompanyListTeamsOfClient(
            this.state.formFields?.client?.value
          );
        }
      }
    );
  };

  _getAllFiltersData = () => {
    getAllFiltersData({
      signingType: this.state.formFields.signingType.value,
    })
      .then((res) => {
        // console.log(res);
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: item.name.full,
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        this.setState({ clients: res?.clients, agents: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllCompanySettings = async () => {
    try {
      let response = await getAllCompanySettings();

      const { formFields } = this.state;
      if (response?.companySetting?.signingInstruction?.length) {
        const defaultInstruction =
          response.companySetting?.signingInstruction.find(
            (each) => each.isDefault
          );

        formFields.instructionId.value = defaultInstruction?._id || "";
        formFields.instructions.value = defaultInstruction?.description || "";
        formFields.paymentInstructions.value =
          response?.companySetting?.paymentInstructions[0]?.description || "";
        formFields.instructionSignerData.value =
          defaultInstruction?.description || "";
      }
      if (response?.companySetting) {
        formFields["isScanBackNeeded"].value =
          response?.companySetting?.scanBackDocument?.isAlwaysRequired;
      }
      this.setState({
        formFields,
        companySetting: response?.companySetting,
        signingInstruction: response?.companySetting?.signingInstruction?.length
          ? response.companySetting.signingInstruction
          : [],
      });
      // setCompanySettings(response.companySetting);
      //  dispatch(updateUserObj(response.signingCompany))
      //  showToast(
      //   "Updated Successfully",
      //   "success"
      // );
    } catch (error) {
      errorHandler(error);
    }
  };

  // _getLoanType = () => {
  //   getLoanType()
  //     .then((res) => {
  //       console.log(res);
  //       let options = res.loanType.map((item) => ({
  //         value: item,
  //         label: item.replace(/([A-Z])/g, " $1").trim(),
  //       }));
  //       options.splice(0, 0, { value: "", label: "Select" });
  //       options.push({ value: "Others", label: "Others" });
  //       this.setState({ loanType: options });
  //     })
  //     .catch((error) => {
  //       showToast(
  //         error && error.reason && error.reason.length
  //           ? error.reason
  //           : "Server error. Try again after sometime.",
  //         "error"
  //       );
  //     });
  // };

  _redirectToClosingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.push(`/${userData.type}/${restLink}`);
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const {
        formFields,
        // isBorrowerSearch,
        // isAppointmentAddressSame,
        pdfFile,
      } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "borrower": {
              // console.log("7777", formFields[key]);
              formFields.borrower.forEach((each, index) => {
                console.log("989898", formFields[key][index]["borrowerName"]);

                if (
                  formFields.signingType.value === "RON" ||
                  each.borrowerName?.value.trim().length
                ) {
                  formFields[key][index]["borrowerName"].isDirty = false;
                  formFields[key][index]["borrowerName"].error = null;
                } else {
                  formFields[key][index]["borrowerName"].isDirty = true;
                  formFields[key][index]["borrowerName"].error = "*Required";
                  isFormValid = false;
                }

                if (
                  formFields[key][index]["email"].value &&
                  formFields[key][index]["email"].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(
                        formFields[key][index]["email"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  } else {
                    formFields[key][index]["email"].isDirty = true;
                    formFields[key][index]["email"].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key][index]["email"].isDirty = false;
                  formFields[key][index]["email"].error = null;
                }

                if (
                  formFields[key][index]["workPhone"].value &&
                  formFields[key][index]["workPhone"].value.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields[key][index]["workPhone"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["workPhone"].isDirty = true;
                    formFields[key][index]["workPhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["workPhone"].isDirty = false;
                  formFields[key][index]["workPhone"].error = null;
                }

                if (
                  formFields?.[key]?.[index]?.["alternatePhone"]?.value &&
                  formFields?.[key]?.[index]?.["alternatePhone"]?.value?.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields?.[key]?.[index]?.["alternatePhone"]?.value
                      )?.toLowerCase()
                    )
                  ) {
                    formFields[key][index]["alternatePhone"].isDirty = false;
                    formFields[key][index]["alternatePhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["alternatePhone"].isDirty = true;
                    formFields[key][index]["alternatePhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["alternatePhone"].isDirty = false;
                  formFields[key][index]["alternatePhone"].error = null;
                }
              });
              break;
            }

            // case "line1":
            // case "city":
            // case "state":
            // case "zip": {
            //   if (!isAppointmentAddressSame && isBorrowerSearch) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            case "closingAddressLine1": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressCity": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressState": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressZip": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "fileNumber": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "personOfContactName": {
              if (
                formFields[key].value &&
                formFields[key]?.value?.trim().length
              ) {
                if (
                  formFields[key]?.value?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    String(formFields[key]?.value).toLowerCase()
                  )
                ) {
                  formFields[key].error =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                  formFields[key].isDirty = true;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // case "personOfContactEmail":
            case "personOfContactEmail": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields.personOfContactEmail.value.forEach((each, index) => {
                  if (RegexConfig.email.test(String(each).toLowerCase())) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                });
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            // case "email": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     if (
            //       RegexConfig.email.test(
            //         String(formFields[key].value).toLowerCase()
            //       )
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Invalid Email";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }

            // case "appointmentDate":
            case "loanType":
            case "client": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "loanTypeOther": {
              if (formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            // case "homePhone":
            // case "workPhone":
            // case "phone": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     if (
            //       RegexConfig.phone.test(
            //         String(formFields[key].value).toLowerCase()
            //       )
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       isFormValid = false;
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Invalid Phone Number";
            //     }
            //   } else {
            //     if (
            //       (formFields["homePhone"].value &&
            //         formFields["homePhone"].value.length) ||
            //       (formFields["workPhone"].value &&
            //         formFields["workPhone"].value.length) ||
            //       (formFields["phone"].value &&
            //         formFields["phone"].value.length)
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       isFormValid = false;
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //     }
            //   }
            //   break;
            // }
            case "pointOfContact": {
              if (pdfFile === null) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Special Character is Not Allowed";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "lender":
            case "location": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "witnessCount": {
            //   if (formFields[key].value && formFields[key].value.length) {
            //     if (
            //       isNaN(formFields[key].value) ||
            //       Number(formFields[key].value) < 0
            //     ) {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Witness Number must be a positive number";
            //       isFormValid = false;
            //     } else {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            case "signingCompanyFee": {
              if (!this.props.userData?.user?.isTitleCompany) {
                if (formFields[key].value && formFields[key].value.length) {
                  if (
                    isNaN(formFields[key].value) ||
                    Number(formFields[key].value) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "borrowerSearch": {
            //   if (!isAppointmentAddressSame && isBorrowerSearch) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       if (formFields[key].placeId) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Invalid Address";
            //         isFormValid = false;
            //       }
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            case "closingSearch": {
              if (
                formFields.signingType.value === "Mobile" &&
                pdfFile === null &&
                this.props.location.search.length === 0 &&
                this.state.isAppointmentSearch
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  // console.log("qsee",formFields[key].placeId,this.state.draftClosing)
                  if (formFields[key].placeId || this.state.draftClosing) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "agentFee": {
              if (
                formFields["agent"].value?.value &&
                formFields["agent"].value?.value?.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Fee must be a positive number";
                  isFormValid = false;
                } else if (
                  !formFields[key].value &&
                  !formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                // formFields[key].isDirty = true;
                // formFields[key].error = "*Required";
                // isFormValid = false;
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _validateDraftForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, pdfFile } = this.state;
      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "closingAddressLine1":
            case "closingAddressCity":
            case "closingAddressZip": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "closingAddressState": {
            //   if (
            //     formFields.signingType.value === "RON" ||
            //     (formFields[key].value && formFields[key].value.trim().length)
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            // case "appointmentDate": {
            //   if (formFields[key].value && formFields[key].value.length) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            case "closingSearch": {
              if (
                pdfFile === null &&
                this.props.location.search.length === 0 &&
                this.state.isAppointmentSearch
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "closingAddressTimeZone": {
              console.log("qwera", formFields.signingType.value === "RON");
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            default: {
            }
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction, textInstruction } = deepClone(
      this.state
    );
    console.log("ioioi", fieldName, value);
    if (fieldName === "signingCompanyFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "closingSearch" || fieldName === "borrowerSearch") {
      googlePlaceSearch(value)
        .then((res) => {
          // console.log(res);
          this.setState({ searchResult: res, show: fieldName });
        })
        .catch((err) => {
          // console.log(err);
          showToast("Errors");
        });

      if (value === "") {
        formFields["closingAddressLine1"].value = "";
        formFields["closingAddressCity"].value = "";
        formFields["closingAddressState"].value = "";
        formFields["closingAddressZip"].value = "";
        formFields["closingSearch"].placeId = null;
      }
    }

    if (fieldName === "instructionId") {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructionId.value = findInstruction?._id || "";
      formFields.instructions.value = findInstruction?.description || "";
      formFields.instructionSignerData.value =
        findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
      formFields.appointmentDate.isFieldDirty = true;
    } else formFields[fieldName].value = value;

    if (fieldName === "internalNotes") {
      formFields.internalNotes.isFieldDirty = true;
    }

    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "alternatePhone"
    )
      formFields[fieldName].value = value.replace(
        /\([^]*\)|[^a-z0-9\s]|\D|\s/gi,
        ""
      );

    if (fieldName === "agent") {
      formFields[fieldName].value = value;
      formFields["agentFee"].isDirty = true;
    }

    // if (
    //   (fieldName === "closingAddressZip" && value.trim().length >= 5) ||
    //   // fieldName === "signingType"
    //   // formFields.closingAddressZip.value.trim().length > 0)
    //   // fieldName === "closingAddressZip" ||
    //   (fieldName === "signingType" &&
    //     formFields.closingAddressZip.value.trim().length > 0)
    // ) {
    //   this._getAgentWithZipcode({ postal: value });
    // }

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !textInstruction.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(textInstruction)
            : textInstruction.replace(/\d+(?= witness required)/, value)
          : textInstruction.replace(/<p>#.*?<\/p>/, "");

      this.setState({
        textInstruction: text,
      });
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );
      formFields["instructions"].value = text;
    }

    if (fieldName === "client") {
      formFields["clientTeams"].value = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        this.setState({ clientTeamsList: [] });
      }
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      // this._validateForm();
      if (
        fieldName === "loanType" ||
        fieldName === "closingAddressState" ||
        fieldName === "closingAddressCity" ||
        fieldName === "closingSearch" ||
        fieldName === "signingType" ||
        fieldName === "client"
      ) {
        // console.log("standardRate");
        this._findStandardrate();
      }
      if (fieldName === "client" || fieldName === "loanType") {
        // console.log("scanBack");
        this._findScanbackDocument();
      }
      if (fieldName === "signingType" && value) {
        this.setState(
          {
            isBorrowerSearch: false,
            isAppointmentSearch: false,
          },
          () => {
            this._validateForm();
          }
        );
      }
      if (fieldName === "witnessCount" || fieldName === "instructionId") {
        this._signerDataInstruction();
      }
      // if (
      //   fieldName === "signingType"
      //   // formFields.closingAddressZip.value.trim().length > 0)
      // ) {
      //   this._getAgentWithZipcode(value);
      // }

      if (
        (fieldName === "closingAddressZip" && value.trim().length >= 5) ||
        (fieldName === "signingType" &&
          formFields.closingAddressZip.value.trim().length > 0)
      ) {
        this._getAgentWithZipcode({ postal: value });
      }
    });
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _signerDataInstruction = () => {
    const { formFields, textInstruction } = deepClone(this.state);
    let finalText = textInstruction.concat(
      formFields.instructionSignerData.value
    );
    formFields["instructions"].value = this._sortText(finalText);

    this.setState({ formFields });
  };

  _findStandardrate = () => {
    const { formFields } = deepClone(this.state);

    let companySettingStandardFee = this._filterObjectForAgent(
      formFields?.closingAddressState?.value,
      formFields?.closingAddressCity?.value,
      formFields.loanType.value,
      formFields?.signingType?.value
    );

    let companySettingStandardFeeClient = this._filterObjectForCompany(
      formFields?.closingAddressState?.value,
      formFields?.closingAddressCity?.value,
      formFields.loanType.value,
      formFields?.signingType?.value,
      formFields?.client?.value
    );
    console.log("comp", companySettingStandardFeeClient);
    const agentFeesObj = companySettingStandardFee.find(
      (each) => !each?.isForClient
    );
    const companyFeesObj = companySettingStandardFeeClient.find(
      (each) => each?.isForClient
    );

    // countyMatched !== undefined ? countyMatched : noCounty;
    // console.log(
    //   "companySettingStandardFee",
    //   companySettingStandardFee[0]?.offerRate !== undefined
    //     ? companySettingStandardFee[0]?.offerRate?.toString()
    //     : formFields?.agentFee?.value
    //     ? formFields?.agentFee?.value?.toString()
    //     : ""
    // );
    // formFields["agentFee"].value =
    //   companySettingStandardFee[0]?.offerRate !== undefined
    //     ? companySettingStandardFee[0]?.offerRate?.toString()
    //     : formFields?.agentFee?.value
    //     ? formFields?.agentFee?.value?.toString()
    //     : "";

    formFields["agentFee"].value =
      agentFeesObj?.offerRate !== undefined
        ? agentFeesObj?.offerRate?.toString()
        : formFields.agentFee.value;

    formFields["signingCompanyFee"].value =
      companyFeesObj?.offerRate !== undefined
        ? companyFeesObj?.offerRate?.toString()
        : formFields.signingCompanyFee.value;

    this.setState({ formFields });
  };

  _filterObjectForAgent = (state, city, productType, signingType) => {
    const { companySetting, formFields } = deepClone(this.state);

    let loanTypes = productType.map((item) => item.value);

    let filteredData =
      companySetting &&
      Object.keys(companySetting).length > 0 &&
      companySetting?.standardFeeRates.filter(
        (item) =>
          !item.isForClient &&
          item.signingType === signingType &&
          loanTypes.includes(item.productType)
      );
    console.log("filteredData1", filteredData);

    if (state || state === "") {
      filteredData =
        Array.isArray(filteredData) &&
        filteredData?.filter((item) =>
          item.state.trim().length > 0 ? item.state === state : true
        );
    }
    console.log("filteredData2", filteredData);

    if (city) {
      filteredData =
        Array.isArray(filteredData) &&
        filteredData?.filter((item) =>
          item.county.trim().length > 0
            ? item.county
                .toLowerCase()
                .includes(formFields?.closingAddressCity?.value.toLowerCase())
            : true
        );
    }
    console.log("filteredData3", filteredData);
    return filteredData;
  };

  // _filterObjectForAgent = (state, city, productType, signingType) => {
  //   const { companySetting, formFields } = deepClone(this.state);

  //   const loanTypes = productType.map((item) => item.value);

  //   // Combine filtering conditions into a single function
  //   const filterItem = (item) =>
  //     !item.isForClient &&
  //     item.signingType === signingType &&
  //     loanTypes.includes(item.productType) &&
  //     (
  //       (!state || state.trim().length === 0 || item.state.trim().length === 0) ||
  //       (item.state.trim().length > 0 && item.state === state)
  //     ) &&
  //     (
  //       (!city || city.trim().length === 0) ||
  //       (item.county?.trim().length > 0 &&
  //         item.county.toLowerCase().includes(formFields?.closingAddressCity?.value.toLowerCase()))
  //     );

  //   // Apply the combined filter directly to companySetting.standardFeeRates
  //   const filteredData = companySetting?.standardFeeRates.filter(filterItem);

  //   console.log("filteredData", filteredData);

  //   return filteredData;
  // };

  // _filterObjectForCompany = (
  //   state,
  //   city,
  //   productType,
  //   signingType,
  //   clientId
  // ) => {
  //   console.log("qwas", state, city, productType, signingType, clientId);
  //   const { companySetting, formFields } = deepClone(this.state);

  //   let loanTypes = productType.map((item) => item.value);

  //   let filteredData =
  //     companySetting &&
  //     Object.keys(companySetting).length > 0 &&
  //     companySetting?.standardFeeRates.filter(
  //       (item) =>
  //         // !item.isForClient &&
  //         item.signingType === signingType &&
  //         loanTypes.includes(item.productType)
  //     );
  //   // console.log("filteredData1",filteredData)

  //   if (state) {
  //     filteredData =
  //       Array.isArray(filteredData) &&
  //       filteredData?.filter((item) =>
  //         item.state.trim().length > 0 ? item.state === state : false
  //       );
  //   } else {
  //     filteredData = filteredData?.filter((item) => item?.state === "");
  //   }
  //   console.log("filteredData2", filteredData);

  //   if (city) {
  //     filteredData =
  //       Array.isArray(filteredData) &&
  //       filteredData?.filter((item) =>
  //         item.county.trim().length > 0
  //           ? item.county
  //               .toLowerCase()
  //               .includes(formFields?.closingAddressCity?.value.toLowerCase())
  //           : false
  //       );
  //   } else {
  //     filteredData = filteredData?.filter((item) => item?.county === "");
  //   }

  //   if (clientId) {
  //     const matched = filteredData?.find(
  //       (item) => item?.isForClient && item?._client?.id === clientId
  //     );
  //     filteredData = matched
  //       ? [matched]
  //       : filteredData?.filter(
  //           (item) => item?.isForClient && item?._client === undefined
  //         );
  //   } else {
  //     filteredData = filteredData?.filter(
  //       (item) => item?.isForClient && item?._client === undefined
  //     );
  //   }
  //   // console.log("filteredData3", filteredData);
  //   return filteredData;
  // };

  _filterObjectForCompany = (
    state,
    city,
    productType,
    signingType,
    clientId
  ) => {
    console.log("qwas", state, city, productType, signingType, clientId);
    const { companySetting, formFields } = deepClone(this.state);

    let loanTypes = productType.map((item) => item.value);

    let filteredData =
      // companySetting &&
      Object.keys(companySetting).length > 0 &&
      companySetting?.standardFeeRates?.filter(
        (item) =>
          item.isForClient &&
          item.signingType === signingType &&
          loanTypes.includes(item.productType)
      );
    console.log("filteredData1", filteredData);

    if (state) {
      const matched = filteredData?.filter(
        (item) => item?.isForClient && item?.state === state
      );
      filteredData =
        matched.length > 0
          ? matched
          : Array.isArray(filteredData) &&
            filteredData?.filter((item) => item?.state === "");
      console.log("matchstate", matched);
    } else {
      filteredData = filteredData?.filter((item) => item?.state === "");
    }
    console.log("filteredData2", filteredData);

    if (city) {
      const matched = filteredData?.filter((item) =>
        item?.county?.toLowerCase().includes(city.toLowerCase())
      );
      console.log("matchcity", matched, filteredData);

      filteredData =
        matched.length > 0
          ? matched
          : filteredData?.filter(
              (item) =>
                item?.county === undefined || item?.county?.trim().length === 0
            );
      console.log("filterdatty", filteredData);
    } else {
      filteredData = filteredData?.filter((item) => !item?.county);
    }

    console.log("filteredData2", filteredData);

    if (clientId) {
      const matched = filteredData?.find(
        (item) => item?.isForClient && item?._client?.id === clientId
      );
      filteredData = matched
        ? [matched]
        : filteredData?.filter(
            (item) => item?.isForClient && item?._client === undefined
          );
    } else {
      filteredData = filteredData?.filter(
        (item) => item?.isForClient && item?._client === undefined
      );
    }
    console.log("filteredData3", filteredData);
    return filteredData;
  };

  // _findStandardrate = () => {
  //   const { companySetting, formFields } = deepClone(this.state);
  //   let countyMatched =
  //     companySetting &&
  //     Object.keys(companySetting).length > 0 &&
  //     companySetting?.standardFeeRates.find(
  //       (item) =>
  //         item.state === formFields?.closingAddressState?.value &&
  //         item.county
  //           .toLowerCase()
  //           .includes(formFields?.closingAddressCity?.value.toLowerCase()) &&
  //         item.productType === formFields?.loanType?.value
  //     );
  //   let noCounty =
  //     companySetting &&
  //     Object.keys(companySetting).length > 0 &&
  //     companySetting?.standardFeeRates.find(
  //       (item) =>
  //         item.state === formFields?.closingAddressState?.value &&
  //         item.county === "" &&
  //         item.productType === formFields?.loanType?.value
  //     );
  //   let companySettingStandardFee =
  //     countyMatched !== undefined ? countyMatched : noCounty;
  //   console.log("companySettingStandardFee", countyMatched, noCounty);
  //   formFields["agentFee"].value =
  //     companySettingStandardFee?.offerRate !== undefined
  //       ? companySettingStandardFee?.offerRate?.toString()
  //       : formFields?.agentFee?.value
  //       ? formFields?.agentFee?.value?.toString()
  //       : "";
  //   this.setState({ formFields });
  // };

  // _findStandardrate = () => {
  //   const { companySetting, formFields } = deepClone(this.state);
  //   if (
  //     formFields?.loanType?.value !== "" &&
  //     (formFields?.closingAddressCity?.value !== "" ||
  //       formFields?.closingAddressState?.value !== "")
  //   ) {
  //     console.log("inside standard rate");
  //     let companySettingStandardFee =
  //       Object.keys(companySetting).length > 0 &&
  //       companySetting.standardFeeRates.find(
  //         (item) =>
  //           item.state === formFields?.closingAddressState?.value &&
  //           item.county.toLowerCase() ===
  //             formFields?.closingAddressCity?.value.toLowerCase() &&
  //           item.productType === formFields?.loanType?.value
  //       );
  //     console.log("offerRate", companySettingStandardFee);
  //     formFields["agentFee"].value =
  //       companySettingStandardFee !== undefined
  //         ? companySettingStandardFee?.offerRate?.toString()
  //         : formFields?.agentFee?.value;
  //     this.setState({ formFields }, () => {
  //       console.log("22222", formFields);
  //     });
  //   }
  // };

  _findScanbackDocument = () => {
    const { companySetting, formFields } = deepClone(this.state);
    console.log("companySetting", companySetting);
    // formFields["isScanBackNeeded"].value =
    //   companySetting?.scanBackDocument?.isAlwaysRequired;
    if (
      formFields?.loanType?.value !== "" &&
      formFields?.client?.value !== ""
    ) {
      let companySettingScanBackDocument =
        companySetting &&
        companySetting?.scanBackDocument?.scanBackDocumentDetail?.some(
          (item) =>
            item?._client?._id === formFields.client.value &&
            item?.productType === formFields.loanType.value
        );
      // console.log("mm",companySettingScanBackDocument)
      // formFields["isScanBackNeeded"].value =
      // companySetting?.scanBackDocument?.isAlwaysRequired;

      formFields["isScanBackNeeded"].value = companySetting?.scanBackDocument
        ?.isAlwaysRequired
        ? companySetting?.scanBackDocument?.isAlwaysRequired
        : companySettingScanBackDocument;

      formFields["agentFee"].value = formFields.agentFee.value
        ? formFields.agentFee.value
        : "";
      this.setState({ formFields }, () => {
        console.log("3333", formFields);
        this._findStandardrate();
      });
    }
  };

  _updateFile = (event) => {
    const { uploadFiles } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      Object.keys(event.target.files).forEach((index) => {
        let objFile = event.target.files[index];
        if (objFile.type.includes("pdf") || objFile.type.includes("word")) {
          uploadFiles.push({
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            category: "documents",
          });
        } else {
          showToast("Only Doc, Docx or PDF file is allowed", "error");
        }
      });
    }
    this.setState({ uploadFiles });
  };

  _deleteContent = (index) => {
    let { uploadFiles } = deepClone(this.state);
    uploadFiles.splice(index, 1);
    this.setState({ uploadFiles });
  };

  _submitCreateSigningClosing = async (e) => {
    if (e) e.preventDefault();

    if (this.state.createClosing) {
      this._manageLoading("submitLoading", true);
      await this._markAllFieldDirty();
    } else {
      this._manageLoading("draftLoading", true);
      await this._markDraftFieldDirty();
    }
    const isFormValid = this.state.createClosing
      ? await this._validateForm()
      : await this._validateDraftForm();

    const isAppointmentSchedulerResponse =
      await this.appointmentSchedulerRef.current.getAppointmentSchedulerData();
    console.log("isAppointmentSchedulerResponse >>", {
      ...isAppointmentSchedulerResponse.appointmentSchedulerData,
    });

    if (!isFormValid) {
      // showToast("Please Fill The Required Fields", "error");
      Object.keys(this.state.formFields)
        .filter((item) => item !== "closingSearch")
        .forEach((key) => {
          if (this.state.formFields[key].error) {
            const result = key.replace(/([A-Z])/g, " $1");
            const finalResult =
              result.charAt(0).toUpperCase() + result.slice(1);
            const finalResult2 =
              finalResult === "Loan Type"
                ? "Product Type"
                : finalResult === "Closing Address Time Zone"
                ? "Closing Time Zone"
                : finalResult;
            // console.log(key)
            showToast(`Please provide ${finalResult2} `, "error");
          }
        });
      if (
        !this.state.createDraft &&
        (!this.state.formFields.fileNumber.value ||
          !this.state.formFields.fileNumber.value.trim().length > 0)
      ) {
        this._toggleFileCheckedModal(true);
      }

      this._manageLoading("submitLoading", false);
      this._manageLoading("draftLoading", false);
    }
    console.log("3443", isFormValid);
    if (isFormValid && isAppointmentSchedulerResponse?.isValid) {
      this.props.showLoader("Create Closing...");
      // setTimeout(()=>{this.props.hideLoader()}, 30000);
      const {
        formFields,
        uploadFiles,
        isBorrowerAddressSame,
        isAppointmentAddressSame,
      } = deepClone(this.state);

      // const splitBorrowerName = formFields.borrowerName.value.trim().split(" ");

      const payload = {
        borrower: [],
        ...isAppointmentSchedulerResponse.appointmentSchedulerData,
        // borrower: {
        //   email: formFields.email.value ? formFields.email.value : undefined,
        //   phone: {},
        //   address: {
        //     line1: formFields.line1?.value?.trim().length
        //       ? formFields?.line1?.value
        //       : undefined,
        //     line2: formFields?.line2?.value?.trim().length
        //       ? formFields?.line2?.value
        //       : undefined,
        //     city: formFields.city?.value?.trim().length
        //       ? formFields?.city?.value
        //       : undefined,
        //     state: formFields.state?.value?.trim().length
        //       ? formFields?.state?.value
        //       : undefined,
        //     zip: formFields.zip?.value?.trim().length
        //       ? formFields?.zip?.value
        //       : undefined,
        //   },
        //   name: {
        //     first: splitBorrowerName.slice(0, 1).join(" "),
        //     last: splitBorrowerName[1]
        //       ? splitBorrowerName.slice(1).join(" ")
        //       : "",
        //   },
        // },
        signingCompanyFee: this.props.userData?.user?.isTitleCompany
          ? "1000"
          : formFields.signingCompanyFee.value
          ? formFields.signingCompanyFee.value
          : undefined,
        fileNumber: formFields.fileNumber.value
          ? formFields.fileNumber.value
          : undefined,
        lender: formFields.lender.value ? formFields.lender.value : undefined,

        personOfContact: {
          name: formFields.personOfContactName.value,
          email:
            formFields.personOfContactEmail.value.length > 0
              ? formFields.personOfContactEmail.value
              : [],
        },

        // loanType:
        //   formFields.loanType.value !== "Other"
        //     ? formFields.loanType.value
        //       ? formFields.loanType.value
        //       : undefined
        //     : "Other",

        loanTypeOther:
          formFields.loanType.value.filter((item) => item.value === "Other")
            .length > 0
            ? formFields.loanTypeOther.value
            : undefined,

        // appointmentDate: formatDateMoment(formFields.appointmentDate.value),

        closingAddress: {
          line1: formFields.closingAddressLine1?.value?.trim().length
            ? formFields?.closingAddressLine1?.value
            : undefined,
          line2: formFields?.closingAddressLine2?.value?.trim().length
            ? formFields?.closingAddressLine2?.value
            : undefined,
          city: formFields?.closingAddressCity?.value?.trim().length
            ? formFields?.closingAddressCity?.value
            : undefined,
          state: formFields?.closingAddressState?.value?.trim().length
            ? formFields?.closingAddressState?.value
            : undefined,
          zip: formFields?.closingAddressZip?.value?.trim().length
            ? formFields?.closingAddressZip?.value
            : undefined,
          timeZone: formFields.closingAddressTimeZone.value
            ? formFields?.closingAddressTimeZone?.value
            : undefined,
        },

        instructions: formFields.instructions.value
          ? formFields.instructions.value
          : undefined,
        paymentInstructions: formFields.paymentInstructions.value
          ? formFields.paymentInstructions.value
          : undefined,
        isScanBackNeeded: formFields.isScanBackNeeded.value,
        clientId: formFields.client.value ? formFields.client.value : undefined,
        agentId:
          formFields.agent.value !== ""
            ? formFields.agent.value.value
            : undefined,
        agentFee:
          formFields.agentFee.value !== ""
            ? formFields.agentFee.value
            : undefined,
      };
      if (formFields.additionalSigner.value) {
        payload["additionalSigner"] = formFields.additionalSigner.value;
      }

      // if (formFields?.appointmentDate?.isFieldDirty) {
      //   payload["appointmentDate"] = formatDateMoment(
      //     formFields?.appointmentDate?.value
      //   );
      // }

      if (formFields.signingType.value === "RON")
        payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
          ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
          : undefined;

      // let borrower = formFields.borrower.map((item, index) =>
      //   Object.fromEntries(
      //     Object.entries(item).map(([key, value]) => [key, value.value])
      //   )
      // );
      let borrower = await formFields.borrower.map(
        ({
          _id,
          borrowerName,
          email,
          workPhone,
          alternatePhone,
          isForeignNational,
          language,
          languageTypeOther,
          timeZone,
        }) => {
          const splitBorrowerName = borrowerName.value.trim().split(" ");

          return {
            _id: _id?.value?.trim().length ? _id.value : undefined,
            name: {
              first: splitBorrowerName.slice(0, 1).join(" "),
              last: splitBorrowerName[1]
                ? splitBorrowerName.slice(1).join(" ")
                : "",
            },
            // _id: _id?.value ? _id.value : undefined,
            email: email.value ? email.value : undefined,
            phone: {
              work: workPhone.value ? workPhone.value : undefined,
              alternate: alternatePhone.value
                ? alternatePhone.value
                : undefined,
            },
            isForeignNational: isForeignNational.value,
            language: language.value ? language.value : undefined,
            languageTypeOther: languageTypeOther.value
              ? languageTypeOther.value
              : undefined,
            timeZone: timeZone.value ? timeZone.value : undefined,
            address: {},
          };
        }
      );
      payload["borrower"] = borrower;

      if (
        formFields.loanOfficer.value &&
        formFields.loanOfficer.value.trim().length
      ) {
        payload["loanOfficer"] = formFields.loanOfficer.value.trim();
      }

      if (
        formFields?.internalNotes?.isFieldDirty &&
        formFields?.internalNotes?.value &&
        formFields?.internalNotes?.value?.trim()?.length
      ) {
        payload["internalNotes"] = formFields.internalNotes.value.trim();
      }

      if (
        formFields.companyNotes.value &&
        formFields.companyNotes.value.trim().length
      ) {
        payload["companyNotes"] = formFields.companyNotes.value.trim();
      }
      if (
        formFields.signingType.value &&
        formFields.signingType.value.trim().length
      ) {
        payload["signingType"] = formFields.signingType.value.trim();
      }

      if (
        formFields.witnessCount.value &&
        formFields.witnessCount.value.trim().length
      ) {
        payload["witnessCount"] = Number(formFields.witnessCount.value.trim());
      }

      if (
        formFields?.clientTeams?.value &&
        formFields?.clientTeams?.value?.trim()?.length
      ) {
        payload["_clientAssistantTeam"] =
          formFields?.clientTeams?.value?.trim();
      }

      if (formFields.loanType.value) {
        payload["loanType"] = await formFields.loanType.value.map(
          (item) => item.value
        );
      }
      if (formFields.loanCategories.value) {
        payload["loanCategories"] = await formFields?.loanCategories?.value;
      }

      if (formFields.ronPlatformType.value) {
        payload["ronPlatformTypes"] =
          await formFields.ronPlatformType.value.map((item) => item.value);
      }

      if (
        formFields.loanOfficer.value &&
        formFields.loanOfficer.value.trim().length
      ) {
        payload["loanOfficer"] = formFields.loanOfficer.value.trim();
      }

      if (formFields.borrowerSearch.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.borrowerSearch.placeId
        );
        let abbr = usaStates.find(
          (item) => item.name === addressObj.state
        ).abbreviation;
        await borrower.map(
          (item) => (
            // eslint-disable-next-line no-sequences
            (item.address["line1"] = addressObj.address),
            (item.address["city"] = addressObj.city),
            (item.address["county"] = addressObj.county),
            (item.address["state"] =
              addressObj.state.length > 2 ? abbr : addressObj.state),
            (item.address["zip"] = addressObj.postal),
            (item.address["lat"] = addressObj.lat),
            (item.address["lng"] = addressObj.lng)
          )
        );
        // payload.borrower.address["line1"] = addressObj.address;
        // payload.borrower.address["city"] = addressObj.city;
        // payload.borrower.address["county"] = addressObj.county;
        // payload.borrower.address["state"] =
        //   addressObj.state.length > 2 ? abbr : addressObj.state;
        // payload.borrower.address["zip"] = addressObj.postal;
        // payload.borrower.address["lat"] = addressObj.lat;
        // payload.borrower.address["lng"] = addressObj.lng;

        if (isBorrowerAddressSame) {
          //never exec
          payload.closingAddress["line1"] = addressObj.address;
          payload.closingAddress["city"] = addressObj.city;
          payload.closingAddress["county"] = addressObj.county;
          payload.closingAddress["state"] =
            addressObj.state.length > 2 ? abbr : addressObj.state;
          payload.closingAddress["zip"] = addressObj.postal;
          payload.closingAddress["lat"] = addressObj.lat;
          payload.closingAddress["lng"] = addressObj.lng;
          payload.closingAddress["timeZone"] =
            formFields.closingAddressTimeZone.value;
        }
      } else {
        await borrower.map(
          (item) => (
            // eslint-disable-next-line no-sequences
            (item.address["line1"] = formFields.line1.value),
            (item.address["line2"] = formFields.line2.value),
            (item.address["city"] = formFields.city.value),
            (item.address["state"] = formFields.state.value),
            (item.address["zip"] = formFields.zip.value)
          )
        );
      }

      if (isBorrowerAddressSame) {
        //never exec
        if (formFields.closingSearch.placeId) {
          const addressObj = await googlePlaceDetails(
            formFields.closingSearch.placeId
          );
          let abbr = usaStates.find(
            (item) => item.name === addressObj.state
          ).abbreviation;

          payload.closingAddress["line1"] = addressObj.address;
          payload.closingAddress["city"] = addressObj.city;
          payload.closingAddress["county"] = addressObj.county;
          payload.closingAddress["state"] =
            addressObj.state.length > 2 ? abbr : addressObj.state;
          payload.closingAddress["zip"] = addressObj.postal;
          payload.closingAddress["lat"] = addressObj.lat;
          payload.closingAddress["lng"] = addressObj.lng;
          payload.closingAddress["timeZone"] =
            formFields.closingAddressTimeZone.value;
        }
      }

      if (isAppointmentAddressSame) {
        if (formFields.closingSearch.placeId) {
          const addressObj = await googlePlaceDetails(
            formFields.closingSearch.placeId
          );
          let abbr = usaStates.find(
            (item) => item.name === addressObj.state
          ).abbreviation;
          await borrower.map(
            (item) => (
              // eslint-disable-next-line no-sequences
              (item.address["line1"] = addressObj.address),
              (item.address["city"] = addressObj.city),
              (item.address["state"] =
                addressObj.state.length > 2 ? abbr : addressObj.state),
              (item.address["zip"] = addressObj.postal),
              (item.address["lat"] = addressObj.lat),
              (item.address["lng"] = addressObj.lng)
            )
          );
          // payload.borrower.address["line1"] = addressObj.address;
          // payload.borrower.address["city"] = addressObj.city;
          // payload.borrower.address["state"] =
          //   addressObj.state.length > 2 ? abbr : addressObj.state;
          // payload.borrower.address["zip"] = addressObj.postal;
          // payload.borrower.address["lat"] = addressObj.lat;
          // payload.borrower.address["lng"] = addressObj.lng;
        } else {
          await borrower.map(
            (item) => (
              // eslint-disable-next-line no-sequences
              (item.address["line1"] = formFields.closingAddressLine1.value),
              (item.address["line2"] = formFields.closingAddressLine2.value),
              (item.address["city"] = formFields.closingAddressCity.value),
              (item.address["state"] = formFields.closingAddressState.value),
              (item.address["zip"] = formFields.closingAddressZip.value)
            )
          );
        }
      }

      // if (
      //   formFields.closingSearch.placeId ||
      //   (formFields.closingAddressCity.value &&
      //     formFields.closingAddressState.value)
      // ) {
      //   delete payload.closingAddress["timeZone"];
      // }

      // if (formFields.homePhone.value) {
      //   payload.borrower.phone[
      //     "home"
      //   ] = `(${formFields.phoneHomeDialCode.value})${formFields.homePhone.value}`;
      // }
      // if (formFields.workPhone.value) {
      //   payload.borrower.phone[
      //     "work"
      //   ] = `(${formFields.phoneWorkDialCode.value})${formFields.workPhone.value}`;
      // }
      // if (formFields.phone.value) {
      //   payload.borrower.phone[
      //     "mobile"
      //   ] = `(${formFields.phoneMobileDialCode.value})${formFields.phone.value}`;
      // }

      if (uploadFiles && uploadFiles.length) {
        try {
          payload["documents"] = [];

          let postID = PostManager.addMediaFilesCount(uploadFiles.length);

          PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
            if (id.postID === postID) {
              console.log("payload :>> ", id);
              await sleepTime(500);
              // this._createSigningCompanyClosing(payload);
              // if (this.state.draftClosing) {
              //   this._draftSigningCompanyClosing(payload);
              // } else {
              //   if (this.props.location.search) {
              //     this._updateCompanyDetails(payload);
              //   } else {
              //     this._createSigningCompanyClosing(payload);
              //   }
              // }
              // if (this.state.draftClosing) {
              //   this._draftSigningCompanyClosing(payload);
              // } else if (
              //   this.props.location.search &&
              //   this.state.draftClosing
              // ) {
              //   let params = this.props.location.search.split("&");
              //   let id = params[0].replace(/[^\w\s]/gi, "");
              //   try {
              //     let response = await this._updateSigningCompanyDraftClosing(
              //       id,
              //       payload
              //     );
              //     console.log(response);
              //     this._updateCompanyDetails(id, payload);
              //   } catch (error) {
              //     showToast(
              //       error && error.reason && error.reason.length
              //         ? error.reason
              //         : "Server error. Try again after sometime.",
              //       "error"
              //     );
              //   }
              // } else {
              //   this._createSigningCompanyClosing(payload);
              // }
              this.apiCallingDraftOrClosing(payload);

              PostManager.deletePostID(postID);
            } else {
              console.log("into else");
              return;
            }
          });

          uploadFiles.forEach((uploadFile) => {
            // for logo images
            let mediaData = {
              file: uploadFile.uploadData,
              blobObject: uploadFile.previewBlob,
              isPrivate: uploadFile.isPrivate
                ? uploadFile.isPrivate
                : undefined,
            };
            const uploadId = UploadQueueManager.addMediaToQueue(
              mediaData,
              "pdf"
            );

            // Listen for upload complete
            UploadQueueManager.onUploadComplete(
              uploadId,
              async (mediaResponse) => {
                PostManager.onSingleMediaFileUploadCompleted(postID);
                // Upload complete
                // Get content id from backend
                payload["documents"].push({
                  title: addUnixTimeToString(
                    mediaResponse.data.media.file.name
                  ),
                  url: mediaResponse.fileUrl,
                  docType: mediaResponse.data.mediaType,
                  category: uploadFile?.category,
                  isPrivate: uploadFile.isPrivate
                    ? uploadFile.isPrivate
                    : undefined,
                });
              }
            );

            UploadQueueManager.onUploadFailed(uploadId, async (error) => {
              console.log("response", error);
              this.props.hideLoader();
              errorHandler(error);
            });
          });
        } catch (error) {
          this.props.hideLoader();
          errorHandler(error);
        }
      } else {
        this.apiCallingDraftOrClosing(payload);
        // if (this.state.draftClosing) {
        //   this._draftSigningCompanyClosing(payload);
        // } else if (this.props.location.search&&this.state.draftClosing) {
        //     let params = this.props.location.search.split("&");
        //     let id = params[0].replace(/[^\w\s]/gi, "");
        //     try {
        //       let response = await this._updateSigningCompanyDraftClosing(
        //         id,
        //         payload
        //       );
        //       console.log(response);
        //       this._updateCompanyDetails(id, payload);
        //     } catch (error) {
        //       showToast(
        //         error && error.reason && error.reason.length
        //           ? error.reason
        //           : "Server error. Try again after sometime.",
        //         "error"
        //       );
        //     }
        //   } else {
        //     this._createSigningCompanyClosing(payload);
        //   }
      }
    } else {
      this._manageLoading("submitLoading", false);
      this._manageLoading("draftLoading", false);
    }
  };

  apiCallingDraftOrClosing = async (payload) => {
    let params = this.props.location?.search?.split("&");
    let id = params[0].replace(/[^\w\s]/gi, "");
    console.log("egtyhv5", this.props.location?.search?.trim().length > 0);
    //for create Closing
    // let { formFields } = deepClone(this.state);

    // console.log("payload: ", payload);
    if (
      this.state.createClosing &&
      !this.state.draftClosing
      //  && !this.props.location.search.trim().length > 0
    ) {
      this._createSigningCompanyClosing(payload);
    }

    // // for create Draft
    if (
      this.state.createDraft
      //  && !this.props.location.search.trim().length > 0
    ) {
      this._draftSigningCompanyClosing(payload);
    }

    // // edit draft
    // if (this.state.draftClosing && this.props.location.search.trim().length) {
    //   this._updateSigningCompanyDraftClosing(id, payload);
    // }

    // for converting draft into closing

    if (
      this.props.location?.search?.trim().length > 0 &&
      this.state.draftClosing &&
      this.state.createClosing
      // formFields.agent?.value?.trim().length
    ) {
      // let isFormValid = await this._validateForm();
      // // console.log("3443",isFormValid)
      // if (!isFormValid) {
      //   // showToast("Please Fill The Required Fields", "error");
      //   Object.keys(this.state.formFields).forEach((key) => {
      //     if (this.state.formFields[key].error) {
      //       const result = key.replace(/([A-Z])/g, " $1");
      //       const finalResult =
      //         result.charAt(0).toUpperCase() + result.slice(1);
      //       // console.log(key)
      //       showToast(`Please provide ${finalResult} `, "error");
      //     }
      //   });
      // }
      // if (isFormValid) {
      try {
        let response = await this._updateSigningCompanyDraftClosing(
          id,
          payload
        );

        if (response?.closing?.internalNotes) {
          const payload = {
            text: response?.closing?.internalNotes,
            closingId: response?.closing?.id,
            isVisibleClient: false,
            isVisibleAgent: false,
            isInternal: true,
          };
          createChatLog(payload);
        }
        this._updateCompanyDetails(id, payload);
      } catch (error) {
        errorHandler(error);
      }
      // } else {
      //   this.props.hideLoader();
      // }
    }
  };
  _updateCompanyDetails = (id, payload) => {
    // const { formFields, companySetting } = deepClone(this.state);
    updateCompanyDetails(id, payload)
      .then((res) => {
        // if (
        //   companySetting.bidAutomationSetting.autoSendBidRequest &&
        //   formFields.agentFee.value.trim().length
        // ) {
        //   this._startAutomation(res.closing);
        // }
        showToast("Closing Draft Successfully", "success");
        this.props.hideLoader();
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _updateSigningCompanyDraftClosing = (id, payload) => {
    return new Promise((resolve, reject) => {
      editDraft(id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _draftSigningCompanyClosing = (data) => {
    // console.log("dahyah", data);
    const { formFields, companySetting } = deepClone(this.state);
    createNewDraft(data)
      .then((res) => {
        if (
          companySetting.bidAutomationSetting.autoSendBidRequest &&
          formFields?.agentFee?.value?.trim()?.length
        ) {
          this._startAutomation(res?.closing);
        }

        if (res?.closing?.internalNotes) {
          const payload = {
            text: res?.closing?.internalNotes,
            closingId: res?.closing?.id,
            isVisibleClient: false,
            isVisibleAgent: false,
            isInternal: true,
          };
          createChatLog(payload);
        }

        showToast("Closing Draft Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("draftLoading", false);
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
        this._manageLoading("draftLoading", false);
      });
  };

  _createSigningCompanyClosing = (data) => {
    const { formFields, companySetting } = deepClone(this.state);
    createNewSigningCompanyClosing(data)
      .then((res) => {
        if (
          companySetting.bidAutomationSetting.autoSendBidRequest &&
          formFields.agentFee?.value?.trim().length &&
          !formFields.agent.value?.value?.trim().length > 0
        ) {
          this._startAutomation(res.closing);
        }

        if (res?.closing?.internalNotes) {
          const payload = {
            text: res?.closing?.internalNotes,
            closingId: res?.closing?.id,
            isVisibleClient: false,
            isVisibleAgent: false,
            isInternal: true,
          };
          createChatLog(payload);
        }

        showToast("Closing Created Successfully", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.push("/signingcompany/dashboard");
      })
      .catch((error) => {
        console.log("errore", error);
        errorHandler(error);
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
      });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };
  _markDraftFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      formFields["closingAddressCity"].isDirty = true;
      formFields["closingAddressState"].isDirty = true;
      formFields["closingAddressZip"].isDirty = true;
      // formFields["appointmentDate"].isDirty = true;
      formFields["closingSearch"].isDirty = true;
      formFields["closingAddressTimeZone"].isDirty = true;
      formFields["clientTeams"].isDirty = true;

      this.setState({ formFields }, () => resolve(true));
    });
  };

  uploadClosingPdfFile = (event) => {
    if (event.target.files[0].type.includes("pdf")) {
      this.setState({ pdfFile: event.target.files[0], uploadFiles: [] }, () => {
        const pdf = new FormData();
        pdf.append("file", this.state.pdfFile);
        this._manageLoading("fileUploadLoading", true);
        HeaderEventEmitter.dispatch("confirmationFileLoading", true);
        uploadClosingPdf(pdf).then(
          (response) => {
            if (response?.data?.borrowers.length) {
              this._resetForm();

              console.log("response?.data >>", response?.data);

              this.setFormData(response?.data);
              this._addingInUpload();
              // showToast("File sent Successfully", "success");
              showToast("order Created", "success");
            } else {
              this.setState({ pdfFile: null });
              showToast("Something went wrong!", "error");
            }
            this._manageLoading("fileUploadLoading", false);
            HeaderEventEmitter.dispatch("confirmationFileLoading", false);
          },
          (error) => {
            console.log("error>>", error);
            errorHandler(error);
            this._manageLoading("fileUploadLoading", false);
            HeaderEventEmitter.dispatch("confirmationFileLoading", false);
          }
        );
      });
    } else {
      showToast("Only PDF file is allowed", "error");
    }
  };

  _addingInUpload = () => {
    const { uploadFiles } = deepClone(this.state);

    let objFile = this.state.pdfFile;
    uploadFiles.push({
      previewBlob: URL.createObjectURL(objFile),
      uploadData: objFile,
      isPrivate: true,
      category: "confirmation",
    });

    this.setState({ uploadFiles });
  };

  checkNotMetioned = (text) => {
    let toAvoid = [
      "Not mentioned",
      "N/A (not specified)",
      "Not Specified",
      "Not mentioned in the text",
      "N/A (not mentioned in the text)",
    ];
    let lowerCaseText = text.toLowerCase();

    for (let phrase of toAvoid) {
      if (lowerCaseText.includes(phrase.toLowerCase())) {
        return false;
      }
    }

    return true;
  };

  // _removeKeyValuePair(obj) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const newObj = Object.entries(obj).reduce((acc, [key, value]) => {
  //         if (
  //           !(
  //             Array.isArray(value) &&
  //             value.some((borrowers) =>
  //               Object.values(borrowers).some(
  //                 (val) =>
  //                   typeof val === "string" &&
  //                   (val.toLowerCase().includes("not") ||
  //                     val.toLowerCase().includes("n/a"))
  //               )
  //             )
  //           ) &&
  //           !(
  //             typeof value === "string" &&
  //             (value.toLowerCase().includes("not") ||
  //               value.toLowerCase().includes("n/a"))
  //           )
  //         ) {
  //           acc[key] = value;
  //         }
  //         return acc;
  //       }, {});
  //       resolve(newObj);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // }

  _removeKeyValuePair(obj) {
    return new Promise((resolve, reject) => {
      try {
        const newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            const updatedBorrowers = value.map((borrower) => {
              const updatedBorrower = {};
              Object.entries(borrower).forEach(
                ([borrowerKey, borrowerValue]) => {
                  if (
                    !(
                      borrowerValue.toLowerCase().includes("not ") ||
                      borrowerValue.toLowerCase().includes("n/a") ||
                      borrowerValue.toLowerCase().includes("none")
                    )
                  ) {
                    updatedBorrower[borrowerKey] = borrowerValue;
                  }
                }
              );
              return updatedBorrower;
            });
            newObj[key] = updatedBorrowers;
          } else if (
            // typeof value === "string" &&
            // !(
            //   value.toLowerCase().includes("not ") ||
            //   value.toLowerCase().includes("n/a") ||
            //   value.toLowerCase().includes("none")
            // )
            !(
              typeof value === "string" &&
              (value.toLowerCase().includes("not ") ||
                value.toLowerCase().includes("n/a") ||
                value.toLowerCase().includes("none"))
            )
          ) {
            newObj[key] = value;
          }
        });
        resolve(newObj);
      } catch (error) {
        reject(error);
      }
    });
  }

  setFormData = async (response) => {
    const { formFields, appointmentSchedulerData } = deepClone(this.state);
    let data = await this._removeKeyValuePair(response);

    console.log("data", data);
    // formFields["borrowerName"].value = data?.borrowers[0]?.borrowerName || "";
    // formFields["email"].value = data?.borrowers[0]?.borrowerEmail || "";
    let borrower = [];
    data?.borrowers?.length > 0
      ? data?.borrowers.forEach((each) => {
          borrower.push(
            // contactNumber: each.contactNumber || "",
            // pointOfContact: each.pointOfContact || "",
            // contactEmail: each.contactEmail || "",
            {
              borrowerName: {
                value: each.borrowerName || "",
                isDirty: false,
                isValidate: true,
              },
              email: {
                value: each.borrowerEmail
                  ? // ? this._filterValidEmail(each.borrowerEmail)
                    each.borrowerEmail
                      ?.match(/[^\s@]+@[^\s@]+\.[^\s@]+/g)
                      ?.join("") || ""
                  : "",
                isDirty: false,
                isValidate: true,
              },
              workPhone: {
                value: each?.borrowerWorkPhone
                  ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
                  : each?.borrowerHomePhone
                  ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
                  : each?.borrowerCellPhone
                  ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
                  : "",
                isDirty: false,
                isValidate: true,
              },
              alternatePhone: {
                value: each?.borrowerWorkPhone
                  ? getPhoneNumberFromBrackets(each?.borrowerWorkPhone)
                  : each?.borrowerHomePhone
                  ? getPhoneNumberFromBrackets(each?.borrowerHomePhone)
                  : each?.borrowerCellPhone
                  ? getPhoneNumberFromBrackets(each?.borrowerCellPhone)
                  : "",
                isDirty: false,
                isValidate: true,
              },
              isForeignNational: {
                value: false,
                isDirty: false,
                isValidate: true,
              },
              language: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              languageTypeOther: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              timeZone: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
            }
          );
        })
      : borrower.push({
          _id: {
            value: "",
          },
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          alternatePhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        });
    formFields["borrower"] = borrower;

    // formFields["phone"].value = getPhoneNumberFromBrackets(
    //   data.borrowers[0].borrowerPhone || data?.borrowers[0]?.borrowerCellPhone
    // );
    // formFields["homePhone"].value = getPhoneNumberFromBrackets(
    //   data?.borrowers[0]?.borrowerHomePhone
    // );
    // formFields["workPhone"].value =
    //   data?.borrowers[0]?.borrowerCellPhone ===
    //     data?.borrowers[0]?.borrowerWorkPhone ||
    //   data?.borrowers[0]?.borrowerHomePhone ===
    //     data?.borrowers[0]?.borrowerWorkPhone
    //     ? ""
    //     : getPhoneNumberFromBrackets(data?.borrowers[0]?.borrowerWorkPhone);
    formFields["fileNumber"].value = data?.fileNumber || "";
    formFields["lender"].value =
      data?.lender && this.checkNotMetioned(data?.lender) ? data.lender : "";
    if (data?.appointmentDateTime) {
      console.log("data?.appointmentDateTime >>", data?.appointmentDateTime);
      if (data?.appointmentDateTime?.includes("EST")) {
        appointmentSchedulerData.appointmentDate =
          data?.appointmentDateTime.slice(
            0,
            data?.appointmentDateTime?.indexOf("E") - 1
          );
        // formFields["appointmentDate"].isFieldDirty = true;
      } else {
        appointmentSchedulerData.appointmentDate = data?.appointmentDateTime;
        // formFields["appointmentDate"].isFieldDirty = true;
      }
    }

    // formFields["loanType"].value = data.closingType || "";

    formFields["signingType"].value =
      data?.signingType &&
      (data?.signingType === "Mobile" || data?.signingType === "RON")
        ? data?.signingType
        : "Mobile";

    formFields["loanType"].value =
      Object.values(this.state.loanType).filter(
        (item) => item === data?.closingType
      ).length === 0
        ? [{ label: "Other", value: "Other" }]
        : [{ label: data?.closingType, value: data?.closingType }];
    formFields["loanTypeOther"].value =
      data?.closingType && this.checkNotMetioned(data?.closingType)
        ? data?.closingType
        : "";

    formFields["signingCompanyFee"].value = data?.companyFee
      ? //  ? data?.companyFee?.replace(/^\$([\d.]+|N\/A|[^$]+)$/, "$1")
        data?.companyFee?.replace(/[^\d.]/g, "")
      : "";
    formFields["agentFee"].value = data?.agentFee
      ? // ? data?.agentFee?.replace(/^\$([\d.]+|N\/A|[^$]+)$/, "$1")
        data?.agentFee?.replace(/[^\d.]/g, "")
      : "";
    formFields["personOfContactName"].value =
      data?.pocName && this.checkNotMetioned(data?.pocName)
        ? data?.pocName
        : "";
    formFields["personOfContactEmail"].value = data?.pocEmail
      ? // ? data?.pocEmail?.replace(RegexConfig.email, "$1").split(" ")
        this._filterValidEmail(data?.pocEmail)
      : [];

    formFields["closingAddressLine1"].value =
      data?.closingAddress?.addressLine || "";
    formFields["closingAddressCity"].value = data?.closingAddress?.city || "";
    formFields["closingAddressState"].value = data?.closingAddress?.state || "";
    formFields["closingAddressZip"].value = data?.closingAddress?.zip || "";
    formFields["closingSearch"].value =
      data?.closingAddress?.addressLine +
      " " +
      data?.closingAddress?.city +
      " " +
      data?.closingAddress?.state +
      " " +
      data?.closingAddress?.zip;

    formFields["line1"].value = data?.propertyAddress?.addressLine || "";
    formFields["city"].value = data?.propertyAddress?.city || "";
    formFields["state"].value = data?.propertyAddress?.state || "";
    formFields["zip"].value = data?.propertyAddress?.zip || "";
    formFields["borrowerSearch"].value =
      data?.propertyAddress?.addressLine +
      " " +
      data?.propertyAddress?.city +
      " " +
      data?.propertyAddress?.state +
      " " +
      data?.propertyAddress?.zip;

    this.setState(
      {
        formFields,
        isAppointmentSearch: false,
        isBorrowerSearch: false,
        appointmentSchedulerData,
      },
      () => {
        // this._getAgentWithZipcode(data);
        this._getAllCompanySettings();
      }
    );
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  getPlaceDetail = async (pid, type) => {
    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);

    if (detail) {
      if (type === "borrowerAddress") {
        if (detail.postal) {
          formFields["line1"].value = detail.address;
          formFields["city"].value = detail.city;
          let state = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["state"].value = state;
          formFields["zip"].value = detail.postal;
          formFields["borrowerSearch"].value =
            detail.address +
            "," +
            detail.city +
            "," +
            state +
            "," +
            detail.postal +
            "," +
            detail.country;
          formFields["borrowerSearch"].placeId = pid.place_id;
          formFields["borrowerSearch"].isDirty = true;
        } else {
          showToast("Invalid Address", "error");
        }
      } else {
        if (detail.postal) {
          formFields["closingAddressLine1"].value = detail.address;
          formFields["closingAddressCity"].value = detail.city;
          let cState = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["closingAddressState"].value = cState;
          formFields["closingAddressZip"].value = detail.postal;
          formFields["closingSearch"].value =
            detail.address +
            "," +
            detail.city +
            "," +
            cState +
            "," +
            detail.postal +
            "," +
            detail.country;
          formFields["closingSearch"].placeId = pid.place_id;
          formFields["closingSearch"].isDirty = true;

          // this._getAgentWithZipcode(detail);
        } else {
          showToast("Invalid Address", "error");
        }
      }
      this.setState({ formFields, show: true }, () => {
        this._validateForm();
        this._findStandardrate();
        this._getAgentWithZipcode(detail);
      });
    }
  };

  useBorrowerAddress = (event) => {
    const { formFields } = deepClone(this.state);
    formFields["closingAddressLine1"].value = event
      ? formFields.line1.value
      : "";
    formFields["closingAddressLine2"].value = event
      ? formFields.line2.value
      : "";
    formFields["closingAddressCity"].value = event ? formFields.city.value : "";
    formFields["closingAddressState"].value = event
      ? formFields.state.value
      : "";
    formFields["closingAddressZip"].value = event ? formFields.zip.value : "";
    formFields["closingSearch"].value = event
      ? formFields.borrowerSearch.value
      : "";

    this.setState({ formFields, isBorrowerAddressSame: event });
  };

  _useAppointmentAddress = (event) => {
    const { formFields } = deepClone(this.state);
    formFields["line1"].value = event
      ? formFields.closingAddressLine1.value
      : "";
    formFields["line2"].value = event
      ? formFields.closingAddressLine2.value
      : "";
    formFields["city"].value = event ? formFields.closingAddressCity.value : "";
    formFields["state"].value = event
      ? formFields.closingAddressState.value
      : "";
    formFields["zip"].value = event ? formFields.closingAddressZip.value : "";
    formFields["borrowerSearch"].value = event
      ? formFields.closingSearch.value
      : "";

    this.setState({ formFields, isAppointmentAddressSame: event }, () =>
      this._validateForm()
    );
  };

  _getAgentWithZipcode = (data) => {
    const { agentsZipCodePage, formFields } = deepClone(this.state);
    let payload = {
      // zipCode: data?.closingAddress?.zip || data?.postal,
      zipCode: formFields?.closingAddressZip?.value
        ? formFields?.closingAddressZip?.value
        : undefined,
      state:
        this.state?.closingDetails?.signingType === "RON"
          ? data?.closingAddress?.state
          : undefined,

      signingType: this.state.formFields.signingType.value,
      // ...this.state.agentsZipCodePage,
      // skip: (agentsZipCodePage.page - 1) * agentsZipCodePage.limit,
      // limit: agentsZipCodePage.limit,
      skip: 0,
      limit: agentsZipCodePage.page * agentsZipCodePage.limit,
      search: data?.search,
    };

    if (
      data ||
      // data?.closingAddress?.zip?.length === 5 ||
      // data?.closingAddress?.state
      // payload ||
      payload?.zipCode === 5 ||
      payload?.state
    ) {
      this._manageLoading("agentSearchLoading", true);

      getAgentWithZipcode(payload)
        .then((res) => {
          let options =
            res?.agents?.map((item) => ({
              value: item._id,
              label: capitalize(item?.name?.first + " " + item?.name?.last),
            })) || [];
          this.setState({
            dropdownAgentList: options,
            agentSuggestedTotalCount: res?.totalCount || 0,
          });
          this._manageLoading("agentSearchLoading", false);
          return options;
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("agentSearchLoading", false);
          // this._manageLoading("showTableLoading", false);
        });
    }
  };

  _onChipSelect = (chips, arrName = "chips") => {
    // const {formField} =deepClone(this.state)
    // console.log("chips >>", chips);
    // console.log("chips length >", chips.length);
    // console.log("chips[1] >", chips[0]);
    // value={formField.personOfContactEmail.value}

    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          // chips: chips,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    console.log(value);
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      console.log("into");
      return true;
    } else {
      console.log("into else");
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _filterValidEmail = (string) => {
    const emailPattern = /[^\s@]+@[^\s@]+\.[^\s@]+/g;
    const emails = string.match(emailPattern) || [];
    return emails.filter((email) =>
      RegexConfig.email.test(String(email).toLowerCase())
    );
  };

  _startAutomation = (data) => {
    const { formFields } = deepClone(this.state);
    const { bidAutomationSetting } = deepClone(this.state.companySetting);
    if (formFields?.agentFee?.value?.toString()?.trim()) {
      let payload = {
        automationType: bidAutomationSetting.automationType,
        isCounter: bidAutomationSetting.autoSendBidRequest,
        biddingAmount: Number(formFields?.agentFee?.value),
        autoAssignCounterResponse:
          bidAutomationSetting.autoAssignCounterResponse,
      };
      console.log(bidAutomationSetting);
      // this._connectToSocket();
      startAutomationForClosing(data._id, payload)
        .then((res) => {
          showToast("Automation started Successfully", "success");
          // this._getAllAgents();
          // this._getSigningClosingById(this.props.data._id);

          // this._resetModal();
          // this.props.resetDetails();
          // this.props.toggle(null);
        })
        .catch((error) => {
          errorHandler(error);
        });
    } else {
      showToast("No Agent is in the list", "error");
    }
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };

  _addBorrower = () => {
    const { formFields } = this.state;
    formFields.borrower.push({
      borrowerName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      workPhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      alternatePhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      isForeignNational: {
        value: false,
        isDirty: false,
        isValidate: true,
      },
      language: {
        value: "English",
        isDirty: false,
        isValidate: true,
      },
      languageTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      timeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
    });
    this.setState({ formFields });
  };
  _removeBorrower = (index) => {
    const { formFields } = this.state;
    formFields.borrower.splice(index, 1);
    this.setState({ formFields });
  };
  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    this.setState({ formFields }, () => {
      this._validateForm();
    });
    this._addTextInInstruction(subField, value, index);
  };

  _addTextInInstruction = (subField, value, index) => {
    const { textInstruction } = deepClone(this.state);

    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            textInstruction
          )
        : textInstruction.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "language") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
  };

  _sortText = (input) => {
    console.log("text", input);
    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input.match(signerRegex);
    console.log("signerMatches", signerMatches);
    // sort the signer strings in ascending order
    if (signerMatches !== null) {
      signerMatches.sort();

      // replace the original signer strings with the sorted ones
      let output = input.replace(signerRegex, () => signerMatches.shift());

      // console.log(output);
      return output;
    } else {
      return input;
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  searchOnChange = debounce((value) => {
    // console.log(value);
    if (value.length > 0) {
      this._getAgentWithZipcode({ search: value });
    }
  }, 1000);

  _duplicateFileNumberCheck = async (e, type = "submitLoading") => {
    // console.log("submit  >>", this.appointmentSchedulerRef);
    let { fileNumber } = deepClone(this.state.formFields);
    try {
      this._manageLoading(type, true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        this._manageLoading(type, false);
      } else {
        this._submitCreateSigningClosing(e);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading(type, false);
    }
  };

  render() {
    const {
      formFields,
      uploadFiles,
      clients,
      // inputLoanType,
      // loanType,
      addClientModalData,
      // eslint-disable-next-line no-unused-vars
      loading,
      fileChecked,
      // agents,
      dropdownAgentList,
      isBorrowerAddressSame,
      isAppointmentAddressSame,
      isBorrowerSearch,
      isAppointmentSearch,
      // isScanBackNeeded,
      signingInstruction,
      confirmationFileLoading,
      // eslint-disable-next-line no-unused-vars
      closingDetails,
      ronPlatformTypeOptions,
      agentSuggestedTotalCount,
      agentsZipCodePage,
      clientTeamsList,
      FileNumberUniqueChecked,
      existingFileNos,
      keepFileNo,
    } = deepClone(this.state);
    console.log("first", this.state);
    const { userData } = this.props;
    // console.log("2222", this.state.draftClosing);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                // onClick={() =>
                //   !isRegularUser() && this._toggleUpgradeAccountModal(true)
                // }
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Create Order</h2>
            </div>

            <div className="rightSide">
              {(!Object.keys(closingDetails).length > 0 ||
                !closingDetails?.isSavedAsDraft) && (
                <>
                  {/* <Button className="themeBtn position-relative my-0">
                  {loading.fileUploadLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <div>
                      <i className="fa fa-upload mr-1" /> Upload
                    </div>
                  )}
                  <Input
                    type="file"

                    accept=".pdf"
                    disabled={loading.fileUploadLoading}
                    title="Upload Confirmation"
                    // multiple
                    value=""
                    onChange={(event) => this.uploadClosingPdfFile(event)}
                  />
                </Button> */}
                  <Label
                    className="uploadBtn floatingButton"
                    for="uploadAutomation"
                    // onClick={() =>
                    //   !isRegularUser() && this._toggleUpgradeAccountModal(true)
                    // }
                  >
                    {loading.fileUploadLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <>
                        <img
                          src={
                            require("../../../assets/img/uploadIcon.svg")
                              .default
                          }
                          alt="upload automation"
                        />{" "}
                        UPLOAD
                      </>
                    )}

                    <Input
                      type="file"
                      id="uploadAutomation"
                      accept=".pdf"
                      disabled={confirmationFileLoading}
                      title="Upload Confirmation"
                      value=""
                      onChange={(event) => this.uploadClosingPdfFile(event)}
                    />
                  </Label>
                </>
              )}

              {/* <Button color="link" onClick={this._resetFilter}>
                <img
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="Filter"
                  height={14}
                />
                
              </Button> */}
            </div>
          </div>

          {/* <div className="pgTitleWrap align-items-start align-items-sm-center">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={this._redirectToClosingDashboard}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h4>Create Order</h4>
            </div>
            {(!Object.keys(closingDetails).length > 0 ||
              !closingDetails?.isSavedAsDraft) && (
                <Button className="themeBtn position-relative my-0">
                  {loading.fileUploadLoading ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <div>
                      <i className="fa fa-upload mr-1" /> Upload
                    </div>
                  )}
                  <Input
                    type="file"

                    accept=".pdf"
                    disabled={loading.fileUploadLoading}
                    title="Upload Confirmation"
                    // multiple
                    value=""
                    onChange={(event) => this.uploadClosingPdfFile(event)}
                  />
                </Button>
              )}
          </div> */}
          <Row>
            <Col>
              {formFields.borrower.map((each, index) => (
                <div className="ClosingWrapper">
                  <Row>
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        {/* add a searchable text field below in place of the input field */}
                        <Input
                          type="text"
                          placeholder=" "
                          value={each.borrowerName.value}
                          name="borrowerName"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "borrowerName",
                              index
                            )
                          }
                          // onBlur={() => this._validateForm()}
                        />
                        <Label>Signer Name</Label>
                        {each.borrowerName.error && (
                          <div className="validation-error">
                            {each.borrowerName.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="email"
                          placeholder=" "
                          value={each.email.value}
                          name="email"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "email",
                              index
                            )
                          }
                          // onBlur={() => this._validateForm()}
                        />
                        <Label>Email</Label>
                        {each.email.error && (
                          <div className="validation-error">
                            {each.email.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>+1</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={each.workPhone.value}
                            name="phone"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "workPhone",
                                index
                              )
                            }
                            onKeyPress={(e) => this._isNumber(e)}
                            // onBlur={() => this._validateForm()}
                          />
                          <Label>Phone</Label>
                        </InputGroup>
                        {each.workPhone.error && (
                          <div className="validation-error">
                            {each.workPhone.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>+1</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={each.alternatePhone.value}
                            name="phone"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "alternatePhone",
                                index
                              )
                            }
                            onKeyPress={(e) => this._isNumber(e)}
                            // onBlur={() => this._validateForm()}
                          />
                          <Label>Alternate Phone</Label>
                        </InputGroup>
                        {each.alternatePhone.error && (
                          <div className="validation-error">
                            {each.alternatePhone.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="language"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "language",
                                index
                              )
                            }
                            value={each.language.value}
                          >
                            <option value="">Select</option>

                            {LanguageList.map((lang, index) => (
                              <option key={index} value={lang.value}>
                                {lang.label}
                              </option>
                            ))}
                          </Input>
                          <Label>Language</Label>
                        </div>
                      </FormGroup>
                    </Col>
                    {each.language.value === "Other" && (
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            value={each.languageTypeOther.value}
                            name="loanType"
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "languageTypeOther",
                                index
                              )
                            }
                            placeholder=" "
                          />
                          <Label>Other Language</Label>
                        </FormGroup>
                      </Col>
                    )}
                    {formFields.signingType.value === "RON" ||
                    formFields?.loanType?.value[0]?.value ===
                      "AttorneyPhone" ? (
                      <Col sm={6} md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <div className="custom-select-wrapper">
                            <Input
                              type="select"
                              name="timeZone"
                              value={each.timeZone.value}
                              onChange={(event) =>
                                this._onChangeFormFieldBorrower(
                                  "borrower",
                                  event.target.value,
                                  "timeZone",
                                  index
                                )
                              }
                            >
                              <option value="">Select</option>
                              {timezoneList().map((item) => (
                                <option>{item}</option>
                              ))}
                            </Input>
                            <Label>Time Zone</Label>
                          </div>
                        </FormGroup>
                      </Col>
                    ) : null}
                    <Col sm={6} md={6} xl={4}>
                      <CustomInput
                        type="checkbox"
                        // id="isSignerForeigner"
                        id={`"isSignerForeigner+${index}"`}
                        label="Is the signer a foreign national?"
                        checked={each.isForeignNational.value}
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.checked,
                            "isForeignNational",
                            index
                          )
                        }
                      />
                    </Col>
                    <Col className="text-right">
                      {index === formFields.borrower.length - 1 && (
                        <Button
                          color="link"
                          onClick={() => this._addBorrower()}
                        >
                          <img
                            src={
                              require("../../../assets/img/addItem.svg").default
                            }
                            alt="add item"
                          />
                        </Button>
                      )}
                      {formFields.borrower.length > 1 ? (
                        <Button
                          color="link"
                          onClick={() => this._removeBorrower(index)}
                        >
                          <img
                            src={
                              require("../../../assets/img/removeItem.svg")
                                .default
                            }
                            alt="remove item"
                          />
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              ))}
              <hr />
              <div className="ClosingWrapper">
                <Row>
                  <Col sm="12">
                    <CustomInput
                      type="checkbox"
                      id="isAppointmentAddressSameAsBorrower_checkbox"
                      label="Signing & Property Address Same Location"
                      checked={isAppointmentAddressSame}
                      onChange={(event) =>
                        this._useAppointmentAddress(event.target.checked)
                      }
                    />
                  </Col>
                  <Col sm="12" className="mt-4 mb-3">
                    {!isAppointmentAddressSame ? (
                      <CustomInput
                        type="switch"
                        checked={!isBorrowerSearch}
                        onChange={(e) =>
                          this.setState({
                            isBorrowerSearch: !e.target.checked,
                          })
                        }
                        id="borrowerAddress_Custom_Switch"
                        name="isBorrowerSearch"
                        label="Enter Address Manually"
                      />
                    ) : null}
                  </Col>
                  {(isAppointmentAddressSame && !isAppointmentSearch) ||
                  !isBorrowerSearch ? (
                    <>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingAddressLine1.value
                                : formFields.line1.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="line1"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "line1",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Property Address 1</Label>
                          {formFields.line1.error && (
                            <div className="validation-error">
                              {formFields.line1.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingAddressLine2.value
                                : formFields.line2.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="line2"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "line2",
                                event.target.value
                              )
                            }
                          />
                          <Label>Property Address 2</Label>
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingAddressCity.value
                                : formFields.city.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="city"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "city",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>City</Label>
                          {formFields.city.error && (
                            <div className="validation-error">
                              {formFields.city.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <div className="custom-select-wrapper">
                            <Input
                              type="select"
                              value={
                                isAppointmentAddressSame
                                  ? formFields.closingAddressState.value
                                  : formFields.state.value
                              }
                              disabled={isAppointmentAddressSame}
                              name="state"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "state",
                                  event.target.value
                                )
                              }
                              onBlur={() => this._validateForm()}
                            >
                              <option value="">Select</option>
                              {usaStates.map((state) => (
                                <option
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </Input>
                            <Label>State</Label>
                          </div>
                          {formFields.state.error && (
                            <div className="validation-error">
                              {formFields.state.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6} xl={4}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="number"
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingAddressZip.value
                                : formFields.zip.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="zip"
                            onChange={(event) =>
                              this._onChangeFormField("zip", event.target.value)
                            }
                            onBlur={() => this._validateForm()}
                            placeholder=" "
                          />
                          <Label>Zip</Label>
                          {formFields.zip.error && (
                            <div className="validation-error">
                              {formFields.zip.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col lg={6} md={6}>
                        <FormGroup className="floatingLabel withInputGroup">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <img
                                  src={
                                    require("../../../assets/img/mapMarker_blue.svg")
                                      .default
                                  }
                                  alt="map marker"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder=" "
                              value={
                                isAppointmentAddressSame
                                  ? formFields.closingSearch.value
                                  : formFields.borrowerSearch.value
                              }
                              disabled={isAppointmentAddressSame}
                              name="search"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "borrowerSearch",
                                  event.target.value
                                )
                              }
                              onBlur={() => this._validateForm()}
                            />
                            <Label>Property Address</Label>
                          </InputGroup>
                          {formFields.borrowerSearch.error && (
                            <div className="validation-error">
                              {formFields.borrowerSearch.error}
                            </div>
                          )}
                          {this.state.show === "borrowerSearch" ? (
                            <ListGroup flush className="customSearchOptions">
                              {" "}
                              {this.state.searchResult &&
                                this.state.searchResult.map((i) => {
                                  return (
                                    <ListGroupItem
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.getPlaceDetail(
                                          i,
                                          "borrowerAddress"
                                        )
                                      }
                                    >
                                      {i.description}
                                    </ListGroupItem>
                                  );
                                })}
                            </ListGroup>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  <Col sm="12">
                    {!isBorrowerAddressSame ? (
                      <div className="d-flex mt-2 mb-3 align-items-start">
                        <Label className="mb-0 mr-3">Search Address</Label>

                        <CustomInput
                          type="switch"
                          checked={!isAppointmentSearch}
                          onChange={(e) =>
                            this.setState({
                              isAppointmentSearch: !e.target.checked,
                            })
                          }
                          id="closingAddress_Custom_Switch"
                          name="closingAddress"
                          label="Enter Manually"
                        />
                      </div>
                    ) : null}
                  </Col>

                  {isAppointmentSearch ? (
                    <>
                      {" "}
                      <Col xs="12" md="4">
                        <FormGroup className="floatingLabel withInputGroup">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <img
                                  src={
                                    require("../../../assets/img/mapMarker_blue.svg")
                                      .default
                                  }
                                  alt="map marker"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder=" "
                              value={
                                isBorrowerAddressSame
                                  ? formFields.borrowerSearch.value
                                  : formFields.closingSearch.value
                              }
                              disabled={isBorrowerAddressSame}
                              name="search"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "closingSearch",
                                  event.target.value
                                )
                              }
                              onBlur={() => this._validateForm()}
                            />
                            <Label>Appointment Address</Label>
                          </InputGroup>
                          {formFields.closingSearch.error && (
                            <div className="validation-error">
                              {formFields.closingSearch.error}
                            </div>
                          )}
                          {this.state.show === "closingSearch" ? (
                            <ListGroup flush className="customSearchOptions">
                              {" "}
                              {this.state.searchResult &&
                                this.state.searchResult.map((i) => {
                                  return (
                                    <ListGroupItem
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.getPlaceDetail(i, "closingAddress")
                                      }
                                    >
                                      {i.description}
                                    </ListGroupItem>
                                  );
                                })}
                            </ListGroup>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSame
                                ? formFields.line1.value
                                : formFields.closingAddressLine1.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="closingAddressLine1"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressLine1",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Appointment Address Line 1</Label>
                          {formFields.closingAddressLine1.error && (
                            <div className="validation-error">
                              {formFields.closingAddressLine1.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSame
                                ? formFields.line2.value
                                : formFields.closingAddressLine2.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="closingAddressLine2"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressLine2",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Appointment Address Line 2 </Label>
                          {formFields.closingAddressLine2.error && (
                            <div className="validation-error">
                              {formFields.closingAddressLine2.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSame
                                ? formFields.city.value
                                : formFields.closingAddressCity.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="closingAddressCity"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressCity",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>City</Label>
                          {formFields.closingAddressCity.error && (
                            <div className="validation-error">
                              {formFields.closingAddressCity.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel ">
                          <div className="custom-select-wrapper">
                            <Input
                              type="select"
                              value={
                                isBorrowerAddressSame
                                  ? formFields.state.value
                                  : formFields.closingAddressState.value
                              }
                              disabled={isBorrowerAddressSame}
                              name="closingAddressState"
                              onChange={(event) =>
                                this._onChangeFormField(
                                  "closingAddressState",
                                  event.target.value
                                )
                              }
                              onBlur={() => this._validateForm()}
                            >
                              <option value="">Select</option>
                              {usaStates.map((state) => (
                                <option
                                  key={state.abbreviation}
                                  value={state.abbreviation}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </Input>
                            <Label>State</Label>
                          </div>
                          {formFields.closingAddressState.error && (
                            <div className="validation-error">
                              {formFields.closingAddressState.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xl={4} md={6}>
                        <FormGroup className="floatingLabel">
                          <Input
                            type="number"
                            value={
                              isBorrowerAddressSame
                                ? formFields.zip.value
                                : formFields.closingAddressZip.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="zip"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressZip",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                            placeholder=" "
                          />
                          <Label>Zip</Label>
                          {formFields.closingAddressZip.error && (
                            <div className="validation-error">
                              {formFields.closingAddressZip.error}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  <Col xl={4} md={6}>
                    {/* <FormGroup
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: " ",
                          value: formatDateAndTime(
                            formFields.appointmentDate.value
                          ),
                        }}
                        onChange={(e) =>
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        onOpen={() =>
                          this.setState({ isOpenReactDatetime: true })
                        }
                        onClose={() =>
                          this.setState({ isOpenReactDatetime: false })
                        }
                        // onClose={this._validateForm}
                        // isValidDate={(current) =>
                        //   current.isAfter(getYesterdayDate())
                        // }
                        timeFormat={true}
                        timeConstraints={{ minutes: { step: 15 } }}
                      />
                      <Label>Appointment Date & Time</Label>
                      {formFields.appointmentDate.error && (
                        <div className="validation-error">
                          {formFields.appointmentDate.error}
                        </div>
                      )}
                    </FormGroup> */}
                    <AppointmentScheduler
                      ref={this.appointmentSchedulerRef}
                      data={this.state.appointmentSchedulerData}
                      timeZone={closingDetails?.timeZone}
                    />
                  </Col>
                </Row>
              </div>
              <hr />

              <div className="ClosingWrapper">
                <Row>
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={formFields.fileNumber.value}
                        name="fileNumber"
                        id="fileNumber"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeFormField(
                            "fileNumber",
                            event.target.value
                          )
                        }
                        onBlur={() => this._validateForm()}
                      />
                      <Label>File Number</Label>
                      {formFields.fileNumber.error && (
                        <div className="validation-error">
                          {formFields.fileNumber.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  {/* <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      {/* add a searchable text field below in place of the input field 
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.lender.value}
                        name="lender"
                        onChange={(event) =>
                          this._onChangeFormField("lender", event.target.value)
                        }
                        onBlur={() => this._validateForm()}
                      />
                      <Label>Order on behalf of</Label>

                      {formFields.lender.error && (
                        <div className="validation-error">
                          {formFields.lender.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col> */}

                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      {/* add a searchable text field below in place of the input field */}
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.personOfContactName.value}
                        name="line2"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "personOfContactName",
                            event.target.value
                          )
                        }
                        onBlur={() => this._validateForm()}
                      />
                      <Label>Person Of Contact (Name)</Label>
                      {formFields.personOfContactName.error && (
                        <div className="validation-error">
                          {formFields.personOfContactName.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <FormGroup className="ChipsWrap floatingLabel valueAdded">
                      <UncontrolledTooltip placement="top" target="pocEmail">
                        Add emails to send status updates to
                      </UncontrolledTooltip>
                      <InputGroup>
                        <Chips
                          value={formFields?.personOfContactEmail?.value}
                          onChange={(arr) => this._onChipSelect(arr, "chips")}
                          getChipValue={(e) => console.log(e)}
                          createChipKeys={[9, 13, 32]} //  Key codes to add chips
                          placeholder="Additional update emails for clients"
                        />
                        <Label>Person Of Contact (Email) </Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText href="#" id="pocEmail">
                            <i className="fa fa-info-circle"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.personOfContactEmail.error && (
                        <div className="validation-error">
                          {formFields.personOfContactEmail.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <Input
                        type="select"
                        name="signingType"
                        value={formFields.signingType.value}
                        onChange={(event) =>
                          this._onChangeFormField(
                            "signingType",
                            event.target.value
                          )
                        }
                      >
                        <option value="Mobile">Mobile Signing</option>
                        <option value="RON">RON</option>
                      </Input>
                      <Label>Signing Type</Label>
                    </FormGroup>
                  </Col>

                  {/* {formFields?.signingType?.value === "RON" ? (
                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel mb-3">
                    <Select
                      isClearable={true}
                      options={this.state.ronPlatformOptions}
                      value={formFields?.ronPlatformType?.value}
                      onChange={(event) =>
                        this._onChangeFormField("ronPlatformType", event)
                      }
                      placeholder="RON Platform Type"
                      className="customSelect"
                      isMulti={true}
                    />
                  </FormGroup>
                </Col>
              ) : null} */}

                  {(formFields?.signingType?.value === "RON" ||
                    (formFields?.loanType?.value?.length === 1 &&
                      formFields?.loanType?.value[0]?.value ===
                        "AttorneyPhone")) && (
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="timeZone"
                            value={formFields.closingAddressTimeZone.value}
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressTimeZone",
                                event.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            {timezoneList().map((item) => (
                              <option>{item}</option>
                            ))}
                          </Input>
                          <Label>Time Zone</Label>
                        </div>
                        {formFields.closingAddressTimeZone.error && (
                          <div className="validation-error">
                            {formFields.closingAddressTimeZone.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  {formFields?.signingType?.value === "RON" ? (
                    <Col xl={4} md={6}>
                      <FormGroup className="withChips floatingLabel valueAdded">
                        <Select
                          isClearable={true}
                          placeholder="Select"
                          options={ronPlatformTypeOptions}
                          value={formFields?.ronPlatformType?.value}
                          onChange={(event) =>
                            this._onChangeFormField("ronPlatformType", event)
                          }
                          // placeholder="RON Platform Type"
                          className="customMultiSelect"
                          isMulti={true}
                        />
                        <Label style={{ zIndex: 3 }}>RON Platform Type</Label>
                      </FormGroup>
                    </Col>
                  ) : null}

                  <Col xl={4} md={6}>
                    {/* <FormGroup className={`withChips floatingLabel valueAdded`}>
                  <CreatableSelect
                    isClearable
                    placeholder="Select"
                    className="customMultiSelect"
                    isMulti
                    components={animatedComponents}
                    value={formFields.loanType.value}
                    options={loanTypeConfig}
                    onChange={(event) =>
                      this._onChangeFormField("loanType", event)
                    }
                    blur={() => this._validateForm()}
                  />
                  <Label style={{ zIndex: 3 }}>Product Type</Label>

                  {formFields.loanType.error && (
                    <div className="validation-error">
                      {formFields.loanType.error}
                    </div>
                  )}
                </FormGroup>  */}
                    <ProductType
                      value={formFields?.loanType?.value}
                      onChange={(type, event) =>
                        this._onChangeFormField(type, event)
                      }
                      // blur={() => this._validateForm()}
                      productTypeField={"loanType"}
                      selectedCategoriesField={"loanCategories"}
                      error={formFields?.loanType?.error}
                      // onClickOutside={() => this._validateForm()}
                      clientCreateOrderOptions={this.state.loanType}
                    />
                  </Col>
                  {formFields?.loanType?.value.filter(
                    (i) => i.value === "Other"
                  ).length > 0 && (
                    <Col sm="6" md={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields.loanTypeOther.value}
                          name="loanType"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "loanTypeOther",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Other</Label>
                        {formFields.loanTypeOther.error && (
                          <div className="validation-error">
                            {formFields.loanTypeOther.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  {!userData.user.isTitleCompany && (
                    <Col xl={4} md={6}>
                      {/* only signing company gets to see the below data */}
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/dollar.svg")
                                    .default
                                }
                                alt="doller"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            // type="number"
                            placeholder=" "
                            value={formFields.signingCompanyFee.value}
                            name="signingCompanyFee"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "signingCompanyFee",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Company Fee</Label>
                        </InputGroup>
                        {formFields.signingCompanyFee.error && (
                          <div className="validation-error">
                            {formFields.signingCompanyFee.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  )}

                  <Col xl={4} md={6}>
                    <div className="InputWithButton">
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            placeholder=""
                            value={formFields.client.value}
                            name="client"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "client",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          >
                            <option value="">Select</option>
                            {clients.map((client) => (
                              <option key={client._id} value={client._id}>
                                {/* {capitalize(client.name.full)} */}
                                {client?.companyName
                                  ? capitalize(client.companyName)
                                  : capitalize(client.name.full)}
                              </option>
                            ))}
                          </Input>
                          <Label>Client</Label>
                        </div>
                        {formFields.client.error && (
                          <div className="validation-error">
                            {formFields.client.error}
                          </div>
                        )}
                      </FormGroup>

                      <Button
                        color="primary"
                        outline
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() => this._toggleAddClientModal(true)}
                      >
                        Add Client
                      </Button>
                    </div>
                  </Col>

                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <div
                        className={`custom-select-wrapper ${
                          formFields?.client?.value === "" ||
                          !clientTeamsList?.length
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <Input
                          type="select"
                          placeholder=" "
                          value={formFields?.clientTeams?.value}
                          onChange={(event) =>
                            this._onChangeFormField(
                              "clientTeams",
                              event.target.value
                            )
                          }
                          name="clientTeamsId"
                          disabled={
                            formFields?.client?.value === "" ||
                            !clientTeamsList?.length
                          }
                          onBlur={() => this._validateForm()}
                        >
                          <option value="">Select</option>
                          {clientTeamsList?.map((team) => (
                            <option key={team?._id} value={team?._id}>
                              {team?.teamName ? capitalize(team?.teamName) : ""}
                            </option>
                          ))}
                        </Input>
                        <Label>Client Teams</Label>
                      </div>
                      {formFields?.clientTeams?.error && (
                        <div className="validation-error">
                          {formFields?.clientTeams?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  {/* {formFields.client.value !== "" ? (
                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      value={
                        clients.filter(
                          (client) => client._id === formFields.client.value
                        )[0]?.email
                      }
                      name="clientId"
                      readOnly
                    />
                    <Label>Client Invoice Email</Label>
                  </FormGroup>
                </Col>
              ) : null} */}

                  {/* <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <InfiniteScrollDropdown
                        optionsConfig={dropdownAgentList}
                        value={formFields.agent.value}
                        onChangeFunc={(event) =>
                          this._onChangeFormField("agent", event)
                        }
                        onInputChangeFunc={(value, action) => {
                          // console.log(action)

                          if (action === "menu-close") {
                            this._getAgentWithZipcode({ value });
                          }

                          if (action === "input-change") {
                            this.searchOnChange(value);
                          }
                        }}
                        shouldMenuShouldScrollIntoView={true}
                        updateOptionsConfig={(payload) =>
                          this._getAgentWithZipcode()
                        }
                        isLoading={loading?.agentSearchLoading}
                        totalDataCount={agentSuggestedTotalCount}
                        totalDataPages={agentsZipCodePage.page}
                        dropdownPayload={agentsZipCodePage}
                        setDropdownPayload={(newDropDownPayload) =>
                          this.setState(
                            {
                              agentsZipCodePage: newDropDownPayload,
                            },
                            () => this._getAgentWithZipcode(newDropDownPayload)
                          )
                        }
                        isMulti={false}
                        isClearable={true}
                        placeholder="Agent"
                      />
                    </FormGroup>
                  </Col> */}

                  {/* {formFields.agent.value !== "" ? ( */}
                  <Col xl={4} md={6}>
                    {/* only signing company gets to see the below data */}
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/dollar.svg")
                                  .default
                              }
                              alt="doller"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          placeholder=" "
                          value={formFields.agentFee.value}
                          name="agentFee"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "agentFee",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Agent Fee</Label>
                      </InputGroup>
                      {formFields.agentFee.error && (
                        <div className="validation-error">
                          {formFields.agentFee.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="number"
                        placeholder=" "
                        value={formFields.witnessCount.value}
                        name="witnessCount"
                        min="0"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "witnessCount",
                            event.target.value
                          )
                        }
                        onKeyPress={(e) => this._isNumber(e)}
                        // disabled={formFields.signingType.value === "Mobile"}
                      />
                      <Label>Witness Number</Label>
                      {/* {formFields.witnessCount.error && (
                          <div className="validation-error">
                            {formFields.witnessCount.error}
                          </div>
                        )} */}
                    </FormGroup>
                  </Col>
                  {/* ) : null} */}
                </Row>
              </div>

              <div className="uploadDocumentInline">
                {/* files upload can be seen by signing company only */}
                <Label className="uploadLink">
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    accept=".pdf,.doc,.docx"
                    multiple
                    value=""
                    onChange={this._updateFile}
                  />
                  <img
                    src={require("../../../assets/img/uploadIcon.png").default}
                    height={40}
                    className="mr-1"
                    alt="upload files"
                  />{" "}
                  Upload Signing Documents
                </Label>
                <div className="uploadList">
                  {uploadFiles && uploadFiles.length
                    ? uploadFiles
                        .filter((each) => each?.category === "documents")
                        .map((media, index) => (
                          <div className="uploadItem" key={index}>
                            {/* please image according to  upload files  */}
                            <img
                              key={index}
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              height={18}
                              alt="file Icon"
                            />

                            {/* file name */}
                            <span>
                              {media.uploadData && media.uploadData.name
                                ? media.uploadData.name
                                : null}
                            </span>
                            {!media?.isPrivate ? (
                              <Button
                                color="link"
                                onClick={() => this._deleteContent(index)}
                                className="deletePreview"
                              >
                                <img
                                  src={
                                    require("../../../assets/img/close_grey.svg")
                                      .default
                                  }
                                  height={15}
                                  alt="delete"
                                />
                              </Button>
                            ) : null}
                          </div>
                        ))
                    : null}
                </div>
              </div>

              <FormGroup className="floatingLabel">
                {/* only signing company gets to see it. */}
                <Input
                  type="textarea"
                  rows="3"
                  placeholder=" "
                  value={formFields.internalNotes.value}
                  name="internalNotes"
                  onChange={(event) =>
                    this._onChangeFormField("internalNotes", event.target.value)
                  }
                />
                <Label>Internal Notes</Label>
              </FormGroup>
              {/* <FormGroup className="floatingLabel">
            // anyone can to see it. 
            <Input
              type="textarea"
              rows="3"
              placeholder=" "
              value={formFields.companyNotes.value}
              name="companyNotes"
              onChange={(event) =>
                this._onChangeFormField("companyNotes", event.target.value)
              }
            />
            <Label>External Notes</Label>
          </FormGroup> */}
              <FormGroup
                style={{ maxWidth: 300 }}
                className="floatingLabel custom-select-wrapper"
              >
                <Input
                  type="select"
                  placeholder=" "
                  value={formFields.instructionId.value}
                  name="instructionId"
                  onChange={(event) =>
                    this._onChangeFormField("instructionId", event.target.value)
                  }
                >
                  <option value="">Select</option>
                  {signingInstruction.map((each) => (
                    <option key={each._id} value={each._id}>
                      {capitalize(each.name)}
                    </option>
                  ))}
                </Input>
                <Label>Instruction Type</Label>
              </FormGroup>
              <Card>
                <CardBody>
                  <FormGroup
                    className={`mb-0 floatingLabel reactQuill ${
                      (formFields.instructions.value &&
                        formFields.instructions.value !== "<p><br></p>") ||
                      this.state.isFocusTextEditor
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <Label>Signing Instructions</Label>
                    <TextEditor
                      content={formFields.instructions.value}
                      onChange={(event) =>
                        this._onChangeFormField("instructions", event)
                      }
                      onFocus={() => this.setState({ isFocusTextEditor: true })}
                      onBlur={() => this.setState({ isFocusTextEditor: false })}
                    />
                  </FormGroup>

                  <FormGroup
                    className={`mt-3 mb-3 floatingLabel reactQuill ${
                      (formFields.paymentInstructions.value &&
                        formFields.paymentInstructions.value !==
                          "<p><br></p>") ||
                      this.state.isFocusTextEditorPay
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <Label>Payment Instructions</Label>
                    <TextEditor
                      content={formFields.paymentInstructions.value}
                      onChange={(event) =>
                        this._onChangeFormField("paymentInstructions", event)
                      }
                      onFocus={() =>
                        this.setState({ isFocusTextEditorPay: true })
                      }
                      onBlur={() =>
                        this.setState({ isFocusTextEditorPay: false })
                      }
                    />
                  </FormGroup>
                  {/* <h2
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                Additional Instruction
              </h2> */}
                  <CustomInput
                    type="checkbox"
                    id="isScanBackNeeded"
                    label="Scan Backs Needed"
                    className="mb-2"
                    checked={formFields.isScanBackNeeded.value}
                    onChange={(event) =>
                      this._onChangeFormField(
                        "isScanBackNeeded",
                        event.target.checked
                      )
                    }
                  />
                  {/* <CustomInput
                type="checkbox"
                id="other"
                label="Other"
                className="mb-2"
                checked={this.state.isChecked}
                onChange={this.handleCheckboxChange}
              />
              {this.state.isChecked && (
                <>
                  <CustomInput
                    type="checkbox"
                    id="demoQuestion1"
                    label="demo question 1"
                    className="mb-2"
                    // checked={formFields.isScanBackNeeded.value}
                    // onChange={(event) =>
                    //   this._onChangeFormField(
                    //     "isScanBackNeeded",
                    //     event.target.checked
                    //   )
                    // }
                    inline
                  />
                  <CustomInput
                    type="checkbox"
                    id="demoQuestion2"
                    label="demo question 2"
                    className="mb-2"
                    // checked={formFields.isScanBackNeeded.value}
                    // onChange={(event) =>
                    //   this._onChangeFormField(
                    //     "isScanBackNeeded",
                    //     event.target.checked
                    //   )
                    // }
                    inline
                  />
                  <CustomInput
                    type="checkbox"
                    id="demoQuestion3"
                    label="demo question 3"
                    className="mb-2"
                    // checked={formFields.isScanBackNeeded.value}
                    // onChange={(event) =>
                    //   this._onChangeFormField(
                    //     "isScanBackNeeded",
                    //     event.target.checked
                    //   )
                    // }
                    inline
                  />
                  <CustomInput
                    type="checkbox"
                    id="demoQuestion4"
                    label="demo question 4"
                    className="mb-2"
                    // checked={formFields.isScanBackNeeded.value}
                    // onChange={(event) =>
                    //   this._onChangeFormField(
                    //     "isScanBackNeeded",
                    //     event.target.checked
                    //   )
                    // }
                    inline
                  />
                  <CustomInput
                    type="checkbox"
                    id="demoQuestion5"
                    label="demo question 5"
                    className="mb-2"
                    // checked={formFields.isScanBackNeeded.value}
                    // onChange={(event) =>
                    //   this._onChangeFormField(
                    //     "isScanBackNeeded",
                    //     event.target.checked
                    //   )
                    // }
                    inline
                  />
                </>
              )} */}
                </CardBody>
              </Card>

              <div className="text-center mt-4">
                <Button
                  color="primary"
                  outline
                  size="lg"
                  onClick={(e) =>
                    this.setState(
                      {
                        draftClosing:
                          this.state.draftClosing !== null
                            ? this.state.draftClosing
                            : false,
                        createClosing: true,
                        createDraft: false,
                      },
                      () =>
                        // this.state.formFields?.fileNumber?.value?.length && !keepFileNo
                        this.state.formFields?.fileNumber?.value?.length &&
                        !keepFileNo &&
                        !this.state.draftClosing
                          ? this._duplicateFileNumberCheck(e, "submitLoading")
                          : this._submitCreateSigningClosing(e)
                    )
                  }
                  disabled={loading?.draftLoading || loading?.submitLoading}
                >
                  {loading?.submitLoading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Create
                </Button>
                {formFields.agent.value === "" && (
                  <Button
                    className="ml-3"
                    size="lg"
                    color="primary"
                    onClick={(e) =>
                      this.setState(
                        { createDraft: true, createClosing: false },
                        () =>
                          this.state.formFields?.fileNumber?.value?.length
                            ? this._duplicateFileNumberCheck(e, "draftLoading")
                            : this._submitCreateSigningClosing(e)
                      )
                    }
                    disabled={loading?.draftLoading || loading?.submitLoading}
                  >
                    {loading?.draftLoading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Draft
                  </Button>
                )}
              </div>
            </Col>

            {uploadFiles.length > 0 && (
              <Col xl={4} lg={4}>
                <div className="pdfView">
                  <iframe src={uploadFiles[0]?.previewBlob}></iframe>
                </div>
              </Col>
            )}
          </Row>

          <InviteClient
            isOpen={addClientModalData.isOpen}
            data={addClientModalData.data}
            type={"add"}
            onSuccess={() => this._getAllFiltersData()}
            toggle={() => this._toggleAddClientModal()}
          />
          <FileChecked
            isOpen={fileChecked.isOpen}
            approved={this._approvedFileNumber}
            toggle={() => this._toggleFileCheckedModal()}
          />

          {FileNumberUniqueChecked?.isOpen ? (
            <FileNumberDuplicateModal
              isOpen={FileNumberUniqueChecked.isOpen}
              fileNumber={formFields.fileNumber.value}
              toggle={() => this._toggleFileNumberUniqueCheckedModal()}
              existingFileNos={existingFileNos}
              keep={(e) =>
                this.setState({ keepFileNo: true }, () => {
                  this._toggleFileNumberUniqueCheckedModal();
                  this._submitCreateSigningClosing(e);
                })
              }
              FileNumberSubmitHandler={
                (value) => this._onChangeFormField("fileNumber", value)
                // this._submitCreateSigningClosing(e)
              }
            />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
