import React, { useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  updateSigningCompanySetting,
} from "../../../http/http-calls";
import TextEditor from "./TextEdittor";

const AddPaymentInstructionsModal = ({
  isOpen,
  toggle,
  companySettings,
  companySettingsData,
  onSuccess,
}) => {
  const [signingInstruction, setSigningInstruction] = useState([
    {
      tempId: Date.now(),
      name: "",
      description: "",
      isDefault: true,
    },
  ]);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setInstructionSetting = (data) => {
    if (data?.length) {
      setSigningInstruction([...data]);
    } else {
      setSigningInstruction([
        {
          tempId: Date.now(),
          name: "",
          description: "",
          isDefault: true,
        },
      ]);
    }
    setLoading(false);
  };

  const _closeModal = () => {
    toggle();
  };

  const _validateInstruction = ({
    newSigningInstruction,
    newIsDirty = {},
    isValidateAll = false,
  }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      newSigningInstruction.forEach((each, index) => {
        if (isValidateAll || newIsDirty[`name_${index}`]) {
          if (each.name?.trim().length) {
            newErrors[`name_${index}`] = null;
            newIsDirty[`name_${index}`] = false;
          } else {
            newErrors[`name_${index}`] = "*Required";
            isFormValid = false;
          }
        }
        if (isValidateAll || newIsDirty[`description_${index}`]) {
          if (each.description?.trim().length) {
            newErrors[`description_${index}`] = null;
            newIsDirty[`description_${index}`] = false;
          } else {
            newErrors[`description_${index}`] = "*Required";
            isFormValid = false;
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onBlurInstruction = (key) => {
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);
    const newSigningInstruction = [...signingInstruction];
    _validateInstruction({ newIsDirty, newSigningInstruction });
  };

  const _onChangeInstruction = ({ key, value, index }) => {
    const newSigningInstruction = [...signingInstruction];
    newSigningInstruction[index][key] = value;
    setSigningInstruction(newSigningInstruction);
  };

  const _onSave = async () => {
    try {
      const newSigningInstruction = [...signingInstruction];

      const isFormValid = await _validateInstruction({
        newSigningInstruction,
        isValidateAll: true,
      });

      if (!isFormValid) {
        showToast("Please fill all form", "error");
        return;
      }

      setLoading(true);

      // company
      signingInstruction.forEach((newObj) => {
        const existingIndex = companySettings.paymentInstructions.findIndex(
          (obj) => obj._id === newObj._id
        );
        if (existingIndex !== -1) {
          // If an object with the id exists, update its properties
          companySettings.paymentInstructions[existingIndex] = {
            ...companySettings.paymentInstructions[existingIndex],
            ...newObj,
          };
        } else {
          // If no object with the id exists, add a new object to the first array
          companySettings.paymentInstructions.push(newObj);
        }
      });

      await updateSigningCompanySetting({
        paymentInstructions: companySettings.paymentInstructions,
      });

      showToast("Payment Instruction has been updated", "success");

      onSuccess();
      _closeModal();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (companySettings) {
        // company
        _setInstructionSetting(companySettingsData);
      }
    }
  }, [isOpen, companySettings]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      scrollable
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Manage Payment Instructions
      </ModalHeader>
      <ModalBody>
        {signingInstruction.map((each, index) => (
          <Card key={each.tempId || each._id + index}>
            <CardBody>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={each.name}
                  onChange={(e) =>
                    _onChangeInstruction({
                      key: "name",
                      value: e.target.value,
                      index,
                    })
                  }
                  onBlur={() => _onBlurInstruction(`name_${index}`)}
                />
                <Label>Name of Instruction</Label>
                {errors[`name_${index}`] ? (
                  <div className="validation-error">
                    {errors[`name_${index}`]}
                  </div>
                ) : null}
              </FormGroup>

              <FormGroup className="floatingLabel">
                <TextEditor
                  content={each.description}
                  placeholder=" "
                  onChange={(e) =>
                    _onChangeInstruction({
                      key: "description",
                      value: e,
                      index,
                    })
                  }
                  onBlur={(e) => _onBlurInstruction(`description_${index}`)}
                />

                {errors[`description_${index}`] ? (
                  <div className="validation-error">
                    {errors[`description_${index}`]}
                  </div>
                ) : null}
              </FormGroup>
            </CardBody>
          </Card>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => _onSave()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPaymentInstructionsModal;
