import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  openUrlOnNewTab,
  sanitizeName,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import { updateAgentSocialLink } from "../../../http/http-calls";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SvgIcons from "./SvgIcons";
import { nnmLink } from "../../../config";
import usaStates from "../../../config/usa_states_titlecase";

const initialBacklinkObj = {
  value: "",
  isValid: false,
  error: "",
  isDirty: false,
  url: "",
  isUrlValid: false,
  urlError: "",
  isUrlDirty: false,
  previewUrl: "",
};

const ManageReviewLinks = ({ agentDetails }) => {
  const [links, setLinks] = useState([]);
  const [placeholder, setPlaceholder] = useState(" ");

  // Function to handle focus event
  const handleFocus = () => {
    setPlaceholder("https://www.nna.com");
  };

  // Function to handle blur event
  const handleBlur = () => {
    setPlaceholder(" ");
  };
  const [socialMediaLink, setSocialMediaLink] = useState({
    nnaProfile: "",
    facebookReviewLink: "",
    instagramReviewLink: "",
    googleReviewLink: "",
    linkedInUrl: "",
    tiktokReviewLink: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (agentDetails !== null) {
      let updatedArray = agentDetails?.customLinks?.map((data) => {
        return {
          value: data?.url,
          isValid: false,
          error: "",
          isDirty: false,
          url: data?.logo,
          isUrlValid: false,
          urlError: "",
          isUrlDirty: false,
          previewUrl: data?.logo,
        };
      });
      setLinks(updatedArray);
      setSocialMediaLink({
        nnaProfile: agentDetails?.nnaProfile,
        facebookReviewLink: agentDetails?.facebookReviewLink,
        instagramReviewLink: agentDetails?.instagramReviewLink,
        googleReviewLink: agentDetails?.googleReviewLink,
        linkedInUrl: agentDetails?.linkedInUrl,
        tiktokReviewLink: agentDetails?.tiktokReviewLink,
      });
    }
  }, []);

  const _validateForm = (updatedArray) => {
    let newArray = updatedArray;
    const upadtedArray = newArray.map((link) => {
      if (link.value?.length && new RegExp(RegexConfig.url).test(link.value)) {
        link.error = "";
        link.isValid = true;
      } else {
        link.error = "*Required";
        link.isValid = false;
      }

      if (link?.previewUrl?.length) {
        link.urlError = "";
        link.isUrlValid = true;
      } else {
        link.urlError = "*Required";
        link.isUrlValid = false;
      }
      return link;
    });

    setLinks(upadtedArray);
  };

  const _handleOnChange = (field, event, index) => {
    let newArray = links;

    if (field === "value") {
      newArray[index][field] = event.target.value;
    } else {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];

      newArray[index][field] = {
        previewBlob: URL.createObjectURL(objFile),
        uploadData: objFile,
        type: objFileType === "application" ? "pdf" : objFileType,
      };
      newArray[index].previewUrl = URL.createObjectURL(event.target.files[0]);
    }

    if (
      event.target.value?.length &&
      field === "value" &&
      !new RegExp(RegexConfig.url).test(event.target.value)
    ) {
      newArray[index].isDirty = true;
    } else {
      newArray[index].isDirty = false;
    }

    if (field === "url" && event.target.value) {
      newArray[index].isUrlDirty = false;
    } else {
      newArray[index].isUrlDirty = true;
    }
    // setLinks(newArray);
    _validateForm(newArray);
  };

  const _addInput = () => {
    let newArray = [];

    newArray.push({
      value: "",
      isValid: false,
      error: "",
      isDirty: false,
      url: "",
      isUrlValid: false,
      urlError: "",
      isUrlDirty: false,
      previewUrl: "",
    });

    setLinks(links.concat(newArray));
  };

  const _deleteInput = (index) => {
    const newArray = [...links];
    newArray.splice(index, 1);
    setLinks(newArray);
  };

  const _allLinkDirty = () => {
    const upadtedArray = links.map((obj) => {
      if (obj.value === "" && obj.previewUrl !== "") {
        return {
          ...obj,
          isDirty: true,
        };
      } else if (obj.value !== "" && obj.previewUrl === "") {
        return {
          ...obj,
          isUrlDirty: true,
        };
      } else {
        if (obj.value === "" && obj.previewUrl === "") {
          return {
            ...obj,
            isDirty: true,
            isUrlDirty: true,
          };
        } else {
          return null;
        }
      }
    });

    const areAllNull = upadtedArray.some((item) => item === null);

    if (areAllNull) {
      setLinks(links);
    } else {
      setLinks(upadtedArray);
    }
  };
  const _submitHandler = async () => {
    setLoading(true);
    _allLinkDirty();

    if (agentDetails?.customLinks?.length === links.length) {
      const finalArray = links.every(
        (obj) =>
          obj.error === "" &&
          obj.urlError === "" &&
          obj.value !== "" &&
          obj.url !== ""
      )
        ? links.map((obj) => ({
            url: obj.value,
            logo: obj.url,
          }))
        : [];

      const files = finalArray.reduce((acc, item) => {
        if (typeof item.logo === "object") {
          acc.push(item.logo);
        }
        return acc;
      }, []);

      // console.log("files in not qual", files);
      //check if new file upload or previous upload file delere
      if (files?.length) {
        const uploadedFilesRes = await uploadFileOnServer(files);

        const indicesWithObjectLogo = finalArray.reduce((acc, item, index) => {
          if (typeof item.logo !== "string") {
            acc.push(index);
          }
          return acc;
        }, []);

        // Replace "logo" with the "url" from additionalUrls at the positions found
        indicesWithObjectLogo.forEach((index) => {
          finalArray[index].logo = uploadedFilesRes.pop().url;
        });

        if (finalArray.length === links.length) {
          //api call
          let payload = { ...socialMediaLink, customLinks: finalArray };

          try {
            await updateAgentSocialLink(agentDetails._id, payload);
            showToast("Submit Successfully", "success");
            setLoading(false);
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        let payload = { ...socialMediaLink, customLinks: finalArray };

        if (finalArray?.length) {
          try {
            await updateAgentSocialLink(agentDetails._id, payload);
            setLoading(false);
            showToast("Submit Successfully", "success");
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        } else {
          try {
            await updateAgentSocialLink(agentDetails._id, {
              ...socialMediaLink,
            });
            setLoading(false);
            showToast("Submit Successfully", "success");
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        }
      }
    } else if (agentDetails?.customLinks?.length !== links.length) {
      const finalArray = links.every(
        (obj) =>
          obj.error === "" &&
          obj.urlError === "" &&
          obj.value !== "" &&
          obj.url !== ""
      )
        ? links.map((obj) => ({
            url: obj.value,
            logo: obj.url,
          }))
        : [];

      const files = finalArray.reduce((acc, item) => {
        if (typeof item.logo === "object") {
          acc.push(item.logo);
        }
        return acc;
      }, []);

      // console.log("files in not qual", files);
      //check if new file upload or previous upload file delere
      if (files?.length) {
        const uploadedFilesRes = await uploadFileOnServer(files);

        const indicesWithObjectLogo = finalArray.reduce((acc, item, index) => {
          if (typeof item.logo !== "string") {
            acc.push(index);
          }
          return acc;
        }, []);

        // Replace "logo" with the "url" from additionalUrls at the positions found
        indicesWithObjectLogo.forEach((index) => {
          finalArray[index].logo = uploadedFilesRes.pop().url;
        });

        if (finalArray.length === links.length) {
          //api call
          let payload = { ...socialMediaLink, customLinks: finalArray };

          try {
            await updateAgentSocialLink(agentDetails._id, payload);
            setLoading(false);
            showToast("Submit Successfully", "success");
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        let payload = { ...socialMediaLink, customLinks: finalArray };

        try {
          await updateAgentSocialLink(agentDetails._id, payload);
          setLoading(false);
          showToast("Submit Successfully", "success");
        } catch (error) {
          console.log("error", error);
          setLoading(false);
        }
      }
    } else {
      const finalArray = links.every(
        (obj) =>
          obj.error === "" &&
          obj.urlError === "" &&
          obj.value !== "" &&
          obj.url !== ""
      )
        ? links.map((obj) => ({
            url: obj.value,
            logo: obj.url,
          }))
        : [];

      if (finalArray.length) {
        let files = finalArray.map((data) => data.logo);

        const uploadedFilesRes = await uploadFileOnServer(files);

        for (let i = 0; i < uploadedFilesRes.length; i++) {
          if (i < finalArray.length) {
            finalArray[i].logo = uploadedFilesRes[i].url;
          }
        }
        if (finalArray.length === links.length) {
          //api call
          let payload = { ...socialMediaLink, customLinks: finalArray };

          try {
            await updateAgentSocialLink(agentDetails._id, payload);
            setLoading(false);
            showToast("Submit Successfully", "success");
          } catch (error) {
            console.log("error", error);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      }
    }
  };

  const _handleSocialLinkChange = (field, event) => {
    let data = { ...socialMediaLink };
    data[field] = event.target.value;
    setSocialMediaLink(data);
  };
  return (
    <>
      <Card className="reviewLinks">
        <CardBody>
          <div className="text-right">
            <Button color="primary" size="md" onClick={() => _addInput()}>
              Add Social Link
            </Button>
          </div>
          <ul className="">
            {/* <li>
              <div>
                <Link
                  to="#"
                  onClick={() =>
                    agentDetails?.id &&
                    openUrlOnNewTab(`${nnmLink}/${agentDetails?.id}`)
                  }
                >
                  <div className="agentIcon">
                    <SvgIcons type={"globe"} />
                  </div>
                  NNM Link
               
                </Link>
              </div>
            </li> */}

            <li>
              {console.log("agentDetails>>>>>>>>>>>>>>>", agentDetails)}
              <InputGroup className="floatingLabel">
                <Input
                  type="text"
                  value={socialMediaLink?.nnaProfile}
                  name="nnaProfile"
                  onChange={(event) =>
                    _handleSocialLinkChange("nnaProfile", event)
                  }
                  // placeholder="https://www.nna.com"
                  placeholder={placeholder}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <Label>NNA Profile</Label>
              </InputGroup>
              {/* </Col> */}
            </li>
            <li>
              <div className="socialIcon">
                <SvgIcons type={"globe"} />
              </div>
              <div className="socialLinks">
                <InputGroup className="floatingLabel">
                  <Input
                    type="text"
                    disabled
                    placeholder="Enter"
                    value={`${nnmLink}/${sanitizeName(
                      usaStates?.find(
                        (each) =>
                          each?.abbreviation === agentDetails?.address?.state
                      )?.name
                    )}/${sanitizeName(
                      agentDetails?.address?.city || "noCity"
                    )}/${encodeURIComponent(
                      sanitizeName(agentDetails?.name?.full)
                    )}/${agentDetails?.id}`}
                    // onChange={(e) =>
                    //   _handleSocialLinkChange("facebookReviewLink", e)
                    // }
                  />
                  <Label> NNM Link</Label>
                  <InputGroupAddon
                    addonType="prepend"
                    style={{ paddingLeft: 10 }}
                  >
                    <CopyToClipboard
                      text={`${nnmLink}/${sanitizeName(
                        usaStates?.find(
                          (each) =>
                            each?.abbreviation === agentDetails?.address?.state
                        )?.name
                      )}/${sanitizeName(
                        agentDetails?.address?.city || "noCity"
                      )}/${encodeURIComponent(
                        sanitizeName(agentDetails?.name?.full)
                      )}/${agentDetails?.id}`}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>
            <li>
              <div className="socialIcon">
                <img
                  src={require("../../../assets/img/facebook.png").default}
                  alt="links"
                />
              </div>
              <div className="socialLinks">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="https://www.facebook.com"
                    value={socialMediaLink?.facebookReviewLink}
                    onChange={(e) =>
                      _handleSocialLinkChange("facebookReviewLink", e)
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <CopyToClipboard
                      text={socialMediaLink?.facebookReviewLink}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>
            <li>
              <div className="socialIcon">
                <img
                  src={require("../../../assets/img/instagram.png").default}
                  alt="links"
                />
              </div>
              <div className="socialLinks">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="https://www.instagram.com"
                    value={socialMediaLink?.instagramReviewLink}
                    onChange={(e) =>
                      _handleSocialLinkChange("instagramReviewLink", e)
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <CopyToClipboard
                      text={socialMediaLink?.instagramReviewLink}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>

            <li>
              <div className="socialIcon">
                <img
                  src={require("../../../assets/img/google.png").default}
                  alt="links"
                />
              </div>
              <div className="socialLinks">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="https://www.google.com"
                    value={socialMediaLink?.googleReviewLink}
                    onChange={(e) =>
                      _handleSocialLinkChange("googleReviewLink", e)
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <CopyToClipboard
                      text={socialMediaLink?.googleReviewLink}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>

            <li>
              <div className="socialIcon">
                <img
                  src={require("../../../assets/img/linkedin.png").default}
                  alt="links"
                />
              </div>
              <div className="socialLinks">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="https://www.linkedin.com"
                    value={socialMediaLink?.linkedInUrl}
                    onChange={(e) => _handleSocialLinkChange("linkedInUrl", e)}
                  />
                  <InputGroupAddon addonType="prepend">
                    <CopyToClipboard
                      text={socialMediaLink?.linkedInUrl}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>

            <li>
              <div className="socialIcon">
                <img
                  src={require("../../../assets/img/tik-tok.png").default}
                  alt="links"
                />
              </div>
              <div className="socialLinks">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="https://www.tiktok.com"
                    value={socialMediaLink?.tiktokReviewLink}
                    onChange={(e) =>
                      _handleSocialLinkChange("tiktokReviewLink", e)
                    }
                  />
                  <InputGroupAddon addonType="prepend">
                    <CopyToClipboard
                      text={socialMediaLink?.tiktokReviewLink}
                      onCopy={() => showToast("Copied", "success")}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="fa fa-clone"></i>
                    </CopyToClipboard>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </li>

            {/* this will show when add new  */}
            {links?.map((link, index) => (
              <li key={index} style={{ display: "flex", alignItems: "start" }}>
                <div className="socialIcon">
                  <Label style={{ marginBottom: "10px" }}>
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(event) => _handleOnChange("url", event, index)}
                    />
                    <img
                      src={
                        link?.url
                          ? link?.previewUrl
                          : require("../../../assets/img/placeholder-img.png")
                              .default
                      }
                      alt="links"
                    />
                  </Label>
                  {/* {link?.isUrlDirty && !link?.isUrlValid ? (
                    <div className="validation-error">Required!</div>
                  ) : null} */}
                </div>
                <div className="socialLinks">
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Enter Social Links "
                      value={link?.value}
                      onChange={(event) =>
                        _handleOnChange("value", event, index)
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <CopyToClipboard
                        text={link?.value}
                        onCopy={() => showToast("Copied", "success")}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-clone"></i>
                      </CopyToClipboard>
                    </InputGroupAddon>
                  </InputGroup>
                  {(link?.isDirty && !link?.isValid) ||
                  (link?.isUrlDirty && !link?.isUrlValid) ? (
                    <div className="validation-error">
                      {/* Required! Please enter a valid url */}
                      Please enter valid URL and logo
                    </div>
                  ) : null}
                </div>
                <div className="socialAction">
                  <Button color="link" onClick={() => _deleteInput(index)}>
                    <img
                      src={
                        require("../../../assets/img/deleteIcon.svg").default
                      }
                      alt="delete"
                      style={{ height: 14 }}
                    />
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          <div className="text-center">
            <Button
              className="mt-4 mb-2"
              color="primary"
              size="md "
              onClick={() => _submitHandler()}
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ManageReviewLinks;
