import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
  Spinner,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Collapse,
  CustomInput,
} from "reactstrap";
import {
  capitalizeFirstLetter,
  deepClone,
  errorHandler,
  showToast,
  sleepTime,
} from "../../../../helper-methods";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import usaStates from "../../../../config/usa_states_titlecase";
import { PostManager } from "../../../../post-manager";
import {
  createClient,
  createClientUser,
  getClientConnectByCompany,
  updateClient,
  updateClientUser,
} from "../../../../http/http-calls";
import { UploadQueueManager } from "../../../../upload-queue-manager";

let initialObject = {
  logo: null,
  clientName: "",
  line1: "",
  city: "",
  state: "",
  zip: "",
  country: "",

  search: "",
  website: "",
};
let initialIsDirty = {
  clientName: false,
  search: false,
};
const NewClient = forwardRef(
  ({ data, manageLoading, type, createdClientData, onSuccess }, ref) => {
    const [newClient, setNewClient] = useState(initialObject);
    const [clientDataInitial, setClientDataInitial] = useState({});
    const [isDirty, setIsDirty] = useState(initialIsDirty);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const _resetData = () => {
      setNewClient(initialObject);
      setIsDirty(initialIsDirty);
      setShow("");
      setSearchResult([]);
    };

    const onChangeFields = (key, value) => {
      const updatedClient = deepClone(newClient);
      updatedClient[key] = value;
      setNewClient(updatedClient);
      if (key === "search") {
        googlePlaceSearch(value)
          .then((res) => {
            setSearchResult(res);
            setShow(true);
          })
          .catch((error) => {
            errorHandler(error);
          });
      }
    };

    const _updateLogoImage = (e) => {
      console.log("e", e.target.files);
      try {
        if (e?.target?.files?.length) {
          const file = e.target.files[0];
          const fileType = file.type.split("/")[0];

          if (fileType === "image") {
            const previewBlob = URL.createObjectURL(file);

            setNewClient((prevState) => ({
              ...prevState,
              logo: {
                file: {
                  uploadData: file,
                  previewBlob: previewBlob,
                  type: fileType,
                  uploadUrl: null,
                },
              },
            }));
          } else {
            showToast("Only image file is allowed", "error");
            return;
          }
        }
      } catch (error) {
        errorHandler();
      }
    };

    const _getPlaceDetail = async (pid, type) => {
      let detail = await googlePlaceDetails(pid.place_id);
      console.log("det", detail);
      if (!detail) {
        showToast("Invalid Address", "error");
        return;
      }

      if (detail.postal) {
        const { address, city, state, postal, country } = detail;
        const stateAbbreviation = usaStates.find(
          (s) => s.name === state
        )?.abbreviation;

        setNewClient((prevState) => ({
          ...prevState,

          line1: detail.address,
          city: detail.city,
          state: stateAbbreviation,
          zip: detail.postal,
          country: detail.country,

          search: `${address},${city},${postal},${country}`,
        }));
      } else {
        showToast("Invalid Address", "error");
      }

      setShow(false);
      setSearchResult([]);
    };

    const _markAllFieldDirty = () => {
      return new Promise((resolve, reject) => {
        const newIsDirty = { ...isDirty };
        Object.keys(newIsDirty).forEach((e) => {
          newIsDirty[e] = true;
        });

        setIsDirty(newIsDirty);

        resolve(newIsDirty);
        //   this.setState({ formFields }, () => resolve(true));
      });
    };
    const _onBlurFormFields = (key) => {
      const newFormFields = { ...newClient };
      const newIsDirty = { ...isDirty };

      newIsDirty[key] = true;

      setIsDirty(newIsDirty);
      _validateFormfields({ newFormFields, newIsDirty });

      // setIsFormDirty(true);
    };

    const _validateFormfields = ({ newFormFields, newIsDirty }) => {
      return new Promise((resolve) => {
        let isFormValid = true;
        const newErrors = {};

        Object.keys(newFormFields)?.forEach((key) => {
          if (newIsDirty[key]) {
            switch (key) {
              case "clientName": {
                if (newIsDirty[key]) {
                  if (newFormFields[key]?.trim()?.length) {
                    if (
                      newFormFields[key].length >= 2 &&
                      newFormFields[key].length <= 50
                    ) {
                      newErrors[key] = null;
                      newIsDirty[key] = false;
                    } else {
                      newErrors[key] =
                        "*Minimum 2 characters and Maximum 50 characters allowed ";
                      isFormValid = false;
                    }
                  } else {
                    newErrors[key] = "*Required";
                    isFormValid = false;
                  }
                }
                break;
              }
              // case "search": {
              //   if (newIsDirty[key]) {
              //     if (newFormFields[key]?.trim()?.length) {
              //       newErrors[key] = null;
              //       newIsDirty[key] = false;
              //     } else {
              //       newErrors[key] = "*Required";
              //       isFormValid = false;
              //     }
              //   }
              //   break;
              // }
              default:
            }
          }
        });

        console.log({ newErrors });
        setIsDirty((prev) => ({
          ...prev,
          ...newIsDirty,
        }));
        setErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));
        resolve(isFormValid);
      });
    };

    const _onSubmit = async () => {
      // console.log(isFormDirty)
      try {
        const newFormFields = { ...newClient };
        const newIsDirty = await _markAllFieldDirty();

        const isValidate = await _validateFormfields({
          newFormFields,
          newIsDirty,
        });
        if (!isValueChanged(newFormFields)) {
          return true;
        }
        // await
        if (isValidate) {
          manageLoading(true);

          // showToast("Successfully created", "success");
          let payload = {
            companyName:
              newClient.clientName.trim().length > 0
                ? capitalizeFirstLetter(newClient.clientName.trim())
                : undefined,
            websiteURL:
              newClient.website.trim().length > 0
                ? newClient.website.trim()
                : undefined,

            address: {
              line1:
                newClient.search.trim().length > 0
                  ? newClient.line1.trim()
                  : undefined,
              city:
                newClient.search.trim().length > 0
                  ? newClient.city.trim()
                  : undefined,
              state:
                newClient.search.trim().length > 0
                  ? newClient.state.trim()
                  : undefined,
              zip:
                newClient.search.trim().length > 0
                  ? newClient.zip.trim()
                  : undefined,
              country:
                newClient.search.trim().length > 0
                  ? newClient.country.trim()
                  : undefined,
            },
          };

            if (
              newFormFields?.logo?.file &&
              newFormFields?.logo?.file?.uploadData
            ) {
              let postID = PostManager.addMediaFilesCount(1);
          
              const allMediaFilesUploadCompleted = new Promise((resolve, reject) => {
                PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
                  if (id.postID === postID) {
                    await sleepTime(500);
                    resolve();
                  } else {
                    reject(new Error("Post ID mismatch"));
                  }
                });
              });
          
              // Upload the logo image
              const mediaData = {
                file: newFormFields.logo.file.uploadData,
                blobObject: newFormFields.logo.file.previewBlob,
              };
              const uploadId = UploadQueueManager.addMediaToQueue(mediaData, "image");
          
              const uploadComplete = new Promise((resolve, reject) => {
                UploadQueueManager.onUploadComplete(uploadId, (mediaResponse) => {
                  PostManager.onSingleMediaFileUploadCompleted(postID);
                  payload["logo"] = mediaResponse.fileUrl;
                  resolve();
                });
              });
          
              try {
                await Promise.all([allMediaFilesUploadCompleted, uploadComplete]);
          
                PostManager.deletePostID(postID);
          
                if (data) {
                  await _updateClientUser(payload, data.id);
                  onSuccess();
                } else {
                  await _createClientUser(payload);
                }
                return true;
              } catch (error) {
                console.error(error);
                return false;
              }
            } else {
              try {
                if (type === "edit") {
                  await _updateClientUser(payload, data.id);
                } else {
                  await _createClientUser(payload);
                }
                onSuccess();
                manageLoading(false);
                return true;
              } catch (error) {
                console.error(error);
                manageLoading(false);
                return false;
              }
            }
          
          // manageLoading(false);
        } else {
          return false;
        }
      } catch (error) {
        errorHandler("Please fill all mandatory form fields correctly!");
        manageLoading(false);
      }
    };

    const _createClientUser = (payload) => {
      return new Promise((resolve, reject) => {
        createClient(payload)
          .then((res) => {
            showToast("New Client has been Added", "success");
            _resetData();
            createdClientData(res.client);
            resolve(res);
          })
          .catch((error) => {
            errorHandler(error);
            reject(error);
          });
      });
    };

    const _updateClientUser = (payload, id) => {
      return new Promise((resolve, reject) => {
        updateClient(id, payload)
          .then((res) => {
            showToast("Client has been updated", "success");
            _resetData();
            resolve(res);
          })
          .catch((error) => {
            errorHandler(error);
            reject(error);
          });
      });
    };

    useImperativeHandle(ref, () => ({
      _onSubmit,
    }));

    const _getClientConnectByCompany = () => {
      return new Promise(async (resolve, reject) => {
        manageLoading(true);
        try {
          // console.log("qw",data)
          const res = await getClientConnectByCompany({ clientId: data._id });
          let clientData = { ...newClient };
          clientData.clientName = res.client.companyName || "N/A";
          clientData.line1 = res.client?.address?.line1 || "";
          clientData.city = res.client?.address?.city || "";
          clientData.state = res.client?.address?.state || "";
          clientData.zip = res.client?.address?.zip || "";
          clientData.country = res.client?.address?.country || "";
          clientData.website = res.client.websiteURL || "";
          clientData.logo = res.client.logo || "";
          // clientData.search = `${res.client?.address?.line1 || ""},${
          //   res.client?.address?.city || ""
          // },${res.client?.address?.zip || ""},${
          //   res.client?.address?.country || ""
          // }`;
          clientData.search = [
            res.client?.address?.line1,
            res.client?.address?.city,
            res.client?.address?.zip,
            res.client?.address?.country,
          ]
            .filter(Boolean) // Removes undefined or empty strings
            .join(",");
          setNewClient(clientData);
          setClientDataInitial(clientData);

          // this._getClientTeamMembers();
          resolve(res?.client || {});
          manageLoading(false);
        } catch (error) {
          manageLoading(false);
          reject(error);
        }
      });
    };

    const isValueChanged = (formFields) => {
      for (let key in formFields) {
        if (formFields[key] !== clientDataInitial[key]) {
          return true;
        }
      }
      return false;
    };

    useEffect(() => {
      if (type === "edit") {
        // console.log("tp", data);
        _getClientConnectByCompany(data.id);
      }
    }, []);

    return (
      <>
        {console.log("p", newClient)}
        <Label className="uploadProfile">
          <Input
            type="file"
            style={{ display: "none" }}
            accept="image/x-png,image/gif,image/jpeg"
            value=""
            onChange={_updateLogoImage}
          />
          <img
            src={
              newClient?.logo?.file?.previewBlob ||
              newClient?.logo?.file?.uploadUrl ||
              newClient?.logo ||
              require("../../../../assets/img/company-logo.png").default
            }
            loading="lazy"
            alt="Client Img"
            style={{ objectFit: "contain" }}
          />
          <i className="fa fa-pencil" />
        </Label>
        <FormGroup className="floatingLabel">
          <Input
            type="text"
            placeholder=" "
            value={newClient.clientName}
            onChange={(e) => onChangeFields("clientName", e.target.value)}
            onBlur={() => _onBlurFormFields("clientName")}
          />
          <Label>Client/Business Name</Label>
          {errors.clientName && (
            <div className="validation-error">{errors.clientName}</div>
          )}
        </FormGroup>
        <FormGroup className="floatingLabel">
          <Input
            type="text"
            placeholder=" "
            value={newClient?.search}
            name="search"
            onChange={(event) => onChangeFields("search", event.target.value)}
            onBlur={() => _onBlurFormFields("clientName")}
          />
          <Label>Search Address</Label>

          {show ? (
            <ListGroup flush className="customSearchOptions">
              {searchResult &&
                searchResult?.map((i) => {
                  return (
                    <ListGroupItem
                      key={i?.place_id}
                      style={{ cursor: "pointer" }}
                      onClick={() => _getPlaceDetail(i)}
                    >
                      {i?.description}
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          ) : null}

          {errors?.search && (
            <div className="validation-error">{errors?.search}</div>
          )}
        </FormGroup>
        <FormGroup className="floatingLabel">
          <Input
            type="url"
            placeholder=" "
            value={newClient.website}
            onChange={(e) => onChangeFields("website", e.target.value)}
            //   onBlur={() => this._onBlurFormFields("email")}
          />
          <Label>Website</Label>
          {errors.email && (
            <div className="validation-error">{errors.email}</div>
          )}
        </FormGroup>
      </>
    );
  }
);

export default NewClient;
