import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  capitalize,
  formatDate,
  showToast,
  formatDateAndTimeForIncomeList,
  errorHandler,
  deepClone,
} from "../../../helper-methods";
import {
  companyCreateIncome,
  agentGetAllUnregisteredClient,
  updateSigningcompanyIncome,
} from "../../../http/http-calls";

class AddIncomeModalCompany extends Component {
  state = {
    unregisterClientList: [],
    formFields: {
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      amount: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      client: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formFields: {
        date: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        amount: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        client: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        notes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        this.setState({ unregisterClientList: res.clients });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen } = this.props;
    const { formFields } = deepClone(this.state);

    if (isOpen && isOpen !== prevProps.isOpen) {
      // this._agentGetAllUnregisteredClient();
      if (this.props.data) {
        formFields["amount"].value = this.props.data.amount.toString();
        formFields["notes"].value = this.props.data.notes;

        this.setState({
          formFields,
        });
      }
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "date": {
              if (
                this.props.data ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "amount": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Amount must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "client": {
              if (this.props.data || formFields[key].value?.trim().length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "date") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[fieldName].value = value;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _companyCreateIncome = (payload) => {
    companyCreateIncome(payload)
      .then((res) => {
        this._closeModal();
        showToast("Income Created", "success");
        this.props.resetDetails();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields } = this.state;

        const payload = {
          appointmentDate: formatDateAndTimeForIncomeList(
            formFields.date.value
          ),
          amount: formFields.amount.value?.trim(),
          notes: formFields.notes.value?.trim() || "",
        };

        if (formFields.client.value.trim().length) {
          payload["clientId"] = formFields.client.value;
        }

        // this._companyCreateIncome(payload);
        if (this.props.data) {
          this._updateSigningcompanyIncome(payload);
        } else {
          this._companyCreateIncome(payload);
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _updateSigningcompanyIncome = (payload) => {
    const keysToKeep = ["notes", "amount"];

    let newPayload = keysToKeep.reduce((acc, key) => {
      if (payload.hasOwnProperty(key)) {
        acc[key] = payload[key];
      }
      return acc;
    }, {});

    updateSigningcompanyIncome(this.props.data?.id, newPayload)
      .then((res) => {
        this._closeModal();
        showToast("Income Updated", "success");
        this.props.resetDetails();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  render() {
    const { formFields, loading } = this.state;

    const { isOpen, clients } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this._closeModal} centered scrollbar>
        <ModalHeader toggle={this._closeModal}>
          {this.props.data ? "Edit" : "Add"} Income
        </ModalHeader>
        <ModalBody>
          {this.props.data === null && (
            <FormGroup
              className={`floatingLabel ${
                formFields.date.value || this.state.isOpenReactDatetime
                  ? "valueAdded"
                  : ""
              }`}
            >
              <ReactDatetime
                inputProps={{
                  className: "form-control",
                  placeholder: " ",
                  value: formatDate(formFields.date.value),
                }}
                onChange={(e) => this._onChangeFormField("date", e._d)}
                closeOnSelect={true}
                isValidDate={(current) => current.isBefore(new Date())}
                timeFormat={false}
                timeConstraints={{ minutes: { step: 15 } }}
                onOpen={() => this.setState({ isOpenReactDatetime: true })}
                onClose={() => this.setState({ isOpenReactDatetime: false })}
              />
              <Label>Date</Label>
              {formFields.date.error && (
                <div className="validation-error">{formFields.date.error}</div>
              )}
            </FormGroup>
          )}
          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/dollar.svg").default}
                    alt="doller"
                    height={14}
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.amount.value}
                name="amount"
                onChange={(event) =>
                  this._onChangeFormField("amount", event.target.value)
                }
              />
              <Label>Amount</Label>
            </InputGroup>
            {formFields.amount.error && (
              <div className="validation-error">{formFields.amount.error}</div>
            )}
          </FormGroup>
          {this.props.data === null && (
            <FormGroup className="floatingLabel custom-select-wrapper">
              <Input
                type="select"
                name="client"
                placeholder=" "
                value={formFields.client.value}
                onChange={(event) =>
                  this._onChangeFormField("client", event.target.value)
                }
              >
                <option value="">Select</option>
                {clients.map((comp) => (
                  <option key={comp._id} value={comp._id}>
                    {(comp?.companyName
                      ? capitalize(comp.companyName)
                      : capitalize(comp.name.full)) || "N/A Client"}
                  </option>
                ))}
              </Input>
              <Label>Client</Label>

              {formFields.client.error && (
                <div className="validation-error">
                  {formFields.client.error}
                </div>
              )}
            </FormGroup>
          )}

          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="3"
              placeholder=" "
              value={formFields.notes.value}
              name="notes"
              onChange={(event) =>
                this._onChangeFormField("notes", event.target.value)
              }
            />
            <Label>Notes</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._onSubmit}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {this.props.data === null ? "Add" : "Update"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddIncomeModalCompany;
