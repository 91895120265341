import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Input,
  CustomInput,
  Spinner,
  Label,
  FormGroup,
} from "reactstrap";
import {
  capitalize,
  checkPermission,
  errorHandler,
  formatDateAndTime,
  showToast,
} from "../../../helper-methods";
import {
  getAllChatLog,
  createChatLog,
  updateChatLog,
  forwardChatLog,
  deleteChatLog,
} from "../../../http/http-calls";
import Swal from "sweetalert2";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import ReadMore from "./readMore";
import CustomPagination from "./CustomPagination";

const ChatLogCardComponent = forwardRef(({ closingDetails }, ref) => {
  const userData = useSelector((state) => state?.userData);
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [commentsPayload, setCommentsPayload] = useState({
    skip: 0,
    limit: 5,
    closingId: closingDetails?._id,
  });
  const [loading, setLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [deleteCommentLoading, setDeleteCommentLoading] = useState(false);
  const [forwardCommentLoading, setForwardCommentLoading] = useState(false);
  const [isVisibleClient, setIsVisibleClient] = useState(false);
  const [isVisibleAgent, setIsVisibleAgent] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [isCreatedByClient, setIsCreatedByClient] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const _resetCommentState = () => {
    setTimeout(() => {
      setComment("");
    }, 0);
    setCommentId(null);
    setIsVisibleClient(false);
    setIsVisibleAgent(false);
    setAddCommentLoading(false);
  };

  useImperativeHandle(ref, () => {
    return {
      _getAllComments,
    };
  });

  // console.log({ closingDetails });

  const _getAllComments = async (payload) => {
    try {
      setLoading(true);
      let res = await getAllChatLog(payload);
      setComments(res.chatLogs?.length ? res.chatLogs : []);
      setCommentsCount(res.totalCount || 0);
      setIsCreatedByClient(res?.isCreatedByClient);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _sendComment = async (comment) => {
    try {
      if (!comment?.trim()?.length) {
        showToast("Please add comment", "error");
        return;
      }
      if (!closingDetails?._id) {
        showToast("Closing not found", "error");
        return;
      }
      if (
        userData?.user?.type?.toLowerCase() === "signingcompany" &&
        !isVisibleAgent &&
        !isVisibleClient &&
        !isInternal
      ) {
        showToast("Please select comment visibility", "error");
        return;
      }

      setAddCommentLoading(true);

      const payload = {
        text: comment.trim(),
        closingId: closingDetails?._id,
        isVisibleClient,
        isVisibleAgent,
        isInternal,
      };

      if (commentId) {
        const newComments = [...comments];
        const findComment = newComments.find((each) => each._id === commentId);
        if (findComment) {
          findComment.text = payload.text;
          setComments(newComments);
        }
        await updateChatLog({ id: commentId, payload });
        _onChangePagination();
      } else {
        const newComments = [...comments];
        newComments.unshift({
          isLoading: true,
          text: payload.text,
          userId: userData?.user?._id,
          name: userData?.user.isAssistant
            ? userData?.user?._assistant?.name?.first
            : userData?.user?.name?.first,
          createdAt: new Date(),
        });
        setComments(newComments);
        await createChatLog(payload);
        _onChangePagination();
      }

      showToast(
        `Comment has been ${commentId ? "updated" : "added"}`,
        "success"
      );

      _resetCommentState();
    } catch (error) {
      _onChangePagination();
      setAddCommentLoading(false);
      errorHandler(error);
    }
  };

  const _onKeyDownComment = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value?.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      _sendComment(e.target.value);
    }
  };

  const _onChangePagination = (pageNo = 1) => {
    const newCommentsPayload = { ...commentsPayload };
    newCommentsPayload.skip = (pageNo - 1) * newCommentsPayload.limit;
    setCurrentPage(pageNo);
    setCommentsPayload(newCommentsPayload);
    _getAllComments(newCommentsPayload);
  };

  const _deleteComment = (commentId) => {
    setDeleteCommentLoading(commentId);

    const newComments = [...comments];
    const findCommentIndex = newComments.findIndex(
      (each) => each._id === commentId
    );
    if (findCommentIndex > -1) {
      newComments.splice(findCommentIndex, 1);
      setComments(newComments);
    }

    deleteChatLog(commentId)
      .then(async (res) => {
        Swal.fire("Deleted!", "Comment has been deleted.", "success");
        if (newComments.length === 0) {
          _onChangePagination();
        }
        setDeleteCommentLoading(false);
      })
      .catch((error) => {
        _onChangePagination();
        errorHandler(error);
        setDeleteCommentLoading(false);
      });
  };

  const _deleteCommentAlert = (comment) => {
    if (!comment?._id) {
      showToast("Comment not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this comment.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteComment(comment._id);
      }
    });
  };

  const _forwardComment = (commentId) => {
    setForwardCommentLoading(commentId);

    const newComments = [...comments];
    const findComment = newComments.find((each) => each._id === commentId);
    if (findComment) {
      findComment.isVisibleAgent = true;
      findComment.isVisibleClient = true;
      setComments(newComments);
    }

    forwardChatLog({
      id: commentId,
      payload: {
        closingId: closingDetails?._id,
        isVisibleAgent: true,
        isVisibleClient: true,
      },
    })
      .then(async (res) => {
        Swal.fire("Forwarded!", "Comment has been forwarded.", "success");
        if (newComments.length === 0) {
          _onChangePagination();
        }
        _onChangePagination();
        setForwardCommentLoading(false);
      })
      .catch((error) => {
        _onChangePagination();
        errorHandler(error);
        setForwardCommentLoading(false);
      });
  };

  const _forwardCommentAlert = (comment) => {
    if (!comment?._id) {
      showToast("Comment not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to forward this comment to ${comment.isVisibleAgent ? "client" : "agent"
        }.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, forward it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _forwardComment(comment._id);
      }
    });
  };

  const _editComment = (comment = null) => {
    setComment(comment?.text || "");
    setCommentId(comment?._id || null);
  };

  useEffect(() => {
    if (closingDetails?._id) {
      const newCommentsPayload = { ...commentsPayload };
      newCommentsPayload.closingId = closingDetails?._id;
      setCommentsPayload(newCommentsPayload);
      _getAllComments(newCommentsPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closingDetails?._id]);

  // eslint-disable-next-line no-unused-vars
  const _addedByLogo = (comment) => {
    if (comment.isLoading) {
      return DEFAULT_COVER_PICTURE;
    }

    if (comment?._createdBy) {
      if (comment?._createdBy?.userType !== "SigningCompany") {
        if (comment?._agent) {
          return comment?._agent?.profilePicture || DEFAULT_COVER_PICTURE;
        }
      } else {
        if (comment?._signingCompanyAssistant) {
          return (
            comment?._signingCompanyAssistant?.profilePicture ||
            DEFAULT_COVER_PICTURE
          );
        } else if (comment?._signingCompany) {
          return (
            comment?._signingCompany?.profilePicture || DEFAULT_COVER_PICTURE
          );
        }
      }
    } else if (comment?._createdByClient) {
      if (comment?._clientAssistant) {
        return (
          comment?._clientAssistant?.profilePicture || DEFAULT_COVER_PICTURE
        );
      } else if (comment?._client) {
        return comment?._client?.profilePicture || DEFAULT_COVER_PICTURE;
      }
    }
  };

  const _addedByName = (comment) => {
    if (comment.isLoading) {
      return capitalize(comment.name) || "Loading";
    }
    if (comment?._createdBy) {
      if (comment?._createdBy?.userType !== "SigningCompany") {
        if (comment?._agent) {
          return comment._agent.name?.first && comment._agent.workType
            ? `${capitalize(comment._agent.name?.first)} of ${comment._agent.workType
            }`
            : capitalize(comment._agent.name?.first);
        }
      } else {
        if (comment?._signingCompanyAssistant) {
          return comment._signingCompanyAssistant.name?.first &&
            comment._signingCompany.companyName
            ? `${capitalize(comment._signingCompanyAssistant.name?.first)} of ${comment._signingCompany.companyName
            }`
            : capitalize(comment._signingCompanyAssistant.name?.first);
        } else if (comment?._signingCompany) {
          return comment._signingCompany.name?.first &&
            comment._signingCompany.companyName
            ? `${capitalize(comment._signingCompany.name?.first)} of ${comment._signingCompany.companyName
            }`
            : capitalize(comment._signingCompany.name?.first);
        }
      }
    } else if (comment?._createdByClient) {
      if (comment?._clientAssistant) {
        return comment._clientAssistant.name?.first &&
          comment._client.companyName
          ? `${capitalize(comment._clientAssistant.name?.first)} of ${comment._client.companyName
          }`
          : capitalize(comment._client.name?.first);
      } else if (comment?._agentClient) {
        return comment?._agentClient?.name?.full
          ? capitalize(comment?._agentClient?.name?.full)
          : "";
      } else if (comment?._client) {
        return comment._client.name?.first && comment._client.companyName
          ? `${capitalize(comment._client.name?.first)} of ${comment._client.companyName
          }`
          : capitalize(comment._client.name?.first);
      }
    }
  };
  const _isAddedBySameUser = (comment) => {
    if (comment.isLoading) {
      return userData?.user?._id === comment?.userId;
    }
    switch (userData?.user?.type?.toLowerCase()) {
      case "client": {
        return userData?.user?._id === comment?._client?._id;
      }
      case "agent": {
        return userData?.user?._id === comment?._agent?._id;
      }
      case "signingcompany": {
        return userData?.user?._id === comment?._signingCompany?._id;
      }
      default: {
        return false;
      }
    }
  };

  return (
    <>
      <div>
        <div className="CardTitle">
          <h2 className="sectionTtile"> Chat Log </h2>
          {loading ? <Spinner size="sm" /> : null}
        </div>
        <Card>
          <CardBody>
            {checkPermission("dashboard", "canEditOrderEntry") ? (
              <>
                <FormGroup className="floatingLabel">
                  <Input
                    type="textarea"
                    style={{ height: 140, marginBottom: 10 }}
                    placeholder=" "
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={(e) => _onKeyDownComment(e)}
                    disabled={addCommentLoading}
                  />
                  <Label>Write a comment</Label>
                </FormGroup>

                {userData?.user?.type?.toLowerCase() === "signingcompany" ? (
                  <>
                    {/* {isCreatedByClient ? ( */}
                      <CustomInput
                        className="m-1"
                        type="checkbox"
                        label="Client"
                        value="Client"
                        id="isVisibleClient"
                        checked={isVisibleClient}
                        onChange={(e) => setIsVisibleClient(e.target.checked)}
                        inline
                        disabled={closingDetails._client === undefined}
                      />
                    {/* ) : null} */}
                    {closingDetails?._agent !== undefined ? (
                      <CustomInput
                        className="m-1"
                        type="checkbox"
                        label="Agent"
                        value="isVisibleAgent"
                        id="isVisibleAgent"
                        checked={isVisibleAgent}
                        onChange={(e) => setIsVisibleAgent(e.target.checked)}
                        inline
                        disabled={closingDetails._agent === undefined}
                      />
                    ) : null}
                    {userData?.user?.type?.toLowerCase() ===
                      "signingcompany" ? (
                      <CustomInput
                        className="m-1"
                        type="checkbox"
                        label="Internal Notes"
                        value="isInternal"
                        id="isInternal"
                        checked={isInternal}
                        onChange={(e) => setIsInternal(e.target.checked)}
                        inline
                      // disabled={closingDetails._agent === undefined}
                      />
                    ) : null}
                  </>
                ) : null}

                <div className="d-flex justify-content-center mt-4">
                  {commentId ? (
                    <Button
                      color="primary"
                      outline
                      onClick={() => _resetCommentState()}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => _sendComment(comment)}
                    disabled={addCommentLoading}
                  >
                    {commentId ? "Update" : "Create"} Comment
                  </Button>
                </div>
              </>
            ) : null}

            <div className="chatWrapper ">
              {comments?.length ? (
                comments.map((each, index) => (
                  <>
                    <div className="chatWrap">
                      {/* add bg-grey class in chatItem when internal notes selected */}
                      <div className={`chatItem ${each.isInternal ? 'bg-grey' : ''}`} key={each._id + index}>
                        <div className="chatHeader">
                          <h2> {_addedByName(each)}</h2>
                          <div className="action">
                            <span className="text-primary">
                              {each?.isForwardedToAgent ||
                                each?.isForwardedToClient
                                ? "(Forwarded)"
                                : null}
                            </span>
                            {userData?.user?.type?.toLowerCase() ===
                              "signingcompany" &&
                              ((each?.isVisibleAgent &&
                                isCreatedByClient &&
                                !each?.isVisibleClient) ||
                                (isCreatedByClient &&
                                  each?.isVisibleClient &&
                                  !each?.isVisibleAgent &&
                                  closingDetails?._agent !== undefined)) ? (
                              <Button
                                color="link"
                                disabled={
                                  forwardCommentLoading || each.isLoading
                                    ? true
                                    : false
                                }
                                onClick={() => _forwardCommentAlert(each)}
                              >
                                <img
                                  src={
                                    require("../../../assets/img/forward.svg")
                                      .default
                                  }
                                  alt="forward"
                                />
                              </Button>
                            ) : null}

                            {checkPermission(
                              "dashboard",
                              "canEditOrderEntry"
                            ) && _isAddedBySameUser(each) ? (
                              each._id === commentId ? (
                                <Button
                                  color="link"
                                  onClick={() => _editComment()}
                                  disabled={each.isLoading}
                                >
                                  <i className="fa fa-times" />
                                </Button>
                              ) : (
                                (each._createdBy?.id === userData?.user?._id ||
                                  each?._createdByClient?.id ===
                                  userData?.user?.id) && (
                                  <>
                                    <Button
                                      color="link"
                                      onClick={() => _editComment(each)}
                                      disabled={each.isLoading}
                                    >
                                      <img
                                        src={
                                          require("../../../assets/img/pencil.svg")
                                            .default
                                        }
                                        alt="pencil"
                                      />
                                    </Button>

                                    <Button
                                      color="link"
                                      disabled={
                                        deleteCommentLoading || each.isLoading
                                          ? true
                                          : false
                                      }
                                      onClick={() => _deleteCommentAlert(each)}
                                      className="remove"
                                    >
                                      <img
                                        src={
                                          require("../../../assets/img/deleteIcon.svg")
                                            .default
                                        }
                                        alt="pencil"
                                      />
                                    </Button>
                                  </>
                                )
                              )
                            ) : null}
                          </div>
                        </div>
                        <div className="Chatcontent">
                          <ReadMore text={each?.text} />
                        </div>
                      </div>
                      <div className="date">
                        {each?.displayDate
                          ? formatDateAndTime(each.displayDate)
                          : formatDateAndTime(each.createdAt)}
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </div>

            <CustomPagination
              data={comments}
              dataCount={commentsCount}
              pageNumber={currentPage}
              pageSize={commentsPayload.limit}
              onPageChange={(pageNo) => _onChangePagination(pageNo)}
              isPageStartFromOne
              isHideForLessData
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
});

export default ChatLogCardComponent;
