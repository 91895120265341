import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledDropdown,
  UncontrolledPopover,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  formatOnlyDateMoment,
  getDateRangeValue,
  getTimeZoneAbbr,
  isRegularUser,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import AddNotesModal from "../components/add-notes-income-list-modal";
// import CustomDataTable from "../components/custom-data-table";
import ReactDatetime from "react-datetime";
import {
  agentClosingStatus,
  customDateRangeConfig,
  monthShortNames,
} from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  agentFetchAllIncome,
  agentGetAllUnregisteredClient,
  agentUpdateIncome,
  agentUpdateIncomeMarkPaidById,
  getAllFiltersData,
  updateAgentIncomeForRecordPayment,
} from "../../../http/http-calls";
import CustomCard from "../components/CustomCard";
import CustomTable from "../components/CustomTable";
import DownloadInvoiceModal from "../components/Modals/DownloadInvoiceModal";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import AddIncomeModalAgent from "../components/add-income-modal-agent";
import SvgIcons from "../components/SvgIcons";

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class IncomeListAccountingAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomeGraphConfig: {
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  var label = context.label || ""; // changeable
                  var dataFor = context.dataset.label || ""; // constant
                  if (label) {
                    label += ": ";
                  }
                  if (dataFor) {
                    label += dataFor + ": ";
                  }
                  if (context.parsed !== null) {
                    label += formatCurrencyValue(context.parsed);
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
        type: "pie",
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Income",
              backgroundColor: "rgba(30, 41, 92, 0.3)",
              borderColor: "rgba(30, 41, 92, 1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
              hoverBorderColor: "rgba(30, 41, 92, 1)",
              data: [],
            },
          ],
        },
      },
      incomeList: [],
      incomeListBackup: [],
      incomeTotalCount: 0,
      totalAmount: 0,
      totalPaidAmount: 0,
      totalPendingDueAmount: 0,
      incomeHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "appointmentDate", label: "Date", noSort: true },
        { id: "fileNumber", label: "File Number", noSort: true },
        { id: "_borrower", label: "Signer", noSort: true },
        { id: "client", label: "Client", noSort: true },
        { id: "amount", label: "Fee", noSort: true },
        { id: "status", label: "Order Status", noSort: true },
        { id: "notes", label: "Notes", noSort: true },
        { id: "duePayment", label: "Balance Due", noSort: true },
        { id: "action", label: "Action", noSort: true },
        { id: "receipt", label: "Receipt", noSort: true },
      ],
      incomeCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      addNotesModal: {
        isOpen: false,
        data: null,
      },
      downloadInvoiceModal: {
        isOpen: false,
        data: null,
      },
      filterDropdownValues: {
        payment: [
          { name: "Pending Payment", value: "Pending" },
          { name: "Paid", value: "Paid" },
        ],
        signingCompany: [],
        unregisterClientList: [],
      },
      addIncomeModalAgent: {
        isOpen: false,
        data: null,
      },
      filters: {
        payment: "",
        company: "",
        clientId: "",
        loanType: "",
        search: "",
        customDateRangeValue: "month",
        dateRangeValue: null,
      },
      loading: {
        showTableLoading: false,
        markPaidLoading: false,
      },
      selectedRows: [],
      amount: "",
      incomeObj: [],
      referenceNumber: "",
      checkDate: "",
      upgradeAccountModal: {
        isOpen: false,
        data: null,
      },
      isClassAdded: false,
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    // this._getAllFiltersData();
    this._agentGetAllUnregisteredClient();
    // call function after set date range -> _agentFetchAllIncome
    this._setDateRangeValue();
    this._setFilterDataFromLocalStorage();

    HeaderEventEmitter.subscribe("reset-filter", () => {
      refreshFunc("agent-accounting-income-list");
      this._resetFilter();
    });
    HeaderEventEmitter.subscribe("add-income", () => {
      this._onToggleAddIncomeModalAgent(true);
    });
  };

  _setGraphData = (resData = []) => {
    try {
      const { incomeGraphConfig, filters } = deepClone(this.state);

      incomeGraphConfig.chartData.labels = resData?.length
        ? resData.map((obj) => {
            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              return `${obj._id.year}`;
            }

            return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
          })
        : [];
      incomeGraphConfig.chartData.datasets[0].data = resData?.length
        ? resData.map((obj) => obj.amount)
        : [];

      this.setState({ incomeGraphConfig });
    } catch (error) {
      showToast("Somthing went wrong. Try again after sometime.", "error");
    }
  };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["unregisterClientList"] = res?.clients;
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
  _agentFetchAllIncome = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, filterDropdownValues } = deepClone(
      this.state
    );

    const filterPayload = {};

    // if (filters.company && filters.company.length)
    //   filterPayload["company"] = filters.company;

    if (filters.clientId && filters.clientId.length) {
      filterDropdownValues.unregisterClientList.find((each) => {
        if (each._id === filters.clientId) {
          if (each.hasOwnProperty("_client") && each._client !== "") {
            filterPayload["company"] = "unregisteredClient";
            filterPayload["clientId"] = each._client;
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            filterPayload["company"] = each._signingCompany;
          } else {
            filterPayload["assistantId"] = each._assistant;
          }
        }
      });
    }
    // filterPayload["clientId"] = filters.clientId;
    if (filters.payment && filters.payment.length)
      filterPayload["status"] = filters.payment;
    if (filters.dateRangeValue) {
      filterPayload["startDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[0]
      );
      filterPayload["endDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[1]
      );
    }
    if (filters.customDateRangeValue) {
      if (
        filters.customDateRangeValue === "month" ||
        filters.customDateRangeValue === "day" ||
        filters.customDateRangeValue === "week"
      ) {
        filterPayload["groupByUnit"] = "day";
      } else if (filters.customDateRangeValue === "allTime") {
        filterPayload["groupByUnit"] = "year";
      }
    }

    if (filters.search && filters.search.length)
      tableConfig["search"] = filters.search;

    tableConfig["filters"] = { ...filterPayload };

    agentFetchAllIncome(tableConfig)
      .then((res) => {
        this._setGraphData(res?.incomeGraph);

        this.setState(
          {
            incomeList: res?.incomes || [],
            incomeListBackup: [
              ...this.state.incomeListBackup,
              res?.incomes,
            ].flat(),
            incomeTotalCount: res?.totalCount || 0,
            totalAmount: res?.totalAmount || 0,
            totalPaidAmount: res?.totalPaidAmount || 0,
            totalPendingDueAmount: res?.totalPendingDueAmount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["signingCompany"] = res?.signingCompany;
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _setDateRangeValue = (value = "month") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValue(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    if (type === "dateRangeValue") {
      if (value) {
        filters["customDateRangeValue"] = "customDateRange";
        filters[type] = value;
        this.setState({ filters }, () => {
          this._paginate();
          this._persistFilter();
        });
      } else {
        this._setDateRangeValue();
      }
    } else if (type === "search") {
      filters[type] = value;
      this.setState({ filters }, () => {
        this._searchOnChange();
      });
    } else {
      filters[type] = value;
      this.setState({ filters }, () => {
        this._paginate();
        this._persistFilter();
      });
    }
  };

  _searchOnChange = debounce(() => {
    this._paginate();
    this._persistFilter();
  }, 1000);

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          payment: "",
          company: "",
          clientId: "",
          loanType: "",
          search: "",
          customDateRangeValue: "month",
          dateRangeValue: null,
        },
      },
      () => {
        refreshFunc("agent-accounting-income-list-responsive");
        this._setDateRangeValue();
      }
    );
  };

  _paginate = (pageNumber = 1, pageSize = 50) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._agentFetchAllIncome();
    });
  };

  _onToggleAddIncomeModalAgent = (isOpen = false, data = null) => {
    this.setState({
      addIncomeModalAgent: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      downloadInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _agentUpdateIncomeMarkPaidById = (id) => {
    this._manageLoading("markPaidLoading", true);

    agentUpdateIncomeMarkPaidById(id)
      .then((res) => {
        const { incomeList } = this.state;
        let findIncomeList = incomeList.find((obj) => obj.id === id);
        findIncomeList["isPaid"] = true;
        this.setState({ incomeList }, () => {
          this._manageLoading("markPaidLoading", false);
        });
        showToast("Mark Paid Successfully", "success");
      })
      .catch((error) => {
        this._setDateRangeValue();
        errorHandler(error);
        this._manageLoading("markPaidLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "_borrower": {
        return (
          <div style={{ width: 120 }}>
            {/* show the below span only when there are more than 1 signers  */}
            {cell.length > 0 ? capitalize(cell[0]?.name?.full) : "N/A"}{" "}
            {cell && cell?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="text-primary"
                  style={{ fontWeight: "600" }}
                >
                  +{cell.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {cell.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }
      case "client": {
        return (
          <>
            <div style={{ width: 100 }}>
              {row?._signingCompany?.companyName
                ? capitalize(row._signingCompany.companyName)
                : row?._client?.name
                ? capitalize(row._client?.name?.full)
                : capitalize(row._assistant?.name?.full) || "N/A"}
            </div>
          </>
        );
      }
      case "amount": {
        return (
          <>
            <div style={{ width: 100 }}>
              {cell || cell === 0 ? formatCurrencyValue(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "appointmentDate": {
        return cell ? (
          <>
            <div style={{ width: 120 }}>
              <span style={{ fontWeight: 600 }}>{formatDate(cell)}</span>{" "}
              {row?._closing && (
                <span>
                  {getTimeZoneAbbr(
                    cell,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </span>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "notes": {
        return row ? (
          <Button
            color="link"
            className=""
            onClick={() => this._onToggleAddNotesModal(true, row)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="Add Notes"
            />
          </Button>
        ) : (
          "-"
        );
      }
      case "status": {
        return (
          <>
            <div style={{ width: 100 }}>
              {cell && cell !== "" ? (
                <Badge
                  color={`${cell === "Closed" ? "success" : "danger"}`}
                  className="outline"
                  pill
                >
                  {cell
                    ? agentClosingStatus.find((obj) => obj.value === cell)
                        ?.label || "N/A"
                    : "N/A"}
                </Badge>
              ) : (
                "N/A"
              )}
            </div>
          </>
        );
      }
      case "duePayment": {
        return (
          <>
            <div style={{ width: 100 }}>
              {cell ? formatCurrencyValue(cell) : "$0"}
            </div>
          </>
        );
      }
      case "receipt": {
        return (
          <div style={{ width: 100 }}>
            <Button
              color="link"
              onClick={() => this._onToggleDownloadInvoiceModal(true, row)}
            >
              Receipt
            </Button>
            {!row?._closing && (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._onToggleAddIncomeModalAgent(true, row)}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
            )}
          </div>
        );
      }

      case "action": {
        return row.isPaid ? (
          <Badge color="success" className="outline" pill>
            Paid
          </Badge>
        ) : (
          <Input
            type="text"
            name="amount"
            value={
              this.state.incomeObj.length > 0
                ? this.state.incomeObj.find((item) => item?.id === row?._id)
                    ?.paymentAmount || ""
                : ""
            }
            disabled={
              !this.state.selectedRows.find((item) => item?._id === row?._id)
            }
            onChange={(event) =>
              this._onChangeFormField(row, event.target.value)
            }
            style={{ width: 120 }}
          />
        );
      }
      case "fileNumber": {
        return (
          <>
            <div style={{ width: 120 }}>
              {row._closing
                ? row?._closing?.fileNumber
                : row.fileNumber
                ? row.fileNumber
                : "N/A"}
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _onSaveNotes = (id, notes) => {
    return new Promise((resolve, reject) => {
      agentUpdateIncome(id, { notes: notes?.trim() ? notes.trim() : "" })
        .then((res) => {
          this._agentFetchAllIncome();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _setSelectedRows = (selectedRows = []) => {
    const { incomeObj } = deepClone(this.state);

    selectedRows = selectedRows.filter(
      (each) => !(each?.duePayment === 0 || each?.duePayment === undefined)
    );

    incomeObj.forEach((obj) => {
      let index = selectedRows.findIndex((each) => obj._id === each._id);
      if (index > -1) {
        selectedRows[index] = obj;
      }
      return selectedRows;
    });

    this.setState({ selectedRows, incomeObj: selectedRows });
  };

  _onChangeFormField = (row, value) => {
    const { incomeObj } = deepClone(this.state);

    this.state.incomeObj.forEach((item) => {
      if (value <= row.duePayment) {
        const objIndex = incomeObj.findIndex((obj) => obj.id === row.id);

        const updatedObj = {
          ...incomeObj[objIndex],
          paymentAmount: value,
        };
        const updatedProjects = [
          ...incomeObj.slice(0, objIndex),
          updatedObj,
          ...incomeObj.slice(objIndex + 1),
        ];

        let replaceArray = this.state.incomeList.map(
          (i) => updatedProjects.find((o) => o.id === i.id) || i
        );

        this.setState({
          incomeObj: updatedProjects,
          incomeList: replaceArray,
        });
        // }
        var regex = /^\d+(\.\d{0,2})?$/g;
        if (!regex.test(value)) {
          showToast("Invalid Input", "error");
        }
      } else {
        showToast("Entered Value is greater than Due Payment", "error");
      }
      // }
    });
  };

  _onFieldChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  _calTotalCheckAmount = () => {
    const { incomeObj } = this.state;

    return incomeObj?.length
      ? incomeObj.reduce((acc, each) => {
          acc = (+acc || 0) + (+each?.paymentAmount || 0);
          return acc;
        }, 0)
      : null;
  };

  _updateIncomeForRecordPayment = () => {
    const { incomeObj, referenceNumber, checkDate, selectedRows } = this.state;

    let commanArray = selectedRows.reduce((arr, item1) => {
      const match = incomeObj.find((item2) => item1.id === item2.id);
      match && arr.push(match);
      return arr;
    }, []);

    let incomeList = commanArray.map((item) => ({
      id: item.id,
      paymentAmount: item.paymentAmount,
    }));

    let noPaymentAmount = incomeList.find(
      (item) => item.paymentAmount === undefined
    );

    if (noPaymentAmount) {
      showToast("Payment Amount Should Not be Empty", "error");
    } else {
      this._manageLoading("markPaidLoading", true);

      let incomeList = incomeObj.map((item) => ({
        id: item.id,
        paymentAmount: item.paymentAmount,
      }));

      const payload = {
        incomeList,
        checkDate,
        referenceNumber,
      };

      const totalCheckAmount = this._calTotalCheckAmount();

      if (totalCheckAmount) payload["totalCheckAmount"] = totalCheckAmount;

      updateAgentIncomeForRecordPayment(payload)
        .then((res) => {
          this.setState({
            checkDate: "",
            referenceNumber: "",
            selectedRows: [],
          });
          this._agentFetchAllIncome();
          this.setState({ incomeObj: [] });
          this._manageLoading("markPaidLoading", false);
          showToast("Recorded Successfully", "success");
        })
        .catch((error) => {
          this._setDateRangeValue();
          errorHandler(error);
          this._manageLoading("markPaidLoading", false);
        });
      // }
    }
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;

    if (
      (filters &&
        (filters.payment ||
          filters.company ||
          filters.clientId ||
          filters.loanType ||
          (filters.customDateRangeValue &&
            filters.customDateRangeValue !== "month") ||
          // filters.dateRangeValue!==null ||
          (filters.search && filters.search.length))) ||
      tableConfig.pageNumber !== 1
    ) {
      const data = { filters, tableConfig };
      localStorage.agentIncome = JSON.stringify(data);
    } else {
      delete localStorage.agentIncome;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentIncome) {
      try {
        const filters = JSON.parse(localStorage.agentIncome);

        this.setState(
          { filters: filters?.filters, tableConfig: filters?.tableConfig },
          () => {
            this._agentFetchAllIncome();
          }
        );
      } catch (e) {
        this._agentFetchAllIncome();
      }
    } else {
      this._agentFetchAllIncome();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.payment) delete filters.payment;
    if (!filters.client) delete filters.client;
    if (!filters.loanType) delete filters.loanType;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };
  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <>
                {row._borrower.length > 0
                  ? capitalize(row._borrower[0]?.name?.full)
                  : "N/A"}{" "}
                {row._borrower && row._borrower?.length > 1 && (
                  <>
                    <span
                      id={`PopoverLegacy${row._id}`}
                      className="text-primary ml-1"
                    >
                      +{row._borrower.length - 1}
                    </span>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`PopoverLegacy${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row._borrower.map((item) => (
                          <p>{capitalize(item.name.full)}</p>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
              </>
              {!row?._closing && (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddIncomeModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };
  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File Number</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row._closing
                  ? row?._closing?.fileNumber
                  : row.fileNumber
                  ? row.fileNumber
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Date</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {formatDate(row.appointmentDate)}
              </span>
              {row?._closing && (
                <span>
                  {getTimeZoneAbbr(
                    row?._closing?.closingAddress,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </span>
              )}{" "}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Client</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?._signingCompany?.companyName
                  ? capitalize(row._signingCompany.companyName)
                  : row?._client?.name
                  ? capitalize(row._client?.name?.full)
                  : capitalize(row._assistant?.name?.full) || "N/A"}
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row.amount || row.amount === 0
                  ? formatCurrencyValue(row.amount)
                  : "N/A"}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Balance Due</Label>
            <div style={{ fontWeight: 600 }}>
              {row.duePayment ? formatCurrencyValue(row.duePayment) : "$0"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Order Status</Label>
            <div>
              {/* <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row.status
                  ? agentClosingStatus.find((obj) => obj.value === row.status)
                      ?.label || "N/A"
                  : "N/A"}
              </span> */}
              <>
                <div style={{ width: 100 }}>
                  {row.status && row.status !== "" ? (
                    <Badge
                      color={`${
                        row.status === "Closed" ? "success" : "danger"
                      }`}
                      className="outline"
                      pill
                    >
                      {row.status
                        ? agentClosingStatus.find(
                            (obj) => obj.value === row.status
                          )?.label || "N/A"
                        : "N/A"}
                    </Badge>
                  ) : (
                    "N/A"
                  )}
                </div>
              </>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Notes</Label>
            <div>
              {row ? (
                <Button
                  color="link"
                  className=""
                  onClick={() => this._onToggleAddNotesModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/nodes.svg").default}
                    alt="Add Notes"
                  />
                </Button>
              ) : (
                "-"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            {row.isPaid ? (
              <Badge color="success" className="outline" pill>
                Paid
              </Badge>
            ) : (
              <Input
                type="text"
                name="amount"
                value={
                  this.state.incomeObj.length > 0
                    ? this.state.incomeObj.find((item) => item?.id === row?._id)
                        ?.paymentAmount || ""
                    : ""
                }
                disabled={
                  !this.state.selectedRows.find(
                    (item) => item?._id === row?._id
                  )
                }
                onChange={(event) =>
                  this._onChangeFormField(row, event.target.value)
                }
                style={{ width: 120, height: 36, margin: "auto" }}
              />
            )}
          </div>
          <div className="dataWrap">
            <div style={{ width: 100 }}>
              <Button
                color="link"
                onClick={() => this._onToggleDownloadInvoiceModal(true, row)}
              >
                Receipt
              </Button>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      filters,
      incomeList,
      incomeTotalCount,
      incomeHeaderKeys,
      filterDropdownValues,
      tableConfig,
      loading,
      addIncomeModalAgent,
      totalPaidAmount,
      totalPendingDueAmount,
      totalAmount,
      addNotesModal,
      upgradeAccountModal,
      selectedRows,
      isClassAdded,
      incomeCardHeaderKeys,
      downloadInvoiceModal,
    } = this.state;

    const calTotalCheckAmount = this._calTotalCheckAmount();

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2> Income List</h2>

            <div className="rightSide d-flex">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-accounting-income-list-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
              <UncontrolledDropdown className="bulkAction">
                <DropdownToggle caret color="primary" size="sm">
                  Bulk Action
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() =>
                      this.props.history.push(`/agent/bulk-upload?paidIncome`)
                    }
                  >
                    Bulk Upload Paid
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.props.history.push(
                        `/agent/bulk-upload?pendingIncome`
                      )
                    }
                  >
                    {" "}
                    Bulk Upload Pending
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <Button
                className="floatingButton"
                color="primary"
                onClick={() =>
                  isRegularUser()
                    ? this._onToggleAddIncomeModalAgent(true)
                    : this._toggleUpgradeAccountModal(true)
                }
              >
                + Add Income
              </Button>
              {/* <Link to={`/agent/bulk-upload?paidIncome`} className="ml-2">
                Bulk upload Paid
              </Link>
              <Link to={`/agent/bulk-upload?pendingIncome`} className="ml-2">
                Bulk upload Pending
              </Link> */}
            </div>
          </div>

          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Signer, Product type & File"
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.handleButtonClick}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              {isRegularUser() && (
                <FormGroup className="searchTable">
                  <Label>Search</Label>
                  <InputGroup>
                    <InputGroupText addonType="prepend">
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                    <Input
                      type="text"
                      placeholder="File Number"
                      value={filters.search}
                      onChange={(e) =>
                        this._filterOnChange("search", e.target.value, true)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              )}
              <FormGroup>
                <Label>Date Added</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.customDateRangeValue}
                    name="customDateRangeValue"
                    onChange={(e) => this._setDateRangeValue(e.target.value)}
                  >
                    {customDateRangeConfig.map((obj) => (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  isOpen={
                    !filters.dateRangeValue &&
                    filters.customDateRangeValue === "customDateRange"
                      ? true
                      : false
                  }
                  // clearIcon={null}
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._filterOnChange("dateRangeValue", dateRangeValue)
                  }
                  // maxDate={new Date()}
                  value={filters.dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Client</Label>
                {/* {filters.company === "unregisteredClient" ? ( */}
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="clientId"
                    value={filters.clientId}
                    onChange={(e) =>
                      this._filterOnChange("clientId", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.unregisterClientList.map((Obj) => (
                      <option key={Obj.id} value={Obj.id}>
                        {/* {capitalize(Obj.name.full) || "N/A"} */}
                        {Obj?._signingCompany
                          ? Obj.companyName
                          : Obj?._client
                          ? Obj.name?.full
                          : Obj?._assistant
                          ? // : Obj?._clientAssistant
                            // ? Obj.name?.full
                            // : Obj?._companyAssistant
                            Obj.name?.full
                          : "N/A"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Payment Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.payment}
                    name="payment"
                    onChange={(e) =>
                      this._filterOnChange("payment", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.payment.map((obj, index) => (
                      <option key={index} value={obj.value}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <Row>
            <Col md="12">
              {/* <div className="pgTitleWrap">
                <h4>Income List</h4>

                <div>
                  // {/* this btn will show below 768px  
                  <Button
                    className="resetBtn d-inline d-md-none ml-3"
                    id="toggler"
                  >
                    <img
                      src={
                        require("../../../assets/img/filter-icon.png").default
                      }
                      alt="Filter"
                    />
                  </Button>

                  <Button
                    className="resetBtn d-none d-md-inline"
                    onClick={this._resetFilter}
                  >
                    <i className="fa fa-refresh" />
                  </Button>

                  <Button
                    color="primary"
                    onClick={() =>
                      isRegularUser()
                        ? this._onToggleAddIncomeModalAgent(true)
                        : this._toggleUpgradeAccountModal(true)
                    }
                  >
                    Add Income
                  </Button>
                </div>
              </div> */}

              {isRegularUser() ? (
                totalAmount || incomeTotalCount ? (
                  <>
                    <Row>
                      <Col xl={3} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="">
                              <span>Total Orders</span>
                              <h4>{incomeTotalCount || "0"}</h4>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/TotalOrders.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="">
                              <span>Total Income</span>
                              <h4>{formatCurrencyValue(totalAmount)}</h4>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/TotalIncome.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="">
                              <span>Paid</span>
                              <h4>{formatCurrencyValue(totalPaidAmount)}</h4>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/invoicePaid.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={3} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="">
                              <span>Pending</span>
                              <h4 className="text-danger">
                                {formatCurrencyValue(totalPendingDueAmount)}
                              </h4>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/invoicepending.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                ) : null
              ) : (
                <div className="upgradeText">Upgrade to unlock.</div>
              )}
              {/* ) : null}  */}

              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={incomeList}
                  headerKeys={incomeCardHeaderKeys}
                  // bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={incomeTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={true}
                  setSelectedRows={(selectedRows) =>
                    this._setSelectedRows(selectedRows)
                  }
                  selectedRows={selectedRows}
                  // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>

              <div className="hideMobile">
                {incomeHeaderKeys && incomeHeaderKeys.length && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={incomeList}
                    headerKeys={incomeHeaderKeys}
                    rowSelection={true}
                    dataFormat={this._dataFormat}
                    totalCount={incomeTotalCount}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) =>
                      // sort function is disabled
                      this._paginate()
                    }
                    setSelectedRows={(selectedRows) =>
                      this._setSelectedRows(selectedRows)
                    }
                    selectedRows={selectedRows}
                    showTableLoading={loading.showTableLoading}
                    pageSize={tableConfig.limit}
                  />
                )}
              </div>

              <div className="incomeListAgent">
                <Row>
                  <Col md={6}>
                    <FormGroup
                      className={`floatingLabel ${
                        this.state.checkDate || this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: " ",
                          value: formatDate(this.state.checkDate),
                          disabled: loading.markPaidLoading,
                        }}
                        value={
                          this.state.checkDate
                            ? new Date(this.state.checkDate)
                            : ""
                        }
                        onChange={(e) =>
                          this._onFieldChange(
                            "checkDate",
                            e._d ? new Date(e._d).toISOString() : ""
                          )
                        }
                        onOpen={() =>
                          this.setState({ isOpenReactDatetime: true })
                        }
                        onClose={() =>
                          this.setState({ isOpenReactDatetime: false })
                        }
                        closeOnSelect={true}
                        dateFormat={true}
                        timeFormat={false}
                      />
                      <Label>Check Date</Label>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={this.state.referenceNumber}
                        name="referenceNumber"
                        onChange={(event) =>
                          this._onFieldChange(
                            "referenceNumber",
                            event.target.value
                          )
                        }
                      />
                      <Label>Reference No</Label>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="recordPayment">
                  {calTotalCheckAmount || calTotalCheckAmount === 0 ? (
                    <h4>
                      Received amount is{" "}
                      <span>{formatCurrencyValue(calTotalCheckAmount)}</span>
                    </h4>
                  ) : null}
                  <Button
                    color="primary"
                    size="md"
                    className="mt-4 mb-2"
                    disabled={loading.markPaidLoading}
                    onClick={() => this._updateIncomeForRecordPayment()}
                  >
                    {loading.markPaidLoading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Record Payment
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <AddNotesModal
            isOpen={addNotesModal.isOpen}
            data={addNotesModal.data}
            toggle={() => this._onToggleAddNotesModal()}
            onSaveNotes={(id, notes) => this._onSaveNotes(id, notes)}
          />

          <AddIncomeModalAgent
            isOpen={addIncomeModalAgent.isOpen}
            data={addIncomeModalAgent.data}
            signingCompany={filterDropdownValues.signingCompany}
            unregisterClientList={filterDropdownValues.unregisterClientList}
            toggle={this._onToggleAddIncomeModalAgent}
            resetDetails={() => this._paginate()}
          />
          <UpgradeAccountModal
            {...this.props}
            isOpen={upgradeAccountModal.isOpen}
            toggle={this._toggleUpgradeAccountModal}
          />

          <DownloadInvoiceModal
            isOpen={downloadInvoiceModal?.isOpen}
            data={downloadInvoiceModal?.data}
            toggle={this._onToggleDownloadInvoiceModal}
            type="agentIncome"
            // showExtraInfo={false}
          />
        </div>
      </>
    );
  }
}

export default IncomeListAccountingAgent;
