import React, { useCallback } from "react";
import { CustomInput, Spinner, Table, UncontrolledTooltip } from "reactstrap";
import CustomPagination from "./CustomPagination";
import SvgIcons from "./SvgIcons";

const CustomTable = ({
  className = "",
  pageNumber,
  pageSize = 10,
  tableData = [],
  headerKeys,
  dataFormat,
  totalCount,
  onPaginate,
  rowStyleFormat,
  rowClassName,
  showTableLoading = false,
  rowSelection = false,
  selectedRows = [],
  setSelectedRows,
  isPageStartFromOne = false,
  striped = false,
  isPagination = true,
  isHideForLessData = true,
  tableDataLoading = false,
  disableRowSelection = false,
  // showHighlightRow = false,
}) => {
  const isRowSelected = useCallback(
    (eachRow) => {
      if (!headerKeys?.length || !selectedRows?.length) return false;

      return selectedRows?.find(
        (eachSelect) =>
          eachSelect[headerKeys?.[0]?.id] === eachRow[headerKeys?.[0]?.id]
      )
        ? true
        : false;
    },
    [selectedRows, headerKeys]
  );

  if (!headerKeys?.length) return <></>;

  const _setSelectedRows = (isChecked, row) => {
    try {
      if (!setSelectedRows) return;

      if (row === "all") {
        if (isChecked) {
          setSelectedRows(tableData);
        } else {
          setSelectedRows();
        }
      } else {
        if (isChecked) {
          const newSelectedRows = [...selectedRows];
          const findRow = newSelectedRows?.find(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (!findRow) {
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
          }
        } else {
          const newSelectedRows = [...selectedRows];
          const findRowIndex = newSelectedRows?.findIndex(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (findRowIndex >= 0) {
            newSelectedRows.splice(findRowIndex, 1);
            setSelectedRows(newSelectedRows);
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <div className={`CustomTableWrapper ${className}`}>
        <Table striped={striped} responsive>
          <thead>
            <tr className={showTableLoading ? "tableRowLoading" : ""}>
              {rowSelection && (
                <th className="checkBoxColumn">
                  <CustomInput
                    id={`all_row_selection`}
                    type="checkbox"
                    onChange={(e) => _setSelectedRows(e.target.checked, "all")}
                    checked={
                      selectedRows?.length &&
                      tableData.every((eachTableData) =>
                        selectedRows?.find(
                          (eachSelect) =>
                            eachSelect[headerKeys?.[0]?.id] ===
                            eachTableData[headerKeys?.[0]?.id]
                        )
                      )
                        ? true
                        : false
                    }
                    disabled={disableRowSelection}
                  />
                </th>
              )}

              {headerKeys.map(
                (eachHeading, headingIndex) =>
                  eachHeading.id !== "id" &&
                  eachHeading.id !== "_id" && (
                    <th
                      key={`table_heading_${
                        eachHeading[headerKeys?.[0]?.id] || headingIndex
                      }`}
                      className={
                        eachHeading?.className ? eachHeading.className : ""
                      }
                      style={eachHeading?.style ? eachHeading.style : {}}
                    >
                      {eachHeading.label}
                      {eachHeading.tooltip ? (
                        <>
                          <span
                            href="#"
                            id={"UncontrolledTooltipExample" + headingIndex}
                            className="ml-2"
                          >
                            <SvgIcons type="info" />
                          </span>
                          <UncontrolledTooltip
                            placement="right"
                            target={"UncontrolledTooltipExample" + headingIndex}
                          >
                            <p>{eachHeading.tooltip}</p>
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {!showTableLoading && tableData?.length ? (
              tableData.map((eachRow, rowIndex) => (
                <tr
                  key={eachRow[headerKeys?.[0]?.id] || rowIndex}
                  // className={
                  //   (rowClassName
                  //     ? rowClassName(eachRow, rowIndex) || ""
                  //     : "") +
                  //   " " +
                  //   (isRowSelected(eachRow) ? "isRowSelected" : "") +
                  //   " "
                  // }
                  className={`${
                    rowClassName ? rowClassName(eachRow, rowIndex) || "" : ""
                  } ${isRowSelected(eachRow) ? "isRowSelected" : ""}
                  `}
                  style={
                    rowStyleFormat ? rowStyleFormat(eachRow, rowIndex) : {}
                  }
                >
                  {rowSelection && (
                    <td
                    style={{
                      pointerEvents: disableRowSelection ? 'none' : 'auto',
                      opacity: disableRowSelection ? 0.5 : 1,
                    }}
                    >
                      <CustomInput
                        id={`row_selection_${
                          eachRow[headerKeys?.[0]?.id] || rowIndex
                        }`}
                        type="checkbox"
                        onChange={(e) =>
                          _setSelectedRows(e.target.checked, eachRow)
                        }
                        checked={isRowSelected(eachRow)}
                        disabled={disableRowSelection}
                      />
                    </td>
                  )}

                  {headerKeys.map(
                    (eachHeading, headingIndex) =>
                      eachHeading.id !== "id" &&
                      eachHeading.id !== "_id" && (
                        <td
                          key={`table_details_${
                            eachRow[headerKeys?.[0]?.id] || rowIndex
                          }_${
                            eachHeading[headerKeys?.[0]?.id] || headingIndex
                          }`}
                          className={
                            eachHeading?.className ? eachHeading.className : ""
                          }
                          style={eachHeading?.style ? eachHeading.style : {}}
                        >
                          {dataFormat
                            ? dataFormat(
                                eachRow[eachHeading.id],
                                eachRow,
                                eachHeading.id
                              )
                            : eachRow[eachHeading.id]}
                        </td>
                      )
                  )}
                </tr>
              ))
            ) : (
              <tr className="text-center">
                <td colSpan={headerKeys?.length - 1 + (rowSelection ? 1 : 0)}>
                  {showTableLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    </>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {isPagination ? (
        //  && totalCount > pageSize
        <CustomPagination
          data={tableData}
          dataCount={totalCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onPageChange={(pageNumber) => onPaginate(pageNumber, pageSize)}
          isPageStartFromOne={isPageStartFromOne}
          isHideForLessData={isHideForLessData}
        />
      ) : null}
    </>
  );
};

export default CustomTable;
