import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  CustomInput,
  Table,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Collapse,
} from "reactstrap";
import AddRatingModal from "../components/add-rating-modal";
import {
  getClosingDetailsById,
  updateClosingDetailsById,
  getAllFiltersData,
  companyExpenseForClosing,
  getRating,
  deleteDocumentForClosing,
  activityLogClosing,
  getLoanType,
  getAgentWithZipcode,
  editDraft,
  getAllCompanySettings,
  companyGetAllProductTypes,
} from "../../../http/http-calls";
import {
  showToast,
  formatPhoneNumber,
  formatCurrencyValue,
  deepClone,
  capitalize,
  formatDateHideYearIfSame,
  formatTime,
  openUrlOnNewTab,
  getPhoneNumberFromBrackets,
  formatAddressInSingleText,
  formatDateAsPerTimeZOne,
  checkPermission,
  errorHandler,
  // formatDateOnlyAsPerTimeZone,
  // timezoneList,
  getTimeZoneAbbr,
  getDropdownColor,
  isRegularUser,
  agentTodayOrFutureDate,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
  formatDateOnlyAsPerTimeZone,
  // agentTodayOrFutureDate,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import DocumentViewModal from "../components/documentViewModal";
import SignerAvailabilityModal from "../components/signer-availability-modal";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import { RegexConfig } from "../../../config/RegexConfig";
import SigningStatusSigningModal from "../components/signing-status-signing";
import Select from "react-select";
import usaStates from "../../../config/usa_states_titlecase";

import { CopyToClipboard } from "react-copy-to-clipboard";
import TextEditor from "../components/TextEdittor";
// import Chips from "react-chips";
// import // agentClosingStatus,
// LanguageList,
// loanTypeConfig,
// "../../../config";
// import ChatLogCardComponent from "../components/ChatLogCardComponent";
import SecureDocumentCardComponent from "../components/SecureDocumentCardComponent";
// import makeAnimated from "react-select/animated";
// import CreatableSelect from "react-select";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomPagination from "../components/CustomPagination";
import ChatLogCardComponent from "../components/ChatLogCardComponent";
import EditOrderDetailsModal from "../components/Modals/EditOrderDetailsModal";
import StarRatings from "react-star-ratings";
import InfiniteScrollDropdown from "../components/AgentDropdown";
import DownloadInvoiceModal from "../components/Modals/DownloadInvoiceModal";
import ClosingAssignment from "../components/closing-assignment-modal";
import { ClientMembers } from "../components/Modals/Company/ClientMembers";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";
import { TeamMembers } from "../components/Modals/Company/TeamMembers";
// const animatedComponents = makeAnimated();

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class ClosingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companySetting: {},
      closingDetails: null,
      previousRating: null,
      isEditAgentFee: false,
      addRatingModal: {
        isOpen: false,
        data: null,
        mode: null,
      },
      loading: {
        statusChangeLoading: false,
        fileUploadLoading: false,
        expensesLoading: false,
        deleteExpensesLoading: false,
        agentSearchLoading: false,
      },
      signingStatusModal: {
        isOpen: false,
        data: null,
        status: null,
      },
      signerAvailabilityModal: {
        isOpen: false,
        data: null,
      },
      documentViewModalData: {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      },
      // expensesHeaderKeys: [
      //   { id: "_id", label: "id" },
      //   { id: "item", label: "Item" },
      //   { id: "amount", label: "Amount" },
      //   { id: "receipt", label: "receipt" },
      // ],
      editing: {
        borrower: false,
        orderDetail: false,
        appointment: false,
        activityLog: false,
        instructions: false,
        agentDetail: false,
      },
      formFields: {
        borrowerName: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        phoneHomeDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        phoneWorkDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        phoneMobileDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        homePhone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        phone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        borrower: [
          {
            _id: {
              value: "",
            },
            borrowerName: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            email: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            workPhone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            isForeignNational: {
              value: false,
              isDirty: false,
              isValidate: true,
            },
            language: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            languageTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            timeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
          },
        ],
        line1: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        line2: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        city: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        county: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        state: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        zip: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        fileNumber: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactName: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        signingCompanyFee: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        client: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        appointmentDate: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine1: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine2: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingAddressCity: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressCounty: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingAddressState: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressZip: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        files: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        notes: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        companyNotes: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        signingStatusDetail: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        agent: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentDncFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        internalNotes: {
          value: "",
          isDirty: false,
          isValidate: false,
        },
        instructionId: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        paymentInstructions: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingSearch: {
          value: "",
          placeId: null,
          isDirty: false,
          isValidate: true,
        },
        borrowerSearch: {
          value: "",
          placeId: null,
          isDirty: false,
          isValidate: true,
        },
        isScanBackNeeded: {
          value: false,
          isDirty: false,
          isValidate: false,
        },
      },
      searchResult: [],
      clients: [],
      agents: [],
      expenses: [],
      activityLog: [],
      signingInstruction: [],
      loanType: [],
      dropdownAgentList: [],
      activityLogPage: {
        skip: 0,
        limit: 5,
        totalCount: null,
      },
      show: false,
      inputLoanType: false,
      // borrowerMapType: "google",
      appointmentMapType: "google",
      editOrderDetailsModal: {
        isOpen: false,
        data: null,
      },
      downloadInvoiceModal: {
        isOpen: false,
        data: null,
      },
      isOpenCollapse: true,
      agentsZipCodePage: {
        page: 1,
        limit: 30,
      },
      assignJobModal: {
        isOpen: false,
        data: null,
      },
      clientMemberModal: {
        isOpen: false,
        data: null,
        success: null,
      },
      agentSuggestedTotalCount: 0,
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  _getAllCompanySettings = () => {
    getAllCompanySettings()
      .then((res) => {
        this.setState(
          {
            signingInstruction: res?.companySetting?.signingInstruction?.length
              ? res?.companySetting?.signingInstruction
              : [],
            companySetting: res?.companySetting || {},
          },
          () => {
            this._findScanbackDocument();
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _findScanbackDocument = () => {
    const { companySetting, formFields, closingDetails } = deepClone(
      this.state
    );
    console.log("companySetting", closingDetails.isScanBackNeeded);

    if (closingDetails?._client?._id && closingDetails?.loanType) {
      let companySettingScanBackDocument =
        companySetting &&
        Object.keys(companySetting).length &&
        companySetting?.scanBackDocument?.scanBackDocumentDetail?.some(
          (item) =>
            item?._client?._id === closingDetails._client._id &&
            item?.productType === closingDetails.loanType
        );
      // console.log("mm",companySettingScanBackDocument)

      formFields["isScanBackNeeded"].value = closingDetails.isScanBackNeeded
        ? closingDetails.isScanBackNeeded
        : companySetting?.scanBackDocument?.isAlwaysRequired
        ? companySetting?.scanBackDocument?.isAlwaysRequired
        : companySettingScanBackDocument;

      this.setState({ formFields });
    } else {
      formFields["isScanBackNeeded"].value = closingDetails.isScanBackNeeded;
      this.setState({ formFields });
    }
  };

  componentDidMount = async () => {
    let LoanType = [
      "Refinance",
      "HomeEquity",
      "AttorneyPhone",
      "Purchase",
      "LoanMod",
      "ReverseMtg",
      "HybridESign",
      "DeedInLieu",
      "SingleDoc",
      "LoanApplication",
      "Other",
    ];

    let options = LoanType.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));
    options.splice(0, 0, { value: "", label: "Select" });
    this.setState({ loanType: options });
    try {
      const id = this.props.match.params.id;
      this.props.showLoader("Fetching Closing Details...");
      await this._getSigningClosingById(id);
      this._getAllCompanySettings();
      this._getcompanyExpenseForClosing(id);
      this._getAllFiltersData();
      this._getrating(id);
      this._getAgentWithZipcode();
      // this._getLoanType();
      this._companyGetAllProductTypes();
      this.props.hideLoader();
    } catch (error) {
      console.log("err", error);
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToDashboard();
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this._closingDashboard();
    });
    HeaderEventEmitter.subscribe("download-invoice", () => {
      this._onToggleDownloadInvoiceModal(true, this.state?.closingDetails);
    });
  };

  _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      console.log({ res });
      if (res?.customProductTypes) {
        this._setProductType(res?.customProductTypes?.products);
      }
      // showToast("Settings are", "success");
      // setLoading(false);
    } catch (error) {
      errorHandler(error);
      // setLoading(false);
    }
  };

  _setProductType = (products) => {
    // console.log("loantype",products)

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    this.setState({ loanType: groupedProducts });
  };

  _getLoanType = () => {
    getLoanType()
      .then((res) => {
        // console.log(res);
        let options =
          res?.loanType?.map((item) => ({
            value: item,
            label: item.replace(/([A-Z])/g, " $1").trim(),
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        options?.push({ value: "Others", label: "Others" });
        this.setState({ loanType: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _activityLogClosing = () => {
    const { activityLogPage } = deepClone(this.state);

    let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

    activityLogClosing(payload)
      .then((res) => {
        this.setState({
          activityLog: res?.logs,
          activityLogPage: {
            ...this.state.activityLogPage,
            totalCount: res?.totalCount,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getrating = (id) => {
    const { closingDetails } = deepClone(this.state);

    if (closingDetails?._agent) {
      getRating(id)
        .then((res) => {
          this.setState({
            previousRating: res?.previousRating,
          });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _getcompanyExpenseForClosing = (id) => {
    companyExpenseForClosing(id)
      .then((res) => {
        this.setState({ expenses: res?.expenses });
        // console.log(res)
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllFiltersData = () => {
    getAllFiltersData({
      signingType: this.state?.closingDetails?.signingType,
    })
      .then((res) => {
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: item.name.full,
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        this.setState({ clients: res?.clients, agents: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAgentWithZipcode = (data) => {
    const { agentsZipCodePage, closingDetails } = deepClone(this.state);

    this._manageLoading("agentSearchLoading", true);
    let payload = {
      // zipCode: this.state.closingDetails?.closingAddress?.zip,
      zipCode:
        closingDetails?.closingAddress?.zip?.trim()?.length > 0
          ? closingDetails?.closingAddress?.zip
          : undefined,
      state:
        closingDetails?.signingType === "RON"
          ? closingDetails?.closingAddress?.state
          : undefined,
      signingType: closingDetails?.signingType,
      skip: 0,
      limit: agentsZipCodePage.page * agentsZipCodePage.limit,
      search: data?.search,
    };
    getAgentWithZipcode(payload)
      .then((res) => {
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: capitalize(item?.name?.first + " " + item?.name?.last),
          })) || [];
        this.setState({
          dropdownAgentList: options,
          agentSuggestedTotalCount: res?.totalCount,
        });
        this._manageLoading("agentSearchLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("agentSearchLoading", false);
        // this._manageLoading("showTableLoading", false);
      });
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.replace(`/${userData.type}/dashboard`);
  };

  _getSigningClosingById = (id = null) => {
    const { formFields } = deepClone(this.state);

    if (!id && this.state?.closingDetails && this?.state?.closingDetails?.id) {
      id = this.state.closingDetails.id;
    }
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this._handleActivityLogPagination();
          this.setState(
            {
              closingDetails: res?.closing || [],
            },
            () => {
              HeaderEventEmitter.dispatch("closing-details", {
                closingDetails: res?.closing,
              });
              document.querySelector("#scroll").scrollTo(0, 0);
            }
          );
          formFields["agentDncFee"].value = res?.closing?.agentDncFee
            ? res?.closing?.agentDncFee.toString()
            : "";

          this.setState({ formFields });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _onToggleAddRatingModal = () => {
    let { addRatingModal, previousRating } = JSON.parse(
      JSON.stringify(this.state)
    );
    addRatingModal.isOpen = !addRatingModal.isOpen;
    addRatingModal.data = {
      id: this.props.match.params.id,
      previousRating: previousRating,
    };
    addRatingModal.mode = previousRating ? "edit" : "add";
    this.setState({
      addRatingModal,
    });
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateSigningCompanyDraftClosing = (id, payload) => {
    return new Promise((resolve, reject) => {
      editDraft(id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    if (status?.status === "DNC" || status?.status === "Closed") {
      if (!data?._agent) {
        errorHandler({ reason: "No Agent was assigned in this order" });
        return;
      }
    }
    if (
      status.status === "DNC" ||
      status.status === "Closed" ||
      status.status === "Cancelled"
    ) {
      this._onToggleSigningStatusModal(true, data, status.status);
    } else {
      try {
        this._manageLoading("statusChangeLoading", true);

        const updateAgentRes = await this._updateClosingDetailsById(
          data.id,
          status
        );
        !status.isCompanyViewDocument &&
          showToast("Status Updated Successfully", "success");
        const { closingDetails } = deepClone(this.state);
        closingDetails["status"] = updateAgentRes.closing.status;
        closingDetails["agentFee"] = updateAgentRes.closing.agentFee;
        closingDetails["companyAcceptDncFee"] =
          updateAgentRes.closing.companyAcceptDncFee;
        closingDetails["isDocumentCompleted"] =
          updateAgentRes.closing.isDocumentCompleted;
        closingDetails["isDocumentVerified"] =
          updateAgentRes.closing.isDocumentVerified;
        closingDetails["isCompanyViewDocument"] =
          updateAgentRes.closing.isCompanyViewDocument;

        closingDetails["isScanBackNeeded"] =
          updateAgentRes.closing.isScanBackNeeded;

        this.setState({ closingDetails });
        if (this.state.isEditAgentFee) {
          this._toggleEditAgentFee();
        }
        this._handleActivityLogPagination();
        this._getSigningClosingById();
        this._manageLoading("statusChangeLoading", false);
      } catch (error) {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      }
    }
  };

  _closingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.replace(`/${userData.type}/${restLink}`);
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      downloadInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleEditAgentFee = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["agentFee"].value = closingDetails.agentFee
      ? closingDetails.agentFee.toString()
      : "";

    this.setState({ formFields, isEditAgentFee: !this.state.isEditAgentFee });
  };

  _documentDataFormat = (cell, row, header) => {
    switch (header) {
      case "title": {
        return row ? (
          <>
            {row.docType === "pdf" ? (
              <i className="fa fa-file-pdf-o mr-1" />
            ) : (
              <i className="fa fa-file-text-o mr-1" />
            )}
            {row.title}
          </>
        ) : (
          "N/A"
        );
      }
      case "createdAt": {
        return cell ? (
          <>
            {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._onToggleDocumentViewModal(row, true)}
            >
              <i className="fa fa-eye" />
            </Button>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._downloadDocument(row)}
            >
              <i className="fa fa-download" />
            </Button>
            {!row.isPrivate ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._deleteDocumentClosing(row)}
              >
                <i className="fa fa-trash-o text-danger" />
              </Button>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _downloadDocument = (row) => {
    let { closingDetails } = deepClone(this.state);

    if (!closingDetails?.isCompanyViewDocument) {
      this._updateClosingDetailsByIdStatus(closingDetails, {
        isCompanyViewDocument: true,
      });
    }
    openUrlOnNewTab(row.url);
  };

  _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let { documentViewModalData, closingDetails } = deepClone(this.state);
    if (doc && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };

      if (
        !closingDetails?.isCompanyViewDocument &&
        this?.props?.userData?.user?.isAssistant !== undefined &&
        !this?.props?.userData?.user?.isAssistant
      ) {
        this._updateClosingDetailsByIdStatus(closingDetails, {
          isCompanyViewDocument: true,
        });
      }
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  _getTotalExpensesPrice = (expenses) => {
    let amount;
    if (expenses && expenses.length) {
      amount = expenses.reduce((totalAmount, exp) => {
        return totalAmount + exp.amount;
      }, 0);
    }
    return amount && amount > 0 ? formatCurrencyValue(amount) : "";
  };

  _expenseDataFormat = (cell, row, header) => {
    switch (header) {
      case "item": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "amount": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "receipt": {
        return cell ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => openUrlOnNewTab(cell)}
          >
            <i className="fa fa-download" />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  editModeOn = (fieldName, value) => {
    let { editing, formFields } = deepClone(this.state);
    Object.keys(editing).forEach((key) => {
      editing[key] = false;
    });
    editing[fieldName] = value;
    this.setState({
      editing,
    });

    if (fieldName === "borrower") {
      this.borrowerDetail();
    }
    if (fieldName === "orderDetail") {
      this.orderDetail();
    }
    if (fieldName === "activityLog") {
      this.activityLog();
    }

    if (fieldName === "appointment") {
      this.appointmentDetail();
    }
    if (fieldName === "instructions") {
      this.instructionsDetail();
    }
    if (fieldName === "paymentInstructions") {
      this.paymentInstructionsDetail();
    }
    if (value === false) {
      Object.keys(formFields).forEach((key) => {
        delete formFields[key].error;
      });
      this.setState({ formFields });
    }
  };

  borrowerDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    let borrower = [];
    // formFields["borrowerName"].value = closingDetails._borrower.name.full;
    // formFields["email"].value = closingDetails._borrower.email;
    // formFields["homePhone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower?.phone?.home
    // );
    // formFields["workPhone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower?.phone?.work
    // );
    // formFields["phone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower?.phone?.mobile
    // );
    // formFields["loanType"].value = closingDetails.loanType
    //   ? closingDetails.loanType
    //   : "";
    closingDetails._borrower.forEach((each) => {
      borrower.push(
        // contactNumber: each.contactNumber || "",
        // pointOfContact: each.pointOfContact || "",
        // contactEmail: each.contactEmail || "",
        {
          _id: {
            value: each._id,
          },
          borrowerName: {
            value: each.name.full || "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: each.email || "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: each.isForeignNational,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: each.language || "English",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: each.languageTypeOther || "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: each.timeZone || "",
            isDirty: false,
            isValidate: true,
          },
        }
      );
    });
    formFields["borrower"] = borrower;
    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    // closingDetails?.loanType !== "Other" ? closingDetails?.loanType : "Other";

    formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

    if (closingDetails._borrower[0]?.address) {
      formFields["borrowerSearch"].value = formatAddressInSingleText(
        closingDetails._borrower[0].address
      );
      formFields["borrowerSearch"].placeId = "initialValue";
    }
    this.setState({ formFields });
  };

  orderDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["fileNumber"].value = closingDetails.fileNumber
      ? closingDetails.fileNumber
      : "";
    formFields["lender"].value = closingDetails.lender
      ? closingDetails.lender
      : "";
    formFields["client"].value = closingDetails?._client?.id
      ? closingDetails?._client?.id
      : "";
    formFields["signingCompanyFee"].value = closingDetails.signingCompanyFee
      ? closingDetails.signingCompanyFee.toString()
      : "";

    formFields["trackingInfo"].value = closingDetails.trackingInfo
      ? closingDetails.trackingInfo
      : "";

    formFields["personOfContactName"].value = closingDetails.personOfContact
      .name
      ? closingDetails.personOfContact.name
      : "";
    formFields["personOfContactEmail"].value = closingDetails.personOfContact
      .email
      ? closingDetails.personOfContact.email.filter((x) => x)
      : [];

    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

    formFields["witnessCount"].value = Number(closingDetails.witnessCount);

    this.setState({ formFields });
  };

  appointmentDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["signingStatusDetail"].value = closingDetails.signingStatusDetail
      ? closingDetails.signingStatusDetail
      : "";
    if (closingDetails.closingAddress) {
      formFields["closingSearch"].value = formatAddressInSingleText(
        closingDetails.closingAddress
      );
      formFields["closingSearch"].placeId = "initialValue";
    }
    formFields["internalNotes"].value = closingDetails.internalNotes
      ? closingDetails.internalNotes
      : "";

    formFields["companyNotes"].value = closingDetails.companyNotes
      ? closingDetails.companyNotes
      : "";
    formFields["witnessCount"].value = closingDetails.witnessCount
      ? closingDetails.witnessCount
      : "";

    this.setState({ formFields });
  };

  instructionsDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["instructions"].value = closingDetails?.instructionId
      ? closingDetails.instructionId
      : "";
    formFields["instructions"].value = closingDetails?.instructions
      ? closingDetails.instructions
      : "";
    this.setState({ formFields });
  };

  paymentInstructionsDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);

    formFields["paymentInstructions"].value =
      closingDetails?.paymentInstructions
        ? closingDetails.paymentInstructions
        : "";
    this.setState({ formFields });
  };

  agentDetail = () => {
    const { formFields } = deepClone(this.state);
    // formFields["companyNotes"].value = closingDetails.companyNotes
    //   ? closingDetails.companyNotes
    //   : "";
    this.setState({ formFields });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction } = deepClone(this.state);

    if (fieldName === "signingCompanyFee" || fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "closingSearch" || fieldName === "borrowerSearch") {
      googlePlaceSearch(value)
        .then((res) => {
          console.log(res);
          this.setState({ searchResult: res, show: false });
        })
        .catch((err) => {
          console.log(err);
          showToast("Errors");
        });
    }

    if (fieldName === "instructionId" && value) {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructions.value = findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();
    });
  };

  getPlaceDetail = async (pid, type) => {
    //  console.log(pid);

    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    console.log(detail);
    if (detail) {
      let abbr = usaStates.find(
        (item) => item.name === detail.state
      ).abbreviation;

      if (type === "borrowerAddress") {
        formFields["line1"].value = detail.address;
        formFields["city"].value = detail.city;
        formFields["county"].value = detail.county;
        formFields["state"].value =
          detail.state.length > 2 ? abbr : detail.state;
        //  detail.state;
        formFields["zip"].value = detail.postal;
        formFields["borrowerSearch"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          abbr +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["borrowerSearch"].placeId = pid.place_id;
        formFields["borrowerSearch"].isDirty = true;
      } else {
        formFields["closingAddressLine1"].value = detail.address;
        formFields["closingAddressCity"].value = detail.city;
        formFields["closingAddressCounty"].value = detail.county;
        formFields["closingAddressState"].value =
          detail.state.length > 2 ? abbr : detail.state;
        // detail.state;
        formFields["closingAddressZip"].value = detail.postal;
        formFields["closingSearch"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          abbr +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["closingSearch"].placeId = pid.place_id;
        formFields["closingSearch"].isDirty = true;
      }
      this.setState({ formFields, show: true });
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        // formFields[e].isDirty = true;
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  updateClosing = async (type) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();
      console.log(isFormValid);
      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields, closingDetails, editing } = deepClone(this.state);
        // const splitBorrowerName = formFields.borrowerName.value
        // .trim()
        // .split(" ");
        let data = {};
        if (type === "borrower") {
          data = {
            // borrower: {
            //   email: formFields.email.value,
            //   phone: {
            //     home:
            //       formFields.homePhone.value &&
            //       formFields.homePhone.value.trim().length
            //         ? `(${formFields.phoneHomeDialCode.value})${formFields.homePhone.value}`
            //         : undefined,
            //     work:
            //       formFields.workPhone.value &&
            //       formFields.workPhone.value.trim().length
            //         ? `(${formFields.phoneWorkDialCode.value})${formFields.workPhone.value}`
            //         : undefined,
            //     mobile:
            //       formFields.phone.value && formFields.phone.value.trim().length
            //         ? `(${formFields.phoneMobileDialCode.value})${formFields.phone.value}`
            //         : undefined,
            //   },
            //   address: {},
            //   name: {
            //     first: splitBorrowerName.slice(0, 1).join(" "),
            //     last: splitBorrowerName[1]
            //       ? splitBorrowerName.slice(1).join(" ")
            //       : "",
            //   },
            // },
            borrower: [],

            // loanType:
            //   formFields.loanType.value !== "Others"
            //     ? formFields.loanType.value
            //     : formFields.loanTypeOther.value || undefined,
            // loanTypeOther: formFields.loanTypeOther.value
            //   ? formFields.loanTypeOther.value
            //   : undefined,
          };
          let borrower = await formFields.borrower.map(
            ({
              _id,
              borrowerName,
              email,
              workPhone,
              isForeignNational,
              language,
              languageTypeOther,
              timeZone,
            }) => {
              const splitBorrowerName = borrowerName.value.trim().split(" ");

              return {
                name: {
                  first: splitBorrowerName.slice(0, 1).join(" "),
                  last: splitBorrowerName[1]
                    ? splitBorrowerName.slice(1).join(" ")
                    : "",
                },
                _id: _id.value,
                email: email.value ? email.value : undefined,
                phone: { work: workPhone.value ? workPhone.value : undefined },
                isForeignNational: isForeignNational.value,
                language: language.value ? language.value : undefined,
                languageTypeOther: languageTypeOther.value
                  ? languageTypeOther.value
                  : undefined,
                timeZone: timeZone.value ? timeZone.value : undefined,
                address: {},
              };
            }
          );
          data["borrower"] = borrower;
          if (formFields.loanType.value) {
            data["loanType"] = await formFields.loanType.value.map(
              (item) => item.value
            );
          }

          if (
            formFields.borrowerSearch.placeId &&
            formFields.borrowerSearch.placeId !== "initialValue"
          ) {
            const addressObj = await googlePlaceDetails(
              formFields.borrowerSearch.placeId
            );

            let abbr = usaStates.find(
              (item) => item.name === addressObj.state
            ).abbreviation;

            // data.borrower.address["line1"] = addressObj.address;
            // data.borrower.address["city"] = addressObj.city;
            // data.borrower.address["county"] = addressObj.county;
            // data.borrower.address["state"] =
            //   addressObj.state.length > 2 ? abbr : addressObj.state;
            // data.borrower.address["zip"] = addressObj.postal;
            // data.borrower.address["lat"] = addressObj.lat;
            // data.borrower.address["lng"] = addressObj.lng;
            await borrower.map(
              (item) => (
                // eslint-disable-next-line no-sequences
                (item.address["line1"] = addressObj.address),
                (item.address["city"] = addressObj.city),
                (item.address["county"] = addressObj.county),
                (item.address["state"] =
                  addressObj.state.length > 2 ? abbr : addressObj.state),
                (item.address["zip"] = addressObj.postal),
                (item.address["lat"] = addressObj.lat),
                (item.address["lng"] = addressObj.lng)
              )
            );
          }
          // else {
          //     delete data.borrower.address;
          //   data.borrower.address["line1"] = "";
          //   data.borrower.address["city"] = "";
          //   data.borrower.address["county"] = "";
          //   data.borrower.address["state"] = "";
          //   // addressObj.state.length > 2 ? abbr : addressObj.state;
          //   data.borrower.address["zip"] = "";
          //   data.borrower.address["lat"] = "";
          //   data.borrower.address["lng"] = "";
          // }
        }

        if (type === "orderDetail") {
          data = {
            fileNumber: formFields.fileNumber.value || undefined,
            signingCompanyFee: formFields.signingCompanyFee.value || undefined,
            lender: formFields.lender.value || undefined,
            loanOfficer: formFields.loanOfficer.value || undefined,
            // agentFee: formFields.agentFee.value || undefined,
            trackingInfo: formFields.trackingInfo.value || undefined,
            clientId: formFields.client.value || undefined,
            personOfContact: {
              name: formFields.personOfContactName.value || undefined,
              email:
                formFields.personOfContactEmail.value.length > 0
                  ? formFields.personOfContactEmail.value.toString()
                  : [],
            },
          };
        }

        if (type === "appointment") {
          data = {
            // internalNotes: formFields.internalNotes.value || undefined,
            internalNotes: formFields.internalNotes.value?.trim().length
              ? formFields.internalNotes.value !== closingDetails.internalNotes
                ? formFields.internalNotes.value.trim()
                : undefined
              : undefined,

            companyNotes: formFields.companyNotes.value?.trim().length
              ? formFields.companyNotes.value !== closingDetails.companyNotes
                ? formFields.companyNotes.value.trim()
                : undefined
              : undefined,
            // companyNotes: formFields.companyNotes.value || undefined,
            closingAddress: {},
            witnessCount: Number(formFields.witnessCount.value),
          };

          if (
            formFields.closingSearch.placeId &&
            formFields.closingSearch.placeId !== "initialValue"
          ) {
            const addressObj = await googlePlaceDetails(
              formFields.closingSearch.placeId
            );

            let abbr = usaStates.find(
              (item) => item.name === addressObj.state
            ).abbreviation;

            data.closingAddress["line1"] = addressObj.address;
            data.closingAddress["city"] = addressObj.city;
            data.closingAddress["county"] = addressObj.county;
            data.closingAddress["state"] =
              addressObj.state.length > 2 ? abbr : addressObj.state;
            data.closingAddress["zip"] = addressObj.postal;
            data.closingAddress["lat"] = addressObj.lat;
            data.closingAddress["lng"] = addressObj.lng;
          } else {
            delete data.closingAddress;
          }
        }
        if (type === "instructions") {
          data = {
            instructionId: formFields.instructionId.value || undefined,
            instructions: formFields.instructions.value || undefined,
          };
        }

        if (type === "paymentInstructions") {
          data = {
            paymentInstructions:
              formFields.paymentInstructions.value || undefined,
          };
        }

        if (type === "agentDetail") {
          if (!closingDetails?.agentFee && closingDetails?.agentFee !== 0) {
            errorHandler({ reason: "Please provide Agent Fee" });
            this._manageLoading("cardUpdateLoading", false);
            return;
          }

          data = {
            agentId: formFields.agent?.value?.value || undefined,
          };
        }
        console.log("data", data);
        try {
          if (type === "agentDetail") {
            await this._updateClosingDetailsById(closingDetails.id, data);
          } else {
            const updateAgentRes = closingDetails.isSavedAsDraft
              ? await this._updateSigningCompanyDraftClosing(
                  closingDetails.id,
                  data
                )
              : await this._updateClosingDetailsById(closingDetails.id, data);
            console.log(updateAgentRes);
          }
          showToast("Updated Successfully", "success");
          Object.keys(editing).forEach((key) => {
            editing[key] = false;
          });
          this.setState({ editing });
          this._getSigningClosingById(closingDetails.id);
          this._manageLoading("cardUpdateLoading", false);
        } catch (error) {
          errorHandler(error);
          this._manageLoading("cardUpdateLoading", false);
        }
      }
    } catch (error) {
      console.log("error", error);
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };
  _validateForm = () => {
    console.log("validating....");
    return new Promise((resolve, reject) => {
      const { formFields, editing } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "borrower": {
              if (editing.appointment) {
                // console.log("7777", formFields[key]);
                formFields.borrower.forEach((each, index) => {
                  console.log("989898", formFields[key][index]["borrowerName"]);

                  if (each.borrowerName?.value.trim().length) {
                    formFields[key][index]["borrowerName"].isDirty = false;
                    formFields[key][index]["borrowerName"].error = null;
                  } else {
                    formFields[key][index]["borrowerName"].isDirty = true;
                    formFields[key][index]["borrowerName"].error = "*Required";
                    isFormValid = false;
                  }

                  if (
                    formFields[key][index]["email"].value &&
                    formFields[key][index]["email"].value.trim().length
                  ) {
                    if (
                      RegexConfig.email.test(
                        String(
                          formFields[key][index]["email"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["email"].isDirty = false;
                      formFields[key][index]["email"].error = null;
                    } else {
                      formFields[key][index]["email"].isDirty = true;
                      formFields[key][index]["email"].error = "*Invalid Email";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  }

                  if (
                    formFields[key][index]["workPhone"].value &&
                    formFields[key][index]["workPhone"].value.length
                  ) {
                    if (
                      RegexConfig.phone.test(
                        String(
                          formFields[key][index]["workPhone"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error =
                        "*Invalid Phone Number";
                    }
                  } else {
                    if (
                      formFields["workPhone"].value &&
                      formFields["workPhone"].value.length
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error = "*Required";
                    }
                  }
                });
              }
              break;
            }
            // case "borrowerName": {
            //   if (editing.borrower) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            // case "borrowerSearch": {
            //   if (editing.borrower) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       if (formFields[key].placeId) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Invalid Address";
            //         isFormValid = false;
            //       }
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }

            case "closingSearch": {
              if (editing.appointment) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                    isFormValid = true;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }

              break;
            }

            case "loanType": {
              if (editing.borrower) {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "loanTypeOther": {
              if (editing.borrower && formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            // case "homePhone":
            // case "workPhone":
            // case "phone": {
            //   if (editing.borrower) {
            //     if (formFields[key].value && formFields[key].value.length) {
            //       if (
            //         RegexConfig.phone.test(
            //           String(formFields[key].value).toLowerCase()
            //         )
            //       ) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         isFormValid = false;
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Invalid Phone Number";
            //       }
            //     } else {
            //       if (
            //         (formFields["homePhone"].value &&
            //           formFields["homePhone"].value.length) ||
            //         (formFields["workPhone"].value &&
            //           formFields["workPhone"].value.length) ||
            //         (formFields["phone"].value &&
            //           formFields["phone"].value.length)
            //       ) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         isFormValid = false;
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Required";
            //       }
            //     }
            //   }
            //   break;
            // }
            // case "lender":
            // case "fileNumber":
            // case "personOfContactName": {
            //   if (editing.orderDetail) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            // case "signingCompanyFee": {
            //   if (editing.orderDetail) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       if (
            //         isNaN(formFields[key].value.trim()) ||
            //         Number(formFields[key].value.trim()) <= 0
            //       ) {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Fee must be a positive number";
            //         isFormValid = false;
            //       } else {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       }
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _deleteDocumentClosing = (row) => {
    const { closingDetails } = deepClone(this.state);
    console.log(row._id, closingDetails._id);
    let payload = { documentId: row._id };
    this.props.showLoader("Deleting Document...");

    deleteDocumentForClosing(closingDetails._id, payload)
      .then((res) => {
        this._getSigningClosingById();
        showToast("Document Deleted Successfully", "success");
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = deepClone(this.state);

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClosing();
    });
  };

  _activityLog = () => {
    const { activityLog, closingDetails } = deepClone(this.state);

    return (
      activityLog?.length > 0 &&
      activityLog.map((item, key) => {
        return (
          <li key={key}>
            {item.activityType !== "ClosingAssigned"
              ? item.isDocumentCompleted
                ? null
                : item?._user?.name?.full
              : "New Order"}{" "}
            {item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Documents Upload by Signing Company"
                : item.isDocumentCompletedByAgent
                ? "Documents Upload by Agent"
                : item.isDocumentVerified
                ? "has Verified"
                : "Uploaded"
              : item.activityType === "AppointmentStatus" ||
                item.activityType === "AppointmentDate" ||
                item.activityType === "ClosingStatus" ||
                item.activityType === "LeftMessage"
              ? "Changed"
              : item.activityType === "ClosingCreated"
              ? "Created the Closing"
              : item.activityType === "ClosingAssigned"
              ? /* ? "Assigned Closing" */
                "Assigned"
              : item.activityType === "DraftCreated"
              ? "created Draft Closing"
              : item.activityType === "DraftToClosing"
              ? "converted Draft to Regular Closing"
              : item.activityType === "ClosingUnAssigned"
              ? "Unassign"
              : item.activityType === "DocumentsDeleted"
              ? "Deleted the Document"
              : "Updated"}{" "}
            {item?.activityType === "ClosingUnAssigned"
              ? `${item?._agent?.name?.full} from closing`
              : null}
            {item.activityType === "Notes"
              ? item.notesType.replace(/([A-Z])/g, " $1").trim()
              : item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Complete"
                : item.isDocumentCompletedByAgent
                ? "as Scanback Upload Complete"
                : item.isDocumentVerified
                ? "Agent Document"
                : "a New File"
              : item.activityType === "ClosingCreated"
              ? ""
              : item.activityType === "DraftCreated"
              ? ""
              : item.activityType === "DraftToClosing"
              ? ""
              : item.activityType === "ClosingUnAssigned"
              ? ""
              : item.activityType === "LeftMessage"
              ? "Appointment Notes"
              : item.activityType === "ClosingAssigned"
              ? null
              : item.activityType === "DocumentsDeleted"
              ? item.documents[0].title
              : item.activityType.replace(/([A-Z])/g, " $1").trim()}{" "}
            {item.activityType === "Documents"
              ? item?.documents.map((each) => (
                  <Button
                    key={each.title}
                    color="link"
                    className="showMap"
                    onClick={() => openUrlOnNewTab(each?.url)}
                  >
                    {each.title}
                  </Button>
                ))
              : null}{" "}
            {/* {item?.activityType === "ClosingUnAssigned" ? } */}
            {item.to
              ? `To ${
                  item.activityType === "AppointmentDate"
                    ? formatDateAsPerTimeZOne(
                        item.to,
                        closingDetails.closingAddress.timeZone
                      )
                    : // ? `${formatDateHideYearIfSame(item.to)}, ${formatTime(
                      //     item.to
                      //   )}`
                      item.to
                }`
              : null}{" "}
            {item.activityType === "ClosingAssigned" ? (
              <>To {item._agent.name.full}</>
            ) : null}
            {/* {item.text ? (
              item.activityType === "Notes" ||
              item.activityType === "LeftMessage" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : (
                `To ${item.text}`
              )
            ) : null}{" "} */}
            {item.activityType === "LeftMessage" ? <>To Left Message </> : null}
            {item.text ? (
              item.activityType === "Notes" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : null
            ) : null}{" "}
            | {formatDateHideYearIfSame(item.timeStamp)},{" "}
            {formatTime(item.timeStamp)}
          </li>
        );
      })
    );
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?._signingCompany) {
      return { backgroundColor: "#eee" };
    } else if (row?._agent) {
      return { backgroundColor: "#cfe2ff" };
    }
    // return {};
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          // chips: chips,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    console.log(value);
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      console.log("into");
      return true;
    } else {
      console.log("into else");
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    this.setState({ formFields });
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  _checkAllFieldsAreAvailable = () => {
    const { closingDetails } = deepClone(this.state);
    return (
      closingDetails._client !== undefined &&
      closingDetails.signingCompanyFee !== undefined &&
      closingDetails?._borrower[0]?.name?.full.trim().length !== undefined &&
      closingDetails.fileNumber !== undefined
    );
  };

  searchOnChange = debounce((value) => {
    // console.log(value);
    if (value.length > 0) {
      this._getAgentWithZipcode({ search: value });
    }
  }, 1000);

  _onToggleAssignJobModal = (data) => {
    let { assignJobModal } = JSON.parse(JSON.stringify(this.state));
    assignJobModal.isOpen = !assignJobModal.isOpen;
    assignJobModal.data = data;
    this.setState({
      assignJobModal,
    });
    if (!assignJobModal.isOpen) {
      this._getSigningClosingById();
    }
  };

  _onToggleClientMembers = (isOpen = false, data = null) => {
    this.setState({
      clientMemberModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const {
      closingDetails,
      loading,
      documentViewModalData,
      addRatingModal,
      signerAvailabilityModal,
      isEditAgentFee,
      editing,
      formFields,
      clients,
      signingStatusModal,
      // agents,
      dropdownAgentList,
      previousRating,
      // borrowerMapType,
      // appointmentMapType,
      activityLog,
      activityLogPage,
      signingInstruction,
      editOrderDetailsModal,
      agentsZipCodePage,
      agentSuggestedTotalCount,
      downloadInvoiceModal,
      clientMemberModal,
      assignJobModal,
    } = this.state;
    console.log("closingDetails >>>", closingDetails);
    return (
      <div id="scroll">
        <div className="content">
          {closingDetails && (
            <>
              <div className="responsiveTitle">
                <div className="d-flex align-items-center">
                  <Button
                    color="link"
                    className="backBtn"
                    onClick={this._closingDashboard}
                  >
                    <img
                      src={require("../../../assets/img/arrowLeft.svg").default}
                      alt="backbutton"
                      height={14}
                    />
                  </Button>
                  <h2>
                    Order Details
                    <span>Order #{closingDetails.closingNumber || "N/A"}</span>
                  </h2>
                </div>

                <div className="rightSide">
                  <Button
                    className="floatingButton"
                    color="primary"
                    onClick={() => {
                      debugger;
                      this.props.history.push(
                        `/signingcompany/create-closing?${closingDetails.id}`
                      );
                    }}
                  >
                    Clone
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      HeaderEventEmitter.dispatch("download-invoice")
                    }
                    disabled={
                      // closingDetails?.status === "Pending" ||
                      closingDetails?.status === "Cancelled" ||
                      closingDetails?._agent === undefined
                    }
                  >
                    Invoice
                  </Button>
                </div>
              </div>

              <Row>
                <Col md="12">
                  {/* <div className="pgTitleWrap mb-4"> */}
                  {/* <div className="d-flex align-items-center"> */}
                  {/*  <Button
                        color="link"
                        className="backBtn"
                        onClick={this._closingDashboard}
                      >
                        <i className="fa fa-chevron-left" />
                      </Button>
                      <h4>
                        Order Details
                        <span>
                          Order #{closingDetails.closingNumber || "N/A"}
                        </span>
                      </h4>*/}
                  {/* </div>  */}

                  {/* <Button
                      className="themeBtn my-0"
                      onClick={() =>
                        this.props.history.push(
                          `/signingcompany/create-closing?${closingDetails.id}`
                        )
                      }
                    >
                      Clone
                    </Button> */}
                  {/* </div> */}
                </Col>
              </Row>

              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Signer
                    <>
                      {closingDetails?.status !== "Closed" &&
                      closingDetails?.status !== "DNC" ? (
                        <Button
                          color="link"
                          onClick={
                            () =>
                              this._toggleEditOrderDetailsModal(
                                true,
                                closingDetails
                              )
                            // !closingDetails?.tBD
                            //   ? this._toggleEditOrderDetailsModal(
                            //       true,
                            //       closingDetails
                            //     )
                            //   : errorHandler({
                            //       reason:
                            //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                            //     })
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/pencil.svg").default
                            }
                            alt="edit"
                          />
                        </Button>
                      ) : null}
                    </>
                  </h2>
                </div>
              </div>

              <div className="CustomTableWrapper withAccordian">
                <Table striped responsive className="hideMobile">
                  <thead>
                    <tr>
                      <th>Signer</th>
                      <th>File#</th>
                      <th>Added By</th>
                      <th>Product Type</th>
                      <th>Signing Type</th>
                      {!this.props.userData?.user?.isTitleCompany && (
                        <th>Company Fee</th>
                      )}
                      <th>Appointment Location</th>
                      {/* <th>Order on behalf of</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <>
                          <div className="tableUserInfo">
                            <div className="userContent">
                              <span
                                id={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                className="signerName"
                              >
                                {closingDetails?._borrower[0]?.name?.full
                                  ? closingDetails?._borrower[0]?.name?.full
                                  : "N/A"}
                                {closingDetails?._borrower?.length > 1
                                  ? `+${closingDetails?._borrower?.length - 1}`
                                  : null}
                              </span>
                              {closingDetails?._borrower?.length ? (
                                <>
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                    className="signerPopover"
                                  >
                                    <PopoverHeader>Signers</PopoverHeader>
                                    <PopoverBody>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Language</th>
                                            <th>Time Zone</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {closingDetails?._borrower?.map(
                                            (each) => (
                                              <tr key={each._id}>
                                                <td>
                                                  {each.name?.full || "N/A"}
                                                </td>
                                                <td>
                                                  {each?.email ? (
                                                    <a
                                                      href={`mailto:${each?.email}`}
                                                    >
                                                      {each?.email}
                                                    </a>
                                                  ) : (
                                                    "N/A"
                                                  )}
                                                </td>
                                                <td>
                                                  {each.phone?.work || "N/A"}
                                                </td>
                                                <td>
                                                  {each.language || "N/A"}
                                                </td>
                                                <td>
                                                  {each.timeZone || "N/A"}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              ) : null}
                              <DateTimeShowWithClosingDetails
                                closingDetails={closingDetails}
                              />
                            </div>
                          </div>
                        </>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {closingDetails?.fileNumber || "N/A"}
                        </div>
                      </td>
                      <td>
                        {closingDetails?._createdBy
                          ? closingDetails?._createdBy?.name?.full
                          : closingDetails?._createdByClient
                          ? closingDetails?._createdByClient?.companyName
                          : "N/A"}
                      </td>
                      <td>
                        {closingDetails?.loanType?.length ? (
                          closingDetails?.loanType?.indexOf("Other") > -1 ? (
                            <span
                              style={{
                                marginRight: 6,
                              }}
                            >
                              {closingDetails?.loanTypeOther}
                            </span>
                          ) : null
                        ) : null}

                        {React.Children.toArray(
                          closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            .map((item) => (
                              <span
                                key={item._id}
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                              </span>
                            ))
                        )}
                      </td>
                      <td>
                        <div style={{ width: 150 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.signingType}
                          </span>
                        </div>
                      </td>
                      {!this.props.userData?.user?.isTitleCompany && (
                        <td>
                          <span style={{ width: 140 }}>
                            {closingDetails?.signingCompanyFee
                              ? formatCurrencyValue(
                                  closingDetails?.signingCompanyFee
                                )
                              : formatCurrencyValue(0)}
                          </span>
                        </td>
                      )}
                      <td>
                        <div
                          style={{
                            width: 200,
                          }}
                        >
                          {formatAddressInSingleText(
                            closingDetails?.closingAddress
                          ) || "N/A"}
                        </div>
                        <Button
                          color="link"
                          className="p-0"
                          title={formatAddressInSingleText(
                            closingDetails?.closingAddress
                          )}
                          onClick={() =>
                            openGoogleMapOnNewTab(
                              closingDetails?.closingAddress
                            )
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/map-marker.svg")
                                .default
                            }
                            alt="map icon"
                            className="mr-1"
                          />
                          Map
                        </Button>
                      </td>
                      {/* <td>
                        <div style={{ width: 150 }}>
                          {closingDetails.lender
                            ? capitalize(closingDetails.lender)
                            : "N/A"}
                        </div>{" "}
                      </td> */}
                      <td>
                        <div
                          className="customSelectWrap"
                          style={{ width: 128 }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              closingDetails?.status
                            )}`}
                            // disabled={
                            //   this.state.loading.statusChangeLoading ||
                            //   !(
                            //     checkPermission(
                            //       "dashboard",
                            //       "canUpdateClosingStatusOrScheduling"
                            //     ) ||
                            //     checkPermission(
                            //       "dashboard",
                            //       "canEditOrderEntry"
                            //     )
                            //   )
                            // }
                            disabled={
                              loading.statusChangeLoading ||
                              closingDetails.isSavedAsDraft
                            }
                            value={closingDetails?.status}
                            onChange={
                              (event) =>
                                this._updateClosingDetailsByIdStatus(
                                  closingDetails,
                                  { status: event.target.value }
                                )
                              // !closingDetails?.tBD
                              //   ? this._updateClosingDetailsByIdStatus(
                              //       closingDetails,
                              //       { status: event.target.value }
                              //     )
                              //   : errorHandler({
                              //       reason:
                              //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                              //     })
                            }
                          >
                            <option key="CCA" value="CCA" disabled={true}>
                              Can't Complete
                            </option>
                            <option key="Pending" value="Pending">
                              Pending
                            </option>
                            <option
                              key="Closed"
                              value="Closed"
                              disabled={closingDetails?.isSavedAsDraft}
                            >
                              Closed
                            </option>
                            <option key="Cancelled" value="Cancelled">
                              Cancelled
                            </option>
                            <option
                              key="DNC"
                              value="DNC"
                              disabled={closingDetails?.isSavedAsDraft}
                            >
                              Did not close
                            </option>
                            <option
                              key="Arrived"
                              value="Arrived"
                              disabled={true}
                            >
                              Arrived
                            </option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                        {/* <Input
                          type="select"
                          name="status"
                          disabled={
                            loading.statusChangeLoading ||
                            closingDetails.isSavedAsDraft
                          }
                          value={closingDetails?.status}
                          onChange={(event) =>
                            this._updateClosingDetailsByIdStatus(
                              closingDetails,
                              { status: event.target.value }
                            )
                          }
                        >
                          {agentClosingStatus.map((obj) => (
                            <option
                              key={obj.value}
                              value={obj.value}
                              disabled={
                                obj.value === "CCA" || obj.value === "Arrived"
                              }
                            >
                              {obj.label}
                            </option>
                          ))}
                        </Input> */}
                        {/* <i
                          className="fa fa-eye"
                          id="UncontrolledTooltipExample"
                        /> */}
                        {/* <UncontrolledTooltip
                          placement="top"
                          target="UncontrolledTooltipExample"
                        >
                          Comment-{" "}
                          {closingDetails.companyCancelledComment || "N/A"}
                          <br />
                          Agent CCA Reason-{" "}
                          {closingDetails.agentCcaReason || "N/A"}
                          <br />
                          Agent CCA Comment-{" "}
                          {closingDetails.agentCcaComment || "N/A"}
                          <br />
                          Agent DNC Comment-{" "}
                          {closingDetails.agentDncComment || "N/A"}
                        </UncontrolledTooltip> */}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Card className="responsiveTableCard hideDesktop">
                  {/* {console.log("ewewewe ", closingDetails)} */}
                  <CardHeader>
                    <>
                      <div className="tableUserInfo">
                        <div className="userContent">
                          <span
                            id={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                            className="signerName"
                          >
                            {closingDetails?._borrower[0]?.name?.full
                              ? closingDetails?._borrower[0]?.name?.full
                              : "N/A"}
                            {closingDetails?._borrower?.length > 1
                              ? `+${closingDetails?._borrower?.length - 1}`
                              : null}
                          </span>
                          {closingDetails?._borrower?.length ? (
                            <>
                              <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                className="signerPopover"
                              >
                                <PopoverHeader>Signers</PopoverHeader>
                                <PopoverBody>
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Language</th>
                                        <th>Time Zone</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {closingDetails?._borrower?.map(
                                        (each) => (
                                          <tr key={each._id}>
                                            <td>{each.name?.full || "N/A"}</td>
                                            <td>
                                              {each?.email ? (
                                                <a
                                                  href={`mailto:${each?.email}`}
                                                >
                                                  {each?.email}
                                                </a>
                                              ) : (
                                                "N/A"
                                              )}
                                            </td>
                                            <td>{each.phone?.work || "N/A"}</td>
                                            <td>{each.language || "N/A"}</td>
                                            <td>{each.timeZone || "N/A"}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </Table>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </>
                          ) : null}
                          {/* <span className="dateTimeFormat">
                            {formatDateAsPerTimeZOne(
                              closingDetails?.appointmentDate,
                              closingDetails?.closingAddress?.timeZone
                            )}
                            ,
                            <span>
                              {getTimeZoneAbbr(
                                closingDetails?.appointmentDate,
                                closingDetails?.closingAddress?.timeZone
                              )}
                            </span>
                          </span> */}
                          <DateTimeShowWithClosingDetails
                            closingDetails={closingDetails}
                          />
                        </div>
                      </div>
                    </>
                  </CardHeader>
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>File# </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.fileNumber || "N/A"}
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label>Added By </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?._createdBy?.name?.full
                            ? closingDetails?._createdBy?.name?.full
                            : "N/A"}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap">
                        <Label>Product Type: </Label>
                        <div style={{ fontWeight: 600 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.loanType &&
                            closingDetails?.loanType?.length > 0
                              ? closingDetails?.loanType?.indexOf("Other") > -1
                                ? closingDetails?.loanTypeOther
                                : null
                              : null}
                            <br />
                            {closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              ?.map((item) => (
                                <>
                                  {item?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                  <br />
                                </>
                              ))}
                          </span>

                          {/* {closingDetails?.length ? (
                          closingDetails.indexOf("Other") > -1 ? (
                            <span
                              style={{
                                marginRight: 6,
                              }}
                            >
                              {closingDetails?.loanTypeOther}
                            </span>
                          ) : null
                        ) : null}

                        {React.Children.toArray(
                          closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            .map((item) => (
                              <span
                                key={item._id}
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                              </span>
                            ))
                        )} */}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Signing Type </Label>

                        <div style={{ fontWeight: 600 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.signingType}
                          </span>
                          {/* 
                          {closingDetails?.length ? (
                            closingDetails?.indexOf("Other") > -1 ? (
                              <span
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}

                          {React.Children.toArray(
                            closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              .map((item) => (
                                <span
                                  key={item._id}
                                  style={{
                                    marginRight: 6,
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              ))
                          )} */}
                        </div>
                      </div>
                    </li>
                    <li>
                      {!this.props.userData?.user?.isTitleCompany && (
                        <div className="dataWrap">
                          <Label>Company Fee </Label>
                          <div>
                            {
                              <div style={{ fontWeight: 600 }}>
                                {closingDetails?.signingCompanyFee
                                  ? formatCurrencyValue(
                                      closingDetails?.signingCompanyFee
                                    )
                                  : formatCurrencyValue(0)}
                              </div>
                            }
                          </div>
                        </div>
                      )}

                      {/* <div className="dataWrap">
                        <Label>Order On Behalf Of </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.lender
                            ? capitalize(closingDetails?.lender)
                            : "N/A"}
                        </div>
                      </div> */}
                    </li>

                    <li>
                      <div className="dataWrap">
                        <div className="d-flex align-items-center justify-content-between">
                          <Label>Appointment Location: </Label>
                          <Button
                            color="link"
                            className="p-0 fs-12"
                            title={formatAddressInSingleText(
                              closingDetails?.closingAddress
                            )}
                            onClick={() =>
                              openGoogleMapOnNewTab(
                                closingDetails?.closingAddress
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/map-marker.svg")
                                  .default
                              }
                              alt="map icon"
                              className="mr-1"
                            />
                            Map
                          </Button>
                        </div>
                        <div style={{ fontWeight: 600 }}>
                          {
                            <>
                              {formatAddressInSingleText(
                                closingDetails?.closingAddress
                              ) || "N/A"}
                            </>
                          }
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap d-flex align-items-center justify-content-between">
                        <Label>Status</Label>
                        <div className="customSelectWrap">
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              closingDetails?.status
                            )}`}
                            // disabled={
                            //   this.state.loading.statusChangeLoading ||
                            //   !(
                            //     checkPermission(
                            //       "dashboard",
                            //       "canUpdateClosingStatusOrScheduling"
                            //     ) ||
                            //     checkPermission(
                            //       "dashboard",
                            //       "canEditOrderEntry"
                            //     )
                            //   )
                            // }
                            disabled={
                              loading.statusChangeLoading ||
                              closingDetails.isSavedAsDraft
                            }
                            value={closingDetails?.status}
                            onChange={
                              (event) =>
                                this._updateClosingDetailsByIdStatus(
                                  closingDetails,
                                  { status: event.target.value }
                                )
                              // !closingDetails?.tBD
                              //   ? this._updateClosingDetailsByIdStatus(
                              //       closingDetails,
                              //       { status: event.target.value }
                              //     )
                              //   : errorHandler({
                              //       reason:
                              //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                              //     })
                            }
                          >
                            <option key="CCA" value="CCA" disabled={true}>
                              Can't Complete
                            </option>
                            <option key="Pending" value="Pending">
                              Pending
                            </option>
                            <option
                              key="Closed"
                              value="Closed"
                              disabled={
                                (closingDetails?.tBD ? true : false) ||
                                closingDetails?.isSavedAsDraft
                              }
                            >
                              Closed
                            </option>
                            <option key="Cancelled" value="Cancelled">
                              Cancelled
                            </option>
                            <option
                              key="DNC"
                              value="DNC"
                              disabled={
                                (closingDetails?.tBD ? true : false) ||
                                closingDetails?.isSavedAsDraft
                              }
                            >
                              Did not close
                            </option>
                            <option
                              key="Arrived"
                              value="Arrived"
                              disabled={true}
                            >
                              Arrived
                            </option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                    {/* <li>
                      <div className="dataWrap">
                        <Label>Location: </Label>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              fontWeight: 600,
                              flex: 1,
                              marginRight: 10,
                            }}
                          >
                            248 MARINA SHORES COURT SUMMERVILLE SC 29486
                          </div>
                          <div className="d-flex">
                            <Button
                              color="link"
                              className="p-0"
                              // title={formatAddressInSingleText(row.closingAddress)}
                              onClick={() =>
                                openGoogleMapOnNewTab(
                                  closingDetails?.closingAddress
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                                    .default
                                }
                                alt="map icon"
                                className="mr-3"
                              />
                            </Button>
                            <Button
                              color="link"
                              className="p-0"
                              // title={formatAddressInSingleText(row.closingAddress)}
                              onClick={() =>
                                openAppleMapOnNewTab(
                                  closingDetails?.closingAddress
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                                    .default
                                }
                                alt="map icon"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap">
                        <Label>Order On Behalf Of: </Label>
                        <div>
                          {" "}
                          {closingDetails.lender
                            ? capitalize(closingDetails.lender)
                            : "N/A"}{" "}
                        </div>
                      </div>
                      <div className="dataWrap  d-flex align-items-center">
                        <Label className="mb-0 mr-2">Status: </Label>
                        <div
                          className="customSelectWrap"
                          style={{
                            width: 120,
                            marginRight: "0",
                          }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor("pending")}`}
                            // value={row.status}
                            // disabled={this.state.loading.statusChangeLoading ? true : false}
                            // onChange={(event) =>
                            //   this._updateClosingDetailsByIdStatus(row, event.target.value)
                            // }
                            disabled={
                              loading.statusChangeLoading ? true : false
                            }
                            value={closingDetails.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                {
                                  status: event.target.value,
                                }
                              )
                            }
                          >
                            <option key="CCA" value="CCA">
                              Can't Complete
                            </option>
                            <option
                              selected
                              key="Pending"
                              value="Pending"
                              // disabled={row._signingCompany !== undefined}
                              // disabled={!row.isCreatedForAgent}
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Pending
                            </option>
                            <option
                              key="Closed"
                              value="Closed"
                              // disabled={
                              //   agentTodayOrFutureDate(row) && row.status !== "Arrived"
                              // }
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Closed
                            </option>
                            <option
                              key="Cancelled"
                              value="Cancelled"
                              // disabled={!row.isCreatedForAgent}
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Cancelled
                            </option>
                            <option
                              key="DNC"
                              value="DNC"
                              // disabled={
                              //   agentTodayOrFutureDate(row) && row.status !== "Arrived"
                              // }
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Did not close
                            </option>
                            <option
                              key="Arrived"
                              value="Arrived"
                              // disabled={
                              //   this._isDisabledCheckStatusDropdown(row) ||
                              //   row.status === "Closed"
                              // }
                              disabled={
                                this._isDisabledCheckStatusDropdown(
                                  closingDetails
                                ) || closingDetails.status === "Closed"
                              }
                            >
                              Arrived
                            </option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                </Card>

                <Collapse
                  className="accordianCollapse"
                  isOpen={this.state.isOpenCollapse}
                >
                  <ul className="clientDetails">
                    <li>
                      <div className="userInfo">
                        <div className="userImage">
                          <img
                            // src={
                            //   require("../../../assets/img/multi.png").default
                            // }
                            src={
                              closingDetails?._signingCompany?.logo
                                ? closingDetails._signingCompany.logo
                                : closingDetails?._client?.logo
                                ? closingDetails?._client?.logo
                                : require("../../../assets/img/default_user_icon.svg")
                                    .default
                            }
                            alt="logo"
                          />
                        </div>
                        <div className="userContent">
                          <span>Client</span>
                          <h5>
                            {/* {closingDetails?._signingCompany?.companyName ||
                              closingDetails?._client?.name?.full ||
                              "N/A"} */}
                            {closingDetails?._client?.companyName || "N/A"}
                          </h5>
                        </div>
                      </div>
                    </li>
                    {closingDetails?._clientAssistantTeam?.teamName ? (
                      <li>
                        <span>Team Name</span>
                        <h5>
                          <Button
                            color="link"
                            className="fs-12"
                            onClick={() =>
                              this._onToggleClientMembers(
                                true,
                                closingDetails?._client
                              )
                            }
                          >
                            {closingDetails?._clientAssistantTeam?.teamName ||
                              "N/A"}
                          </Button>
                        </h5>
                      </li>
                    ) : null}
                    <li>
                      <span>POC Name/Email</span>
                      <h5>
                        {capitalize(closingDetails?.personOfContact?.name) ||
                          "N/A"}
                      </h5>
                      <h5>
                        {closingDetails?.personOfContact?.email.length > 0
                          ? closingDetails?.personOfContact?.email?.map(
                              (each) => (
                                <a key={each} href={`mailto:${each}`}>
                                  {each}
                                </a>
                              )
                            )
                          : "N/A"}
                      </h5>
                    </li>
                    {/* <li>
                      <span>Contact Name/Email</span>
                      <h5>
                        {closingDetails?.personOfContact?.email.length > 0
                          ? closingDetails?.personOfContact?.email?.join(", ")
                          : "N/A"}
                      </h5>
                    </li> */}
                    {/* <li>
                      <span>Signing Type</span>{" "}
                      <h5>{closingDetails?.signingType || "N/A"}</h5>
                    </li> */}
                    <li>
                      <span>Witness Number</span>
                      <h5>{closingDetails?.witnessCount || "N/A"}</h5>
                    </li>
                    <li>
                      <span>Property Address</span>
                      <h5>
                        {formatAddressInSingleText(
                          closingDetails?._borrower[0]?.address
                        )}
                      </h5>
                    </li>
                    <li>
                      <span>Tracking Info</span>
                      <h5>
                        {closingDetails?.trackingInfo ? (
                          <CopyToClipboard
                            text={closingDetails.trackingInfo}
                            onCopy={() => showToast("Copied", "success")}
                            style={{ cursor: "pointer" }}
                          >
                            <span>{closingDetails.trackingInfo}</span>
                          </CopyToClipboard>
                        ) : (
                          "N/A"
                        )}
                      </h5>
                    </li>
                    <li>
                      <span>Funds Collected</span>
                      <h5>
                        <div className="d-flex flex-wrap">
                          <Input
                            type="Select"
                            name="agentFundsCollected"
                            style={{ height: 35, width: 50 }}
                            // value="true"
                            value={
                              closingDetails.agentFundsCollected ? "Yes" : "No"
                            }
                            disabled={closingDetails.isSavedAsDraft}
                            readOnly
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Input>
                          {/* <div className="form-check-radio ml-2 mb-0">
                            <Label check className="cursorPointer mb-0">
                              <Input
                                type="radio"
                                name="agentFundsCollected"
                                value="true"
                                checked={closingDetails.agentFundsCollected}
                                disabled={closingDetails.isSavedAsDraft}
                                readOnly
                              />
                              Yes
                              <span className="form-check-sign" />
                            </Label>
                          </div>
                          <div className="form-check-radio ml-2 mb-0">
                            <Label check className="cursorPointer mb-0">
                              <Input
                                type="radio"
                                name="agentFundsCollected"
                                value="false"
                                checked={!closingDetails.agentFundsCollected}
                                disabled={closingDetails.isSavedAsDraft}
                                readOnly
                              />
                              No
                              <span className="form-check-sign" />
                            </Label>
                          </div> */}
                        </div>
                      </h5>
                    </li>
                    {/* <li>
                      <span className="d-block">Internal Notes</span>
                      <img
                        src={require("../../../assets/img/nodes.svg").default}
                        style={{ marginRight: 6 }}
                        alt="notes"
                      />
                      {closingDetails?.internalNotes || "N/A"}
                    </li> */}
                    {closingDetails?.signingType === "RON" ? (
                      <li>
                        <span>RON Platform Types</span>{" "}
                        <h5>
                          {closingDetails?.ronPlatformTypes?.length
                            ? closingDetails?.ronPlatformTypes?.map(
                                (each) => each + ", "
                              )
                            : "N/A"}
                        </h5>
                      </li>
                    ) : null}
                  </ul>
                  <ul className="questionPoint">
                    {closingDetails?.questions.length > 0 &&
                      closingDetails?.questions.map((question) => (
                        <li className={question.reply ? "yes" : "No"}>
                          {question.query}
                          <div className="answer">
                            {" "}
                            {question.reply ? (
                              <img
                                src={
                                  require("../../../assets/img/checkIcon.svg")
                                    .default
                                }
                                alt="yes check"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../../assets/img/cross.png")
                                    .default
                                }
                                alt="No cross"
                              />
                            )}{" "}
                            {question.reply ? "Yes" : "No"}
                          </div>
                        </li>
                      ))}
                  </ul>
                </Collapse>

                <div className="text-center">
                  <Button
                    color="link"
                    className={`accordianButton ${
                      this.state.isOpenCollapse ? "" : "show"
                    }`}
                    onClick={() =>
                      this.setState({
                        isOpenCollapse: !this.state.isOpenCollapse,
                      })
                    }
                  >
                    {this.state.isOpenCollapse ? "Hide" : "Show"} Other Details
                    <img
                      src={
                        require("../../../assets/img/arrow-blue.svg").default
                      }
                      alt="details"
                    />
                  </Button>
                </div>
              </div>

              {/* <Row>
                <Col xl="6" className="mb-3">
                  <Card className="normalTableWrap h-100">
                    <CardHeader>
                      <CardTitle tag="h6">Appointment</CardTitle>

                      <div>
                        {!editing.appointment ? (
                          <Button
                            onClick={() => this.editModeOn("appointment", true)}
                            disabled={
                              !checkPermission("dashboard", "canEditOrderEntry")
                            }
                          >
                            Edit
                          </Button>
                        ) : (
                          <>
                            <Button
                              disabled={loading.cardUpdateLoading}
                              onClick={() => this.updateClosing("appointment")}
                            >
                              {loading.cardUpdateLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : null}{" "}
                              Save
                            </Button>
                            <Button
                              className="px-2 px-sm-3"
                              onClick={() =>
                                this.editModeOn("appointment", false)
                              }
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ListGroup className="customList">
                        <ListGroupItem>
                          Address
                          <div className="customTextAlignment">
                            {editing.appointment ||
                            closingDetails.closingAddress ? (
                              <div>
                                {!editing.appointment ? (
                                  <>
                                    <span>
                                      {closingDetails.closingAddress.line1
                                        ? `${closingDetails.closingAddress.line1}, `
                                        : ""}
                                      {closingDetails.closingAddress.line2
                                        ? `${closingDetails.closingAddress.line2}, `
                                        : ""}
                                      {closingDetails.closingAddress.city
                                        ? `${closingDetails.closingAddress.city}, `
                                        : ""}
                                      {closingDetails.closingAddress.state
                                        ? `${closingDetails.closingAddress.state}, `
                                        : ""}
                                      {closingDetails.closingAddress.zip
                                        ? `${closingDetails.closingAddress.zip}`
                                        : ""}
                                    </span>
                                    <Button
                                      color="link"
                                      className="showMap"
                                      onClick={() =>
                                        appointmentMapType === "apple"
                                          ? openAppleMapOnNewTab(
                                              closingDetails.closingAddress
                                            )
                                          : openGoogleMapOnNewTab(
                                              closingDetails.closingAddress
                                            )
                                      }
                                    >
                                      Show Map
                                    </Button>
                                    <div className="d-flex flex-wrap">
                                      <div className="form-check-radio mr-3 mb-0 mt-2">
                                        <Label check className="cursorPointer">
                                          <Input
                                            type="radio"
                                            name="appointmentMapType"
                                            value="google"
                                            checked={
                                              appointmentMapType === "google"
                                            }
                                            onChange={() =>
                                              this.setState({
                                                appointmentMapType: "google",
                                              })
                                            }
                                          />
                                          Google Map{" "}
                                          <span className="form-check-sign" />
                                        </Label>
                                      </div>
                                      <div className="form-check-radio mb-0 mt-2">
                                        <Label check className="cursorPointer">
                                          <Input
                                            type="radio"
                                            name="appointmentMapType"
                                            value="apple"
                                            checked={
                                              appointmentMapType === "apple"
                                            }
                                            onChange={() =>
                                              this.setState({
                                                appointmentMapType: "apple",
                                              })
                                            }
                                          />
                                          Apple Map
                                          <span className="form-check-sign" />
                                        </Label>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      type="text"
                                      value={formFields.closingSearch.value}
                                      name="search"
                                      onChange={(event) =>
                                        this._onChangeFormField(
                                          "closingSearch",
                                          event.target.value
                                        )
                                      }
                                    />
                                    {!this.state.show ? (
                                      <ListGroup
                                        flush
                                        className="customSearchOptions"
                                      >
                                        {" "}
                                        {this.state.searchResult &&
                                          this.state.searchResult.map((i) => {
                                            return (
                                              <ListGroupItem
                                                key={i.place_id}
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.getPlaceDetail(
                                                    i,
                                                    "ClosingAddess"
                                                  )
                                                }
                                              >
                                                {i.description}
                                              </ListGroupItem>
                                            );
                                          })}
                                      </ListGroup>
                                    ) : null}
                                    {formFields.closingSearch.error && (
                                      <div className="validation-error">
                                        {formFields.closingSearch.error}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Appointment Notes
                          <div>{closingDetails?.appointmentNotes || "N/A"}</div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Agent Notes
                          <div>{closingDetails?.agentNotes || "N/A"}</div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Internal Notes
                          <div>
                            {!editing.appointment ? (
                              <p>{closingDetails?.internalNotes || "N/A"}</p>
                            ) : (
                              <Input
                                type="textarea"
                                rows="3"
                                value={formFields.internalNotes.value}
                                name="companyNotes"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "internalNotes",
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Order Notes
                          <div>
                            {!editing.appointment ? (
                              <p>{closingDetails.companyNotes || "N/A"}</p>
                            ) : (
                              <Input
                                type="textarea"
                                rows="3"
                                value={formFields.companyNotes.value}
                                name="companyNotes"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "companyNotes",
                                    event.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Witness Number
                          <div>
                            {editing.appointment ? (
                              <Input
                                type="number"
                                value={formFields.witnessCount.value}
                                name="witnessCount"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "witnessCount",
                                    event.target.value
                                  )
                                }
                                disabled={
                                  formFields.signingType.value === "Mobile"
                                }
                                onKeyPress={(e) => this._isNumber(e)}
                              />
                            ) : closingDetails.witnessCount ? (
                              closingDetails.witnessCount
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Appointment On
                          <div className="customTextAlignment">
                            <div>
                              <Button
                                color="link"
                                className="pl-0 pl-sm-2 pr-2"
                                onClick={() =>
                                  this._onToggleSignerAvailabilityModal(
                                    true,
                                    closingDetails
                                  )
                                }
                                disabled={
                                  !checkPermission(
                                    "dashboard",
                                    "canEditOrderEntry"
                                  )
                                }
                              >
                                <i className="fa fa-pencil" />
                              </Button>

                              {closingDetails.appointmentDate ? (
                                <>
                                  {!closingDetails.tBD
                                    ? formatDateAsPerTimeZOne(
                                        closingDetails.appointmentDate,
                                        closingDetails.closingAddress.timeZone
                                      )
                                    : formatDateOnlyAsPerTimeZone(
                                        closingDetails.appointmentDate,
                                        closingDetails.closingAddress.timeZone
                                      )}
                                </>
                              ) : (
                                "N/A"
                              )}
                            </div>
                            <div
                              className={`${
                                closingDetails.appointmentStatus === "Confirmed"
                                  ? "text-success"
                                  : "text-info"
                              }`}
                            >
                              {closingDetails?.appointmentStatus}
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="6" className="mb-3">
                  <Card className="normalTableWrap h-100">
                    <CardHeader className="flex-column flex-sm-row align-items-start align-items-sm-center">
                      <CardTitle tag="h6">Order Details</CardTitle>

                      <div className="addedBy">
                        <div className="mr-0 mr-sm-4 customTextAlignment mb-0">
                          <Label>Added By</Label>
                          <p className="themeColor">
                            {closingDetails?._createdBy?.name?.full ||
                              closingDetails?._createdByClient?.name?.full ||
                              "N/A"}
                          </p>
                        </div>
                        {!editing.orderDetail ? (
                          <Button
                            onClick={() => this.editModeOn("orderDetail", true)}
                            disabled={
                              !checkPermission("dashboard", "canEditOrderEntry")
                            }
                          >
                            Edit
                          </Button>
                        ) : (
                          <div className="d-flex">
                            <Button
                              disabled={loading.cardUpdateLoading}
                              onClick={() => this.updateClosing("orderDetail")}
                            >
                              {loading.cardUpdateLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : null}{" "}
                              Save
                            </Button>
                            <Button
                              className="px-2 px-sm-3"
                              onClick={() =>
                                this.editModeOn("orderDetail", false)
                              }
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </div>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ListGroup className="customList">
                        <ListGroupItem>
                          File No.
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.fileNumber || "N/A"
                            ) : (
                              <Input
                                type="text"
                                placeholder=""
                                value={formFields.fileNumber.value}
                                name="fileNumber"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "fileNumber",
                                    event.target.value
                                  )
                                }
                              />
                            )}
                            {formFields.fileNumber.error && (
                              <div className="validation-error">
                                {formFields.fileNumber.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          // {/* Lender 
                          Order on behalf of
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.lender ? (
                                capitalize(closingDetails.lender)
                              ) : (
                                "N/A"
                              )
                            ) : (
                              <Input
                                type="text"
                                placeholder=""
                                value={formFields.lender.value}
                                name="lender"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "lender",
                                    event.target.value
                                  )
                                }
                              />
                            )}
                            {formFields.lender.error && (
                              <div className="validation-error">
                                {formFields.lender.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>

                        <ListGroupItem>
                          Client
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails._client ? (
                                closingDetails?._client?.companyName ? (
                                  capitalize(
                                    closingDetails?._client?.companyName
                                  )
                                ) : (
                                  "N/A"
                                )
                              ) : (
                                "N/A"
                              )
                            ) : (
                              <Input
                                type="select"
                                placeholder=""
                                value={formFields.client.value}
                                name="client"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "client",
                                    event.target.value
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {clients.map((client) => (
                                  <option key={client._id} value={client._id}>
                                    // {/* {capitalize(client.name.full)} 
                                    {client?.companyName
                                      ? capitalize(client.companyName)
                                      : capitalize(client.name.full)}
                                  </option>
                                ))}
                              </Input>
                            )}
                            {formFields.client.error && (
                              <div className="validation-error">
                                {formFields.client.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Payment
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.signingCompanyFee ? (
                                formatCurrencyValue(
                                  closingDetails.signingCompanyFee
                                )
                              ) : (
                                "N/A"
                              )
                            ) : (
                              <div className="d-flex align-items-center">
                                <span className="mr-1">$</span>
                                <Input
                                  type="text"
                                  value={formFields.signingCompanyFee.value}
                                  name="signingCompanyFee"
                                  onChange={(event) =>
                                    this._onChangeFormField(
                                      "signingCompanyFee",
                                      event.target.value
                                    )
                                  }
                                  // disabled={closingDetails.isSavedAsDraft}
                                />
                              </div>
                            )}
                            {formFields.signingCompanyFee.error && (
                              <div className="validation-error">
                                {formFields.signingCompanyFee.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Tracking Info
                          {/* <div>
                           {closingDetails.trackingInfo ? (
                            <div className="copyTrackingNum">
                              <Button
                                color="link"
                                className="actionBtn"
                                onClick={() =>
                                  copyToClipboard(closingDetails.trackingInfo)
                                }
                              >
                                <i className="fa fa-copy fs-15" />
                              </Button>
                              <span className="ml-2">
                                {closingDetails.trackingInfo}
                              </span>
                            </div>
                          ) : (
                            <span>N/A</span>
                          )} 
                        // </div>
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.trackingInfo ? (
                                <CopyToClipboard
                                  text={closingDetails.trackingInfo}
                                  onCopy={() => showToast("Copied", "success")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>{closingDetails.trackingInfo}</span>
                                </CopyToClipboard>
                              ) : (
                                "N/A"
                              )
                            ) : (
                              <Input
                                type="text"
                                placeholder=""
                                value={formFields.trackingInfo.value}
                                name="line2"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "trackingInfo",
                                    event.target.value
                                  )
                                }
                                disabled={closingDetails.isSavedAsDraft}
                              />
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Person Of Contact (Name)
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.personOfContact?.name ? (
                                <CopyToClipboard
                                  text={closingDetails.personOfContact.name}
                                  onCopy={() => showToast("Copied", "success")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <span>
                                    {capitalize(
                                      closingDetails.personOfContact.name
                                    )}
                                  </span>
                                </CopyToClipboard>
                              ) : (
                                "N/A"
                              )
                            ) : (
                              <Input
                                type="text"
                                placeholder=""
                                value={formFields.personOfContactName.value}
                                name="line2"
                                onChange={(event) =>
                                  this._onChangeFormField(
                                    "personOfContactName",
                                    event.target.value
                                  )
                                }
                              />
                            )}
                            {formFields.personOfContactName.error && (
                              <div className="validation-error">
                                {formFields.personOfContactName.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Person Of Contact (Email)
                          <div>
                            {!editing.orderDetail ? (
                              closingDetails.personOfContact?.email.length ? (
                                // <CopyToClipboard
                                //   text={closingDetails.personOfContact.email}
                                //   onCopy={() => showToast("Copied", "success")}
                                //   style={{ cursor: "pointer" }}
                                // >
                                //   <span>
                                //     {closingDetails.personOfContact.email}
                                //   </span>
                                // </CopyToClipboard>
                                closingDetails.personOfContact.email.map(
                                  (item) => (
                                    <CopyToClipboard
                                      key={item}
                                      text={
                                        closingDetails.personOfContact.email
                                      }
                                      onCopy={() =>
                                        showToast("Copied", "success")
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {/* <span>
                                  {closingDetails.personOfContact.email}
                                // </span> 
                                      <div>{item}</div>
                                    </CopyToClipboard>
                                  )
                                )
                              ) : (
                                "N/A"
                              )
                            ) : (
                              // <Input
                              //   type="email"
                              //   placeholder=""
                              //   value={formFields.personOfContactEmail.value}
                              //   name="line2"
                              //   onChange={(event) =>
                              //     this._onChangeFormField(
                              //       "personOfContactEmail",
                              //       event.target.value
                              //     )
                              //   }
                              // />
                              <div className="chipsWrap">
                                // {/* don't remove this label *
                                <Label className="d-none">Chips</Label>
                                <Chips
                                  value={formFields.personOfContactEmail.value}
                                  onChange={(arr) =>
                                    this._onChipSelect(arr, "chips")
                                  }
                                  getChipValue={(e) => console.log(e)}
                                  createChipKeys={[9, 13]} //  Key codes to add chips
                                  placeholder={"Add Email"}
                                />
                              </div>
                            )}
                            {formFields.personOfContactEmail.error && (
                              <div className="validation-error">
                                {formFields.personOfContactEmail.error}
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                        <ListGroupItem>
                          Status
                          <Input
                            type="select"
                            name="status"
                            disabled={
                              loading.statusChangeLoading ||
                              closingDetails.isSavedAsDraft
                            }
                            value={closingDetails?.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                { status: event.target.value }
                              )
                            }
                          >
                            {agentClosingStatus.map((obj) => (
                              <option
                                key={obj.value}
                                value={obj.value}
                                disabled={
                                  obj.value === "CCA" || obj.value === "Arrived"
                                }
                              >
                                {obj.label}
                              </option>
                            ))}
                          </Input>
                          {/* <i
                          className="fa fa-eye"
                          id="UncontrolledTooltipExample"
                        // /> *
                          {/* <UncontrolledTooltip
                          placement="top"
                          target="UncontrolledTooltipExample"
                        >
                          Comment-{" "}
                          {closingDetails.companyCancelledComment || "N/A"}
                          <br />
                          Agent CCA Reason-{" "}
                          {closingDetails.agentCcaReason || "N/A"}
                          <br />
                          Agent CCA Comment-{" "}
                          {closingDetails.agentCcaComment || "N/A"}
                          <br />
                          Agent DNC Comment-{" "}
                          {closingDetails.agentDncComment || "N/A"}
                        // </UncontrolledTooltip> 
                        </ListGroupItem>

                        {/* {closingDetails?.companyCancelledComment ? (
                        <ListGroupItem>
                          Comment
                          <div>
                            {closingDetails.companyCancelledComment || "N/A"}
                          </div>
                        </ListGroupItem>
                      // ) : null} *

                        {closingDetails?.agentCcaReason ? (
                          <ListGroupItem>
                            Agent CCA Reason
                            <div> {closingDetails.agentCcaReason || "N/A"}</div>
                          </ListGroupItem>
                        ) : null}

                        {/* {closingDetails?.agentCcaComment ? (
                        <ListGroupItem>
                          Agent CCA Comment
                          <div> {closingDetails.agentCcaComment || "N/A"}</div>
                        </ListGroupItem>
                      // ) : null} *

                        {closingDetails?.agentDncReason ? (
                          <ListGroupItem>
                            Agent DNC Reason
                            <div>{closingDetails?.agentDncReason || "N/A"}</div>
                          </ListGroupItem>
                        ) : null}

                        {/* {closingDetails?.agentDncComment ? (
                        <ListGroupItem>
                          Agent DNC Comment
                          <div> {closingDetails.agentDncComment || "N/A"}</div>
                        </ListGroupItem>
                      // ) : null} *

                        <ListGroupItem>
                          Funds Collected
                          <div className="d-flex flex-wrap">
                            <div className="form-check-radio ml-2 mb-0">
                              <Label check className="cursorPointer mb-0">
                                <Input
                                  type="radio"
                                  name="agentFundsCollected"
                                  value="true"
                                  checked={closingDetails.agentFundsCollected}
                                  disabled={closingDetails.isSavedAsDraft}
                                  readOnly
                                />
                                Yes
                                <span className="form-check-sign" />
                              </Label>
                            </div>
                            <div className="form-check-radio ml-2 mb-0">
                              <Label check className="cursorPointer mb-0">
                                <Input
                                  type="radio"
                                  name="agentFundsCollected"
                                  value="false"
                                  checked={!closingDetails.agentFundsCollected}
                                  disabled={closingDetails.isSavedAsDraft}
                                  readOnly
                                />
                                No
                                <span className="form-check-sign" />
                              </Label>
                            </div>
                          </div>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              <Row className="mt-4">
                <Col xl="6">
                  <div className="CardWrapper">
                    <SecureDocumentCardComponent
                      closingDetails={closingDetails}
                      updateClosingState={(closingDetails) =>
                        this._updateClosingState(closingDetails)
                      }
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="CardWrapper">
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          {closingDetails._agent?.workType
                            ? closingDetails._agent.workType
                            : "Agent"}{" "}
                          Details
                          {!editing.agentDetail ? (
                            <Button
                              color="link"
                              onClick={
                                () =>
                                  this._onToggleAssignJobModal({
                                    ...closingDetails,
                                    _id: closingDetails?._id,
                                  })
                                // this._checkAllFieldsAreAvailable()
                                //   ? this._onToggleAssignJobModal({
                                //       _id: closingDetails._id,
                                //     })
                                //   : // this.editModeOn("agentDetail", true)
                                //     showToast(
                                //       "Sorry!! Agent can’t be assigned to drafts",
                                //       "error"
                                //     )
                                // !closingDetails?.tBD
                                //   ? this._onToggleAssignJobModal({
                                //       ...closingDetails,
                                //       _id: closingDetails?._id,
                                //     })
                                //   : errorHandler({
                                //       reason:
                                //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                                //     })
                              }
                              disabled={
                                // closingDetails.staus!=="Pending"||
                                !checkPermission(
                                  "dashboard",
                                  "canEditOrderEntry"
                                )
                                // || closingDetails.isSavedAsDraft
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="edit"
                              />
                            </Button>
                          ) : null}
                        </CardTitle>
                      </CardHeader>
                      <CardBody className="p-0">
                        <div className="profilePic">
                          <img
                            src={
                              closingDetails?._agent?.profilePicture
                                ? closingDetails?._agent?.profilePicture
                                : require("../../../assets/img/default-user.png")
                                    .default
                            }
                            // src={require('../../../assets/img/default-user.png').default}
                            alt="user"
                          />
                        </div>

                        <ul className="noataryAgentList">
                          <li>
                            Name
                            <div className="value">
                              {closingDetails._agent?.name?.full ? (
                                <span
                                  className="text-primary cursorPointer"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/signingcompany/agent-details/${closingDetails?._agent?.id}`
                                    )
                                  }
                                >
                                  {capitalize(closingDetails._agent.name.full)}
                                </span>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </li>
                          <li>
                            Email
                            <div className="value">
                              {closingDetails?._agent?.email ? (
                                <a
                                  href={`mailto:${closingDetails?._agent?.email}`}
                                >
                                  {closingDetails?._agent.email}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </li>
                          <li>
                            Phone
                            <div className="value">
                              {closingDetails._agent?.phone
                                ? formatPhoneNumber(closingDetails._agent.phone)
                                : "N/A"}
                            </div>
                          </li>
                          <li>
                            Location
                            <div className="value">
                              {closingDetails._agent?.address ? (
                                <span>
                                  {closingDetails._agent.address.line1
                                    ? `${closingDetails._agent.address.line1}, `
                                    : ""}
                                  {closingDetails._agent.address.line2
                                    ? `${closingDetails._agent.address.line2}, `
                                    : ""}
                                  {closingDetails._agent.address.city
                                    ? `${closingDetails._agent.address.city}, `
                                    : ""}
                                  {closingDetails._agent.address.state
                                    ? `${closingDetails._agent.address.state}, `
                                    : ""}
                                  {closingDetails._agent.address.zip
                                    ? `${closingDetails._agent.address.zip}`
                                    : ""}
                                </span>
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </li>

                          <li>
                            Fee
                            {/* default view would be this. On clicking the below pencil icon, the below div should hide & the `edit` view should be shown(present below)  */}
                            <div className="value">
                              <span>
                                {/* {closingDetails._agent ? ( */}
                                <>
                                  {!isEditAgentFee ? (
                                    <>
                                      {closingDetails?.agentFee
                                        ? formatCurrencyValue(
                                            closingDetails?.agentFee
                                          )
                                        : closingDetails?.agentFee === 0
                                        ? "$0"
                                        : "N/A"}

                                      <img
                                        className="cursorPointer ml-2"
                                        onClick={this._toggleEditAgentFee}
                                        src={
                                          require("../../../assets/img/pencil.svg")
                                            .default
                                        }
                                        alt="edit"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {/* Edit Mode (this should be shown when the above pencil icon is clicked) */}
                                      <div className="d-flex justify-content-end">
                                        {/* On clicking the below btn the default view (present above) 
                                              should be shown & this section should hide */}

                                        {/* <Button
                                            color="link"
                                            className="px-2 ml-0"
                                            onClick={this._toggleEditAgentFee}
                                          >
                                            <i className="fa fa-times fs-18" />
                                          </Button> */}
                                        <Input
                                          type="text"
                                          placeholder=""
                                          value={formFields.agentFee.value}
                                          style={{ height: 30, width: 100 }}
                                          onChange={(event) =>
                                            this._onChangeFormField(
                                              "agentFee",
                                              event.target.value
                                            )
                                          }
                                        />
                                        <Button
                                          color="link"
                                          className="pl-1"
                                          disabled={
                                            formFields.agentFee.value.trim()
                                              .length === 0
                                          }
                                          onClick={() =>
                                            this._updateClosingDetailsByIdStatus(
                                              closingDetails,
                                              {
                                                agentFee:
                                                  formFields.agentFee.value,
                                              }
                                            )
                                          }
                                        >
                                          <img
                                            className="cursorPointer ml-2"
                                            src={
                                              require("../../../assets/img/checkBlueIcon.svg")
                                                .default
                                            }
                                            alt="edit"
                                          />
                                        </Button>
                                      </div>
                                    </>
                                  )}
                                </>
                                {/* // ) : (
                                //   "N/A"
                                // )} */}
                              </span>
                            </div>
                          </li>
                          <li>
                            Dnc Fee
                            <div className="value">
                              {closingDetails.agentDncFee ? (
                                closingDetails.companyAcceptDncFee ? (
                                  <span>${closingDetails.agentFee}</span>
                                ) : (
                                  <>
                                    <InputGroup>
                                      {/* <InputGroupAddon addonType="prepend">
                                        <InputGroupText>$</InputGroupText>
                                      </InputGroupAddon> */}
                                      <Input
                                        type="text"
                                        placeholder=""
                                        value={formFields.agentDncFee.value}
                                        onChange={(event) =>
                                          this._onChangeFormField(
                                            "agentDncFee",
                                            event.target.value
                                          )
                                        }
                                      />
                                      <InputGroupAddon addonType="append">
                                        <Button
                                          color="primary"
                                          className="fs-12"
                                          onClick={() =>
                                            formFields.agentDncFee.value <=
                                            closingDetails.agentDncFee
                                              ? this._updateClosingDetailsByIdStatus(
                                                  closingDetails,
                                                  {
                                                    companyAcceptDncFee: true,
                                                    agentFee:
                                                      formFields.agentDncFee
                                                        .value !==
                                                      closingDetails.agentDncFee.toString()
                                                        ? formFields.agentDncFee
                                                            .value
                                                        : undefined,
                                                  }
                                                )
                                              : showToast(
                                                  "Agent Dnc Fee should not be Greater",
                                                  "error"
                                                )
                                          }
                                        >
                                          Approve
                                        </Button>
                                      </InputGroupAddon>
                                    </InputGroup>
                                  </>
                                )
                              ) : (
                                "N/A"
                              )}
                            </div>
                          </li>
                          {/* <li>
                            License
                            <div className="value">
                              {closingDetails._agent?.notaryLicense
                                ?.licenseNumber || "N/A"}
                            </div>
                          </li> */}

                          {/* <li>
                            Jobs Scheduled
                            <div className="value">
                              {closingDetails._agent?.closingScheduled || "N/A"}
                            </div>
                          </li> */}
                          {/* <li>
                            Jobs Completed
                            <div className="value">
                              {closingDetails._agent?.closingCompleted || "N/A"}
                            </div>
                          </li> */}
                          <li>
                            Rating
                            <div className="rating">
                              {previousRating !== null ? (
                                <StarRatings
                                  rating={closingDetails._agent?.totalRating}
                                  starRatedColor="#FED82F"
                                  changeRating={
                                    closingDetails._agent.totalRating
                                  }
                                  starHoverColor="#FED82F"
                                  numberOfStars={5}
                                  name="rating"
                                />
                              ) : null}

                              {closingDetails._agent ? (
                                <Button
                                  color="link"
                                  onClick={() => this._onToggleAddRatingModal()}
                                >
                                  {previousRating !== null ? (
                                    <img
                                      src={
                                        require("../../../assets/img/pencil.svg")
                                          .default
                                      }
                                      alt="edit"
                                    />
                                  ) : (
                                    "Add Rating"
                                  )}{" "}
                                </Button>
                              ) : null}
                            </div>
                            {/* {closingDetails._agent?.totalRating
                                ? `${closingDetails._agent.totalRating}/5`
                                : "N/A"} */}
                          </li>
                        </ul>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <Col xl="6" className="mb-3">
                  <Card className="normalTableWrap h-100">
                    <CardHeader>
                      <CardTitle tag="h6">Instructions</CardTitle>

                      <div>
                        {!editing.instructions ? (
                          <Button
                            onClick={() =>
                              this.editModeOn("instructions", true)
                            }
                            disabled={
                              !checkPermission("dashboard", "canEditOrderEntry")
                            }
                          >
                            Edit
                          </Button>
                        ) : (
                          <>
                            <Button
                              disabled={loading.cardUpdateLoading}
                              onClick={() => this.updateClosing("instructions")}
                            >
                              {loading.cardUpdateLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : null}{" "}
                              Save
                            </Button>
                            <Button
                              className="px-2 px-sm-3"
                              onClick={() =>
                                this.editModeOn("instructions", false)
                              }
                            >
                              <i className="fa fa-times" />
                            </Button>
                          </>
                        )}
                      </div>
                    </CardHeader>
                    <CardBody className="p-3">
                      {editing.instructions ? (
                        <FormGroup style={{ maxWidth: 300 }}>
                          <Label>Instructions Type</Label>
                          <Input
                            type="select"
                            value={formFields.instructionId.value}
                            name="instructionId"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "instructionId",
                                event.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            {signingInstruction.map((each) => (
                              <option key={each._id} value={each._id}>
                                {capitalize(each.name)}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      ) : null}

                      {!editing.instructions ? (
                       
                        <TextEditor
                          enabled={true}
                          content={closingDetails.instructions}
                          onChange={(event) =>
                            this._onChangeFormField("instructions", event)
                          }
                        />
                      ) : (
                        <TextEditor
                          disabled
                          content={formFields.instructions.value}
                          onChange={(event) =>
                            this._onChangeFormField("instructions", event)
                          }
                        />
                      )}

                      <FormGroup>
                        <CustomInput
                          className="mt-3"
                          type="checkbox"
                          id="isScanBackNeeded"
                          label="Scan Backs Needed"
                          checked={closingDetails.isScanBackNeeded}
                          
                          onChange={(event) =>
                            this._updateClosingDetailsByIdStatus(
                              closingDetails,
                              {
                                isScanBackNeeded: event.target.checked,
                              }
                            )
                          }
                        />
                      </FormGroup>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="6" className="mb-3">
                  <Card className="normalTableWrap h-100">
                    <CardHeader>
                      <CardTitle tag="h6">Activity Log</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ListGroup className="customList">
                        {this._activityLog()}
                        {activityLog.length > 0 && (
                          <Pagination
                            activePage={
                              parseInt(
                                activityLogPage.skip / activityLogPage.limit
                              ) + 1
                            }
                            itemsCountPerPage={activityLogPage.limit}
                            totalItemsCount={activityLogPage.totalCount}
                            pageRangeDisplayed={5}
                            itemClass="page-item"
                            linkClass="page-link"
                            onChange={this._handleActivityLogPagination}
                          />
                        )}
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}

              <ChatLogCardComponent closingDetails={closingDetails} />

              {/* Order Instructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Instructions
                    {!editing.instructions ? (
                      <Button
                        color="link"
                        onClick={() => this.editModeOn("instructions", true)}
                        disabled={
                          !checkPermission("dashboard", "canEditOrderEntry")
                        }
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit"
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="link"
                          disabled={loading.cardUpdateLoading}
                          onClick={() => this.updateClosing("instructions")}
                        >
                          {loading.cardUpdateLoading ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          <img
                            src={
                              require("../../../assets/img/checkBlueIcon.svg")
                                .default
                            }
                            alt="save"
                          />
                        </Button>
                        <Button
                          color="link"
                          className="px-2 px-sm-3"
                          onClick={() => this.editModeOn("instructions", false)}
                        >
                          <img
                            src={
                              require("../../../assets/img/close.svg").default
                            }
                            height={16}
                            alt="close"
                          />
                        </Button>
                      </>
                    )}
                  </h2>
                </div>
                <Card>
                  <CardBody>
                    <CustomInput
                      className="mb-2"
                      type="checkbox"
                      id="isScanBackNeeded"
                      label="Scan Backs Needed"
                      checked={closingDetails.isScanBackNeeded}
                      // checked={formFields.isScanBackNeeded.value}
                      onChange={(event) =>
                        this._updateClosingDetailsByIdStatus(closingDetails, {
                          isScanBackNeeded: event.target.checked,
                        })
                      }
                    />
                    {editing.instructions ? (
                      <FormGroup
                        className="floatingLabel"
                        style={{ maxWidth: 300 }}
                      >
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            value={formFields.instructionId.value}
                            name="instructionId"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "instructionId",
                                event.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            {signingInstruction.map((each) => (
                              <option key={each._id} value={each._id}>
                                {capitalize(each.name)}
                              </option>
                            ))}
                          </Input>
                          <Label>Instructions Type</Label>
                        </div>
                      </FormGroup>
                    ) : null}

                    {!editing.instructions ? (
                      // <p>{closingDetails.instructions || "N/A"}</p>
                      // closingDetails.instructions ? (
                      //   closingDetails.instructions.replace(/(<([^>]+)>)/gi, "")
                      // ) : (
                      //   "N/A"
                      // )
                      <TextEditor
                        enabled={true}
                        content={closingDetails.instructions}
                        onChange={(event) =>
                          this._onChangeFormField("instructions", event)
                        }
                      />
                    ) : (
                      <TextEditor
                        disabled
                        content={formFields.instructions.value}
                        onChange={(event) =>
                          this._onChangeFormField("instructions", event)
                        }
                      />
                    )}
                  </CardBody>
                </Card>
              </div>

              {/* payment Instructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Payment Instructions
                    {!editing.paymentInstructions ? (
                      <Button
                        color="link"
                        onClick={() =>
                          this.editModeOn("paymentInstructions", true)
                        }
                        // disabled={
                        //   !checkPermission("dashboard", "canEditOrderEntry")
                        // }
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit"
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="link"
                          disabled={loading.cardUpdateLoading}
                          onClick={() =>
                            this.updateClosing("paymentInstructions")
                          }
                        >
                          {loading.cardUpdateLoading ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          <img
                            src={
                              require("../../../assets/img/checkBlueIcon.svg")
                                .default
                            }
                            alt="save"
                          />
                        </Button>
                        <Button
                          color="link"
                          className="px-2 px-sm-3"
                          onClick={() =>
                            this.editModeOn("paymentInstructions", false)
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/close.svg").default
                            }
                            height={16}
                            alt="close"
                          />
                        </Button>
                      </>
                    )}
                  </h2>
                </div>
                <Card>
                  <CardBody>
                    {/* <CustomInput
                      className="mb-2"
                      type="checkbox"
                      id="isScanBackNeeded"
                      label="Scan Backs Needed"
                      checked={closingDetails.isScanBackNeeded}
                      // checked={formFields.isScanBackNeeded.value}
                      onChange={(event) =>
                        this._updateClosingDetailsByIdStatus(closingDetails, {
                          isScanBackNeeded: event.target.checked,
                        })
                      }
                    /> */}
                    {/* {editing.paymentInstructions ? (
                      <FormGroup
                        className="floatingLabel"
                        style={{ maxWidth: 300 }}
                      >
                        <div className="custom-select-wrapper">
                        <Input
                            type="select"
                            value={formFields.instructionId.value}
                            name="instructionId"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "instructionId",
                                event.target.value
                              )
                            }
                          >
                            <option value="">select</option>
                            {signingInstruction.map((each) => (
                              <option key={each._id} value={each._id}>
                                {capitalize(each.name)}
                              </option>
                            ))}
                          </Input>
                          <Label>Instructions Type</Label>
                        </div>
                      </FormGroup>
                    ) : null} */}

                    {!editing.paymentInstructions ? (
                      <TextEditor
                        enabled={true}
                        content={closingDetails.paymentInstructions}
                        onChange={(event) =>
                          this._onChangeFormField("instructions", event)
                        }
                      />
                    ) : (
                      <TextEditor
                        disabled
                        content={formFields.paymentInstructions.value}
                        onChange={(event) =>
                          this._onChangeFormField("paymentInstructions", event)
                        }
                      />
                    )}
                  </CardBody>
                </Card>
              </div>

              {/* activity */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Activity Log</h2>
                </div>
                <Card>
                  <CardBody>
                    <ul className="activityLog">{this._activityLog()}</ul>
                    <CustomPagination
                      data={activityLog}
                      dataCount={activityLogPage.totalCount}
                      pageNumber={
                        parseInt(activityLogPage.skip / activityLogPage.limit) +
                        1
                      }
                      pageSize={activityLogPage.limit}
                      onPageChange={(pageNo) =>
                        this._handleActivityLogPagination(pageNo)
                      }
                      isPageStartFromOne
                      isHideForLessData
                    />
                  </CardBody>
                </Card>
              </div>
            </>
          )}
          {documentViewModalData.isOpen && (
            <DocumentViewModal
              isOpen={documentViewModalData.isOpen}
              externalUrl={documentViewModalData.externalUrl}
              fileType={documentViewModalData.fileType}
              modalHeading={documentViewModalData.modalHeading}
              toggle={this._onToggleDocumentViewModal}
            />
          )}

          <AddRatingModal
            isOpen={addRatingModal.isOpen}
            data={addRatingModal.data}
            mode={addRatingModal.mode}
            toggle={() => this._onToggleAddRatingModal()}
            resetDetails={() => this._getSigningClosingById()}
            ratingDetail={() => this._getrating(closingDetails._id)}
          />

          {signerAvailabilityModal.isOpen ? (
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              resetDetails={this._getSigningClosingById}
              toggle={() => this._onToggleSignerAvailabilityModal()}
            />
          ) : null}

          {signingStatusModal.isOpen ? (
            <SigningStatusSigningModal
              isOpen={signingStatusModal.isOpen}
              data={signingStatusModal.data}
              status={signingStatusModal.status}
              resetDetails={() => this._getSigningClosingById()}
              toggle={() => this._onToggleSigningStatusModal()}
              isDateFormat={false}
              initialViewMode={"time"}
            />
          ) : null}

          <EditOrderDetailsModal
            isOpen={editOrderDetailsModal.isOpen}
            data={editOrderDetailsModal.data}
            toggle={() => this._toggleEditOrderDetailsModal()}
            updateClosingDetailsById={
              closingDetails?.isSavedAsDraft
                ? this._updateSigningCompanyDraftClosing
                : this._updateClosingDetailsById
            }
            resetDetails={this._getSigningClosingById}
            clients={clients}
            loanType={this.state.loanType}
          />

          <DownloadInvoiceModal
            isOpen={downloadInvoiceModal?.isOpen}
            data={downloadInvoiceModal?.data}
            toggle={this._onToggleDownloadInvoiceModal}
            type="signingCompany"
          />

          <ClosingAssignment
            {...this.props}
            isOpen={assignJobModal.isOpen}
            data={assignJobModal.data}
            // agents={agents}
            resetDetails={() => this._getSigningClosingById()}
            toggle={() => this._onToggleAssignJobModal()}
          />

          {/* {clientMemberModal?.isOpen ? ( */}
          <TeamMembers
            clientTeamMembers={
              closingDetails?._clientAssistantTeam?.teamMembers
            }
            isOpen={clientMemberModal?.isOpen}
            data={clientMemberModal?.data}
            toggle={() => this._onToggleClientMembers()}
          />
          {/* ) : null} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClosingDetails);
