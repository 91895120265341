import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  Col,
  Row,
  UncontrolledCollapse,
  FormGroup,
  CustomInput,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Badge,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  capitalize,
  deepClone,
  showToast,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  // formatTime,
  structureQueryParams,
  getLoggedInUserId,
  getTimeZoneAbbr,
  formatOnlyDateMoment,
  errorHandler,
} from "../../../helper-methods";
import {
  getAllCompanyClosingForInvoice,
  createInvoiceCompany,
  updateClosingDetailsById,
  companyGetAssistantTeams,
  getClientTeamMembers,
  comapanyGetAllClientTeams,
} from "../../../http/http-calls";
import { makeGetRequest } from "../../../http/http-service";
import Chips from "react-chips";
import { RegexConfig } from "../../../config/RegexConfig";
import { agentClosingStatus, BASE_URL } from "../../../config";
import CustomTable from "./CustomTable";
import CustomCard from "./CustomCard";

class CreateInvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chips: [],
      teams: {
        data: [],
        totalCount: 0,
      },
      closingList: [],
      closingTotalCount: 0,
      closingSelectedRows: [],
      closingHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "closingNumber", label: "Order#", noSort: true },
        { id: "appointmentDate", label: "Date", noSort: true },
        { id: "invoice", label: "Invoice", noSort: true },
        { id: "_borrower", label: "Signer", noSort: true },
        { id: "amount", label: "Amount", noSort: true },
        { id: "status", label: "Status", noSort: true },
        { id: "action", label: "Company Dnc Fee", noSort: true },
      ],
      closingCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      filters: {
        client: "",
        status: "",
        _clientAssistantTeam: "",
      },
      dateRangeValue: null,
      isInvoiceActive: false,
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      loading: {
        showTableLoading: false,
        createClosingLoading: false,
        isEmailLoading: false,
      },
      companyDncFee: "",
      isPendingInvoice: false,
      selectAll: false,
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _resetStateModal = () => {
    this.setState({
      chips: [],
      teams: {
        data: [],
        totalCount: 0,
      },
      closingList: [],
      closingTotalCount: 0,
      closingSelectedRows: [],
      isPendingInvoice: false,
      selectAll: false,
      filters: {
        client: "",
        status: "",
        _clientAssistantTeam: "",
      },
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      dateRangeValue: null,
      loading: {
        showTableLoading: false,
        createClosingLoading: false,
        isEmailLoading: false,
      },
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _getAllClosingForCreatingInvoice = () => {
    // this._manageLoading("showTableLoading", true);

    const {
      tableConfig,
      filters,
      dateRangeValue,
      isPendingInvoice,
      selectAll,
    } = deepClone(this.state);

    const payload = {
      ...tableConfig,
      filters: {},
    };

    // if (!filters._clientAssistantTeam.trim().length > 0) {
    //   return;
    // }

    if (!filters.client.trim().length > 0) {
      return;
    }

    if (dateRangeValue && dateRangeValue.length) {
      payload.filters["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload.filters["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    // if (isInvoiceActive) payload.filters["isDoneInvoice"] = isInvoiceActive;

    if (isPendingInvoice)
      payload.filters["isPendingInvoice"] = isPendingInvoice;

    if (filters.client) payload.filters["clientId"] = filters.client;
    if (filters.status) payload.filters["status"] = filters.status;
    if (filters._clientAssistantTeam)
      payload.filters["_clientAssistantTeam"] = filters._clientAssistantTeam;

    getAllCompanyClosingForInvoice(payload)
      .then((res) => {
        this.setState(
          {
            closingList: res?.closings || [],
            closingTotalCount: res.totalCount || 0,
            closingSelectedRows: selectAll
              ? [
                  ...new Set([
                    ...this.state.closingSelectedRows,
                    ...res.closings,
                  ]),
                ]
              : this.state.closingSelectedRows,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  componentDidUpdate = (prevProps) => {
    console.log(this.props);
    const { isOpen } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen) {
      this._paginate();
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._getAllClosingForCreatingInvoice();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    // console.log("sortName, sortOrder :", sortName, sortOrder);
    this._paginate();
  };

  // _setSelectedRows = (selectedRows = []) => {
  //   this.setState({
  //     selectedRows,
  //   });
  // };

  _setSelectedRows = (closingSelectedRow = []) => {
    const { closingSelectedRows, tableConfig } = this.state;
    // console.log(closingSelectedRow, closingSelectedRows);
    // Check if the current limit matches the length of the incoming rows
    if (tableConfig.limit !== closingSelectedRow.length) {
      // Combine the current selected rows with the new rows
      const updatedArray = closingSelectedRows
        .slice(
          tableConfig.pageNumber * tableConfig.limit,
          (tableConfig.pageNumber + 1) * tableConfig.limit
        )
        .concat(closingSelectedRow);

      // Filter out duplicates based on `id` using a Map
      const uniqueArray = Array.from(
        new Map(updatedArray.map((item) => [item.id, item])).values()
      );

      this.setState((prevState) => ({
        closingSelectedRows: uniqueArray,
      }));
    } else {
      // Combine the current selected rows with the new rows
      const combinedArray = [...closingSelectedRows, ...closingSelectedRow];

      // Filter out duplicates based on `id` using a Map
      const uniqueArray = Array.from(
        new Map(combinedArray.map((item) => [item.id, item])).values()
      );

      this.setState((prevState) => ({
        closingSelectedRows: uniqueArray,
      }));
    }
  };

  _selectAllClosing = (value) => {
    const { closingList } = this.state;
    this.setState({
      selectAll: value,
      closingSelectedRows: value ? closingList : [],
    });
  };

  _filterOnChange = (type, value) => {
    console.log(type, value);
    const { filters, teams } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      if (type === "client") {
        this._comapanyGetAllClientTeams(value);
        // return;
      }
      this._paginate();
    });
    if (type === "_clientAssistantTeam") {
      this._filterInvoicingClosing(value);
    }
  };

  _filterInvoicingClosing = (value = "") => {
    const { teams } = this.state;

    let team = teams.data.find((item) => {
      // console.log(item);
      return String(item._id) === String(value);
    });
    // console.log(team)
    if (!team) {
      team = teams.data;
    } else {
      team = [team];
    }
    // console.log(team)
    let emailId = team.flatMap((teamItem) =>
      teamItem.teamMembers
        .map((teamMember) => {
          if (teamMember.roles.includes(2) || teamMember.roles.includes(5)) {
            // console.log(teamMember)
            return teamMember._user?.email || teamMember.email;
          }
        })
        .filter((email) => email !== undefined)
    );

    // Remove duplicate emails
    emailId = [...new Set(emailId)];

    console.log("first", emailId);
    // let payloadEmail = emailId;
    //  emailId?.isSignedUp
    //   ? emailId?.accountingEmail || undefined
    //   : emailId?.accountingEmail?.length
    //   ? emailId?.accountingEmail
    //   : emailId?.email;

    let email = emailId ? emailId : [];

    this.setState({ chips: email });
    this._setSelectedRows([]);
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          client: "",
          status: "",
        },
        dateRangeValue: null,
        isInvoiceActive: false,
        isDoneInvoice: false,
        // selectAll: false,
      },
      () => {
        this._paginate();
      }
    );
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  _onChangeInvoiceActive = () => {
    this.setState({ isPendingInvoice: !this.state.isPendingInvoice }, () => {
      this._paginate();
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "closingNumber": {
        return cell ? cell : "N/A";
      }
      case "appointmentDate": {
        return cell ? (
          <>
            {/* {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}{" "} */}
            {formatDateAsPerTimeZOne(cell, row.closingAddress.timeZone)}{" "}
            <span className="mr-1">
              {getTimeZoneAbbr(cell, row.closingAddress.timeZone)}
            </span>
          </>
        ) : (
          "N/A"
        );
      }
      case "invoice": {
        // return row ? row?._companyInvoice?.invoiceNumber || "N/A" : "N/A";
        return row?._companyInvoice?.length
          ? row?._companyInvoice.map((item) => item.invoiceNumber)
          : "N/A";
      }
      case "_borrower": {
        // return cell && cell.name && cell.name.full
        //   ? capitalize(cell.name.full)
        //   : "N/A";
        // return (
        //   <div>
        //     {/* show the below span only when there are more than 1 signers  */}
        //     John Doe <span id="PopoverLegacy" className="themeColor cursorPointer" style={{fontWeight: '600'}}>+2</span>
        //     <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy" className="signerPopover">
        //       <PopoverHeader>Signers</PopoverHeader>
        //       <PopoverBody>
        //         <p>Sam Doe</p>
        //         <p>Luis Suarez</p>
        //       </PopoverBody>
        //     </UncontrolledPopover>
        //   </div>
        // )
        return (
          <div>
            {/* show the below span only when there are more than 1 signers  */}
            {cell ? capitalize(cell[0]?.name?.full) : "N/A"}{" "}
            {cell && cell?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="text-primary cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{cell.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {cell.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }
      case "amount": {
        return row?._income[0]?.duePayment
          ? formatCurrencyValue(row?._income[0]?.duePayment)
          : "$0";
      }
      case "status": {
        // return cell ? row.status : "N/A";
        return cell ? (
          <Badge
            color={
              cell === "pending" || cell === "CCA" || cell === "DNC"
                ? "warning"
                : cell === "Closed" || cell === "Cancelled"
                ? "success"
                : "danger"
            }
            className="outline"
            pill
          >
            {cell}
          </Badge>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row.companyDncFee ? (
          <span>${row.companyDncFee}</span>
        ) : row.status === "DNC" ? (
          <>
            <div className="d-flex">
              <Input
                type="text"
                name="amount"
                style={{ height: 30, width: 100 }}
                // value={
                //   this.state.incomeObj.length > 0
                //     ? this.state.incomeObj.find((item) => item.id === row._id)
                //         ?.paymentAmount || ""
                //     : ""
                // }
                // disabled={row.status !== "DNC"}
                onChange={(event) =>
                  this._onChangeFormField(event.target.value)
                }
              />
              <Button
                color="link"
                disabled={row.status !== "DNC"}
                onClick={() => this._updateCompanyDncFee(row)}
              >
                Update
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _onChangeFormField = (value) => {
    this.setState({ companyDncFee: value });
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateCompanyDncFee = async (row) => {
    console.log(row);
    try {
      this._manageLoading("createClosingLoading", true);

      await this._updateClosingDetailsById(row.id, {
        companyDncFee: this.state.companyDncFee,
      });

      showToast("Status Updated Successfully", "success");
      this._getAllClosingForCreatingInvoice();
      this._manageLoading("createClosingLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("createClosingLoading", false);
    }
  };

  _createInvoice = () => {
    const {
      closingSelectedRows,
      dateRangeValue,
      isPendingInvoice,
      filters,
      selectAll,
    } = deepClone(this.state);

    if (closingSelectedRows?.length) {
      this._manageLoading("createClosingLoading", true);
      const closingId = closingSelectedRows?.map((each) => each?.id);

      let payload = {
        closingId,
        _clientAssistantTeam: filters?._clientAssistantTeam || "",
        emails: this.state.chips,
        selectAll: selectAll ? true : undefined,
        filters: {},
      };
      if (dateRangeValue && dateRangeValue.length) {
        payload.filters["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
        payload.filters["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }

      // if (isDoneInvoice) payload.filters["isDoneInvoice"] = isDoneInvoice;
      if (isPendingInvoice)
        payload.filters["isPendingInvoice"] = isPendingInvoice;

      if (filters.client) payload.filters["clientId"] = filters.client;

      if (filters.status) payload.filters["status"] = filters.status;
      if (filters._clientAssistantTeam)
        payload.filters["_clientAssistantTeam"] = filters._clientAssistantTeam;

      if (!selectAll) {
        delete payload.filters;
      }

      createInvoiceCompany(payload)
        .then((res) => {
          this.props.resetDetails();
          showToast("Invoice Created", "success");
          this._closeModal();
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("createClosingLoading", false);
        });
    } else {
      showToast("Select at least one closing", "error");
    }
  };

  _downloadData = (action = "email") => {
    const { closingList, dateRangeValue, filters, isInvoiceActive } = deepClone(
      this.state
    );

    if (!closingList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
      action,
    };

    if (filters.client) payload["clientId"] = filters.client;
    else {
      showToast("Please select client from filter", "error");
      return;
    }
    if (filters.status) payload["status"] = filters.status;

    if (dateRangeValue && dateRangeValue.length) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (isInvoiceActive) payload["isDoneInvoice"] = isInvoiceActive;

    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/signingcompany/mail/invoices${queryParams}`;

    if (action === "email") {
      this._manageLoading("isEmailLoading", true);
      makeGetRequest(apiUrl, true)
        .then((res) => {
          this._manageLoading("isEmailLoading", false);
          showToast("Email sent successfully.", "success");
        })
        .catch((error) => {
          console.log("error>>", error);
          this._manageLoading("isEmailLoading", false);
          errorHandler(error);
        });
    }
  };

  _isValidEmail = (value) => {
    console.log(value);
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      console.log("into");
      return true;
    } else {
      console.log("into else");
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _onChipSelect = (chips, arrName = "chips") => {
    // console.log("chips >>", chips);
    // console.log("chips length >", chips.length);
    // console.log("chips[1] >", chips[0]);
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({ chips: chips });
    }
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?._companyInvoice?.length) {
      return { backgroundColor: "#6bd098" };
    }
    return {};
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="cardTableTitle">
              <Label>Signer: </Label>
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                {
                  <>
                    {/* show the below span only when there are more than 1 signers  */}
                    {row?._borrower
                      ? capitalize(row?._borrower[0]?.name?.full)
                      : "N/A"}{" "}
                    {row?._borrower && row?._borrower?.length > 1 && (
                      <>
                        <span id={`PopoverLegacy${row._id}`}>
                          +{row?._borrower?.length - 1}
                        </span>
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="bottom"
                          target={`PopoverLegacy${row._id}`}
                          className="signerPopover"
                        >
                          <PopoverHeader>Signers</PopoverHeader>
                          <PopoverBody>
                            {row?._borrower?.map((item) => (
                              <p>{capitalize(item.name.full)}</p>
                            ))}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                    )}
                  </>
                }
              </div>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Order#: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingNumber ? row?.closingNumber : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Date: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.appointmentDate ? (
                <>
                  {/* {formatDateHideYearIfSame(row?.appointmentDate)} <br /> {formatTime(row?.appointmentDate)}{" "} */}
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}{" "}
                  <span className="mr-1">
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row?.closingAddress?.timeZone
                    )}
                  </span>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Invoice: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._companyInvoice?.length
                ? row?._companyInvoice?.map((item) => item?.invoiceNumber)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._income[0]?.duePayment
                ? formatCurrencyValue(row?._income[0]?.duePayment)
                : "$0"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Company Dnc Fee: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.companyDncFee ? (
                <span>${row?.companyDncFee}</span>
              ) : row?.status === "DNC" ? (
                <>
                  <div className="d-flex">
                    <Input
                      type="text"
                      name="amount"
                      style={{ height: 30, width: 100 }}
                      // value={
                      //   this.state.incomeObj.length > 0
                      //     ? this.state.incomeObj.find((item) => item.id === row._id)
                      //         ?.paymentAmount || ""
                      //     : ""
                      // }
                      // disabled={row.status !== "DNC"}
                      onChange={(event) =>
                        this._onChangeFormField(event.target.value)
                      }
                    />
                    <Button
                      color="link"
                      disabled={row.status !== "DNC"}
                      onClick={() => this._updateCompanyDncFee(row)}
                    >
                      Update
                    </Button>
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            {row?.status ? (
              <Badge
                color={
                  row?.status === "pending" ||
                  row?.status === "CCA" ||
                  row?.status === "DNC"
                    ? "warning"
                    : row?.status === "Closed" || row?.status === "Cancelled"
                    ? "success"
                    : "danger"
                }
                className="outline"
                pill
              >
                {row?.status}
              </Badge>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  _comapanyGetAllClientTeams = async (clientId) => {
    const { filters } = deepClone(this.state);
    try {
      // const { id } = this.props.match.params;

      if (!filters.client.trim().length > 0) {
        return;
      }

      const payload = {
        clientId,
      };

      const res = await comapanyGetAllClientTeams(payload);
      this.setState(
        {
          teams: {
            data: res?.assistantTeams || [],
          },
          // isEditable: res?.client?.isEditable || false,
        },
        () => {
          this._filterInvoicingClosing();
        }
      );
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const {
      dateRangeValue,
      filters,
      isInvoiceActive,
      closingList,
      closingTotalCount,
      closingHeaderKeys,
      loading,
      tableConfig,
      selectedRows,
      closingSelectedRows,
      closingCardHeaderKeys,
      teams,
      isPendingInvoice,
      selectAll,
    } = this.state;

    const { isOpen, clients } = this.props;
    console.log("qw", this.state);
    return (
      <Modal
        isOpen={isOpen}
        toggle={this._closeModal}
        className="modal-xl modal-dialog-scrollable"
      >
        <ModalHeader toggle={this._closeModal}>Create Invoice</ModalHeader>
        <ModalBody>
          <div className="filterContainer responsiveView">
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <Label>Client</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="client"
                    value={filters.client}
                    onChange={(e) =>
                      this._filterOnChange("client", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {/* {(client.name &&
                      client.name.full &&
                      capitalize(client.name.full)) ||
                      "N/A Company"} */}
                        {(client?.companyName
                          ? capitalize(client.companyName)
                          : capitalize(client.name.full)) || "N/A Client"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>

              <FormGroup>
                <Label>Client Assistant</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="client"
                    value={filters._clientAssistantTeam}
                    onChange={(e) =>
                      this._filterOnChange(
                        "_clientAssistantTeam",
                        e.target.value
                      )
                    }
                  >
                    <option value="">All</option>
                    {/* {teams?.data?.map((assistent) => (
                      <option key={assistent.id} value={assistent.id}>
                         {assistent?.name?.full}
                      </option>
                    ))} */}
                    {teams?.data?.length > 0 &&
                      teams?.data?.map((item) => (
                        <option value={item._id}>{item?.teamName}</option>
                      ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.status}
                    name="status"
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((obj) =>
                      obj.value === "Pending" ? null : (
                        <option key={obj.value} value={obj.value}>
                          {obj.label}
                        </option>
                      )
                    )}
                  </Input>
                </div>
              </FormGroup>
              {/* <FormGroup>
                <Label>Already Invoiced</Label>
                <CustomInput
                  type="switch"
                  checked={isInvoiceActive}
                  onChange={this._onChangeInvoiceActive}
                  id="isInvoiceActive_Custom_Switch"
                  name="isInvoiceActive"
                />
              </FormGroup> */}
              <FormGroup>
                <Label>Invoice Pending</Label>
                <CustomInput
                  type="switch"
                  checked={isPendingInvoice}
                  onChange={this._onChangeInvoiceActive}
                  id="isDoneInvoice_Custom_Switch"
                  name="isPendingInvoice"
                />
              </FormGroup>
              {/* <Button className="resetBtn mr-0" onClick={this._resetFilter}>
                  <i className="fa fa-refresh" />
                </Button> */}
            </div>
          </div>

          {/* <div>
            <Label>Email:</Label>
            <Chips
              style={{ width: "100%" }}
              value={this.state.chips}
              onChange={(arr) => this._onChipSelect(arr, "chips")}
              getChipValue={(e) => console.log(e)}
              createChipKeys={[9, 13]} //  Key codes to add chips
              placeholder={"Add email.."}
            />
          </div> */}

          {closingList.length && closingSelectedRows?.length ? (
            <div style={{ padding: 12 }}>
              {/* when the checkboxes in the below table are selected/checked 
						  then below text is to be shown to indicate the count selected */}
              {/* {selectedRows.length}{" "}
              {selectedRows.length > 1 ? "Records" : "Record"} are selected. */}
              {this.state.selectAll
                ? closingTotalCount
                : closingSelectedRows.length}{" "}
              Records are selected.
            </div>
          ) : null}

          {closingList.length > 0 && (
            <CustomInput
              className="ml-4 mb-2"
              id={`all_selected`}
              label="Selected All"
              type="checkbox"
              onChange={(e) => this._selectAllClosing(e.target.checked)}
              checked={selectAll}
            />
          )}

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={closingList}
              headerKeys={closingCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              columnSizeMD={12}
              dataFormat={this._dataFormat}
              totalCount={closingTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={true}
              selectedRows={closingSelectedRows}
              setSelectedRows={(selectedRows) =>
                this._setSelectedRows(selectedRows)
              }
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {closingHeaderKeys && closingHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                pageSize={tableConfig.limit}
                rowSelection={true}
                tableData={closingList}
                headerKeys={closingHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={closingTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  this._onSortChange(sortName, sortOrder)
                }
                rowStyleFormat={(row, rowIdx) =>
                  this._rowStyleFormat(row, rowIdx)
                }
                selectedRows={closingSelectedRows}
                setSelectedRows={(selectedRows) =>
                  this._setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
                disableRowSelection={selectAll ? true : false}
              />
            )}
          </div>

          {/* the below div will be shown only when above checkboxes is/are selected */}

          {/* add a tags plugin plugin below in place of the input field */}
          {/* <Input
              type="textarea"
              name="text"
              id="exampleText"
              placeholder="Enter"
              rows="3"
            /> */}

          <FormGroup className="mt-3 ChipsWrap floatingLabel valueAdded">
            <Chips
              value={this.state.chips}
              onChange={(arr) => this._onChipSelect(arr, "chips")}
              getChipValue={(e) => console.log(e)}
              createChipKeys={[9, 13]} //  Key codes to add chips
              placeholder={"+Add Email"}
            />
            <Label> Send Email To</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" outline onClick={this._closeModal}>
            Close
          </Button>
          <Button
            color="primary"
            size="lg"
            disabled={loading.createClosingLoading}
            onClick={this._createInvoice}
          >
            {loading.createClosingLoading ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}{" "}
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateInvoiceModal;
